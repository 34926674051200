import { ITenantUser } from 'api/models/tenantAccount.model';
import { map } from 'lodash';
import { createSelector } from 'reselect';
import { RootState } from 'state/store';

export const selectUsers = (state: RootState) => state.users;
export const showActiveOnly = (state: RootState) => state.users.showActiveOnly;
export const selectUsersAsList = (state: RootState) =>
    map(state.users.data, (user) => {
        return user;
    }).filter((user) => user !== undefined) as ITenantUser[];

export const selectFilteredUserList = createSelector([selectUsersAsList, showActiveOnly], (users, activeOnly) => {
    return users.filter((user) => (activeOnly ? user?.isDeleted !== activeOnly : users));
});
