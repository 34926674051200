import { IProcedureSearch } from 'api/models/procedure.model';
import useSelector from 'hooks/useSelector';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import {
    clearProcedures,
    getProcedures,
    proceduresSearch,
    proceduresOptionsList,
    selectProceduresData,
    selectProceduresDataAsList,
    selectProceduresSearchData,
    selectProceduresSearchDataAsList,
    selectProcedureState,
} from 'state/slices/procedures/procedures.slice';

function useProcedure() {
    const dispatch = useDispatch();

    const { loading, searchLoading } = useSelector(selectProcedureState);

    const _searchProcedures = useSelector(selectProceduresSearchData);
    const _searchProceduresAsList = useSelector(selectProceduresSearchDataAsList);
    const _proceduresOptionsList = useSelector(proceduresOptionsList);

    const _proceduresData = useSelector(selectProceduresData);
    const _proceduresDataAsList = useSelector(selectProceduresDataAsList);

    const _proceduresSearch = useCallback(
        (query: IProcedureSearch) => {
            dispatch(proceduresSearch({ query }));
        },
        [dispatch],
    );

    const _getProcedures = useCallback(() => {
        dispatch(getProcedures());
    }, [dispatch]);

    const _clearProcedures = useCallback(() => {
        dispatch(clearProcedures());
    }, [dispatch]);

    return {
        loading,
        searchLoading,

        searchProcedures: _searchProcedures,
        searchProceduresAsList: _searchProceduresAsList,
        proceduresOptionsList: _proceduresOptionsList,

        proceduresData: _proceduresData,
        proceduresDataAsList: _proceduresDataAsList,

        getProcedures: _getProcedures,
        proceduresSearch: _proceduresSearch,
        clearProcedures: _clearProcedures,
    };
}

export default useProcedure;
