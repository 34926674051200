import { SearchBox, Stack, Toggle } from '@fluentui/react';
import { useSelector } from 'hooks';
import { useDispatch } from 'react-redux';
import { showActiveOnly } from 'state/slices/users/users.selectors';
import { setShowActiveOnly } from 'state/slices/users/users.slice';

type UserFilterProps = {
    searchTerm: string;
    placeholder: string;
    onSearchChange: (value: string) => void;
};

const UserFilter = ({ searchTerm, placeholder, onSearchChange }: UserFilterProps) => {
    const dispatch = useDispatch();

    const _showActiveOnly = useSelector(showActiveOnly);

    const toggleActive = (checked: boolean) => {
        dispatch(setShowActiveOnly(checked));
    };

    return (
        <Stack styles={{ root: { paddingBottom: 10 } }} tokens={{ childrenGap: 10 }} horizontal verticalAlign="center">
            <Stack grow horizontalAlign="end">
                <SearchBox placeholder={placeholder} value={searchTerm} onChange={(ev, value) => onSearchChange(value ?? '')} />
            </Stack>

            <Stack verticalAlign="center">
                <Toggle
                    checked={_showActiveOnly}
                    inlineLabel
                    label="Active Only"
                    onChange={(_, checked) => {
                        toggleActive(checked as boolean);
                    }}
                />
            </Stack>
        </Stack>
    );
};

export default UserFilter;
