import membershipApi from 'api/membership.api';
import ITenant from 'api/models/tenant.model';
import { FeatureFlag } from './feature-flags.slice';
import { ITenantConfig, ITenantConfigSetups } from './model';

export const tenantApi = {
    getById,
    getTenantSubscriptions,
    getTenantConfig,
    updateConfig,
    getFeatureFlagsForTenant,
};

function getById(tenantId: string) {
    return membershipApi.client.get<ITenant>(`Tenants/${tenantId}`);
}
function getTenantSubscriptions(tenantId: string) {
    return membershipApi.client.get(`Tenants/${tenantId}/Subscriptions`);
}

function getTenantConfig(tenantId: string) {
    return membershipApi.client.get<ITenantConfig>(`TenantConfigViews/${tenantId}`);
}

function updateConfig(tenantConfig: ITenantConfigSetups) {
    return membershipApi.client.post<ITenantConfigSetups>(`TenantSetups/Change`, tenantConfig);
}
function getFeatureFlagsForTenant(tenantId: string) {
    return membershipApi.client.get<FeatureFlag[]>(`FeatureFlags/Tenant/${tenantId}`);
}
