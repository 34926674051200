import { AppointmentCancellationReasonState } from './state';
import {
    getAppointmentCancellationsReasons,
    refreshAppointmentCancellationsReasons,
} from './actions';
import { LoadingStatus } from 'interfaces/loading-statuses';
import { ExtraReducer } from 'state/store';

export const appointmentCancellationReasonsExtraReducers: ExtraReducer<AppointmentCancellationReasonState> = (builder) =>
    builder
        .addCase(getAppointmentCancellationsReasons.pending, (state) => {
            state.loading = LoadingStatus.Idle;
        })
        .addCase(getAppointmentCancellationsReasons.fulfilled, (state, action) => {
            state.data = action.payload;
            state.loading = LoadingStatus.Completed;
        })
        .addCase(getAppointmentCancellationsReasons.rejected, (state) => {
            state.loading = LoadingStatus.Failed;
        })
        .addCase(refreshAppointmentCancellationsReasons.pending, (state) => {
            state.loading = LoadingStatus.Idle;
        })
        .addCase(refreshAppointmentCancellationsReasons.fulfilled, (state, action) => {
            state.data = { ...state.data, ...action.payload };
            state.loading = LoadingStatus.Completed;
        })
        .addCase(refreshAppointmentCancellationsReasons.rejected, (state) => {
            state.loading = LoadingStatus.Failed;
        });
