import { ScrollablePane, ScrollbarVisibility, Spinner, SpinnerSize, Stack } from '@fluentui/react';
import { Section } from 'components';
import useSlidingFee from 'hooks/store/useSlidingFee';
import { LoadingStatus } from 'interfaces/loading-statuses';
import ProgramPlanPanel from './components/ProgramPlanPanel';
import SlidingFeesDetailsList from './components/SlidingFeeDetailsList';
import SlidingFeesToolbar from './components/SlidingFeeToolbar';
import ManageProgram from './ManageProgram';

function ManageSlidingFee() {
    const { selectedProgramLoading } = useSlidingFee();

    return (
        <>
            {selectedProgramLoading === LoadingStatus.Pending ? (
                <Spinner style={{ marginBottom: 12 }} size={SpinnerSize.large} label="Loading plans..." labelPosition="right" />
            ) : (
                <Stack style={{ position: 'relative', display: 'flex', flex: 1 }}>
                    <ScrollablePane scrollbarVisibility={ScrollbarVisibility.auto}>
                        <Section heading="Details">
                            <Stack tokens={{ childrenGap: 10 }}>
                                <ManageProgram />
                            </Stack>
                        </Section>
                        <Section heading={'Plans'} headingRightContent={<SlidingFeesToolbar />}>
                            <Stack tokens={{ childrenGap: 10 }}>
                                <SlidingFeesDetailsList />
                            </Stack>
                        </Section>
                    </ScrollablePane>
                </Stack>
            )}
            <ProgramPlanPanel />
        </>
    );
}

export default ManageSlidingFee;
