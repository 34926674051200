import './NoMatch.scss';
import { Helmet } from 'react-helmet';
import { useHistory } from 'react-router';
import { Stack } from '@fluentui/react';
import { ActionButton, TText } from 'components';
import { useRouteMatch } from 'react-router-dom';

function NoMatch() {
    const { push } = useHistory();
    const match = useRouteMatch<{ tenantId: string }>('/:tenantId');
   
    function getCustomerId() {
        return match ? match.params.tenantId : '';
    }

    const onHomeClick = () => {
        const item = getCustomerId();

        if(item) {
            push(`/${item}/`);
        } else {
            push('/');
        }
    };

    // add theme props to get primary colors for main stack bg
    return (
        <Stack
            verticalAlign="center"
            horizontalAlign="center"
            styles={{
                root: {
                    background:
                        'radial-gradient( circle farthest-corner at 50.5% 50.1%,  rgba(0,112,218,1) 0%, rgba(15,27,49,1) 90% )',
                },
            }}
            grow
        >
            <Helmet>
                <title>Unknown URL</title>
            </Helmet>
            <Stack
                horizontalAlign="center"
                styles={{
                    root: {
                        maxWidth: 500,
                        textAlign: 'center',
                        color: '#FFF !important',
                        textShadow: '0 3px 3px rgba(0,0,0,0.25)',
                    },
                }}
                tokens={{ childrenGap: 10 }}
            >
                <TText variant="xxLargePlus" color="white" bold>
                    404 - PAGE NOT FOUND
                </TText>
                <TText variant="xxLargePlus" color="white">
                    The page you were looking for does not exist.
                </TText>
                <Stack horizontal tokens={{ childrenGap: 10 }}>
                    <ActionButton onClick={onHomeClick}>Home</ActionButton>
                    <ActionButton>Try Again?</ActionButton>
                </Stack>
            </Stack>
        </Stack>
    );
}
export default NoMatch;
