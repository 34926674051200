import React, { ReactNode } from 'react';
import { styled, IStyleFunctionOrObject, ITheme, Stack, StackItem } from '@fluentui/react';
import TText from './TText';
import { ActionButton } from 'components';
import { IActionButton } from './ActionButton';

type Props = {
    theme?: ITheme;
    styles?: IStyleFunctionOrObject<any, any>;
    heading: string | JSX.Element;
    style?: React.CSSProperties;
    sectionButtons?: IActionButton[];
    headingCenterContent?: ReactNode;
    headingRightContent?: ReactNode;
};

const ActivitySubHeading = (props: Props) => {
    const headerProperties: React.CSSProperties = {
        padding: '5px 10px 0 10px',
        backgroundColor: props.theme?.palette.neutralLighterAlt,
        display: 'flex',
        alignItems: 'center',
        minHeight: 50,
    };

    const _hasSectionButtons = props.sectionButtons && props.sectionButtons.length;
    const _sectionButtons = _hasSectionButtons
        ? props.sectionButtons?.map((button, index) => {
              return <ActionButton {...button} key={index} />;
          })
        : null;

    return (
        <Stack style={{ ...headerProperties }} horizontal tokens={{ childrenGap: 20 }}>
            <StackItem>
                <TText variant="large" style={{ ...props.style! }} block>
                    {props.heading}
                </TText>
            </StackItem>
            <StackItem grow={1}>
                <Stack>{props.headingCenterContent}</Stack>
            </StackItem>
            <StackItem>
                <Stack
                    horizontal
                    style={{ justifyContent: 'flex-end', display: 'flex' }}
                    tokens={{ childrenGap: 10 }}
                >
                    {_sectionButtons}
                    {props.headingRightContent}
                </Stack>
            </StackItem>
        </Stack>
    );
};

export default styled<Props, any, any>(ActivitySubHeading, {});
