import ReactDOM from 'react-dom';
import Root from './Root';
import './index.scss';
import * as serviceWorker from './serviceWorker';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { history } from 'state/store';

const instrumentationKey =
    process.env.NODE_ENV === 'production' ? '#{AIInstrumentationKey}#' : '9429eb9e-5a4e-4beb-85ca-31850bcb6159';

if (instrumentationKey) {
    const reactPlugin = new ReactPlugin();
    const appInsights = new ApplicationInsights({
        config: {
            instrumentationKey,
            enableAutoRouteTracking: true,
            extensions: [reactPlugin],
            extensionConfig: {
                [reactPlugin.identifier]: { history },
            },
        },
    });
    appInsights.loadAppInsights();
    appInsights.trackPageView();
}

// eslint-disable-next-line react/no-render-return-value
const render = () => ReactDOM.render(<Root />, document.getElementById('root'));

render();
// reportWebVitals(console.log);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
