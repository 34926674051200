import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';
import membershipApi from 'api/membership.api';
import IServiceUserAccount, { GlobalPermissions } from 'api/models/account.model';
import { AxiosResponse } from 'axios';
import { LoadingStatus, LoadingStatuses } from 'interfaces/loading-statuses';
import { map } from 'lodash';
import { RootState } from 'state/store';

type AccountState = {
    loading: LoadingStatuses;
    data?: IServiceUserAccount;
};

const initialState: AccountState = {
    loading: LoadingStatus.Idle,
};

export const getAccount = createAsyncThunk<
    AxiosResponse<IServiceUserAccount>,
    void,
    {
        state: RootState;
        dispatch: any;
    }
    // eslint-disable-next-line no-shadow-restricted-names
>('getAccount', async (undefined, thunkApi) => {
    const res = await membershipApi.getAccount();
    return res;
});

const accountSlice = createSlice({
    name: 'account',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getAccount.pending, (state) => {
                state.loading = LoadingStatus.Pending;
            })
            .addCase(getAccount.fulfilled, (state, action) => {
                state.loading = LoadingStatus.Completed;
                state.data = action.payload.data;
            })
            .addCase(getAccount.rejected, (state, action) => {
                state.loading = LoadingStatus.Failed;
            });
    },
});

export const selectAccount = (state: RootState) => state.account;
export const selectAccountTenantsData = createSelector(selectAccount, (account) => account.data?.tenants ?? {});
export const selectAccountTenantsAsList = createSelector(selectAccountTenantsData, (data) => map(data));
export const selectIsAccountGlobalAdmin = (state: RootState) =>
    state.account?.data?.identity.systemRoles
        ? state.account.data.identity.systemRoles.indexOf(GlobalPermissions.GlobalAdmin) > -1
        : false;

const { reducer } = accountSlice;
export default reducer;
