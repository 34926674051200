import { PrimaryButton, Stack, Toggle } from '@fluentui/react';
import { ISlidingFeePlan } from 'api/models/slidingFee.model';
import { useSelector } from 'hooks';
import useSlidingFee from 'hooks/store/useSlidingFee';
import { useDispatch } from 'react-redux';
import { selectSelectedProgramPlanActive, toggleExpiredPlans } from 'state/slices/sliding-fee/sliding-fee.slice';
import { v4 as uuid } from 'uuid';

export default function SlidingFeesToolbar() {
    const { setSelectedProgramPlan, toggleIsProgramPlanPanelOpen } = useSlidingFee();
    const createPlan = () => {
        setSelectedProgramPlan(newProgramPlan(), true);
        toggleIsProgramPlanPanelOpen();
    };
    const togglePlan = useSelector(selectSelectedProgramPlanActive);

    const dispatch = useDispatch();
    return (
        <Stack horizontal verticalAlign="end" tokens={{ childrenGap: 10 }} grow>
            <Toggle
                styles={{ root: { marginBottom: 2, minWidth: 150 } }}
                checked={togglePlan}
                inlineLabel
                label="Active Only"
                onChange={() => {
                    dispatch(toggleExpiredPlans());
                }}
            />
            <PrimaryButton text="Add Plan" iconProps={{ iconName: 'Add' }} onClick={createPlan} />
        </Stack>
    );
}

function newProgramPlan(): ISlidingFeePlan {
    return {
        id: uuid(),
        name: '',
        adjustmentType: '',
        calculationType: '',
        codeCategoryExclusions: [],
        codeExclusions: [],
        codeRangeExclusions: [],
        effectiveDate: '',
        expirationDate: '',
        flatFee: 0,
        maxFee: 0,
        minFee: 0,
        patientPercentage: 0,
        povertyPercentageFrom: 0,
        povertyPercentageTo: 0,
    };
}
