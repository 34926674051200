import { IContextualMenuProps, Link, Stack, Text } from '@fluentui/react';
import { IconContextualMenu } from './IconContextualMenu';

export function EditDetailsColumn({
    title: displayField,
    menuProps,
    editOnClick: onClick,
}: {
    menuProps?: IContextualMenuProps;
    title: string;
    editOnClick?: () => void;
}) {
    const _title = onClick ? (
        <Link onClick={onClick} title={displayField} style={{ textOverflow: 'ellipsis', overflow: 'hidden', width: '95%' }}>
            {displayField}
        </Link>
    ) : (
        displayField
    );
    return (
        <Stack horizontal>
            <Stack grow={1} style={{ width: '100%', textOverflow: 'ellipsis' }}>
                <Text nowrap variant="smallPlus" style={{ textOverflow: 'ellipsis' }}>
                    {_title}
                </Text>
            </Stack>
            {menuProps && (
                <Stack
                    grow
                    horizontalAlign="end"
                    verticalAlign="center"
                    style={{
                        position: 'absolute',
                        right: 0,
                        height: '100%',
                        top: 0
                    }}
                >
                    <IconContextualMenu {...menuProps} />
                </Stack>
            )}
        </Stack>
    );
}
