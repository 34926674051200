import React from 'react';
import './Loading.scss';
import logo from '../../../logo.svg';
import {
    Spinner,
    SpinnerSize,
    Stack,
    styled,
    IStyleFunctionOrObject,
    ITheme,
} from '@fluentui/react';
import { useSelector } from 'hooks';

interface IComponentProps {
    text?: string;
    theme?: ITheme;
    styles?: IStyleFunctionOrObject<any, any>;
}

function Loading(props: IComponentProps) {
    const { selectedTheme } = useSelector((state) => state.ui);
    const { text, theme } = props;

    return (
        <div className="loading-wrapper">
            <Stack
                className="ms-depth-64"
                style={{ height: 60, display: 'flex' }}
                horizontal
                verticalAlign="center"
            >
                <div
                    style={{
                        backgroundColor:
                            selectedTheme === 'light'
                                ? theme?.palette.themePrimary
                                : theme?.palette.themeTertiary,
                        position: 'relative',
                        display: 'flex',
                        width: 60,
                        flex: 1,
                        height: '100%',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                    <Spinner size={SpinnerSize.large} />
                    <img
                        style={{ position: 'absolute' }}
                        src={logo}
                        width="25px"
                        height="25px"
                        alt=""
                    />
                </div>
                <div
                    style={{
                        backgroundColor: theme?.palette.white,
                        display: 'flex',
                        flex: 1,
                        height: '100%',
                        alignItems: 'center',
                        paddingLeft: 10,
                        paddingRight: 10,
                    }}
                >
                    {text ? text : 'Loading...'}
                </div>
            </Stack>
        </div>
    );
}

export default styled<IComponentProps, any, any>(Loading, {});
