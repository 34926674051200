import { useSelector } from 'react-redux';
import TooltipCardsPortal from './TooltipCardsPortal';
import React from 'react';
import TooltipCard from './TooltipCard';
import { RootState } from 'state/store';

interface IComponentProps {
  domNode: any;
}

const TooltipCards = (props: IComponentProps) => {
  const { tooltipCards } = useSelector((state: RootState) => state.ui);

  const renderCards = tooltipCards.map((card, index) => (
    <TooltipCardsPortal domNode={props.domNode} key={index}>
      <TooltipCard item={card} />
    </TooltipCardsPortal>
  ));
  return <div>{renderCards}</div>;
};

export default TooltipCards;
