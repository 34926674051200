import { AnyAction, createAsyncThunk, createSlice, Dictionary, PayloadAction, ThunkDispatch } from '@reduxjs/toolkit';
import membershipApi from 'api/membership.api';
import ILocationOfCare from 'api/models/locationsOfCare.model';
import axios from 'axios';
import { LoadingStatus } from 'interfaces/loading-statuses';
import { isEqual, map } from 'lodash';
import { AppThunk, RootState } from 'state/store';
import { getUserTasksByTarget } from '../tasks/actions';
import { UserTaskTarget } from '../tasks/model';

export const getAllLocationsOfCare = createAsyncThunk<Dictionary<ILocationOfCare>, { tenantId: string }, { rejectValue: string }>(
    'locationsOfCare/getAllLocationsOfCare',
    async ({ tenantId }, { rejectWithValue }) => {
        try {
            const request = await membershipApi.getAllLocationsOfCare(tenantId);
            return request.data;
        } catch (err) {
            return rejectWithValue(err as string);
        }
    },
);

export const getLocationOfCareById = createAsyncThunk<
    ILocationOfCare,
    { tenantId: string; locationOfCareId: string },
    { rejectValue: string }
>('locationsOfCare/getLocationOfCareById', async ({ tenantId, locationOfCareId }, { rejectWithValue }) => {
    try {
        const request = await membershipApi.getLocationOfCareById(tenantId, locationOfCareId);
        return request.data;
    } catch (err) {
        return rejectWithValue(err as string);
    }
});

export const createLocationOfCare = createAsyncThunk<
    ILocationOfCare,
    { tenantId: string; locationOfCare: ILocationOfCare },
    { rejectValue: string }
>('locationsOfCare/createLocationOfCare', async ({ tenantId, locationOfCare }, { rejectWithValue }) => {
    try {
        const request = await membershipApi.createLocationOfCare(tenantId, locationOfCare);
        return request.data;
    } catch (err) {
        return rejectWithValue(err as string);
    }
});

export const updateLocationOfCare = createAsyncThunk<
    ILocationOfCare,
    { tenantId: string; locationOfCare: ILocationOfCare },
    { rejectValue: string }
>('locationsOfCare/updateLocationOfCare', async ({ tenantId, locationOfCare }, { rejectWithValue }) => {
    try {
        const request = await membershipApi.updateLocationOfCare(tenantId, locationOfCare);
        return request.data;
    } catch (err) {
        return rejectWithValue(err as string);
    }
});

export const updateLocationsOfCare = createAsyncThunk<
    ILocationOfCare[],
    { tenantId: string; locationsOfCare: ILocationOfCare[] },
    { rejectValue: string }
>('locationsOfCare/updateLocationsOfCare', async ({ tenantId, locationsOfCare }, { rejectWithValue }) => {
    try {
        const requests = locationsOfCare.map((locationOfCare) => membershipApi.updateLocationOfCare(tenantId, locationOfCare));
        const response = await axios.all(requests);
        return response.map((res) => res.data);
    } catch (err) {
        return rejectWithValue(err as string);
    }
});

export const refreshLocationsOfCare = createAsyncThunk<
    Dictionary<ILocationOfCare>,
    { tenantId: string },
    { rejectValue: string }
>('locationsOfCare/refreshLocationsOfCare', async ({ tenantId }, { rejectWithValue }) => {
    try {
        const request = await membershipApi.refreshLocationsOfCare(tenantId);
        const filteredRequest = map(request.data).filter((item) => !item?.isDeleted);

        const dictionary = Object.assign({ ...filteredRequest });

        return dictionary;
    } catch (err) {
        return rejectWithValue(err as string);
    }
});

let timer: NodeJS.Timeout | null = null;
let locsToUpdate: ILocationOfCare[] = [];

export const autoUpdateLocationOfCare =
    (tenantId: string, loc: ILocationOfCare) =>
    (dispatch: ThunkDispatch<RootState, null, AnyAction>): void => {
        const indexOfLOC = locsToUpdate.findIndex((p) => p.id === loc.id);
        if (indexOfLOC === -1) {
            locsToUpdate.push(loc);
        } else {
            locsToUpdate[indexOfLOC] = loc;
        }
        if (timer) clearTimeout(timer);
        timer = setTimeout(() => {
            if (locsToUpdate.length) {
                dispatch(updateLocationsOfCare({ tenantId, locationsOfCare: locsToUpdate }));
                locsToUpdate = [];
            }
        }, 2000);
    };

export const setLocationOfCarePropAndSave =
    (tenantId: string, id: string, path: keyof ILocationOfCare, value: string | number | boolean | undefined) =>
    async (dispatch: ThunkDispatch<RootState, null, AnyAction>, getState: () => RootState): Promise<void> => {
        await dispatch(updateLocationOfCareProp({ id, path, value }));
        const data = getState().locationsOfCare.data;
        if (data) {
            const loc = data[id];
            if (loc) dispatch(autoUpdateLocationOfCare(tenantId, loc));
        }
    };

const initialState: LocationsOfCareState = {
    loading: LoadingStatus.Idle,
    saving: LoadingStatus.Idle,
};

const locationsOfCareSlice = createSlice({
    name: 'locationsOfCare',
    initialState,
    reducers: {
        setSearchResults: (state, action: PayloadAction<ILocationOfCare[]>) => {
            state.searchResults = action.payload;
        },
        setSearch: (state, action: PayloadAction<string | undefined>) => {
            state.search = action.payload ? action.payload : undefined;
        },
        updateLocationOfCareProp: (state, action: PayloadAction<{ id: string; path: keyof ILocationOfCare; value: any }>) => {
            const { path, value, id } = action.payload;
            if (state.data) (state.data as any)[id][path] = value;
        },
        setLocationCareRefresh: (state, action: PayloadAction<Dictionary<ILocationOfCare>>) => {
            state.data = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getAllLocationsOfCare.pending, (state) => {
                state.loading = LoadingStatus.Pending;
            })
            .addCase(getAllLocationsOfCare.fulfilled, (state, action) => {
                state.loading = LoadingStatus.Completed;
                state.data = action.payload;
            })
            .addCase(getAllLocationsOfCare.rejected, (state) => {
                state.loading = LoadingStatus.Failed;
            })
            .addCase(refreshLocationsOfCare.pending, (state) => {
                state.loading = LoadingStatus.Pending;
            })
            .addCase(refreshLocationsOfCare.fulfilled, (state, action) => {
                state.loading = LoadingStatus.Completed;
                state.data = { ...state.data, ...action.payload };
            })
            .addCase(refreshLocationsOfCare.rejected, (state) => {
                state.loading = LoadingStatus.Failed;
            })
            .addCase(updateLocationsOfCare.pending, (state) => {
                state.saving = LoadingStatus.Pending;
            })
            .addCase(updateLocationsOfCare.fulfilled, (state, action) => {
                const locationsOfCare = action.payload;
                locationsOfCare.forEach((location) => {
                    const locationId = location.id;
                    if (state.data && !isEqual(location, state.data[locationId])) {
                        if (state.data[locationId]) {
                            (state.data[locationId] as ILocationOfCare)._etag = location._etag;
                            (state.data[locationId] as ILocationOfCare).modifiedOn = location.modifiedOn;
                            (state.data[locationId] as ILocationOfCare).modifiedBy = location.modifiedBy;
                        }
                    }
                });
                state.saving = LoadingStatus.Completed;
            })
            .addCase(updateLocationsOfCare.rejected, (state) => {
                state.saving = LoadingStatus.Failed;
            });
    },
});

const { reducer, actions } = locationsOfCareSlice;

export const { setSearch, setSearchResults, updateLocationOfCareProp, setLocationCareRefresh } = actions;

export default reducer;

export type LocationsOfCareState = {
    data?: Dictionary<ILocationOfCare>;
    loading: LoadingStatus;
    saving: LoadingStatus;
    search?: string;
    searchResults?: ILocationOfCare[];
};
