import { Link, ScrollablePane, ScrollbarVisibility, SelectionMode, Stack, Text } from '@fluentui/react';
import IProcedureDetail, { IProcedureFee } from 'api/models/procedure-details.model';
import { IProcedure } from 'api/models/procedure.model';
import { SortableDetailsList } from 'components';
import Fuse from 'fuse.js';
import useProcedure from 'hooks/store/useProcedure';
import useProcedureDetails from 'hooks/store/useProcedureDetails';
import { LoadingStatus } from 'interfaces/loading-statuses';
import { isEqual } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { toggleProcedureDetailsList } from 'state/slices/procedureDetails/procedureDetails.selectors';
import ProcedureDetailPanel from './ProcedureDetailPanel';
import { isDateBetween } from 'utils/isDateBetween';
const currency = new Intl.NumberFormat('en-En', { style: 'currency', currency: 'USD' });

type ProcedureDetailsListProps = {
    search: string;

    results: IProcedure[];
};
export default function ProcedureDetailsList({ results, search }: ProcedureDetailsListProps) {
    const {
        getProcedureDetails,

        loading: loadingProcedureDetails,
        getOrCreateProcedureDetail,
    } = useProcedureDetails();

    const { getProcedures, loading } = useProcedure();

    const procedureList = useSelector(toggleProcedureDetailsList);

    useEffect(() => {
        getProcedureDetails();
        getProcedures();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const _onProcedureEdit = (item?: IProcedure) => {
        if (item) getOrCreateProcedureDetail(item.id);
    };

    const loadingAssets = loadingProcedureDetails === LoadingStatus.Pending || loading === LoadingStatus.Pending;

    const isActive = (item: IProcedure) => {
        const active =
            isDateBetween({ dateToCheck: new Date().toISOString(), start: item.effectiveDate, end: item.endDate }) &&
            !item.isDeleted;
        return active;
    };

    return (
        <>
            <ProcedureDetailPanel />
            <Stack tokens={{ childrenGap: 10 }} style={{ padding: 10, display: 'flex', flex: 1 }} grow>
                <div style={{ position: 'relative', display: 'flex', flex: 1 }}>
                    <ScrollablePane scrollbarVisibility={ScrollbarVisibility.auto}>
                        <SortableDetailsList<IProcedure>
                            items={search ? results : procedureList}
                            onItemInvoked={_onProcedureEdit}
                            selectionMode={SelectionMode.none}
                            sortOnMount
                            sortColumns={['code']}
                            stickyHeader
                            initialSortDirection={'asc'}
                            enableShimmer={loadingAssets}
                            compact
                            columns={[
                                {
                                    key: 'category',
                                    name: 'Category',
                                    fieldName: 'category',
                                    minWidth: 75,
                                    maxWidth: 150,
                                    isResizable: true,
                                    onRender: (item) =>
                                        item && isActive(item) ? (
                                            <Link onClick={() => _onProcedureEdit(item)}>{item?.category ?? ''}</Link>
                                        ) : (
                                            <Link onClick={() => _onProcedureEdit(item)}>
                                                <i>{item?.category ?? ''}</i>
                                            </Link>
                                        ),
                                },
                                {
                                    key: 'code',
                                    name: 'Code',
                                    fieldName: 'code',
                                    minWidth: 75,
                                    maxWidth: 75,
                                    isResizable: true,
                                    onRender: (item) =>
                                        item && isActive(item) ? (
                                            <Link onClick={() => _onProcedureEdit(item)}>{item?.code ?? ''}</Link>
                                        ) : (
                                            <Link onClick={() => _onProcedureEdit(item)}>
                                                <i>{item?.code ?? ''}</i>
                                            </Link>
                                        ),
                                },
                                {
                                    key: 'description',
                                    name: 'Description',
                                    fieldName: 'description',
                                    minWidth: 75,
                                    isResizable: true,
                                    onRender: (item) =>
                                        item && isActive(item) ? (
                                            <Link onClick={() => _onProcedureEdit(item)}>{item?.description ?? ''}</Link>
                                        ) : (
                                            <Link onClick={() => _onProcedureEdit(item)}>
                                                <i>{item?.description ?? ''}</i>
                                            </Link>
                                        ),
                                },
                                {
                                    key: 'fee',
                                    name: 'Fee',
                                    minWidth: 120,
                                    fieldName: 'fee',
                                    isResizable: true,
                                    onRender: (item?: IProcedureFee) => {
                                        if (item?.fee !== undefined) {
                                            return (
                                                <Stack horizontalAlign="end">
                                                    <Text variant="smallPlus">{currency.format(item.fee)}</Text>
                                                </Stack>
                                            );
                                        }
                                    },
                                },
                            ]}
                        />
                    </ScrollablePane>
                </div>
            </Stack>
        </>
    );
}
