import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'state/store';
import { LoadingStatus } from 'interfaces/loading-statuses';
import { map } from 'lodash';
import { IComboBoxOption } from '@fluentui/react';
import { selectDentalDefaultAppointmentCancellationReason } from 'state/slices/tenant/selectors';

export const appointmentCancellationReasonState = (state: RootState) => state.appointmentCancellationReasons;
export const appointmentCancellationReasonData = createSelector(appointmentCancellationReasonState, (state) => state.data);
export const appointmentCancellationReasonDataAsList = createSelector(
    appointmentCancellationReasonData,
    selectDentalDefaultAppointmentCancellationReason,
    (data) => map(data).filter((item) => !item?.isDeleted),
);

export const selectDentalDefaultACRObject = createSelector(
    appointmentCancellationReasonData,
    selectDentalDefaultAppointmentCancellationReason,
    (data, defaultACR) => {
        const defaultACRObject = map(data).find((item) => parseInt(item?.references?.athenaOne) === defaultACR);
        return defaultACRObject ?? undefined;
    },
);

export const appointmentCancellationReasonOptions = createSelector(appointmentCancellationReasonDataAsList, (data) => {
    const dataMapped: IComboBoxOption[] = map(data).map((res) => ({
        key: res.references?.athenaOne,
        text: res.displayName,
    }));
    return dataMapped;
});

export const appointmentCancellationReasonIsLoading = createSelector(
    appointmentCancellationReasonState,
    (state) => state.loading === LoadingStatus.Pending,
);
