import { Dictionary } from '@reduxjs/toolkit';
import { IProviderProfile } from 'api/models/providerProfile.model';
import { LoadingStatus } from 'interfaces/loading-statuses';

type ProviderProfileState = {
    loading: LoadingStatus;
    data: Dictionary<IProviderProfile>;
    selectedProviderProfile?: IProviderProfile;
};

export const initialProviderProfileState: ProviderProfileState = {
    data: {},
    loading: LoadingStatus.Idle,
};

export default ProviderProfileState;
