import { Stack, TextField, Toggle, IComboBox, IComboBoxOption } from '@fluentui/react';
import useUsers from 'hooks/store/useUsers';
import ProfessionalSuffixInput from 'pages/components/ProfessionalSuffixInput';
import React from 'react';

function EditUserOverview() {
    const { selectedUser, updateIdentityProperty, updateUserProperty } = useUsers();

    const _updateEmail = (ev?: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, value?: string) => {
        if (selectedUser?.identity) {
            updateIdentityProperty('email', value ? value.trim() : '');
        }
    };
    const _updateFirstName = (ev?: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, value?: string) => {
        if (selectedUser?.identity) {
            updateIdentityProperty('firstName', value ? value : '');
        }
    };
    const _updateLastName = (ev?: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, value?: string) => {
        if (selectedUser?.identity) {
            updateIdentityProperty('lastName', value ? value : '');
        }
    };
    const _updateProfessionalSuffix = (
        ev?: React.FormEvent<IComboBox>,
        option?: IComboBoxOption | undefined,
        index?: number,
        value?: string,
    ) => {
        if (selectedUser?.identity) {
            if (option) {
                updateIdentityProperty('professionalSuffix', option.key ? option.key : '');
            } else if (value !== undefined) {
                const newOption: IComboBoxOption = {
                    key: value,
                    text: value,
                };

                updateIdentityProperty('professionalSuffix', newOption.key.toString() ? newOption.key.toString() : '');
            }
        }
    };
    const _updateIsDeleted = (ev?: React.MouseEvent<HTMLElement | MouseEvent>, checked?: boolean) => {
        if (selectedUser?.identity) {
            updateUserProperty('isDeleted', !checked);
        }
    };

    const _isNewUser = selectedUser?.id ? false : true;

    return (
        <Stack tokens={{ childrenGap: 10 }}>
            <Stack.Item>
                <TextField
                    value={selectedUser?.identity.email}
                    readOnly={!_isNewUser}
                    required={_isNewUser}
                    disabled={!_isNewUser}
                    label="Email (Username)"
                    onChange={_updateEmail}
                    autoFocus={_isNewUser}
                />
            </Stack.Item>
            <Stack.Item>
                <Stack horizontal tokens={{ childrenGap: 12 }}>
                    <Stack.Item grow>
                        <TextField
                            value={selectedUser?.identity.firstName}
                            label="First"
                            autoFocus={!_isNewUser}
                            onChange={_updateFirstName}
                        />
                    </Stack.Item>
                    <Stack.Item grow>
                        <TextField value={selectedUser?.identity.lastName} label="Last" onChange={_updateLastName} />
                    </Stack.Item>
                    <Stack.Item>
                        <ProfessionalSuffixInput
                            onChange={_updateProfessionalSuffix}
                            maxResults={1000}
                            selectedKey={selectedUser?.identity.professionalSuffix}
                        />
                    </Stack.Item>
                </Stack>
            </Stack.Item>
            <Stack.Item>
                <Toggle
                    checked={selectedUser?.isDeleted ? false : true}
                    label="Status"
                    onText="Active"
                    offText="Disabled"
                    onChange={_updateIsDeleted}
                />
            </Stack.Item>
        </Stack>
    );
}

export default EditUserOverview;
