import { Dictionary } from '@reduxjs/toolkit';
import { CombinedPayerAndPayerDetail, IPayer, IPayerDetail, IFeeSchedule } from 'api/models/payer.model';
import { LoadingStatus, LoadingStatuses } from 'interfaces/loading-statuses';
import { createSelector } from 'reselect';
import { RootState } from 'state/store';
import { isDateBetween } from 'utils/isDateBetween';

export const selectPayers = (state: RootState): Dictionary<IPayer> | undefined => state.payers.data;
export const selectPayersLoading = (state: RootState): LoadingStatuses => state.payers.loading;
export const selectLoading = (state: RootState) => state.payers.loading === LoadingStatus.Pending;
export const selectPayerDetailSaving = (state: RootState): boolean => state.payers.payerDetails.saving === LoadingStatus.Pending;
export const selectPayersError = (state: RootState): string | undefined => state.payers.error;
export const payersDetailSearch = (state: RootState): string | undefined => state.payers.payerDetailsSearch;
export const selectSelectedPayer = (state: RootState): CombinedPayerAndPayerDetail | undefined => state.payers.selectedPayer;
export const selectPayersAsList = createSelector(selectPayers, (data) => {
    if (data)
        return Object.keys(data)
            .map((key) => data[key])
            .filter((payer) => payer !== undefined)
            .filter((payer) => !payer?.isDeleted) as IPayer[];
    return [];
});

export const selectPayersDetails = (state: RootState): Dictionary<IPayerDetail> | undefined => state.payers.payerDetails.data;
export const selectPayersDetailsLoading = (state: RootState): LoadingStatuses => state.payers.payerDetails.loading;
export const selectPayersDetailsAsList = createSelector(selectPayersDetails, (data) => {
    if (data) return Object.keys(data).map((key) => data[key]);
    return [];
});

function getActiveFeeSchedules(feeSchedules?: IFeeSchedule[]): IFeeSchedule[] {
    if (!feeSchedules?.length) return [];

    const today = new Date();

    return feeSchedules.filter((feeSchedule) => {
        if (feeSchedule.isDeleted) return false;
        const startDate = feeSchedule.effectiveDate ? new Date(feeSchedule.effectiveDate) : '';
        const endDate = feeSchedule.endDate ? new Date(feeSchedule.endDate) : '';
        if (
            isDateBetween({
                dateToCheck: today.toISOString(),
                start: startDate.toString(),
                end: endDate.toString(),
            })
        ) {
            return feeSchedule;
        }
    });
}

export const selectCombinedPayerAndPayerDetails = createSelector(
    [selectPayersAsList, selectPayersDetails],
    (payers, payerDetails) => {
        if (payers.length && payerDetails) {
            const payerAndPayerDetails: CombinedPayerAndPayerDetail[] = payers.map((payer) => {
                const payerDetail = payerDetails[payer.id];
                return {
                    id: payer.id,
                    name: payer.name,
                    payerId: payer.payerId,
                    displayName: payerDetail?.displayName,
                    feeSchedules: getActiveFeeSchedules(payerDetail?.feeSchedules),
                    isQuickPick: !!payerDetail?.isQuickPick,
                    isDeleted: payerDetail?.isDeleted ? payerDetail?.isDeleted : false,
                    street1: payer.street1,
                    street2: payer.street2,
                    city: payer.city,
                    state: payer.state,
                    zip: payer.zip,
                    modifiedBy: payerDetail?.modifiedBy ?? '',
                    modifiedOn: payerDetail?.modifiedOn,
                };
            });
            return payerAndPayerDetails;
        }
        return [];
    },
);

export const showNonQuickPicksHistory = (state: RootState): boolean | undefined => state.payers.showNonQuickPicksHistory;
export const showQuickPicksHistory = (state: RootState): boolean | undefined => state.payers.showQuickPickHistory;

export const selectPayerQuickPicks = createSelector(
    selectCombinedPayerAndPayerDetails,
    showQuickPicksHistory,
    (data, showHistory) => {
        if (data)
            return !showHistory
                ? data.filter((payer) => payer.isQuickPick)
                : data.filter((payer) => payer.isQuickPick && !payer.isDeleted);
        return [];
    },
);

export const selectPayerNonQuickPicks = createSelector(
    selectCombinedPayerAndPayerDetails,
    showNonQuickPicksHistory,
    (data, showHistory) => {
        if (data)
            return !showHistory
                ? data.filter((payer) => !payer.isQuickPick)
                : data.filter((payer) => !payer.isQuickPick && !payer.isDeleted);
        return [];
    },
);
