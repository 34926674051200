import { IconButton, Stack, useTheme } from '@fluentui/react';
import useSubscriptions from 'hooks/store/useSubscriptions';
import { Permission } from 'hooks/usePermissions';
import { useParams } from 'react-router-dom';
import { IStatementConfigurationLink } from '../StatementConfig';
import StatementConfigBarLink from './StatmentConfigBarLink';

export interface IActivity {
    keyString: string;
    tooltip: string;
    path: string;
    iconName?: string;
    icon?: JSX.Element;
    exact: boolean;
    notifications?: number;
    permissions?: Permission[];
}

type StatementConfigurationProps = {
    statementConfigurationLink: IStatementConfigurationLink[];
};

export const StatementConfigurationBar = ({ statementConfigurationLink }: StatementConfigurationProps) => {
    const theme = useTheme();
    const params = useParams<{ tenantId: string }>();
    const { tenantId } = params;

    const { isExpanded, toggleExpanded } = useSubscriptions();

    const activities: IActivity[] = statementConfigurationLink.map((p: IStatementConfigurationLink) => {
        return {
            keyString: p.key,
            path: `/${tenantId}/statementConfiguration/${p.url}`,
            tooltip: p.name,
            exact: true,
            permissions: [p.permission],
        };
    });

    const { neutralLighterAlt } = theme.palette;

    const activityLinks = activities.map((activity) => (
        <StatementConfigBarLink key={activity.keyString} expanded={isExpanded} {...activity} />
    ));

    const wrapperClassName = isExpanded ? 'activityBar activityBar--expanded' : 'activityBar';

    const wrapperStyle = {
        background: neutralLighterAlt,
        borderRight: `1px solid ${theme?.semanticColors.bodyDivider}`,
    };

    const expanderIcon = {
        iconName: isExpanded ? 'DoubleChevronLeftMed' : 'DoubleChevronLeftMedMirrored',
    };

    const _setExpanded = () => {
        toggleExpanded();
    };

    return (
        <Stack className={wrapperClassName} style={wrapperStyle}>
            <Stack style={{ flex: 1 }}>
                <Stack.Item grow>{activityLinks}</Stack.Item>
            </Stack>

            <div className={'activityBar__footer'}>
                <IconButton onClick={_setExpanded} iconProps={expanderIcon} />
            </div>
        </Stack>
    );
};
