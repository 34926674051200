import { Separator, Stack, Text, Toggle } from '@fluentui/react';
import IProductRole from 'api/models/productRole.model';
import { ITenantSubscription } from 'api/models/tenantSubscription.model';
import { TText } from 'components';
import { useSelector } from 'hooks';
import useSubscriptionUsers from 'hooks/store/useSubscriptionUsers';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { selectTenantSubscriptions } from 'state/slices/tenant/selectors';

function UserPermissions() {
    const { selectedUser, updateSubscriptionUserProperty } = useSubscriptionUsers();
    const params = useParams<{ tenantId: string; productId: string }>();
    const { tenantId, productId } = params;

    const _tenantSubscriptions = useSelector(selectTenantSubscriptions);

    const [roles, setRoles] = useState<IProductRole[]>();
    const subscriptions = useSelector((state) => {
        if (state.tenants.data) {
            const tenant = state.tenants.data[tenantId];

            if (tenant) return _tenantSubscriptions;
        }

        return [] as ITenantSubscription[];
    });

    useEffect(() => {
        const subIndex = subscriptions?.findIndex((e) => e.productId === productId) as number;
        if (subscriptions && subIndex > -1) {
            const subscription = subscriptions[subIndex] as ITenantSubscription;
            setRoles(subscription.productRoles);
        }
    }, [subscriptions, productId]);

    const _permissionItems = roles?.map((perm) => {
        const handleOnClick = () => {
            if (selectedUser?.permissions?.length) {
                if (selectedUser.permissions.indexOf(perm.id) > -1) {
                    updateSubscriptionUserProperty(
                        'permissions',
                        selectedUser.permissions.filter((key) => key !== perm.id),
                    );
                } else {
                    updateSubscriptionUserProperty('permissions', [...selectedUser.permissions, perm.id]);
                }
            } else {
                updateSubscriptionUserProperty('permissions', [perm.id]);
            }
        };

        const permissionChecked =
            selectedUser && selectedUser.permissions ? selectedUser.permissions.indexOf(perm.id) > -1 : false;

        return (
            <Stack key={perm.id}>
                <Stack horizontal verticalAlign="center" styles={{ root: { cursor: 'pointer' } }} onClick={handleOnClick}>
                    <Stack.Item grow={1}>
                        <Text variant="mediumPlus">{perm.displayName}</Text>
                    </Stack.Item>
                    <Stack.Item>
                        <Toggle checked={permissionChecked} />
                    </Stack.Item>
                </Stack>
                <Stack>
                    <Text variant="medium">{perm.description}</Text>
                </Stack>
                <Separator />
            </Stack>
        );
    });

    return (
        <Stack styles={{ root: { width: '100%' } }}>
            <Stack>
                <Separator>
                    <TText variant="mediumPlus" block>
                        <b>Subscription Role(s)</b>
                    </TText>
                </Separator>
            </Stack>

            {_permissionItems}
        </Stack>
    );
}

export default UserPermissions;
