import { createSlice, Dictionary } from '@reduxjs/toolkit';
import { CombinedPayerAndPayerDetail, IPayer, IPayerDetail } from 'api/models/payer.model';
import { LoadingStatus, LoadingStatuses } from 'interfaces/loading-statuses';
import payersReducers, { payersExtraReducers } from './payers.reducers';

export type PayersState = {
    data?: Dictionary<IPayer>;
    loading: LoadingStatuses;
    selectedPayer?: CombinedPayerAndPayerDetail;
    payerDetails: {
        data?: Dictionary<IPayerDetail>;
        loading: LoadingStatuses;
        saving: LoadingStatus;
    };
    payerDetailsSearch?: string;
    error?: string;
    showNonQuickPicksHistory?: boolean;
    showQuickPickHistory?: boolean;
};

const initialState: PayersState = {
    data: undefined,
    loading: LoadingStatus.Idle,
    selectedPayer: undefined,
    payerDetails: {
        data: undefined,
        loading: LoadingStatus.Idle,
        saving: LoadingStatus.Idle,
    },
    payerDetailsSearch: undefined,
    error: '',
    showNonQuickPicksHistory: true,
    showQuickPickHistory: true,
};

const payersSlice = createSlice({
    name: 'payers',
    initialState,
    reducers: {
        ...payersReducers,
    },
    extraReducers: (builder) => {
        payersExtraReducers(builder);
    },
});

const { actions, reducer } = payersSlice;

export const { setSelectedPayer, setPayersDetailsSearch, setShowNonQuickPicksHistory, setShowQuickPicksHistory } = actions;

export default reducer;
