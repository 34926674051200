import { useState, useEffect } from 'react';
import { isAfter, isBefore, isEqual, isFuture, isValid } from 'date-fns';
import { stateTypeZipFormatLookup } from 'components/Field/ZipCodeField';
import { StateType } from 'components/Field/StateOptions';

export const ValidationType = {
    Required: 'required',
    CharacterLimit: 'characterLimit',
    Phone: 'phone',
    Email: 'email',
    SSN: 'ssn',
    Taxonomy: 'taxonomy',
    Date: 'date',
    NotFutureDate: 'notFutureDate',
    MinMaxDate: 'minMaxDate',
    LetterComparison: 'letterComparison',
    NotBeforeNineteenHundreds: 'notBeforeNineteenHundreds',
    Zip: 'zip',
    Time: 'time',
    Start: 'start',
    End: 'end',
    NotDuplicateItem: 'notDuplicateItem',
    EqualTime: 'equalTime',
    NumberComparison: 'numberComparison',
} as const;

export type ValidationTypes = (typeof ValidationType)[keyof typeof ValidationType];

export interface IValidationItemOptions {
    /**
     * Character limit specification for character limit validation
     *
     * @type {number}
     * @memberof IValidationItem
     */
    characterLimit?: number;

    //add Min and Max date on Date Fields

    minDate?: Date;
    maxDate?: Date;

    startTime?: Date;
    endTime?: Date;

    alphaFrom?: string;
    alphaTo?: string;

    numberFrom?: number;
    numberTo?: number;

    itemList?: (number | string)[];

    phoneValidationPlan?: PhoneValidationPlan;
    stateType?: StateType;
}

export enum PhoneValidationPlan {
    NorthAmericanNumberingPlan = 'NorthAmericanNumberingPlan',
}

export interface IValidationItem {
    value: any;
    fieldName: string;
    validation: ValidationTypes[];
    /**
     * Further options for validation type
     *
     * @type {IValidationOptions}
     * @memberof IValidationItem
     */
    itemOptions?: IValidationItemOptions;
}

export interface IValidationError {
    fieldName: string;
    errorTypes: string[];
}

export type IValidationConfig = IValidationItem[];
export interface IValidationOptions {
    shouldZerosNotFulfillRequired?: boolean;
}

export function getValidationError(errors: IValidationError[], fieldName: string) {
    return errors.find((err) => err.fieldName === fieldName);
}
/**
 * Used to further validation phone numbers based on region/country numbering system.
 * @type {*} */
const phoneValidationPlanLookup: Record<PhoneValidationPlan, (phone: string) => boolean> = {
    [PhoneValidationPlan.NorthAmericanNumberingPlan]: (phone: string) => {
        const firstCharInNumber = +phone.charAt(0);
        const fourthCharInNumber = +phone.charAt(3);
        if (isNaN(firstCharInNumber) || isNaN(fourthCharInNumber)) return true;
        if (firstCharInNumber > 1 && fourthCharInNumber > 1) return true;
        return false;
    },
};

/**
 * useValidation Hook
 *
 * @export
 * @param {IValidationConfig} config Array of items to validate
 * @param {() => void} [onSubmit] Method to fire when everything is valid
 * @returns {[IValidationError[], () => void, () => void]} [Array of errors, method to execute validation, method to cleanup errors]s
 */
export default function useValidation(
    config: IValidationConfig,
    onSubmit?: () => void,
    options?: IValidationOptions,
): [IValidationError[], () => void, () => void] {
    const [errors, setErrors] = useState<IValidationError[]>([]);
    const [loaded, setLoaded] = useState<boolean>(false);

    useEffect(() => {
        if (!loaded) setLoaded(true);
        // eslint-disable-next-line
    }, []);

    function handleSubmit() {
        let newErrors: IValidationError[] = [];

        config.forEach((item) => {
            const fieldError = parseFieldItem(item);
            if (fieldError) {
                newErrors = [...addUpdateError(newErrors, fieldError)];
            } else {
                newErrors = [...removeError(newErrors, item)];
            }
        });

        setErrors([...newErrors]);

        const hasErrors = newErrors.length > 0;
        if (!hasErrors && onSubmit) {
            onSubmit();
        }
    }

    function handleCleanupErrors() {
        setLoaded(false);
        setErrors([]);
    }

    function addUpdateError(errorList: IValidationError[], item: IValidationError) {
        const newErrors = errorList;
        newErrors.push(item);
        return [...newErrors];
    }

    function removeError(errorList: IValidationError[], item: IValidationItem) {
        const indexOfField = errorList.findIndex((f) => f.fieldName === item.fieldName);
        if (indexOfField > -1) {
            const newErrors = [...errorList.slice(0, indexOfField), ...errorList.slice(indexOfField + 1)];
            return [...newErrors];
        }
        return [...errorList];
    }

    const validations: { [v in ValidationTypes]: (value: any, options?: IValidationItemOptions) => string | null } = {
        [ValidationType.Required]: validateRequired,
        [ValidationType.Date]: validateDate,
        [ValidationType.NotFutureDate]: validateNotFutureDate,
        [ValidationType.CharacterLimit]: validateCharacterLimit,
        [ValidationType.MinMaxDate]: validateMinMaxDate,
        [ValidationType.Email]: validateEmail,
        [ValidationType.Phone]: validatePhone,
        [ValidationType.SSN]: validateSSN,
        [ValidationType.Time]: validateTime,
        [ValidationType.Zip]: validateZip,
        [ValidationType.Taxonomy]: validateTaxonomy,
        [ValidationType.Start]: validateTime,
        [ValidationType.End]: validateTime,
        [ValidationType.NotBeforeNineteenHundreds]: validateNotBeforeNineteenHundreds,
        [ValidationType.NotDuplicateItem]: validateIsNotDuplicateItem,
        [ValidationType.EqualTime]: isAppointmentTimeEqual,
        [ValidationType.LetterComparison]: validateLetterComparison,
        [ValidationType.NumberComparison]: validateNumberComparison,
    };

    function parseFieldItem(fieldItem: IValidationItem): IValidationError | null {
        const { validation, value } = fieldItem;

        const validationError: IValidationError = {
            fieldName: fieldItem.fieldName,
            errorTypes: [],
        };

        validation.forEach((v) => {
            const errorString = validations[v](value, fieldItem.itemOptions);
            if (errorString) {
                validationError.errorTypes.push(errorString);
            }
        });

        return validationError.errorTypes.length ? validationError : null;
    }

    function validateCharacterLimit(value: any, options?: IValidationItemOptions): string | null {
        if (!value) return null;
        if (value && typeof value === 'string') {
            const limit = options?.characterLimit !== undefined ? options.characterLimit : 100;
            if (value.length <= limit) return null;
        }

        return ValidationType.CharacterLimit;
    }

    function validateRequired(value: any): string | null {
        if (Array.isArray(value)) {
            if (!value.length) {
                return ValidationType.Required;
            } else {
                return null;
            }
        } else {
            if (
                value === '' ||
                value === undefined ||
                value === null ||
                (options?.shouldZerosNotFulfillRequired ? value === 0 : false)
            ) {
                return ValidationType.Required;
            } else {
                return null;
            }
        }
    }
    function validatePhone(value: any, options?: IValidationItemOptions): string | null {
        if (value && typeof value === 'string') {
            const isValidPhone = (phone: string, validationPlan?: PhoneValidationPlan) => {
                const isPhoneValidAgainstNumberPlan = validationPlan ? phoneValidationPlanLookup[validationPlan](phone) : true;
                return /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/.test(phone) && isPhoneValidAgainstNumberPlan;
            };
            if (isValidPhone(value, options?.phoneValidationPlan)) return null;
        }
        return ValidationType.Phone;
    }
    function validateEmail(value: any): string | null {
        if (value && typeof value === 'string') {
            const isValidEmail = (email: string) =>
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
                    email,
                );
            if (isValidEmail(value)) return null;
        }
        return ValidationType.Email;
    }
    function validateSSN(value: any): string | null {
        if (value && typeof value === 'string') {
            const isValidSSN = (ssn: string) => {
                return /^(?!(000|666|9))(\d{3}-?(?!(00))\d{2}-?(?!(0000))\d{4})$/.test(ssn);
            };
            if (isValidSSN(value)) return null;
        }
        return ValidationType.SSN;
    }
    function validateDate(value: any): string | null {
        if (value && typeof value === 'string' && isValid(new Date(value))) {
            return null;
        }
        return ValidationType.Date;
    }
    function validateNotFutureDate(value: any): string | null {
        if (typeof value === 'string' && !isFuture(new Date(value))) {
            return null;
        }
        return ValidationType.NotFutureDate;
    }
    function validateNotBeforeNineteenHundreds(value: any): string | null {
        if (typeof value === 'string' && !isBefore(new Date(value), new Date('01/01/1900'))) {
            return null;
        }
        return ValidationType.NotBeforeNineteenHundreds;
    }
    function validateMinMaxDate(value: any, options?: IValidationItemOptions): string | null {
        const minDate = options?.minDate !== undefined ? new Date(options.minDate) : new Date('01/01/1900');
        const maxDate = options?.maxDate !== undefined ? new Date(options.maxDate) : new Date('01/01/2100');
        if (
            typeof value === 'string' &&
            isAfter(new Date(value), new Date(minDate)) &&
            isBefore(new Date(value), new Date(maxDate))
        ) {
            return null;
        }
        return ValidationType.MinMaxDate;
    }

    function validateLetterComparison(value: any, options?: IValidationItemOptions): string | null {
        const alphaFrom = options?.alphaFrom !== undefined ? options.alphaFrom : 'A';
        const alphaTo = options?.alphaTo !== undefined ? options.alphaTo : 'Z';
        if (typeof value === 'string' && value >= alphaFrom && value <= alphaTo) {
            return null;
        }
        return ValidationType.LetterComparison;
    }

    function validateNumberComparison(value: any, options?: IValidationItemOptions): string | null {
        const numberFrom = options?.numberFrom !== undefined ? options.numberFrom : 0;
        const numberTo = options?.numberTo !== undefined ? options.numberTo : 1000;
        if (typeof value === 'number' && value >= numberFrom && value <= numberTo) {
            return null;
        }
        return ValidationType.NumberComparison;
    }

    function validateZip(value: any, options?: IValidationItemOptions): string | null {
        if (typeof value === 'string') {
            const stateType = options?.stateType ?? StateType.US;
            const { validRegex } = stateTypeZipFormatLookup[stateType];

            if (validRegex.test(value)) return null;
        }
        return ValidationType.Zip;
    }
    function validateTaxonomy(value: any): string | null {
        if (value && typeof value === 'string') {
            const isValidTaxonomy = (value: string | undefined) => {
                return value ? value.length === 10 : false;
            };
            if (isValidTaxonomy(value)) return null;
        }
        return ValidationType.Taxonomy;
    }
    function validateTime(value: any): string | null {
        return null;
    }

    function isAppointmentTimeEqual(value: any, options?: IValidationItemOptions): string | null {
        const startTime = options?.startTime !== undefined ? options.startTime : new Date();
        const endTime = options?.endTime !== undefined ? options.endTime : new Date();
        if (
            value &&
            typeof value === 'string' &&
            isEqual(new Date(value), new Date(startTime)) &&
            isEqual(new Date(value), new Date(endTime))
        ) {
            return null;
        }
        return ValidationType.EqualTime;
    }

    // function isTrueOrFalse(value: any, options?: IValidationItemOptions): string | null {
    //     if (options?.isTrueOrFalseValidationType) return null;
    //     return options?.isTrueOrFalseValidationType ? options.isTrueOrFalseValidationType : ValidationType.IsTrueOrFalse;
    // }
    function validateIsNotDuplicateItem(value: any, options?: IValidationItemOptions): string | null {
        if (value !== undefined && (typeof value === 'string' || typeof value === 'number') && options?.itemList) {
            const duplicates = options.itemList?.filter((o) => o === value);
            if (!duplicates.length) {
                return null;
            } else {
                return ValidationType.NotDuplicateItem;
            }
        }
        return null;
    }

    return [errors, handleSubmit, handleCleanupErrors];
}

export const validateErrorMessage = (error: IValidationError | undefined) => {
    if (!error) return undefined;
    if (error.errorTypes.includes('required')) {
        return ' Date Is Required';
    }
    if (error.errorTypes.includes('minMaxDate') || error.errorTypes.includes('notBeforeNineteenHundreds')) {
        return 'Unreasonable Date';
    }

    if (error.errorTypes.includes('notFutureDate')) {
        return 'Date cannot be in the future';
    }
};
