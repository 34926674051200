import React, { useState, useEffect } from 'react';
import { ITextFieldProps, TextField } from '@fluentui/react';

type Props = { handleError?: (error: string | undefined) => void } & ITextFieldProps;

export default function TaxonomyField(props: Props): JSX.Element {
    const { onChange, value, handleError } = props;
    const [_value, _setValue] = useState<string | undefined>();

    const isValidTaxonomy = (value: string) => value.length === 10;

    useEffect(() => {
        if (value !== null && value !== '' && _value !== '') _setValue(value);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value]);

    function _onChange(ev: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, value?: string) {
        _setValue(value);
        if (onChange && value) {
            if (isValidTaxonomy(value)) {
                onChange(ev, value);
            } else {
                onChange(ev, '');
            }
        }
    }

    const onGetErrorMessage = (value: string) => {
        const errorEmailMessage = isValidTaxonomy(value) ? undefined : 'Invalid Taxonomy Code';
        if (handleError) handleError(errorEmailMessage);
        return errorEmailMessage;
    };

    return (
        <TextField
            {...props}
            value={_value}
            onChange={_onChange}
            onGetErrorMessage={onGetErrorMessage}
            validateOnLoad={!!props.value}
            validateOnFocusOut
        />
    );
}
