import React, { PropsWithChildren, useRef } from 'react';
import { Switch, RouteProps } from 'react-router-dom';
import { TooltipCards } from 'pages/components';
import { AppLoader, NavBar } from 'components';
import './App.scss';
import routes from 'routes';
import { RouteWrapper } from 'routes/RouteWrapper';
import { Helmet } from 'react-helmet';
import CustomerSelector from './components/CustomerSelector';
import BentoBox from './BentoBox';
import TenantSubscriptionServices from './TenantSubscriptionServices';

const App = () => {
    const routeList = routes.map((route: RouteProps, index: number) => <RouteWrapper key={index} {...route} />);

    return (
        <AppLoader>
            <TooltipProvider>
                <Helmet defaultTitle="Membership" titleTemplate="%s - athenaOne Membership" />
                <NavBar preAvatarContent={<TenantSubscriptionServices />} preAppName={<BentoBox />} appName="Membership">
                    <CustomerSelector />
                </NavBar>
                <Switch>{routeList}</Switch>
            </TooltipProvider>
        </AppLoader>
    );
};

export default App;

function TooltipProvider(props: PropsWithChildren<any>) {
    const tooltipRef = useRef(null);
    return (
        <React.Fragment>
            {props.children}
            <div style={{ position: 'absolute', right: 15, bottom: 3 }} ref={tooltipRef} />
            {tooltipRef.current ? <TooltipCards domNode={tooltipRef.current} /> : null}
        </React.Fragment>
    );
}
