import { format, intervalToDuration, isBefore, isValid } from 'date-fns';
import { isAfter } from 'date-fns/esm';

/**
 * Classic Date Only (Removes local timezone for dates)
 * @description Returns the date portion of a valid date string disregarding the local timezone
 * @param {string} dateToConvert
 * @param {string} [dateFormat='MM/dd/yyyy']
 * @returns {string} Formatted date only string
 */
export function classicDateOnly(dateToConvert: string, dateFormat = 'MM/dd/yyyy'): string {
    const convertToLocal = new Date(dateToConvert).toString();
    const newDate = new Date(convertToLocal);
    if (dateToConvert && isValid(newDate)) {
        const dateOnly = new Date(newDate.valueOf() + newDate.getTimezoneOffset() * 60 * 1000);
        return format(dateOnly, dateFormat);
    }

    throw new Error(`Date Error: (${dateToConvert}) is not a valid date string.`);
}

/**
 * Date Only
 * @description Returns the date portion of a valid date string disregarding the local timezone
 * @param {string} dateToConvert
 * @param {string} [dateFormat='MM/dd/yyyy']
 * @returns {string} Formatted date only string
 */
export default function dateOnly(dateToConvert: string, dateFormat = 'MM/dd/yyyy'): string {
    const convertToLocal = new Date(dateToConvert).toString();
    const newDate = new Date(convertToLocal);
    if (dateToConvert && isValid(newDate)) {
        //Don't think this is needed anymore as we convert it now back to local time and then format it. But will keep for now.
        //const dateOnly = new Date(newDate.valueOf() + newDate.getTimezoneOffset() * 60 * 1000);
        return format(newDate, dateFormat);
    }

    throw new Error(`Date Error: (${dateToConvert}) is not a valid date string.`);
}

/**
 *
 * @description Returns age in years based on current date
 * @export
 * @param {string} dob
 * @return {*}  {(number | undefined)}
 */
export function calculateAgeInYears(dob?: string): number {
    if (!dob) return 0;
    const durationAlive = intervalToDuration({ start: new Date(dateOnly(dob)), end: new Date() });
    return durationAlive.years ?? 0;
}

/**
 *
 * @description Returns formatted age based on current date
 * @param {string} dob
 * @return {string}
 */
export function calculateFullAge(dob: string): string {
    if (!isAfter(new Date(dateOnly(dob)), new Date()) && !isBefore(new Date(dateOnly(dob)), new Date('1/1/1900'))) {
        const durationAlive = intervalToDuration({ start: new Date(dateOnly(dob)), end: new Date() });

        if (durationAlive.years === 0) {
            return `${durationAlive.months}m${durationAlive.days}do`;
        }

        return `${durationAlive.years}yo`;
    }
    return 'Invalid Date';
}
