import { createSlice, Dictionary } from '@reduxjs/toolkit';
import { IEncounter } from 'api/models/encounter.model';
import { LoadingStatus } from 'interfaces/loading-statuses';
import encountersReducers, { encountersExtraReducers } from './encounters.reducers';

export type EncounterState = {
    data?: Dictionary<IEncounter>;
    loading: LoadingStatus;
    saving: LoadingStatus;
    encountersSearch?: string;
};

const initialState: EncounterState = {
    loading: LoadingStatus.Idle,
    saving: LoadingStatus.Idle,
    encountersSearch: undefined,
};

const encounterSlice = createSlice({
    name: 'encounters',
    initialState,
    reducers: { ...encountersReducers },
    extraReducers: encountersExtraReducers,
});

const { actions, reducer } = encounterSlice;

export const { setEncountersSearch, cleanupEncountersSearch } = actions;

export default reducer;
