import { useReducer, useEffect } from 'react';
import { TextField, ITextFieldProps } from '@fluentui/react';

type Props = { handleError?: (error: string | undefined) => void } & ITextFieldProps;

type State = {
    value?: string;
};

type Actions = { type: 'setInternalValue'; value?: string };

const initialState = (value: string): State => ({
    value,
});

const validateEmail = (email: string | undefined) =>
    (email &&
        // eslint-disable-next-line no-useless-escape
        /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
            email,
        )) ||
    email === ''
        ? true
        : false;

const reducer = (state: State, action: Actions) => {
    switch (action.type) {
        case 'setInternalValue':
            return { ...state, value: action.value };
        default:
            return state;
    }
};

const EmailField = ({ value, onChange, handleError, ...props }: Props): JSX.Element => {
    const [state, dispatch] = useReducer(reducer, value ? value : '', initialState);

    useEffect(() => {
        if (value !== null && value !== '' && state.value !== '') dispatch({ type: 'setInternalValue', value });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value]);

    const onGetErrorMessage = (value: string) => {
        const errorEmailMessage = validateEmail(value) ? undefined : 'Invalid Email';
        if (handleError) handleError(errorEmailMessage);
        return errorEmailMessage;
    };

    const handleChange = (e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, value?: string) => {
        dispatch({ type: 'setInternalValue', value });
        if (value !== undefined && onChange) {
            if (validateEmail(value)) {
                onChange(e, value);
            } else {
                onChange(e, '');
            }
            if (state.value === '') {
                onChange(e, '');
            }
        }
    };

    return (
        <TextField
            {...props}
            onChange={handleChange}
            label={props.label || 'Email'}
            value={state.value}
            onGetErrorMessage={onGetErrorMessage}
            validateOnFocusOut
            validateOnLoad={value ? true : false}
        />
    );
};

export default EmailField;
