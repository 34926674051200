import React from 'react';
import { TooltipHost, DirectionalHint, IStyleFunctionOrObject, useTheme, Text } from '@fluentui/react';
import { NavLink } from 'react-router-dom';
import 'components/ActivityBar/ActivityBarLink.scss';
import { Badge } from 'components';
import { BadgeSize } from 'components/Badge';
import { useBoolean } from '@uifabric/react-hooks';
import { IActivity } from './SubscriptionBar';
import usePermissions from 'hooks/usePermissions';

type Props = IActivity & {
    styles?: IStyleFunctionOrObject<any, any>;
    expanded?: boolean;
    onClick?: (event: React.MouseEvent<HTMLAnchorElement | MouseEvent>) => void;
};

/**
 * Activity Bar Link
 * Wrapper for react-router-dom NavLink to pass in expanded property and add text when expanded
 * @export
 * @param {Props} props
 * @returns
 */
function SubscriptionBarLink(props: Props) {
    const theme = useTheme();
    const [hasPermission] = usePermissions(props.permissions);

    const { themePrimary, neutralPrimary, neutralLight, neutralQuaternaryAlt } = theme.palette;
    const activeLinkStyles = { background: neutralQuaternaryAlt };

    const [hovered, { toggle, setFalse: onLeave }] = useBoolean(false);

    const linkStyles: React.CSSProperties = {
        color: neutralPrimary,
        userSelect: 'none',
        background: hovered ? neutralLight : '',
        transition: 'background .15s',
        cursor: hovered ? 'pointer' : '',
        fontSize: '16px',
    };

    if (!hasPermission) return null;

    return (
        <TooltipHost content={props.tooltip} directionalHint={DirectionalHint.rightCenter} hidden={props.expanded}>
            <NavLink
                className={props.expanded ? 'activityLink activityLink--expanded' : 'activityLink'}
                to={props.path}
                key={props.keyString}
                exact={props.exact}
                activeStyle={activeLinkStyles}
                activeClassName="activityLink--active"
                style={linkStyles}
                onMouseEnter={toggle}
                onMouseLeave={onLeave}
            >
                <div className="activitiyLink__activeBar" style={{ background: themePrimary }}></div>
                {!props.expanded && (
                    <Badge value={props.tooltip[0]} className="activityLink__namebadge" size={BadgeSize.size32} />
                )}

                <Text style={{ paddingLeft: '15px' }}>{props.expanded ? props.tooltip : null}</Text>
                {props.notifications && (
                    <Badge value={props.notifications} className="activityLink__namebadge" size={BadgeSize.size16} />
                )}
            </NavLink>
        </TooltipHost>
    );
}

export default SubscriptionBarLink;
