import { PrimaryButton, Stack, Toggle } from '@fluentui/react';
import { ITenantUser } from 'api/models/tenantAccount.model';
import useSubscriptionUsers from 'hooks/store/useSubscriptionUsers';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { toggleActiveOnly, toggleProviderOnly } from 'state/slices/subscriptions/subscriptions.slice';
import {
    selectSubscriptionUsersActive,
    selectSubscriptionUsersAProvider,
} from 'state/slices/subscriptions/users/users.selectors';

type UsersSearchBoxProps = {
    setEditorOpen: () => void;
};

const toggleStyles = {
    root: {
        marginBottom: 2,
        minWidth: 150,
    },
};

const SubscriptionFilter = ({ setEditorOpen }: UsersSearchBoxProps) => {
    const { setSelectedUser } = useSubscriptionUsers();
    const { productId } = useParams<{ productId: string }>();

    const providerOnly = useSelector(selectSubscriptionUsersAProvider);

    const isActive = useSelector(selectSubscriptionUsersActive);
    const dispatch = useDispatch();
    const onAddUser = () => {
        const user: ITenantUser = {
            id: '',
            identity: {
                id: '',
                email: '',
                firstName: '',
                lastName: '',
            },
            isDeleted: false,
            permissions: [],
        };

        setSelectedUser(user);
        setEditorOpen();
    };

    const isMembership = productId === 'membership';

    useEffect(() => {
        //TODO: Probably need to create separate user selectors for membership page vs dental.
        if (isMembership) {
            dispatch(toggleProviderOnly(false));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [providerOnly, productId]);

    return (
        <Stack horizontal verticalAlign="end" tokens={{ childrenGap: 10 }} grow>
            <Toggle
                styles={toggleStyles}
                checked={isActive}
                inlineLabel
                label="Active Only"
                onChange={() => dispatch(toggleActiveOnly())}
            />
            {!isMembership && (
                <Toggle
                    styles={toggleStyles}
                    checked={providerOnly}
                    inlineLabel
                    label="Provider Only"
                    onChange={() => dispatch(toggleProviderOnly())}
                />
            )}

            <PrimaryButton text="Add User" iconProps={{ iconName: 'Add' }} onClick={onAddUser} />
        </Stack>
    );
};

export default SubscriptionFilter;
