import { Dictionary } from '@reduxjs/toolkit';
import useSelector from 'hooks/useSelector';
import useTenantId from 'hooks/useTenantId';
import { map } from 'lodash';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { selectIsAccountGlobalAdmin } from 'state/slices/account.slice';
import { clearProcedures } from 'state/slices/procedures/procedures.slice';
import { selectTenantSubscriptions } from 'state/slices/tenant/selectors';
import { cleanupTenantsSearch, searchTenants, tenantData, tenants, tenantsState } from 'state/slices/tenants/tenants.slice';

export interface ISubscriptionBentoData {
    url: string;
    displayName: string;
}

function useTenant() {
    const dispatch = useDispatch();
    const tenantId = useTenantId();

    const tenantApps: Dictionary<string> = {
        dental: `#{DentalClientUrl}#/${tenantId}`,
        platform: `#{PlatformClientUrl}#/tenants/${tenantId}`,
        credentialing: `#{CredentialingClientUrl}#/${tenantId}/profile`,
    };

    const { loading, errors } = useSelector(tenantsState);
    const _tenantData = useSelector((state) => tenantData(state, tenantId));
    const _tenantSubscriptions = useSelector(selectTenantSubscriptions);
    const _isGlobalAdmin = useSelector(selectIsAccountGlobalAdmin);

    const _getSubscriptionBentoData = _isGlobalAdmin
        ? map(tenantApps, (url, key) => ({
              url,
              displayName: `${key[0].toUpperCase()}${key.slice(1, key.length)}`,
          }))
        : map(
              _tenantSubscriptions?.filter((sub) => sub.product.id !== 'membership'),
              (subscription) => ({
                  url: tenantApps[subscription.product.id],
                  displayName: subscription.product.displayName,
              }),
          );

    const _tenants = useSelector(tenants);

    const _searchTenants = useCallback(
        (search: string | undefined) => {
            dispatch(searchTenants(search));
        },
        [dispatch],
    );

    const _cleanupTenantsSearch = useCallback(() => {
        dispatch(cleanupTenantsSearch());
    }, [dispatch]);

    const _clearProcedures = useCallback(() => {
        dispatch(clearProcedures());
    }, [dispatch]);

    return {
        loading,
        errors,
        tenantData: _tenantData,
        tenants: _tenants,
        getSubscriptionBentoData: _getSubscriptionBentoData,

        searchTenants: _searchTenants,
        clearProcedures: _clearProcedures,
        cleanupTenantsSearch: _cleanupTenantsSearch,
    };
}

export default useTenant;
