import {
    ComboBox,
    DefaultButton,
    IComboBoxOption,
    Panel,
    PanelType,
    PrimaryButton,
    SelectableOptionMenuItemType,
    Stack,
    TextField,
    Toggle,
} from '@fluentui/react';
import ISlidingFeeProgram from 'api/models/slidingFee.model';
import { Field, SubSection } from 'components';
import useSlidingFee from 'hooks/store/useSlidingFee';
import { useEffect, useState } from 'react';
import { v4 as uuid } from 'uuid';

function ProgramPanel(): JSX.Element {
    const { createSlidingFeeProgram, isProgramPanelOpen, toggleIsProgramPanelOpen } = useSlidingFee();
    const [program, setProgram] = useState<ISlidingFeeProgram | undefined>(getProgram());

    const options: IComboBoxOption[] = [
        { key: 'selectAll', text: 'Select All', itemType: SelectableOptionMenuItemType.SelectAll },
        { key: 'A', text: 'Option A' },
        { key: 'B', text: 'Option B' },
        { key: 'C', text: 'Option C' },
        { key: 'D', text: 'Option D' },
    ];

    const onProgramPropertyChange = (key: keyof ISlidingFeeProgram, value: string | number | boolean | string[]) => {
        if (program) setProgram({ ...program, [key]: value });
    };

    const onProgramPropertyInclusionsChange = (key: keyof ISlidingFeeProgram, option?: IComboBoxOption | undefined) => {
        if (option && program) {
            let newIds: string[] = [];
            if (option?.itemType === SelectableOptionMenuItemType.SelectAll) {
                newIds = options.map((o) => o.key as string);
            } else {
                let keyIds: string[] | undefined = [];
                if (key === 'providerIds') keyIds = program.providerIds;
                if (key === 'providerIds') keyIds = program.providerIds;
                if (key === 'taxonomyCodes') keyIds = program.taxonomyCodes;
                if (keyIds && keyIds.length) {
                    const isIdExist = keyIds.includes(option.key + '');

                    if (isIdExist) {
                        newIds = keyIds.filter((id) => id !== option.key);
                    } else {
                        newIds = [...keyIds, option.key as string];
                    }
                } else {
                    newIds = [option.key as string];
                }
            }
            onProgramPropertyChange(key, newIds);
        }
    };

    const _onSave = () => {
        if (program) {
            createSlidingFeeProgram(program);
        }
    };

    const _onDismiss = () => {
        setProgram(undefined);

        toggleIsProgramPanelOpen();
    };

    const _onRenderFooterContent = () => {
        return (
            <Stack horizontal tokens={{ childrenGap: 12 }}>
                <PrimaryButton
                    text={'Save'}
                    iconProps={{ iconName: 'SaveAndClose' }}
                    disabled={disabledButton}
                    onClick={_onSave}
                />
                <DefaultButton text="Cancel" onClick={_onDismiss} />
            </Stack>
        );
    };

    const nameHasError = !program?.name ? true : false;
    const monthsForUpdateHasError = !program?.monthsForUpdate && program?.monthsForUpdate?.toString() === '' ? true : false;

    const disabledButton = nameHasError || monthsForUpdateHasError;

    return (
        <Panel
            isOpen={isProgramPanelOpen}
            onDismiss={_onDismiss}
            headerText={'Create Program'}
            styles={{
                content: { overflowY: 'auto', overflowX: 'hidden', flex: 1, position: 'relative' },
                root: { overflow: 'hidden' },
                scrollableContent: { overflow: 'hidden', display: 'flex', flexDirection: 'column' },
            }}
            type={PanelType.medium}
            onRenderFooterContent={_onRenderFooterContent}
            isFooterAtBottom={true}
        >
            <Stack tokens={{ childrenGap: 10 }}>
                <Stack tokens={{ childrenGap: 10 }} horizontal grow>
                    <Stack.Item grow>
                        <TextField
                            value={program?.name}
                            label="Name"
                            description={'The name of your sliding fee example Medical or Dental.'}
                            required
                            autoComplete="off"
                            onChange={(event, newValue) => onProgramPropertyChange('name', newValue ? newValue : '')}
                        />
                    </Stack.Item>
                </Stack>
                <Stack tokens={{ childrenGap: 10 }} horizontal grow>
                    <Stack.Item grow>
                        <Field.Date
                            value={program?.effectiveDate}
                            label="Effective Date"
                            onChange={(event, newValue) => onProgramPropertyChange('effectiveDate', newValue ? newValue : '')}
                        />
                    </Stack.Item>
                    <Stack.Item grow>
                        <Field.Date
                            value={program?.expirationDate}
                            label="Expiration Date"
                            onChange={(event, newValue) => onProgramPropertyChange('expirationDate', newValue ? newValue : '')}
                        />
                    </Stack.Item>
                    <Stack.Item grow>
                        <TextField
                            value={program?.monthsForUpdate ? program.monthsForUpdate.toString() : undefined}
                            label="Number of months between updates"
                            required
                            onChange={(event, newValue) =>
                                onProgramPropertyChange('monthsForUpdate', newValue ? parseInt(newValue) : '')
                            }
                            style={{ width: 280 }}
                        />
                    </Stack.Item>
                </Stack>
                <Stack tokens={{ childrenGap: 10 }} horizontal grow>
                    <Stack.Item grow>
                        <Toggle
                            checked={program?.isDeleted}
                            label="Status"
                            inlineLabel
                            // onChange={(event, newValue) => newValue && onProgramPropertyChange("isDeleted", newValue)}
                        />
                    </Stack.Item>
                </Stack>
                {/* <SubSection title="Inclusions">
                    <Stack tokens={{ childrenGap: 10 }} horizontal grow>
                        <ComboBox
                            label="locations of Care"
                            multiSelect
                            options={options}
                            selectedKey={program?.locationOfCareIds}
                            onChange={(ev, option) => option && onProgramPropertyInclusionsChange('locationOfCareIds', option)}
                        />
                        <ComboBox
                            label="Provider"
                            multiSelect
                            options={options}
                            selectedKey={program?.providerIds}
                            onChange={(ev, option) => option && onProgramPropertyInclusionsChange('providerIds', option)}
                        />
                        <ComboBox
                            label="Taxonomy"
                            multiSelect
                            options={options}
                            selectedKey={program?.taxonomyCodes}
                            onChange={(ev, option) => option && onProgramPropertyInclusionsChange('taxonomyCodes', option)}
                        />
                    </Stack>
                </SubSection> */}
            </Stack>
        </Panel>
    );
}

export default ProgramPanel;

function getProgram(): ISlidingFeeProgram {
    return {
        id: uuid(),
        isDeleted: false,
        isDefaultSlidingFeeProgram: false,
        isRyanWhite: false,
    };
}
