import { Icon, ITheme, memoizeFunction, mergeStyleSets, Stack, Text, useTheme } from '@fluentui/react';
import usePermissions, { Permission } from 'hooks/usePermissions';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { FeatureFlag, selectActiveFeatureFlagsLookup } from 'state/slices/tenant/feature-flags.slice';

export interface ICategoryCard {
    displayName: string;
    path: string;
    permissions?: Permission[];
    featureFlag?: FeatureFlag;
    icon?: string;
}

export function CategoryCard(props: ICategoryCard): JSX.Element | null {
    const theme = useTheme();
    const { push } = useHistory();
    const featureFlags = useSelector(selectActiveFeatureFlagsLookup);
    const hasFeatureFlag = (props.featureFlag ? featureFlags.has(props.featureFlag) : true);
    const [hasPermission] = usePermissions(props.permissions);

    if ((!hasPermission && props.permissions?.length) || !hasFeatureFlag)
        return null;

    const { root, text, icon } = getClasses(theme);

    const _onClick = () => {
        push(props.path);
    };

    return (
        <Stack className={root} horizontalAlign="center" onClick={_onClick}>
            <Stack horizontalAlign="center" tokens={{ childrenGap: 20 }}>
                <Text className={text}>{props.displayName}</Text>
                {props.icon ? <Icon iconName={props.icon} className={icon} /> : null}
            </Stack>
        </Stack>
    );
}

const classNames = {
    root: 'card',
    text: 'card_text',
    icon: 'card_icon',
};

const getClasses = memoizeFunction((theme: ITheme) =>
    mergeStyleSets({
        root: [
            classNames.root,
            {
                width: 200,
                height: 100,
                padding: 10,
                background: theme.palette.themeSecondary,
                cursor: 'pointer',
                borderRadius: 3,
                transition: 'background .15s',
                [`:hover`]: {
                    background: theme.palette.themePrimary,
                },
            },
        ],
        text: [
            classNames.text,
            {
                fontSize: 12,
                color: theme.palette.white,
            },
        ],
        icon: [
            classNames.icon,
            {
                fontSize: 32,
                color: theme.palette.white,
            },
        ],
    }),
);
