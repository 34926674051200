import { PayloadAction } from '@reduxjs/toolkit';
import ProcedureDetailsState from './procedureDetails.state';
import IProcedureDetail, { IProcedureFee } from 'api/models/procedure-details.model';
import { v4 as uuid } from 'uuid';

const procedureDetailsReducers = {
    /**
     * Remove data for the currently selected procedure detail
     */
    cleanupSelectedProcedureDetail: (state: ProcedureDetailsState) => {
        state.selectedProcedureDetail = undefined;
    },
    /**
     * Set a property value on the currently selected procedure detail
     */
    setSelectedProcedureDetailProp: (
        state: ProcedureDetailsState,
        action: PayloadAction<{ path: keyof IProcedureDetail; value: any }>,
    ) => {
        if (state.selectedProcedureDetail) {
            const { path, value } = action.payload;
            (state.selectedProcedureDetail[path] as unknown) = value;
        }
    },
    /**
     * Set a property value of a procedure detail with the specified id.
     */
    setProcedureDetailsDataProp: (
        state: ProcedureDetailsState,
        action: PayloadAction<{ procedureId: string; path: keyof IProcedureDetail; value: any }>,
    ) => {
        const { procedureId, path, value } = action.payload;
        if (!state.data[procedureId]) (state.data[procedureId] as unknown) = {};
        if (state.data) {
            ((state.data[procedureId] as IProcedureDetail)[path] as unknown) = value;
        }
    },
    /**
     * Set search value for procedures on the procedure detail page
     */
    setProcedureDetailsSearch: (state: ProcedureDetailsState, action: PayloadAction<string | undefined>) => {
        state.procedureDetailsSearch = action.payload;
    },
    cleanupProcedureDetailsSearch: (state: ProcedureDetailsState, action: PayloadAction<string | undefined>) => {
        state.procedureDetailsSearch = undefined;
    },
    updateProcedureFee: (
        state: ProcedureDetailsState,
        action: PayloadAction<{ index: number; path: keyof IProcedureFee; value: unknown }>,
    ) => {
        if (state.selectedProcedureDetail?.fees) {
            const { index, path, value } = action.payload;
            (state.selectedProcedureDetail.fees[index] as any)[path] = value as number | boolean | string;
        }
    },
    createProcedureFee: (state: ProcedureDetailsState) => {
        const newProcedureFee: IProcedureFee = {
            id: uuid(),
            fee: 0,
        };

        const newProcedureFees = [
            newProcedureFee,
            ...(state.selectedProcedureDetail?.fees
                ? state.selectedProcedureDetail.fees.map((fee) => ({
                      ...fee,
                  }))
                : []),
        ];

        if (state.selectedProcedureDetail) {
            state.selectedProcedureDetail['fees'] = newProcedureFees;
        }
    },
    toggleProceduresList: (state: ProcedureDetailsState) => {
        state.isProcedureActive = !state.isProcedureActive;
    },
};

export default procedureDetailsReducers;
