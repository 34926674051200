import { SelectionMode, Stack, Text } from '@fluentui/react';
import { ITenantUser } from 'api/models/tenantAccount.model';
import { EditDetailsColumn } from 'components/EditDetailsColumn';
import SortableDetailsList from 'components/SortableDetailsList/SortableDetailsList';
import StatusCircle from 'components/StatusCircle';
import useUsers from 'hooks/store/useUsers';

type Props = {
    setPanelOpen: () => void;
    searchTerm: string;
    results: ITenantUser[];
};

function UsersDetailsList({ searchTerm, setPanelOpen, results }: Props) {
    const { selectFilteredUserList, setSelectedUser } = useUsers();

    const _onItemInvoked = (item?: ITenantUser) => {
        if (item?.identity) {
            setPanelOpen();
            setSelectedUser(item);
        } else {
            // Warn that user is inaccessible?
        }
    };

    return (
        <SortableDetailsList
            onItemInvoked={_onItemInvoked}
            items={results && searchTerm ? results : selectFilteredUserList}
            sortOnMount={true}
            sortColumns={['lastName']}
            selectionMode={SelectionMode.none}
            columns={[
                {
                    key: 'name',
                    fieldName: 'first',
                    minWidth: 100,
                    maxWidth: 120,
                    name: 'First',
                    getValueKey: (item?: ITenantUser) => {
                        return item?.identity.firstName ?? '';
                    },
                    onRender: (item?: ITenantUser) => {
                        if (item && item.identity) {
                            return (
                                <EditDetailsColumn
                                    editOnClick={() => _onItemInvoked(item)}
                                    title={item ? item?.identity.firstName : ''}
                                />
                            );
                        } else {
                            return undefined;
                        }
                    },
                },
                {
                    key: 'lastName',
                    fieldName: 'last',
                    minWidth: 100,
                    maxWidth: 100,
                    name: 'Last',
                    getValueKey: (item?: ITenantUser) => {
                        return item?.identity.lastName ?? '';
                    },
                    onRender: (item?: ITenantUser) => {
                        if (item && item.identity) {
                            return (
                                <EditDetailsColumn
                                    editOnClick={() => _onItemInvoked(item)}
                                    title={item ? item?.identity.lastName : ''}
                                />
                            );
                        } else {
                            return undefined;
                        }
                    },
                },
                {
                    key: 'email',
                    fieldName: 'email',
                    minWidth: 100,
                    maxWidth: 250,
                    name: 'E-Mail',
                    getValueKey: (item?: ITenantUser) => {
                        return item?.identity.email ?? '';
                    },
                    onRender: (item?: ITenantUser) => {
                        if (item && item.identity) {
                            return (
                                <EditDetailsColumn
                                    editOnClick={() => _onItemInvoked(item)}
                                    title={item ? item?.identity.email : ''}
                                />
                            );
                        } else {
                            return undefined;
                        }
                    },
                },
                {
                    key: 'status',
                    name: 'Status',
                    minWidth: 100,
                    maxWidth: 120,
                    fieldName: 'isDeleted',
                    getValueKey: (item?: ITenantUser) => {
                        return item?.isDeleted ? 'disabled' : 'active';
                    },
                    onRender: (item?: ITenantUser) => {
                        if (!item) return null;

                        const activeText = item.isDeleted ? 'Disabled' : 'Active';
                        return (
                            <Stack horizontal verticalAlign="center" tokens={{ childrenGap: 6 }}>
                                <StatusCircle isActive={item.isDeleted ? false : true} />
                                <Text variant="smallPlus">{activeText}</Text>
                            </Stack>
                        );
                    },
                },
            ]}
        />
    );
}

export default UsersDetailsList;
