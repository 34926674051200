import React, { useState, useEffect } from 'react';
import { Breadcrumb, IBreadcrumbItem } from '@fluentui/react';
import { useHistory, useLocation, useParams } from 'react-router-dom';

const BreadcrumbMenu = () => {
    const history = useHistory();
    const { pathname } = useLocation();
    const params = useParams<{ tenantId: string }>();
    const { tenantId } = params;

    const getParams = (): { path: string; itemId: string } => {
        const items = pathname.split('/').slice(1);
        return {
            path: items[1],
            itemId: items[2],
        };
    };

    const { path } = getParams();
    const [breadcrumbItems, setBreadcrumbItems] = useState<IBreadcrumbItem[]>([]);

    useEffect(() => {
        setBreadcrumbItems([
            {
                text: 'Overview',
                key: 'overview',
                onClick: () => history.push(`/${tenantId}/`),
            },
        ]);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (breadcrumbItems.length) {
            switch (path) {
                case 'subscriptions':
                    setPathBreadcrumb(`${tenantId}/${path}`, 'Subscriptions');
                    break;
                case 'users':
                    setPathBreadcrumb(`${tenantId}/${path}`, 'Manage Users');
                    break;
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [path, breadcrumbItems.length]);

    function setPathBreadcrumb(path: string, text?: string, itemId?: string) {
        let breadcrumbs = breadcrumbItems;

        if (breadcrumbs.length > 1) {
            breadcrumbs = breadcrumbs.slice(0, 1);
        }

        breadcrumbs.push({
            key: path,
            text: text!,
            onClick: () => history.push(`/${path}`),
        });

        if (itemId) {
            breadcrumbs.push({
                key: `${path}-item`,
                text: '',
                onClick: () => history.push(`/${path}/${itemId}`),
            });
        }

        setBreadcrumbItems(breadcrumbs);
    }

    return (
        <Breadcrumb
            styles={{ root: { margin: 0 } }}
            items={breadcrumbItems}
            maxDisplayedItems={3}
        />
    );
};

export default BreadcrumbMenu;
