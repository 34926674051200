import { LoadingStatus } from 'interfaces/loading-statuses';
import { IUserTaskItemList } from './model';

export type UserTaskState = {
    userTasks: IUserTaskItemList;
    userTasksLoading: LoadingStatus;
};

export const initialState: UserTaskState = {
    userTasks: [],
    userTasksLoading: LoadingStatus.Idle,
};
