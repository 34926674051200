import { HubConnectionState } from '@microsoft/signalr';
import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'state/store';

type SignalRState = {
    connectionStatus: HubConnectionState;
};

const initialSignalRState: SignalRState = {
    connectionStatus: HubConnectionState.Disconnected,
};

const signalRSlice = createSlice({
    name: 'signalr',
    initialState: initialSignalRState,
    reducers: {
        setHubConnectionStatus: (state: SignalRState, action: PayloadAction<HubConnectionState>): void => {
            state.connectionStatus = action.payload;
        },
    },
});

const { reducer, actions } = signalRSlice;

export const { setHubConnectionStatus } = actions;

export default reducer;

export const selectSignalRState = (state: RootState) => state.signalr;
export const selectSignalRConnectionStatus = createSelector(selectSignalRState, (state) => state.connectionStatus);
export const selectSignalRIsConnected = createSelector(
    selectSignalRState,
    (state) => state.connectionStatus === HubConnectionState.Connected,
);
