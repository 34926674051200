import { createSlice } from '@reduxjs/toolkit';
import { initialState } from './state';
import { extraReducers, reducers } from './reducers';

const tasksSlice = createSlice({
    name: 'userTasks',
    initialState,
    reducers,
    extraReducers,
});

const { reducer } = tasksSlice;

export const { setNewOrUpdatedUserTask, removeCompletedUserTasks } = tasksSlice.actions;

export default reducer;
