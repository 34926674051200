import { PrimaryButton, SearchBox, Spinner, SpinnerSize } from '@fluentui/react';
import { ActivityPage } from 'components';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getAllEncounterReasons, refreshEncounterReasons } from 'state/slices/encounters/encounters.actions';
import { encounterReasonsLoading, encountersReasonsAsList } from 'state/slices/encounters/encounters.selectors';
import { setEncountersSearch } from 'state/slices/encounters/encounters.slice';
import EncounterReasonsList from './components/EncounterReasonsList';
import { getUserTasksByTarget } from 'state/slices/tasks/actions';
import { UserTaskTarget } from 'state/slices/tasks/model';
import { TaskType, selectUserTasksByTarget } from 'state/slices/tasks/selectors';

export default function EncounterReasons() {
    const { tenantId } = useParams<{
        tenantId: string;
    }>();
    const encountersData = useSelector(encountersReasonsAsList);
    const encountersLoading = useSelector(encounterReasonsLoading);
    const [search, setSearch] = useState<string | undefined>('');
    const dispatch = useDispatch();
    const task = useSelector(selectUserTasksByTarget);

    let timer: NodeJS.Timeout | null = null;
    const handleChange = (value: string) => {
        if (timer) clearTimeout(timer);
        timer = setTimeout(() => {
            dispatch(setEncountersSearch(value ? value : ''));
        }, 200);
    };

    useEffect(() => {
        dispatch(getUserTasksByTarget({ tenantId, target: UserTaskTarget.TenantSetup }));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch]);

    useEffect(() => {
        dispatch(getAllEncounterReasons({ tenantId }));

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tenantId, dispatch, getAllEncounterReasons]);

    const disableRefreshTask = task?.some((t) => t.type === TaskType.RefreshingEncounterReason);

    return (
        <ActivityPage
            rightContent={
                <>
                    {!disableRefreshTask ? (
                        <PrimaryButton
                            text={'Refresh Data'}
                            onClick={(res) => {
                                dispatch(refreshEncounterReasons({ tenantId }));

                                setSearch('');
                            }}
                        />
                    ) : (
                        <Spinner size={SpinnerSize.medium} />
                    )}
                    <SearchBox
                        onChange={(ev, value) => {
                            const newValue = value ?? '';
                            setSearch(newValue);
                            if (newValue === '') {
                                dispatch(setEncountersSearch(''));
                            } else {
                                handleChange(value ? value : '');
                            }
                        }}
                        onSearch={(value) => dispatch(setEncountersSearch(value ? value : ''))}
                        value={search ? search : ''}
                        style={{ minWidth: 200 }}
                        placeholder="Search"
                    />
                </>
            }
            title="Encounter Reasons"
            hideFooter
            hasPageHeader
        >
            <EncounterReasonsList items={encountersData} loading={encountersLoading} />
        </ActivityPage>
    );
}
