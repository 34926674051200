import { IEncounter } from 'api/models/encounter.model';
import { LoadingStatuses } from 'interfaces/loading-statuses';
import { map } from 'lodash';
import { createSelector } from 'reselect';
import { RootState } from 'state/store';
import { EncounterState } from './encounters.slice';

const encountersReasons = (state: RootState): EncounterState => state.encounters;
export const encounterReasonsLoading = (state: RootState): LoadingStatuses =>
    state.encounters.loading;
export const encountersReasonsData = createSelector(encountersReasons, (state) => state.data);
export const encountersSearch = (state: RootState): string | undefined =>
    state.encounters.encountersSearch;

export const encountersReasonsAsList = createSelector([encountersReasonsData], (res) => {
    return map(res).filter((res) => res !== undefined) as IEncounter[];
});
