import { IColumn, SelectionMode, Stack, Text } from '@fluentui/react';
import { ITenantUser } from 'api/models/tenantAccount.model';
import { ITenantSubscription } from 'api/models/tenantSubscription.model';
import { EditDetailsColumn } from 'components/EditDetailsColumn';
import SortableDetailsList from 'components/SortableDetailsList/SortableDetailsList';
import StatusCircle from 'components/StatusCircle';
import { useSelector } from 'hooks';
import useSubscriptionUsers from 'hooks/store/useSubscriptionUsers';
import { identity } from 'lodash';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import { user } from 'state/slices';
import { selectProviderProfilesAsList } from 'state/slices/providerProfiles/providerProfiles.selectors';
import { setSelectProviderProfile } from 'state/slices/providerProfiles/providerProfiles.slice';

import { selectSubscriptionUsersAsProviderList } from 'state/slices/subscriptions/users/users.selectors';

import { selectTenantSubscriptions } from 'state/slices/tenant/selectors';

type Props = {
    searchTerm: string;
    results: ITenantUser[];
    isEditorOpen: boolean;
    onEditorOpen: () => void;
    onEditorClose: () => void;
};

function UsersDetailsList({ searchTerm, onEditorOpen, results }: Props) {
    const params = useParams<{ tenantId: string; productId: string }>();
    const { tenantId, productId } = params;

    const { setSelectedUser } = useSubscriptionUsers();
    const providerProfiles = useSelector(selectProviderProfilesAsList);

    const subscriptionsUsers = useSelector(selectSubscriptionUsersAsProviderList);

    const _tenantSubscriptions = useSelector(selectTenantSubscriptions);
    const dispatch = useDispatch();

    const _onItemInvoked = (item?: ITenantUser) => {
        if (item) {
            const providerProfile = providerProfiles.find((providerProfile) => providerProfile?.id === item.identity.providerId);
            onEditorOpen();
            setSelectedUser(item);
            dispatch(setSelectProviderProfile(providerProfile));
        } else {
            // Warn that user is inaccessible?
        }
    };

    const subscriptions = useSelector((state) => {
        if (state.tenants.data) {
            const tenant = state.tenants.data[tenantId];

            if (tenant) return _tenantSubscriptions;
        }

        return [] as ITenantSubscription[];
    });

    const getRoleNames = (roles: string[] | undefined) => {
        const subIndex = subscriptions?.findIndex((e) => e.productId === productId) as number;
        if (subscriptions && subIndex > -1) {
            const productRoles = subscriptions[subIndex].productRoles;

            return roles
                ?.map((role) => {
                    const roleIndex = productRoles?.findIndex((e) => e.id === role);

                    if (roleIndex > -1) return productRoles[roleIndex].displayName;
                    else return undefined;
                })
                .filter((e) => {
                    return e;
                })
                .join(', ');
        }

        return '';
    };

    const getProviderTypeName = (item?: ITenantUser) => {
        if (item) {
            const providerProfile = providerProfiles.find((providerProfile) => providerProfile?.id === item.identity.providerId);

            return <Text variant="smallPlus">{providerProfile?.providerTypeMapping}</Text>;
        }

        return '';
    };

    const getGridColumns = useCallback(() => {
        const firstColumns: IColumn[] = [
            {
                key: 'firstName',
                fieldName: 'firstName',
                minWidth: 100,
                maxWidth: 120,
                name: 'First',
                getValueKey: (item?: ITenantUser) => {
                    return item?.identity.firstName ?? '';
                },
                onRender: (item?: ITenantUser) => {
                    if (item && item.identity) {
                        return (
                            <EditDetailsColumn
                                editOnClick={() => _onItemInvoked(item)}
                                title={item ? item?.identity.firstName : ''}
                            />
                        );
                    } else {
                        return undefined;
                    }
                },
            },
            {
                key: 'lastName',
                fieldName: 'lastName',
                minWidth: 100,
                maxWidth: 100,
                name: 'Last',
                getValueKey: (item?: ITenantUser) => {
                    return item?.identity.lastName ?? '';
                },
                onRender: (item?: ITenantUser) => {
                    if (item && item.identity) {
                        return (
                            <EditDetailsColumn
                                editOnClick={() => _onItemInvoked(item)}
                                title={item ? item?.identity.lastName : ''}
                            />
                        );
                    } else {
                        return undefined;
                    }
                },
            },
        ];

        if (productId !== 'membership')
            firstColumns.push({
                key: 'providerType',
                fieldName: 'providerType',
                minWidth: 100,
                maxWidth: 150,
                name: 'Provider Type',
                getValueKey: (item?: ITenantUser) => {
                    return item?.identity.providerId ?? '';
                },
                onRender: getProviderTypeName,
            });

        return [
            ...firstColumns,
            {
                key: 'email',
                fieldName: 'email',
                minWidth: 100,
                maxWidth: 250,
                name: 'E-Mail',
                getValueKey: (item?: ITenantUser) => {
                    return item?.identity.email ?? '';
                },
                onRender: (item?: ITenantUser) => {
                    if (item && item.identity) {
                        return (
                            <EditDetailsColumn
                                editOnClick={() => _onItemInvoked(item)}
                                title={item ? item?.identity.email : ''}
                            />
                        );
                    } else {
                        return undefined;
                    }
                },
            },
            {
                key: 'status',
                name: 'Status',
                minWidth: 100,
                maxWidth: 100,
                fieldName: 'isDeleted',
                getValueKey: (item?: ITenantUser) => {
                    return item?.isDeleted ? 'disabled' : 'active';
                },
                onRender: (item?: ITenantUser) => {
                    if (!item) return null;

                    const activeText = item.isDeleted ? 'Disabled' : 'Active';
                    return (
                        <Stack horizontal verticalAlign="center" tokens={{ childrenGap: 6 }}>
                            <StatusCircle isActive={item.isDeleted ? false : true} />
                            <Text variant="smallPlus">{activeText}</Text>
                        </Stack>
                    );
                },
            },
            {
                key: 'roles',
                name: 'Role(s)',
                minWidth: 100,
                maxWidth: 400,
                isResizable: true,
                fieldName: 'roles',
                onRender: (item?: ITenantUser) => {
                    if (!item) return null;

                    return (
                        <Stack horizontal verticalAlign="center" tokens={{ childrenGap: 6 }}>
                            <Text variant="smallPlus">{getRoleNames(item.permissions)}</Text>
                        </Stack>
                    );
                },
            },
            {
                key: 'providerId',
                fieldName: 'providerId',
                name: 'athenaOne ID',
                minWidth: 100,
                maxWidth: 200,
                isResizable: true,
                getValueKey: (item?: ITenantUser) => {
                    const parsedProviderId = item?.identity.providerId ? item.identity.providerId : '0';

                    return parsedProviderId;
                },
                onRender: (item?: ITenantUser) => {
                    if (!item) return null;
                    const providerId = item.identity.providerId;
                    const providerText = providerId && providerId !== '000' ? providerId : providerId === '000' ? 'Unmapped' : '';
                    return <Text variant="smallPlus">{providerText}</Text>;
                },
            },
        ];
    }, [productId]);

    return (
        <SortableDetailsList
            onItemInvoked={_onItemInvoked}
            items={searchTerm ? results : subscriptionsUsers}
            sortOnMount={true}
            initialSortDirection={'asc'}
            sortColumns={['lastName']}
            selectionMode={SelectionMode.none}
            columns={getGridColumns()}
            stickyHeader
        />
    );
}

export default UsersDetailsList;
