import { useSelector } from 'hooks';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { getAccount, selectAccount } from 'state/slices/account.slice';

export default function useAccount() {
    const dispatch = useDispatch();
    const _account = useSelector(selectAccount);
    const _getAccount = useCallback(() => dispatch(getAccount()), [dispatch]);

    return {
        account: _account,
        getAccount: _getAccount,
    };
}
