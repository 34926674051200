import React, { useEffect } from 'react';
import {
    mergeStyleSets,
    getFocusStyle,
    getTheme,
    ITheme,
    FontSizes,
    FocusZone,
    FocusZoneDirection,
    List,
    Stack,
    SearchBox,
} from '@fluentui/react';
import { Field } from 'components';
import { useSelector } from 'hooks';
import { tenants, cleanupTenantsSearch, searchTenants } from 'state/slices/tenants/tenants.slice';
import { useDispatch } from 'react-redux';
import ITenantAccount from 'api/models/tenantAccount.model';
import { useFuseSearch } from 'hooks/useFuseSearch';

export type Props = {
    items?: ITenantAccount[];
    onClick: (item: ITenantAccount) => void;
};

const theme: ITheme = getTheme();
const { palette, semanticColors } = theme;

export default function CustomerSelection({ onClick, items }: Props) {
    const dispatch = useDispatch();
    const _tenants = useSelector(tenants);

    const { setSearch, search, results } = useFuseSearch({
        list: _tenants,
        fuseOptions: { threshold: 0.1, keys: ['displayName', 'id'] },
    });

    useEffect(() => {
        return () => {
            dispatch(cleanupTenantsSearch());
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const classNames: any = mergeStyleSets({
        item: [
            getFocusStyle(theme, { inset: -1 }),
            {
                padding: 10,
                boxSizing: 'border-box',
                borderBottom: `1px solid ${semanticColors.bodyDivider}`,
                display: 'flex',
                fontSize: FontSizes.xLargePlus,
                selectors: {
                    '&:hover': {
                        background: palette.themePrimary,
                        color: palette.white,
                        cursor: 'pointer',
                    },
                },
            },
        ],
        itemContainer: {
            overflow: 'hidden',
            flexGrow: 1,
        },
    });

    const _onRenderCell = (item: ITenantAccount | undefined): JSX.Element => {
        return item ? (
            <div className={classNames.itemContainer} data-is-focusable={true}>
                <div className={classNames.item} id={`tenant-${item.displayName}-${item.id}`} onClick={() => onClick(item)}>
                    {item.displayName} ({item.id})
                </div>
            </div>
        ) : (
            <></>
        );
    };

    return (
        <FocusZone direction={FocusZoneDirection.vertical}>
            <SearchBox placeholder="Search... " value={search} onChange={(ev, value) => setSearch(value ?? '')} />

            <Stack style={{ overflow: 'auto', maxHeight: 500 }}>
                <List items={results && search ? results : _tenants} onRenderCell={_onRenderCell} />
            </Stack>
        </FocusZone>
    );
}
