import { Field } from 'components';
import { ISearchComboFieldProps } from 'components/Field/SearchComboField';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    getProfessionalSuffixes,
    professionalSuffixCategories,
} from 'state/slices/professionalsuffixes/professionalsuffixes.slice';

type ProfessionalSuffixInputProps = Omit<ISearchComboFieldProps, 'options'>;

export default function ProfessionalSuffixInput(props: ProfessionalSuffixInputProps) {
    const profSuffixOptions = useSelector(professionalSuffixCategories);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getProfessionalSuffixes());
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Field.SearchCombo
            label="Professional Suffix"
            placeholder="(Select professional suffix)"
            allowFreeform
            options={profSuffixOptions}
            {...props}
        />
    );
}
