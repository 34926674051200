import { useEffect, useReducer, FormEvent } from 'react';
import { MaskedTextField, ITextFieldProps } from '@fluentui/react';

type Props = { handleError?: (error: string | undefined) => void } & ITextFieldProps;

type State = {
    value?: string;
    fieldVisible: boolean;
};

type Actions = { type: 'setInternalValue'; value?: string };

const initialState = (value: string): State => ({
    value,
    fieldVisible: false,
});

const reducer = (state: State, action: Actions) => {
    switch (action.type) {
        case 'setInternalValue':
            return { ...state, value: action.value };
        default:
            return state;
    }
};

const isValueEmpty = (value?: string) => value === '___-__-____';

const validSSN = (value: string | undefined) => {
    return (value && /^(?!(000|666|9))(\d{3}-?(?!(00))\d{2}-?(?!(0000))\d{4})$/.test(value)) ||
        value === '' ||
        isValueEmpty(value)
        ? true
        : false;
};
const SSNField = ({ value, onChange, style, handleError, ...props }: Props): JSX.Element => {
    const [state, dispatch] = useReducer(reducer, value ? value : '', initialState);

    useEffect(() => {
        if (value) {
            dispatch({ type: 'setInternalValue', value });
        }
    }, [value]);

    const onGetErrorMessage = (value?: string) => {
        const ssnErrorMessage = validSSN(value) ? undefined : 'Invalid SSN';
        if (handleError) handleError(ssnErrorMessage);
        return ssnErrorMessage;
    };

    const handleChange = (e: FormEvent<HTMLTextAreaElement | HTMLInputElement>, value?: string) => {
        dispatch({ type: 'setInternalValue', value });
        if (value && onChange) {
            if (validSSN(value)) {
                onChange(e, value);
            } else {
                onChange(e, '');
            }
            if (isValueEmpty(state.value)) {
                onChange(e, '');
            }
        }
    };

    return (
        <MaskedTextField
            {...props}
            type="password"
            canRevealPassword
            onChange={handleChange}
            label="SSN"
            validateOnFocusOut
            validateOnLoad={value ? true : false}
            onGetErrorMessage={onGetErrorMessage}
            value={state.value}
            autoComplete="false"
            mask="999-99-9999"
            style={{ ...style }}
        />
    );
};

export default SSNField;
