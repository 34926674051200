import {
    Dialog,
    DialogFooter,
    DialogType,
    PrimaryButton,
    Spinner,
    SpinnerSize,
    Stack,
    Text,
} from '@fluentui/react';
import { useBoolean, useId } from '@uifabric/react-hooks';
import { Field } from 'components';
import { LoadingStatus, LoadingStatuses } from 'interfaces/loading-statuses';
import { ChangeEvent, useMemo } from 'react';

type UploadFileProps = {
    fileData?: File;
    disabled?: boolean;
    onFileSelected?: (value: any) => void;
    pendingFileLoading?: LoadingStatuses;
};

const UploadFile = ({
    fileData,
    disabled,
    onFileSelected,
    pendingFileLoading,
}: UploadFileProps): JSX.Element => {
    const [hideDialog, { toggle: toggleHideDialog }] = useBoolean(true);

    const _onFilesSelected = (_file: File) => {
        if (onFileSelected) onFileSelected(_file);
    };

    const onFileSelect = (e: ChangeEvent<HTMLInputElement>) => {
        const { files } = e.target;
        if (files && files.length === 1) {
            const file = files[0];
            _onFilesSelected(file);
        } else {
            toggleHideDialog();
        }
    };

    const dialogContentProps = {
        type: DialogType.normal,
        title: 'Alert',
        closeButtonAriaLabel: 'Close',
        subText: 'More than one file was uploaded.',
    };

    const labelId: string = useId('dialogLabel');
    const subTextId: string = useId('subTextLabel');
    const dialogStyles = { main: { maxWidth: 450 } };

    const modalProps = useMemo(
        () => ({
            titleAriaId: labelId,
            subtitleAriaId: subTextId,
            isBlocking: false,
            styles: dialogStyles,
        }),
        [labelId, subTextId],
    );

    return (
        <Stack>
            <Stack tokens={{ childrenGap: 5 }} horizontal verticalAlign="center">
                <Field.FileInput
                    text={'Upload Excel'}
                    disabled={disabled}
                    onChange={onFileSelect}
                    onRenderIcon={() => (
                        <>
                            {pendingFileLoading === LoadingStatus.Pending ? (
                                <Spinner
                                    size={SpinnerSize.small}
                                    styles={{ root: { marginRight: 5 } }}
                                />
                            ) : null}
                        </>
                    )}
                    primary
                />
                {fileData?.name ? (
                    <Text styles={{ root: { padding: '0px 5px 0px 5px' } }}>{fileData.name}</Text>
                ) : null}
            </Stack>
            <Dialog
                hidden={hideDialog}
                onDismiss={toggleHideDialog}
                dialogContentProps={dialogContentProps}
                modalProps={modalProps}
            >
                <DialogFooter>
                    <PrimaryButton onClick={toggleHideDialog} text="Ok" />
                </DialogFooter>
            </Dialog>
        </Stack>
    );
};

export default UploadFile;
