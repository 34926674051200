import { IColumn, IconButton, Link, MessageBar, MessageBarType, PrimaryButton, SelectionMode, Stack } from '@fluentui/react';
import { Section, SortableDetailsList } from 'components';
import React from 'react';
import { setSelectedPracticeStatementNote, toggleExampleModal, toggleStatementConfigurationPanel } from '../state/slice';
import { useSelector } from 'hooks';
import { disableStatementConfig, statementConfigurations } from '../state/selectors';
import { useDispatch } from 'react-redux';
import { IAdditionalText } from '../state/models';
import { classicDateOnly } from 'utils/dateOnly';
import { updateStatementConfiguration } from '../state/actions';
import useTenantId from 'hooks/useTenantId';
import { v4 as uuid } from 'uuid';
import { isDateBetween } from 'utils/isDateBetween';

const PracticeStatementNotes = () => {
    const practiceNotesList = useSelector(statementConfigurations);
    const dispatch = useDispatch();
    const tenantId = useTenantId();
    const _disableStatementConfig = useSelector(disableStatementConfig);

    const onEdit = (practiceStatementNote: IAdditionalText) => {
        dispatch(toggleStatementConfigurationPanel());
        dispatch(setSelectedPracticeStatementNote(practiceStatementNote));
    };

    const onDelete = (practiceStatementNote: IAdditionalText) => {
        if (practiceNotesList && practiceNotesList.additionalText) {
            const updatedAdditionalText = practiceNotesList.additionalText.filter(
                (note) => note.id !== practiceStatementNote?.id,
            );
            const updatedConfig = {
                ...practiceNotesList,
                additionalText: [...updatedAdditionalText],
            };

            dispatch(updateStatementConfiguration({ tenantId, statementConfiguration: updatedConfig }));
        }
    };

    const columns: IColumn[] = [
        {
            key: 'displayName',
            name: 'Name',
            fieldName: 'displayName',
            minWidth: 100,
            maxWidth: 120,
            isResizable: true,
            onRender: (item: IAdditionalText) => {
                return !_disableStatementConfig ? <Link onClick={() => onEdit(item)}>{item.displayName}</Link> : item.displayName;
            },
        },
        {
            key: 'message',
            name: 'Message',
            fieldName: 'message',
            minWidth: 100,
            maxWidth: 220,
            isResizable: true,
            isMultiline: true,
        },
        {
            key: 'startDate',
            name: 'Effective Date',
            fieldName: 'startDate',
            minWidth: 100,
            maxWidth: 120,
            onRender: (item) => {
                return item.startDate ? classicDateOnly(item.startDate) : '';
            },
            isResizable: true,
        },
        {
            key: 'endDate',
            name: 'Expiration Date',
            fieldName: 'endDate',
            minWidth: 100,
            onRender: (item) => {
                return item.endDate ? classicDateOnly(item.endDate) : '';
            },
            isResizable: true,
        },

        {
            key: 'delete',
            name: 'Delete',
            fieldName: 'delete',
            minWidth: 100,
            onRender: (item) => {
                return (
                    <IconButton
                        iconProps={{ iconName: 'Delete' }}
                        disabled={_disableStatementConfig}
                        onClick={() => {
                            onDelete(item);
                        }}
                    />
                );
            },
        },
    ];

    const addPracticeStatementNote = () => {
        dispatch(setSelectedPracticeStatementNote(newPracticeStatementNote()));
        dispatch(toggleStatementConfigurationPanel());
    };

    //disable add button if 3 actives notes

    const disableAddButton = practiceNotesList?.additionalText
        ? practiceNotesList?.additionalText?.filter((note) =>
              isDateBetween({
                  dateToCheck: new Date().toISOString(),
                  start: note.startDate,
                  end: note.endDate,
              }),
          ).length >= 1
        : false;

    return (
        <Section
            heading="Practice Statement Notes"
            headingRightContent={
                <>
                    <PrimaryButton disabled={disableAddButton || _disableStatementConfig} onClick={addPracticeStatementNote}>
                        Add
                    </PrimaryButton>
                </>
            }
        >
            <Stack tokens={{ childrenGap: 5 }} grow>
                <MessageBar messageBarType={MessageBarType.info} isMultiline>
                    {`A maximum of one active Practice Statement Note is allowed.  Delete or expire existing Practice Statement Notes to enable the "New" button. `}
                </MessageBar>
                <Link onClick={() => dispatch(toggleExampleModal())}> View Examples</Link>
                <SortableDetailsList
                    items={practiceNotesList?.additionalText ?? []}
                    sortOnMount={true}
                    initialSortDirection={'asc'}
                    sortColumns={['displayName']}
                    selectionMode={SelectionMode.none}
                    columns={columns}
                    stickyHeader
                />
            </Stack>
        </Section>
    );
};

function newPracticeStatementNote(): IAdditionalText {
    return {
        id: uuid(),
        displayName: '',
        message: '',
        startDate: '',
        endDate: '',
        defaultLanguageId: 'eng',
    };
}

export default PracticeStatementNotes;
