import useSelector from 'hooks/useSelector';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import {
    selectExpanded, setExpanded,
} from 'state/slices/subscriptions/subscriptions.slice';

function useSubscriptions() {
    const dispatch = useDispatch();

    const isExpanded = useSelector(selectExpanded);

    const _toggleExpanded = useCallback(() => {
        dispatch(setExpanded(!isExpanded));
    }, [dispatch, isExpanded]);

    return {
        toggleExpanded: _toggleExpanded,

        isExpanded
    };
}

export default useSubscriptions;