import { useTheme } from '@fluentui/react';

function StatusCircle(props: { isActive: boolean }) {
    const theme = useTheme();
    const circleBgColor = props.isActive ? theme.semanticColors.successIcon : theme.semanticColors.errorIcon;
    return (
        <span
            style={{
                width: 10,
                height: 10,
                backgroundColor: circleBgColor,
                display: 'inline-block',
                borderRadius: '50%',
            }}
        ></span>
    );
}

export default StatusCircle;
