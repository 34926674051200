import { ExtraReducer } from 'state/store';
import { ITenantState } from './state';
import { LoadingStatus } from 'interfaces/loading-statuses';
import { getTenant, getTenantConfigs, getTenantSubscriptions, updateTenantConfigSetups } from './actions';
import { PayloadAction } from '@reduxjs/toolkit';
import { ITenantConfig } from './model';

export const reducers = {
    updateTenantConfigProps: (
        state: ITenantState,
        action: PayloadAction<{ path: keyof ITenantConfig; value: string | number | boolean | undefined }>,
    ) => {
        const { path, value } = action.payload;
        if (state.config) state.config = { ...state.config, [path]: value };
    },
    cleanUpTenantConfig: (state: ITenantState) => {
        state.config = undefined;
    },
};

export const extraReducers: ExtraReducer<ITenantState> = (builder) =>
    builder
        //get Tenant
        .addCase(getTenant.pending, (state) => {
            state.loadingTenant = LoadingStatus.Pending;
        })
        .addCase(getTenant.fulfilled, (state, { payload: tenant }) => {
            state.loadingTenant = LoadingStatus.Completed;
            state.data = tenant;
        })
        .addCase(getTenant.rejected, (state) => {
            state.loadingTenant = LoadingStatus.Failed;
        })

        // Get Tenant Subscriptions
        .addCase(getTenantSubscriptions.pending, (state) => {
            state.loadingSubscriptions = LoadingStatus.Pending;
        })
        .addCase(getTenantSubscriptions.fulfilled, (state, { payload: subscriptions }) => {
            state.loadingSubscriptions = LoadingStatus.Completed;
            state.subscriptions = subscriptions;
        })
        .addCase(getTenantSubscriptions.rejected, (state) => {
            state.loadingSubscriptions = LoadingStatus.Failed;
        })

        // Get Tenant Configs
        .addCase(getTenantConfigs.pending, (state) => {
            state.loadingConfig = LoadingStatus.Idle;
        })
        .addCase(getTenantConfigs.fulfilled, (state, { payload: tenantConfig }) => {
            state.config = tenantConfig;
            state.loadingConfig = LoadingStatus.Completed;
        })
        .addCase(getTenantConfigs.rejected, (state) => {
            state.loadingConfig = LoadingStatus.Failed;
        })

        // Update Tenant Configs
        .addCase(updateTenantConfigSetups.pending, (state) => {
            // state.loading = LoadingStatus.Idle;
        })
        .addCase(updateTenantConfigSetups.fulfilled, (state, { payload: tenantSetup }) => {
            //
        })
        .addCase(updateTenantConfigSetups.rejected, (state) => {
            // state.loading = LoadingStatus.Failed;
        });
