import { DefaultButton, IButtonProps } from '@fluentui/react';
import { ChangeEvent, useRef } from 'react';

const FileInputField = (
    props: IButtonProps & { onChange: (ev: ChangeEvent<HTMLInputElement>) => void },
): JSX.Element => {
    const inputReference = useRef<HTMLInputElement>(null);
    return (
        <>
            <input
                style={{ display: 'none' }}
                type="file"
                title=" "
                multiple
                onChange={props.onChange}
                ref={inputReference}
            />
            <DefaultButton
                text={props.text ? props.text : 'Choose Files'}
                {...props}
                onClick={() => {
                    if (inputReference.current) {
                        inputReference.current.click();
                    }
                }}
            />
        </>
    );
};

export default FileInputField;
