import React, { PropsWithChildren } from 'react';
import { Stack, DefaultButton, IContextualMenuProps, IStyleFunctionOrObject, ITheme, useTheme } from '@fluentui/react';
import TText from './TText';
import ActionButton, { IActionButton } from './ActionButton';
import TypeaheadSearchField, { IAsyncSearchComboField } from './Field/TypeaheadSearchField';

type Props = {
    title: JSX.Element | string;
    subTitle?: React.ReactNode;
    farItems?: IContextualMenuProps;
    mainButtons?: IActionButton[];
    typeaheadSearchField?: IAsyncSearchComboField;
    rightContent?: React.ReactNode;
    leftContent?: React.ReactNode;
    style?: React.CSSProperties;
    theme?: ITheme;
    styles?: IStyleFunctionOrObject<any, any>;
};

/**
 * ActivityHeader
 * Global header for a given page/activity
 * @param {Props} props
 * @returns
 */
const ActivityHeader = (props: PropsWithChildren<Props>) => {
    const theme = useTheme();
    
    const _hasMainButtons = props.mainButtons && props.mainButtons.length;
    const _createButton = (button: IActionButton, index: number) => {
        return <ActionButton {...button} key={index} />;
    };
    const _mainButtons = _hasMainButtons
        ? props.mainButtons?.map((button, index) => {
              return _createButton(button, index);
          })
        : null;

    const _typeaheadSearchField = props.typeaheadSearchField ? (
        <TypeaheadSearchField {...props.typeaheadSearchField}></TypeaheadSearchField>
    ) : null;

    const subTitle = (props.subTitle != null && typeof props.title === 'string') ?
        <TText variant="small" block>{props.subTitle}</TText>:
        props.subTitle;

    const _farItems = props.farItems ? (
        <DefaultButton
            style={{ minWidth: `30px`, padding: 0 }}
            menuProps={props.farItems}
            iconProps={{ iconName: 'MoreVertical' }}
            onRenderMenuIcon={() => null}
        />
    ) : null;

    return (
        <Stack
            horizontal
            styles={{
                root: {
                    padding: '10px',
                    display: 'flex',
                    backgroundColor: theme?.palette.neutralLighterAlt,
                    borderBottom: `1px solid ${theme?.semanticColors.bodyDivider}`,
                },
            }}
            style={props.style}
        >
            <Stack horizontal={typeof props.title === 'string'} tokens={{ childrenGap: 12 }} grow>
                {typeof props.title === 'string' ? (
                    <>
                        <TText variant="xLargePlus" block>
                            {props.title}

                            {subTitle}
                        </TText>
                        
                        {props.leftContent}
                    </>
                ) : (
                    props.title
                )}
            </Stack>
            {props.leftContent && typeof props.title !== 'string' && props.leftContent}
            <Stack
                horizontal
                style={{ flex: 1, justifyContent: 'flex-end', display: 'flex' }}
                tokens={{ childrenGap: 12 }}
                verticalAlign="center"
            >
                {props.children}
                {_mainButtons}
                {_typeaheadSearchField ? _typeaheadSearchField : null}
                {props.rightContent}
                {_farItems}
            </Stack>
        </Stack>
    );
};

export default ActivityHeader;
