import { Stack, IconButton, useTheme } from '@fluentui/react';
import 'components/ActivityBar/ActivityBar.scss';
import { PropsWithChildren, useEffect } from 'react';
import useSlidingFee from 'hooks/store/useSlidingFee';
import ISlidingFeeProgram from 'api/models/slidingFee.model';
import { useParams } from 'react-router-dom';
import SlidingFeeBarLink from './SlidingFeeBarLink';

interface IActivity {
    keyString: string;
    tooltip: string;
    path: string;
    iconName?: string;
    icon?: JSX.Element;
    exact: boolean;
    notifications?: number;
}

type Props = {
    programs: any[];
};

function SlidingFeeBar(props: PropsWithChildren<Props>) {
    const { tenantId, programId } = useParams<{ tenantId: string; programId: string }>();
    const { isExpanded, toggleExpanded, getSlidingFeeProgramById } = useSlidingFee();
    const theme = useTheme();

    useEffect(() => {
        if (programId) getSlidingFeeProgramById(programId);
    }, [programId, getSlidingFeeProgramById]);

    const activities: IActivity[] = props.programs.map((program: ISlidingFeeProgram) => {
        return {
            keyString: program.id,
            path: `/${tenantId}/sliding-fee/${program.id}`,
            tooltip: program.name ? program.name : 'N/A',
            exact: true,
        };
    });

    const { neutralLighterAlt } = theme.palette;

    const activityLinks = activities.map((activity, index) => (
        <SlidingFeeBarLink key={index} expanded={isExpanded} {...activity} />
    ));

    const wrapperClassName = isExpanded ? 'activityBar activityBar--expanded' : 'activityBar';

    const wrapperStyle = {
        background: neutralLighterAlt,
        borderRight: `1px solid ${theme?.semanticColors.bodyDivider}`,
    };

    const expanderIcon = {
        iconName: isExpanded ? 'DoubleChevronLeftMed' : 'DoubleChevronLeftMedMirrored',
    };

    if (activityLinks.length > 0) {
        return (
            <Stack className={wrapperClassName} style={wrapperStyle}>
                <Stack style={{ flex: 1 }}>
                    <Stack.Item grow>{activityLinks}</Stack.Item>
                </Stack>

                <div className={'activityBar__footer'}>
                    <IconButton onClick={() => toggleExpanded()} iconProps={expanderIcon} />
                </div>
            </Stack>
        );
    }

    return <></>;
}

export default SlidingFeeBar;
