import { AnyAction, createAsyncThunk, createSlice, Dictionary, ThunkDispatch } from '@reduxjs/toolkit';
import membershipApi from 'api/membership.api';
import IProcedureDetail from 'api/models/procedure-details.model';
import axios, { AxiosError } from 'axios';
import { LoadingStatus } from 'interfaces/loading-statuses';
import { forEach, isEmpty, map } from 'lodash';
import { RootState } from 'state/store';
import { getProcedures } from '../procedures/procedures.slice';
import procedureDetailsReducers from './procedureDetails.reducers';
import { initialProcedureDetailsState } from './procedureDetails.state';
import { TenantBlobFilePayload } from '../payers/payers.actions';

export const getProcedureDetails = createAsyncThunk<
    Dictionary<IProcedureDetail>,
    {
        tenantId: string;
    }
>('procedureDetails/getProcedureDetails', async ({ tenantId }) => {
    const response = await membershipApi.getProcedureDetails(tenantId);
    return response.data;
});

export const getProcedureDetailById = createAsyncThunk<
    IProcedureDetail,
    {
        tenantId: string;
        id: string;
    }
>('procedureDetails/getProcedureDetailById', async ({ tenantId, id }) => {
    const response = await membershipApi.getProcedureDetailById(tenantId, id);
    return response.data;
});

export const getExcelProcedureDetails = createAsyncThunk<
    TenantBlobFilePayload,
    {
        tenantId: string;
    },
    {
        state: RootState;
    }
>('procedureDetailsExcel/getProcedureDetailsExcel', async ({ tenantId }, { getState }) => {
    const account = getState().account.data;
    const tenant = map(account?.tenants);
    const currentTenant = tenant.find((tenant) => tenant.id === tenantId);
    const response = await membershipApi.getExcelProcedureDetails(tenantId);

    const newObject = {
        blob: response.data,
        tenantName: currentTenant?.displayName,
    };
    return newObject;
});

export const createProcedureDetails = createAsyncThunk<
    IProcedureDetail,
    {
        tenantId: string;
        model: IProcedureDetail;
    }
>('procedureDetails/createProcedureDetail', async ({ tenantId, model }) => {
    const response = await membershipApi.createProcedureDetails(tenantId, model);
    return response.data;
});

export const updateProcedureDetails = createAsyncThunk<
    IProcedureDetail,
    {
        tenantId: string;
        model: IProcedureDetail;
    }
>('procedureDetails/updateProcedureDetail', async ({ tenantId, model }) => {
    const response = await membershipApi.updateProcedureDetails(tenantId, model);
    return response.data;
});

/**
 * Update multiple procedure details at once for a tenant.
 */
export const updateProcedureDetailsAsList = createAsyncThunk<
    IProcedureDetail[],
    {
        tenantId: string;
        items: IProcedureDetail[];
    }
>('procedureDetails/updateProcedureDetailsAsList', async ({ tenantId, items }) => {
    const requests = items.map((p) => membershipApi.updateProcedureDetails(tenantId, p));
    const responses = await axios.all(requests);
    return responses.map((res) => res.data);
});

/**
 * Create multiple procedure details at once for a tenant.
 */
export const createProcedureDetailsAsList = createAsyncThunk<
    IProcedureDetail[],
    {
        tenantId: string;
        items: IProcedureDetail[];
    }
>('procedureDetails/createProcedureDetailsAsList', async ({ tenantId, items }) => {
    const requests = items.map((p) => membershipApi.createProcedureDetails(tenantId, p));
    const responses = await axios.all(requests);
    return responses.map((res) => res.data);
});

type IEditProcedureClickParams = { tenantId: string; procedureId: string };

/**
 * Get a procedure detail by id if it exists.
 * Creates a new procedure detail if it doesn't.
 */
export const getOrCreateProcedureDetail =
    ({ tenantId, procedureId }: IEditProcedureClickParams) =>
    async (dispatch: ThunkDispatch<RootState, unknown, AnyAction>, getState: () => RootState): Promise<void> => {
        const proceduresData = getState().procedures.data;
        const procedure = proceduresData[procedureId];

        if (procedure && !procedure.isDeleted) {
            const procedureDetailsData = getState().procedureDetails.data;
            const procedureDetailsLoading = getState().procedureDetails.loading;

            if (isEmpty(proceduresData)) dispatch(getProcedures());
            if (isEmpty(procedureDetailsData)) dispatch(getProcedureDetails({ tenantId }));

            const procedureDetailExists = !!procedureDetailsData[procedureId];
            const procedureDetail: IProcedureDetail = procedureDetailsData[procedureId] || {
                id: procedure.id,
                isDeleted: false,
                displayName: procedure.displayName,
                code: procedure.code,
                fees: [],
            };

            if (procedureDetailsLoading !== LoadingStatus.Pending)
                if (procedureDetailExists) {
                    dispatch(getProcedureDetailById({ tenantId, id: procedureId }));
                } else {
                    dispatch(createProcedureDetails({ tenantId, model: procedureDetail }));
                }
        }
    };

export const addExcelProcedureDetails = createAsyncThunk<
    Dictionary<IProcedureDetail>,
    { tenantId: string; formFile: File },
    { rejectValue: AxiosError<any> }
>('procedureDetails/addExcelProcedureDetails', async ({ tenantId, formFile }, { rejectWithValue }) => {
    try {
        const res = await membershipApi.addExcelProcedureDetails(tenantId, formFile);
        return res.data;
    } catch (e: any) {
        return rejectWithValue(e);
    }
});

export const bulkUpdateProcedureDetails = createAsyncThunk<
    IProcedureDetail[],
    {
        tenantId: string;
        model: IProcedureDetail[];
    }
>('procedureDetails/bulkUpdateProcedureDetails', async ({ tenantId, model }) => {
    const response = await membershipApi.bulkUpdateProcedureDetails(tenantId, model);
    return response.data;
});

const procedureDetailsSlice = createSlice({
    name: 'procedureDetailsSlice',
    initialState: initialProcedureDetailsState,
    reducers: procedureDetailsReducers,
    extraReducers: (builder) => {
        builder
            .addCase(getProcedureDetails.pending, (state) => {
                state.loading = LoadingStatus.Pending;
                state.error = '';
                state.excelDataUploaded = false;
            })
            .addCase(getProcedureDetails.fulfilled, (state, action) => {
                state.loading = LoadingStatus.Completed;
                state.data = action.payload;
            })
            .addCase(getProcedureDetails.rejected, (state) => {
                state.loading = LoadingStatus.Failed;
            })
            .addCase(getProcedureDetailById.pending, (state) => {
                state.loadingProcedureDetail = LoadingStatus.Pending;
            })
            .addCase(getProcedureDetailById.fulfilled, (state, action) => {
                state.loadingProcedureDetail = LoadingStatus.Completed;
                state.selectedProcedureDetail = action.payload;
            })
            .addCase(getProcedureDetailById.rejected, (state) => {
                state.loadingProcedureDetail = LoadingStatus.Failed;
            })
            .addCase(createProcedureDetails.pending, (state) => {
                state.savingProcedureDetail = LoadingStatus.Pending;
            })
            .addCase(createProcedureDetails.fulfilled, (state, action) => {
                state.savingProcedureDetail = LoadingStatus.Completed;
                state.data[action.payload.id] = action.payload;
                state.selectedProcedureDetail = action.payload;
            })
            .addCase(createProcedureDetails.rejected, (state) => {
                state.savingProcedureDetail = LoadingStatus.Failed;
            })
            .addCase(updateProcedureDetails.pending, (state) => {
                state.savingProcedureDetail = LoadingStatus.Pending;
            })
            .addCase(updateProcedureDetails.fulfilled, (state, action) => {
                state.savingProcedureDetail = LoadingStatus.Completed;
                state.data[action.payload.id] = action.payload;
                state.selectedProcedureDetail = undefined;
            })
            .addCase(updateProcedureDetails.rejected, (state) => {
                state.savingProcedureDetail = LoadingStatus.Failed;
            })
            .addCase(updateProcedureDetailsAsList.fulfilled, (state, action) => {
                const items = action.payload;
                items.forEach((item) => {
                    state.data[item.id] = item;
                });
            })
            .addCase(createProcedureDetailsAsList.fulfilled, (state, action) => {
                const items = action.payload;
                items.forEach((item) => {
                    state.data[item.id] = item;
                });
            })
            .addCase(addExcelProcedureDetails.pending, (state) => {
                state.loading = LoadingStatus.Pending;
                state.excelDataUploading = LoadingStatus.Pending;
                state.error = '';
                state.excelDataUploaded = false;
            })
            .addCase(addExcelProcedureDetails.fulfilled, (state, { payload: items }) => {
                forEach(items, (item) => {
                    if (item) {
                        state.data[item.id] = item;
                    }
                });
                state.loading = LoadingStatus.Completed;
                state.excelDataUploading = LoadingStatus.Completed;
                state.excelDataUploaded = true;
            })
            .addCase(addExcelProcedureDetails.rejected, (state, action) => {
                state.loading = LoadingStatus.Failed;
                state.excelDataUploading = LoadingStatus.Failed;
                state.error = action.payload?.response ? action.payload.response.data.message : 'Error';
                state.excelDataUploaded = false;
            })
            .addCase(bulkUpdateProcedureDetails.pending, (state) => {
                state.loadingProcedureDetail = LoadingStatus.Pending;
            })
            .addCase(bulkUpdateProcedureDetails.fulfilled, (state, action) => {
                state.loadingProcedureDetail = LoadingStatus.Completed;

                state.excelDataUploaded = false;
                state.error = '';
            })
            .addCase(bulkUpdateProcedureDetails.rejected, (state) => {
                state.loadingProcedureDetail = LoadingStatus.Failed;
            })
            .addCase(getExcelProcedureDetails.pending, (state) => {
                state.loading = LoadingStatus.Pending;
            })
            .addCase(getExcelProcedureDetails.fulfilled, (state, action) => {
                const url = window.URL.createObjectURL(new Blob([action.payload.blob]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `${action.payload.tenantName}-Procedure-Details.xlsx`);
                document.body.appendChild(link);
                link.click();
            })
            .addCase(getExcelProcedureDetails.rejected, (state) => {
                state.loading = LoadingStatus.Failed;
            });
    },
});

const { actions, reducer } = procedureDetailsSlice;
export const {
    cleanupSelectedProcedureDetail,
    cleanupProcedureDetailsSearch,
    setSelectedProcedureDetailProp,
    setProcedureDetailsDataProp,
    setProcedureDetailsSearch,
    createProcedureFee,
    updateProcedureFee,
    toggleProceduresList,
} = actions;

export default reducer;
