import { IDropdownProps, Dropdown, ISelectableOption, IDropdownStyles, useTheme } from '@fluentui/react';
import { TText } from 'components';

type Props = IDropdownProps;

const DropdownField = ({ ...dropdown }: Props): JSX.Element => {
    const theme = useTheme();
    const onRenderDropdownOptions = (props?: ISelectableOption): JSX.Element => {
        return props?.key && !props.selected ? (
            <TText>{props?.text}</TText>
        ) : (
            <TText color="neutralSecondaryAlt">{props?.text}</TText>
        );
    };

    const getStyles = (value: unknown | undefined): Partial<IDropdownStyles> => ({
        title: {
            color: value === '' || value === null || value === undefined ? theme?.palette.neutralSecondary : '',
        },
    });

    return <Dropdown onRenderOption={onRenderDropdownOptions} styles={getStyles(dropdown.selectedKey)} {...dropdown} />;
};

export default DropdownField;
