import membershipApi from 'api/membership.api';

import { IAppointmentCancellationReasons } from './model';

const appointmentCancellationReasonsApi = {
    getAll,
    refresh,
};

function getAll(tenantId: string) {
    return membershipApi.client.get<IAppointmentCancellationReasons>(`Tenants/${tenantId}/AppointmentCancellationReasons`);
}

function refresh(tenantId: string) {
    return membershipApi.client.get<IAppointmentCancellationReasons>(
        `Tenants/${tenantId}/AppointmentCancellationReasons/Refresh`,
    );
}

export default appointmentCancellationReasonsApi;
