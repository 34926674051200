import { IconButton, SelectionMode, Stack, Text, Toggle, TooltipHost } from '@fluentui/react';
import { ICodeCategoryExclusion, ISlidingFeePlan } from 'api/models/slidingFee.model';
import { SortableDetailsList, SubSection } from 'components';
import { EditDetailsColumn } from 'components/EditDetailsColumn';
import useSlidingFee from 'hooks/store/useSlidingFee';
import { useDispatch, useSelector } from 'react-redux';
import {
    selectSelectedCategoryExclusionList,
    selectSelectedProgramPlanCategoryExclusionListDeleted,
    toggleDeletedCategoryExclusions,
} from 'state/slices/sliding-fee/sliding-fee.slice';
import dateOnly from 'utils/dateOnly';
import { v4 as uuid } from 'uuid';
import CategoryExclusionPanel from './CategoryExclusionPanel';

type Props = {
    programPlan?: ISlidingFeePlan;
    onProgramPlanPropertyChange: (
        key: keyof ISlidingFeePlan,
        value: string | number | ICodeCategoryExclusion[] | boolean,
    ) => void;
};

export default function CodeCategoryExclusion(props: Props): JSX.Element {
    const { programPlan, onProgramPlanPropertyChange } = props;
    const { setSelectedCategoryExclusion, toggleIsCategoryExclusionPanelOpen } = useSlidingFee();
    const _selectSelectedCatergoryExclusion = useSelector(selectSelectedCategoryExclusionList);
    const isActive = useSelector(selectSelectedProgramPlanCategoryExclusionListDeleted);

    const dispatch = useDispatch();

    const _onItemInvoked = (item: ICodeCategoryExclusion, index?: number) => {
        setSelectedCategoryExclusion(item);
        toggleIsCategoryExclusionPanelOpen();
    };
    const onRenderCalculationType = (item?: ICodeCategoryExclusion) => {
        if (item?.calculationType === 'flatFeeOnly') {
            return <Text variant="smallPlus">Flat Fee Only</Text>;
        }
        if (item?.calculationType === 'percentagePerCode') {
            return <Text variant="smallPlus">Percentage Per Code</Text>;
        }
        if (item?.calculationType === 'flatFeePerCode') {
            return <Text variant="smallPlus">Flat Fee Per Code </Text>;
        } else {
            return '';
        }
    };

    return (
        <SubSection
            title="Code Category Exclusions"
            headingCenterContent={
                <Stack horizontal horizontalAlign="space-between">
                    <Stack.Item>
                        <TooltipHost content="Add Code Category Exclusions">
                            <IconButton
                                iconProps={{ iconName: 'Add' }}
                                onClick={() => {
                                    setSelectedCategoryExclusion(newCategoryExclusion());
                                    toggleIsCategoryExclusionPanelOpen();
                                }}
                            />
                        </TooltipHost>
                    </Stack.Item>
                    <Stack.Item>
                        <Toggle
                            styles={{ root: { marginBottom: 2, minWidth: 150 } }}
                            checked={!!isActive}
                            inlineLabel
                            label="Active Only"
                            onChange={() => {
                                dispatch(toggleDeletedCategoryExclusions());
                            }}
                        />
                    </Stack.Item>
                </Stack>
            }
        >
            <CategoryExclusionPanel programPlan={programPlan} onProgramPlanPropertyChange={onProgramPlanPropertyChange} />
            {programPlan && programPlan.codeCategoryExclusions && programPlan.codeCategoryExclusions.length ? (
                <SortableDetailsList
                    selectionMode={SelectionMode.none}
                    items={_selectSelectedCatergoryExclusion}
                    columns={[
                        {
                            key: 'category',
                            minWidth: 100,
                            maxWidth: 150,
                            fieldName: 'category',
                            name: 'Category',
                            getValueKey: (item?: ICodeCategoryExclusion) => {
                                return item?.category ?? '';
                            },
                            onRender: (item?: ICodeCategoryExclusion, index?: number) => {
                                if (item) {
                                    return (
                                        <EditDetailsColumn
                                            editOnClick={() => _onItemInvoked(item, index)}
                                            title={item?.category ?? ''}
                                        />
                                    );
                                } else {
                                    return undefined;
                                }
                            },
                        },
                        {
                            key: 'calculationType',
                            minWidth: 150,
                            maxWidth: 150,
                            fieldName: 'calculationType',
                            name: 'Calculation Type',
                            onRender: onRenderCalculationType,
                        },
                        {
                            key: 'doesNotSlide',
                            minWidth: 150,
                            maxWidth: 150,
                            fieldName: 'doesNotSlide',
                            name: 'Does Not Slide',
                            onRender: (item?: ICodeCategoryExclusion) => {
                                if (item?.doesNotSlide) {
                                    return <Text variant="smallPlus">&#10003;</Text>;
                                }
                            },
                        },
                        {
                            key: 'feePercentage',
                            minWidth: 120,
                            maxWidth: 120,
                            fieldName: 'feePercentage',
                            name: 'Fee or Percentage',
                        },
                        {
                            key: 'labFee',
                            minWidth: 100,
                            maxWidth: 100,
                            fieldName: 'labFee',
                            name: 'Lab Fee',
                        },
                        {
                            key: 'absoluteFee',
                            minWidth: 100,
                            maxWidth: 100,
                            fieldName: 'absoluteFee',
                            name: 'Absolute Fee',
                        },
                        {
                            key: 'effectiveDate',
                            fieldName: 'effectiveDate',
                            minWidth: 100,
                            maxWidth: 250,
                            name: 'Effective Date',
                            onRender: (item?: ICodeCategoryExclusion) => {
                                if (item) {
                                    const date = item.effectiveDate ? dateOnly(item.effectiveDate as string) : '';
                                    return <Text variant="smallPlus">{date}</Text>;
                                }
                            },
                        },
                        {
                            key: 'expirationDate',
                            name: 'Expiration Date',
                            minWidth: 100,
                            maxWidth: 100,
                            fieldName: 'expirationDate',
                            onRender: (item?: ICodeCategoryExclusion) => {
                                if (item) {
                                    const date = item.expirationDate ? dateOnly(item.expirationDate as string) : '';
                                    return <Text variant="smallPlus">{date}</Text>;
                                }
                            },
                        },
                    ]}
                />
            ) : null}
        </SubSection>
    );
}

function newCategoryExclusion(): ICodeCategoryExclusion {
    return {
        id: uuid(),
        isDeleted: false,
        absoluteFee: 0,
        calculationType: '',
        doesNotSlide: false,
        effectiveDate: '',
        expirationDate: '',
        feePercentage: 0,
        labFee: 0,
        category: '',
    };
}
