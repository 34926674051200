import { Dictionary } from '@reduxjs/toolkit';
import IProduct, { IProductService } from './product.model';
import IProductRole from './productRole.model';
import { IdSupport, ETagSupport, SoftDeleteSupport, ChangeAuditSupport } from './support-types';

type TenantSubscriptionSupports = IdSupport & ETagSupport & SoftDeleteSupport & ChangeAuditSupport;
export interface ITenantSubscription extends TenantSubscriptionSupports {
    startDate: Date;
    endDate: Date;
    productId: string;
    licenseCount: number;

    product: IProduct;
    productRoles: IProductRole[];
    services?: Dictionary<ITenantSubscriptionService>;
}

export type ITenantSubscriptions = ITenantSubscription[];

export enum Services {
    EnhancedClaimsResolution = 'enhancedClaimsResolution',
    ThirdPartyCoding = 'thirdPartyCoding',
}

type TenantSubscriptionServiceSupports = ChangeAuditSupport & SoftDeleteSupport;
export interface ITenantSubscriptionService extends TenantSubscriptionServiceSupports {
    startDate?: string;
    endDate?: string;
    serviceName?: string;
    serviceType?: Services;
}
