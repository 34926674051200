import { ISearchBoxProps, SearchBox } from '@fluentui/react';
import Fuse from 'fuse.js';
import { isEqual } from 'lodash';
import { useCallback, useEffect, useMemo } from 'react';

export interface IFuseSearchProps<T> extends ISearchBoxProps {
    /**
     * @name resultItems
     * @type {ReadonlyArray<T>}
     * @memberof IFuseSearchProps
     * @description List of items to search
     */
    items: ReadonlyArray<T>;
    /**
     * @name resultItems
     * @type {ReadonlyArray<T>}
     * @memberof IFuseSearchProps
     * @description List of items as a result of searching
     */
    resultItems: ReadonlyArray<T>;
    /**
     * @name options
     * @type {Fuse.IFuseOptions<T>}
     * @memberof IFuseSearchProps
     * @description Options for fuse search
     */
    options?: Fuse.IFuseOptions<T>;
    /**
     * @name index
     * @type {Fuse.FuseIndex<T>}
     * @memberof IFuseSearchProps
     * @description Indexing for fuse search
     */
    index?: Fuse.FuseIndex<T>;
    /**
     * @name loading
     * @type {(string | boolean)}
     * @memberof IFuseSearchProps
     * @description If the items list is async, use this to set initial list.
     */
    loading?: string | boolean;
    onSearch?: (value: T[]) => void;
}

export default function FuseSearchField<T>(props: IFuseSearchProps<T>) {
    const { onSearch, value, loading, items, resultItems, options, index } = props;
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const fuse = useMemo(() => new Fuse(items, options, index), []);

    const fuseSearch = useCallback(() => {
        if (onSearch) {
            const results = fuse.search(value ? value : '');
            const mappedResults = results.map((proc) => proc.item);
            onSearch(mappedResults);
        }
    }, [value, onSearch, fuse]);

    useEffect(() => {
        fuseSearch();
    }, [value, fuseSearch]);

    useEffect(() => {
        if (loading === undefined) {
            fuse.setCollection(items);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (
            (typeof loading === 'string'
                ? loading.toLowerCase() === 'completed'
                : loading !== undefined && loading) &&
            !isEqual(resultItems, items)
        ) {
            fuse.setCollection(items);
        }
    }, [items, fuse, loading, resultItems]);

    return <SearchBox {...props} />;
}
