import { createAsyncThunk, Dictionary } from '@reduxjs/toolkit';
import membershipApi from 'api/membership.api';
import { IProviderProfile } from 'api/models/providerProfile.model';
import { ITenantUser } from 'api/models/tenantAccount.model';
import { map } from 'lodash';
import { RootState } from 'state/store';
import { v4 as uuid } from 'uuid';

export const getProviderProfiles = createAsyncThunk<Dictionary<IProviderProfile>, { tenantId: string }>(
    'providerProfiles/getProviderProfiles',
    async ({ tenantId }) => {
        const result = await membershipApi.getProviderProfiles(tenantId);
        return result.data;
    },
);

export interface IProviderProfileSavePayload {
    productId: string;
    provider?: IProviderProfile;
    user?: ITenantUser;
}

export const updateProviderProfile = createAsyncThunk<
    IProviderProfileSavePayload,
    { tenantId: string; productId: string; user?: ITenantUser; provider?: IProviderProfile }
>('providerProfiles/updateProviderProfile', async ({ tenantId, productId, user, provider }, { rejectWithValue }) => {
    try {
        //If the user being saved has no id, then create it.
        const newUserId = uuid();
        //Create the new user object if no id, otherwise keep the current user.
        const newUser: ITenantUser | undefined = user
            ? user?.id
                ? user
                : { ...user, id: newUserId, identity: { ...user.identity, id: newUserId } }
            : undefined;
        const result = await membershipApi.updateProviderProfile(tenantId, { productId, user: newUser, provider });
        return result.data;
    } catch (e) {
        return rejectWithValue(e);
    }
});

export const refreshProviderProfiles = createAsyncThunk<
    Dictionary<IProviderProfile>,
    { tenantId: string },
    { state: RootState; rejectValue: string }
>('providerProfiles/refreshProviderProfiles', async ({ tenantId }) => {
    const result = await membershipApi.refreshProviderProfiles(tenantId);

    const filteredRequest = map(result.data).filter((item) => !item?.isDeleted);

    const filteredResult = Object.assign({ ...filteredRequest });

    return filteredResult;
});
