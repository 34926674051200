import { createSlice } from '@reduxjs/toolkit';
import initialAppointmentCancellationReasonState from './state';
import { appointmentCancellationReasonsExtraReducers } from './reducers';

const appointmentCancellationReasonsSlice = createSlice({
    name: 'appointmentCancellationReasons',
    initialState: initialAppointmentCancellationReasonState,
    reducers: {},
    extraReducers(builder) {
        appointmentCancellationReasonsExtraReducers(builder);
    },
});

const { reducer } = appointmentCancellationReasonsSlice;

export default reducer;

// export const {} = actions;
