import React, { useEffect } from 'react';
import { Stack, Spinner, SpinnerSize, FontSizes } from '@fluentui/react';
import { useHistory } from 'react-router-dom';
import CustomerSelection from './CustomerSelection';
import { useSelector } from 'hooks';
import ITenantAccount from 'api/models/tenantAccount.model';
import { tenants, tenantsLoading } from 'state/slices/tenants/tenants.slice';

const CustomerSelectionContainer = () => {
    const history = useHistory();

    const _tenants = useSelector(tenants);
    const _tenantsLoading = useSelector(tenantsLoading);

    useEffect(() => {
        document.title = 'Organizations - athenaOne | Membership';
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const _onTenantSelect = (item: ITenantAccount) => history.push(`/${item.id}/`);

    return (
        <>
            {_tenantsLoading === 'pending' ? (
                <Stack verticalAlign="center" horizontalAlign="center" grow>
                    <Spinner size={SpinnerSize.large} label="Loading..." />
                </Stack>
            ) : (
                <Stack tokens={{ childrenGap: 10, padding: '40px' }} horizontalAlign="center">
                    <Stack>
                        <div style={{ fontSize: FontSizes.size32, fontWeight: 'bold' }}>WELCOME TO MEMBERSHIP</div>
                    </Stack>
                    <Stack>
                        <div style={{ fontSize: FontSizes.size20 }}>Please Select an Organization</div>
                    </Stack>
                    <Stack styles={{ root: { width: '600px' } }}>
                        <CustomerSelection items={_tenants} onClick={_onTenantSelect} />
                    </Stack>
                </Stack>
            )}
        </>
    );
};

export default CustomerSelectionContainer;
