import { createSlice } from '@reduxjs/toolkit';
import { RootState } from 'state/store';

const initialState: AuthState = {
    initializing: false,
    initialized: false,
    idToken: null,
    accessToken: null,
    // account: Account,
    // state: AuthenticationState.Unauthenticated,
};

const accountSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        // builder
        //     .addCase(AuthenticationActions.Initializing, (state, action) => {
        //         state.initializing = true;
        //         state.initialized = false;
        //     })
        //     .addCase(AuthenticationActions.Initialized, (state) => {
        //         state.initializing = false;
        //         state.initialized = true;
        //     })
        //     .addCase(AuthenticationActions.LoginSuccess, (state, action) => {
        //         state.account = (action as any).payload.account;
        //     })
        //     .addCase(AuthenticationActions.AcquiredAccessTokenSuccess, (state, action) => {
        //         state.accessToken = (action as any).payload.accessToken;
        //     })
        //     .addCase(AuthenticationActions.AcquiredIdTokenSuccess, (state, action) => {
        //         state.idToken = (action as any).payload.idToken;
        //     })
        //     .addCase(AuthenticationActions.AuthenticatedStateChanged, (state, action) => {
        //         state.state = (action as any).payload;
        //     });
    },
});

const { reducer } = accountSlice;

export default reducer;

export const authStateSelector = (state: RootState) => state.auth;

type AuthState = {
    initializing: boolean;
    initialized: boolean;
    idToken: string | null;
    accessToken: string | null;
    // account: any | null;
    // state: AuthenticationState;
};
