import { IComboBoxOption } from '@fluentui/react';
import { Dictionary, createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';

import membershipApi from 'api/membership.api';

import IProfessionalSuffixes from 'api/models/professionalSuffixes.model';

import { AxiosResponse } from 'axios';
import { LoadingStatus, LoadingStatuses } from 'interfaces/loading-statuses';

import { map } from 'lodash';
import { RootState } from 'state/store';

const initialState: ProfessionalSuffixesState = {
    initialLoad: LoadingStatus.Idle,
    loading: LoadingStatus.Idle,
    data: {},
};

// export const getLanguages = createAsyncThunk<ILanguages>('getLanguages', async () => {
//     const res = await dentalApi.getLanguages();
//     return res;
// });

export const getProfessionalSuffixes = createAsyncThunk<AxiosResponse<Dictionary<IProfessionalSuffixes>>>(
    'getProfessionalSuffixes',
    async () => {
        const res = await membershipApi.getProfessionalSuffixes();
        return res;
    },
);

const professionalSuffixesSlice = createSlice({
    name: 'languages',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getProfessionalSuffixes.pending, (state) => {
                state.loading = LoadingStatus.Pending;
            })
            .addCase(getProfessionalSuffixes.fulfilled, (state, action) => {
                state.loading = LoadingStatus.Completed;
                state.data = action.payload.data;
            })
            .addCase(getProfessionalSuffixes.rejected, (state, action) => {
                state.loading = LoadingStatus.Failed;
            });
    },
});

const { reducer } = professionalSuffixesSlice;

export const selectProfessionalSuffixes = (state: RootState): Dictionary<IProfessionalSuffixes> =>
    state.professionalSuffixes.data;

export const professionalSuffixesData = createSelector(selectProfessionalSuffixes, (state) =>
    state ? (map(state, (dx) => dx) as IProfessionalSuffixes[]).filter((dx) => !dx.isDeleted).filter((dx) => dx.displayName) : [],
);

export const professionalSuffixCategories = createSelector(
    professionalSuffixesData,
    (ps) =>
        ps.map((dx) => ({
            key: dx?.displayName ?? '',
            text: dx?.displayName ?? '',
        })) as IComboBoxOption[],
);

type ProfessionalSuffixesState = {
    initialLoad: LoadingStatuses;
    loading: LoadingStatuses;
    data: Dictionary<IProfessionalSuffixes>;
};

export default reducer;
