import { Icon, mergeStyles, MessageBar, Panel, Persona, PersonaSize, Spinner, Stack, Text, useTheme } from '@fluentui/react';
import { useBoolean } from '@uifabric/react-hooks';
import useTenant from 'hooks/store/useTenant';
import useTenantId from 'hooks/useTenantId';
import { LoadingStatus } from 'interfaces/loading-statuses';
import { map } from 'lodash';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { getTenantSubscriptions } from 'state/slices/tenant/actions';

export default function BentoBox() {
    const tenantId = useTenantId();
    const [isOpen, { toggle: toggleIsOpen, setFalse: onDismiss }] = useBoolean(false);

    const WaffleStyle = {
        root: { color: '#fff', transform: 'scale(2)', marginLeft: 10, cursor: 'pointer' },
    };

    if (!tenantId) return null;

    return (
        <Stack horizontal>
            <Icon iconName="Waffle" onClick={toggleIsOpen} styles={WaffleStyle} />
            <BentoBoxPanel onDismiss={onDismiss} isOpen={isOpen} />
        </Stack>
    );
}

export function BentoBoxPanel({ isOpen, onDismiss }: { isOpen: boolean; onDismiss: () => void }) {
    const dispatch = useDispatch();
    const { getSubscriptionBentoData: getSubscriptionUrls } = useTenant();
    const { loading } = useTenant();
    const tenantId = useTenantId();
    const { palette } = useTheme();

    useEffect(() => {
        if (isOpen) {
            dispatch(getTenantSubscriptions(tenantId));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOpen]);

    const subClassName = mergeStyles({
        cursor: 'pointer',
        ':hover': {
            backgroundColor: palette.neutralLighter,
        },
    });

    return (
        <Panel
            styles={{ main: { left: 0 } }}
            hasCloseButton
            isOpen={isOpen}
            onDismiss={onDismiss}
            isLightDismiss
            headerText="Applications"
        >
            <Stack grow>
                {getSubscriptionUrls?.length ? (
                    map(getSubscriptionUrls, (data, i) => (
                        <a key={i} style={{ textDecoration: 'none' }} href={data.url} target="_blank" rel="noreferrer">
                            <Stack
                                className={subClassName}
                                style={{
                                    minHeight: 50,
                                    borderBottom:
                                        i !== (getSubscriptionUrls?.length ? getSubscriptionUrls.length - 1 : 0)
                                            ? `1px solid ${palette.neutralPrimaryAlt}`
                                            : '',
                                }}
                                verticalAlign="center"
                            >
                                <Stack horizontal verticalAlign="center" grow>
                                    <Persona imageInitials={data.displayName[0].toUpperCase()} size={PersonaSize.size32} />
                                    <Text>{`${data.displayName[0].toUpperCase()}${data.displayName.slice(
                                        1,
                                        data.displayName.length,
                                    )}`}</Text>
                                </Stack>
                            </Stack>
                        </a>
                    ))
                ) : loading === LoadingStatus.Completed ? (
                    <MessageBar>No applications have been subscribed to for this tenant.</MessageBar>
                ) : (
                    <Spinner label="Loading..." labelPosition="left" />
                )}
            </Stack>
        </Panel>
    );
}
