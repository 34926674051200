import { Stack } from '@fluentui/react';
import { ActivityHeader, ActivityPage, Section } from 'components';
import useTenantId from 'hooks/useTenantId';
import usePermissions, { Permission } from 'hooks/usePermissions';
import { CategoryCard, ICategoryCard } from './CategoryCard';
import TaskBeacon from './TaskBeacon';
import { FeatureFlag } from 'state/slices/tenant/feature-flags.slice';

export default function LandingPage() {
    return (
        <ActivityPage title="Home">
            <ActivityHeader title="Membership Home" />
            <HomeDashboard />
        </ActivityPage>
    );
}

export function HomeDashboard(): JSX.Element | null {
    const tenantId = useTenantId();

    const cardConfigs: ICategoryCard[] = [
        {
            displayName: 'Appointment Cancellation Reason',
            path: `/${tenantId}/appointmentCancellationReasons`,
            permissions: [Permission.AppointmentCancellationReason],
            icon: 'RemoveEvent',
        },
        {
            displayName: 'Encounter Reasons',
            path: `/${tenantId}/encounterReasons`,
            permissions: [Permission.EncounterReasons],
            icon: 'ClipboardList',
        },
        {
            displayName: 'Locations Of Care',
            path: `/${tenantId}/locationsOfCare`,
            permissions: [Permission.LocationsOfCare],
            icon: 'CityNext',
        },
        {
            displayName: 'Payers',
            path: `/${tenantId}/payers`,
            permissions: [Permission.Payers],
            icon: 'Money',
        },
        {
            displayName: 'Procedure Details',
            path: `/${tenantId}/procedures`,
            permissions: [Permission.ProcedureDetails],
            icon: 'List',
        },

        {
            displayName: 'Programs',
            path: `/${tenantId}/sliding-fee`,
            permissions: [Permission.Programs],
            icon: 'PageList',
        },

        {
            displayName: 'Statement Configuration',
            path: `/${tenantId}/statementConfiguration`,
            icon: 'Articles',
            permissions: [Permission.StatementConfiguration],
            featureFlag: FeatureFlag.AutomatedStatementsConfiguration 
        },

        {
            displayName: 'Subscriptions',
            path: `/${tenantId}/subscriptions`,
            permissions: [Permission.DentalSubscription, Permission.MembershipSubscription],
            icon: 'TaskManager',
        },
        {
            displayName: 'Users',
            path: `/${tenantId}/users`,
            permissions: [Permission.Users],
            icon: 'AccountManagement',
        },
    ];

    const [hasTasks] = usePermissions([Permission.Tasks]);

    const cards = cardConfigs.map((c) => <CategoryCard key={c.path} {...c} />);

    return (
        <Stack style={{ padding: 10 }} tokens={{ childrenGap: 10 }}>
            {hasTasks && <TaskBeacon />}

            <Section>
                <Stack horizontal style={{ padding: 10 }} tokens={{ childrenGap: 10 }} wrap>
                    {cards}
                </Stack>
            </Section>
        </Stack>
    );
}
