import { Stack } from '@fluentui/react';
import { ActivityPage } from 'components';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import ManagePayer from './components/ManagePayer';
import ManageFeeSchedule from './components/ManageFeeSchedule';
import PayersPage from './PayersPage';
import { useEffect } from 'react';
import useProcedure from 'hooks/store/useProcedure';
import useTenantId from 'hooks/useTenantId';
import { useDispatch } from 'react-redux';
import { getPayers, getPayersDetails } from 'state/slices/payers/payers.actions';

export default function Payers() {
    const dispatch = useDispatch();
    const { path } = useRouteMatch();
    const { getProcedures } = useProcedure();
    const tenantId = useTenantId();

    useEffect(() => {
        dispatch(getPayers());
        dispatch(getPayersDetails({ tenantId }));
        getProcedures();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <ActivityPage title={'Payers'} hideFooter hasPageHeader>
            <Stack tokens={{ childrenGap: 10, padding: 10 }} grow>
                <Switch>
                    <Route path={path} exact component={PayersPage} />
                    <Route path={`${path}:payerId`} exact component={ManagePayer} />
                    <Route path={`${path}:payerId/fee-schedules/:feeScheduleId`} exact component={ManageFeeSchedule} />
                </Switch>
            </Stack>
        </ActivityPage>
    );
}
