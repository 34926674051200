import { isAfter, isBefore, isEqual } from 'date-fns';
import dateOnly, { classicDateOnly } from './dateOnly';

type IsDateBetweenArgs = {
    dateToCheck?: string;
    start?: string;
    end?: string;
    classic?: boolean;
    debug?: boolean;
};

/**
 * isDateBetween
 *
 * @export
 * @param {IsDateBetweenArgs} params
 * @param {string | undefined} params.dateToCheck The date to compare to start and end dates
 * @param {string | undefined} params.start Starting date
 * @param {string | undefined} params.end Ending date
 * @param {boolean} params.classic Use classic date only
 * @return {*}  {boolean}
 */
export function isDateBetween({ dateToCheck, start, end, classic, debug }: IsDateBetweenArgs): boolean {
    let dateFunc;

    if (classic) {
        dateFunc = classicDateOnly;
    } else {
        dateFunc = dateOnly;
    }

    const date = dateToCheck ? dateFunc(dateToCheck) : '';
    const startDate = start ? dateFunc(start) : '';
    const endDate = end ? dateFunc(end) : '';

    // No dates to compare, return false
    if (!date || !startDate) return false;

    const dateObject = new Date(date);
    const startDateObject = new Date(startDate);

    const dateIsAfterStart = isAfter(dateObject, startDateObject) || isEqual(dateObject, startDateObject);

    // Date is after start, and no end date set, return true
    if (dateIsAfterStart && !endDate) return true;

    const endDateObject = new Date(endDate);
    const dateIsBeforeEnd = isBefore(dateObject, endDateObject) || isEqual(dateObject, endDateObject);

    if (debug) {
        console.group('Is Date Between');
        console.log('Date to Check:', date);
        console.log('Start:', startDate);
        console.log('End:', endDate);
        console.log('Date is after start:', dateIsAfterStart);
        console.log('Date is before end:', dateIsBeforeEnd);
        console.groupEnd();
    }

    return dateIsAfterStart && dateIsBeforeEnd;
}
