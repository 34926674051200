import { createSelector } from '@reduxjs/toolkit';
import { LoadingStatus } from 'interfaces/loading-statuses';
import { RootState } from 'state/store';
import { UserTaskTarget } from './model';
import { ActivityBarKey } from 'components/ActivityBar/ActivityBar';

const selectUserTasksState = (state: RootState) => state.userTasks;
export const selectUserTasksLoading = createSelector(
    selectUserTasksState,
    (state) => state.userTasksLoading === LoadingStatus.Pending,
);
export const selectUserTasksByTarget = createSelector(
    selectUserTasksState,
    (_: RootState, target?: UserTaskTarget) => target,
    (state, target) => {
        if (!target) return state.userTasks;
        return state.userTasks?.filter((task) => task.target === target);
    },
);

export enum TaskType {
    RefreshingAppointmentCancellationReasons = 'refreshing-appointment-cancellation-reasons',
    RefreshingLocationOfCare = 'refreshing-locations-of-care',
    RefreshingEncounterReason = 'refreshing-encounter-reasons',
    RefreshingProviderProfiles = 'refreshing-provider-profiles',

    MappingDentalLocationOfCare = 'map-dental-locations-of-care',
    SetDefaultDentalDepartmentId = 'set-default-dental-department-id',

    SetRescheduleCancellationReasonId = 'set-reschedule-cancellation-reason-id',

    MappingProviderLicenseNumbers = 'map-provider-license-numbers',
    MappingApplicableUsersAsProviders = 'map-applicable-users-as-providers',
}

export type UserTasksCountByPage = Partial<Record<ActivityBarKey, number | undefined>>;
export const selectTaskCountsByPage = createSelector(selectUserTasksState, (state) => {
    const countsByPage: UserTasksCountByPage = {};
    if (!state.userTasks) return countsByPage;
    state.userTasks
        .filter((task) => !task.completedOn)
        .forEach((task) => {
            switch (task.type) {
                case TaskType.MappingDentalLocationOfCare:
                case TaskType.SetDefaultDentalDepartmentId:
                    countsByPage[ActivityBarKey.LocationsOfCare] = (countsByPage[ActivityBarKey.LocationsOfCare] || 0) + 1;
                    break;

                case TaskType.SetRescheduleCancellationReasonId:
                    countsByPage[ActivityBarKey.AppointmentCancellationReasons] =
                        (countsByPage[ActivityBarKey.AppointmentCancellationReasons] || 0) + 1;
                    break;

                case TaskType.MappingProviderLicenseNumbers:
                case TaskType.MappingApplicableUsersAsProviders:
                    countsByPage[ActivityBarKey.Subscriptions] = (countsByPage[ActivityBarKey.Subscriptions] || 0) + 1;

                    break;
            }
        });
    return countsByPage;
});
