import {
    Checkbox,
    DefaultButton,
    IDropdownOption,
    MessageBar,
    MessageBarType,
    Panel,
    PanelType,
    PrimaryButton,
    Stack,
    Text,
    TextField,
    Toggle,
} from '@fluentui/react';
import { ICodeCategoryExclusion, ISlidingFeePlan } from 'api/models/slidingFee.model';
import { Field } from 'components';
import useSlidingFee from 'hooks/store/useSlidingFee';

import { useSelector } from 'react-redux';
import { selectCategoryExclusionIsValid, selectSelectedCategoryExclusionIsNew } from 'state/slices/sliding-fee/sliding-fee.slice';
import { exclusionCalculationTypeOptions } from './ProgramPlan';

type Props = {
    programPlan?: ISlidingFeePlan;
    onProgramPlanPropertyChange: (
        key: keyof ISlidingFeePlan,
        value: string | number | ICodeCategoryExclusion[] | boolean,
    ) => void;
};

function CategoryExclusionPanel(props: Props): JSX.Element {
    const { programPlan, onProgramPlanPropertyChange } = props;
    const {
        selectedCategoryExclusion,
        toggleIsCategoryExclusionPanelOpen,
        isCategoryExclusionPanelOpen,
        setSelectedCategoryExclusion,
    } = useSlidingFee();
    const exclusion = selectedCategoryExclusion.data;
    const isNewExclusion = useSelector(selectSelectedCategoryExclusionIsNew);
    const exclusionValidationErrors = useSelector(selectCategoryExclusionIsValid);

    const categoryOptions: IDropdownOption[] = [
        {
            key: '',
            text: 'None',
        },
        {
            key: 'Diagnostic',
            text: 'Diagnostic',
        },
        {
            key: 'Preventive',
            text: 'Preventive',
        },
        {
            key: 'Restorative',
            text: 'Restorative',
        },
        {
            key: 'Endodontics',
            text: 'Endodontics',
        },
        {
            key: 'Periodontics',
            text: 'Periodontics',
        },
        {
            key: 'Prosthodontics, removable',
            text: 'Prosthodontics, removable',
        },
        {
            key: 'Maxillofacial Prosthetics',
            text: 'Maxillofacial Prosthetics',
        },
        {
            key: 'Implant Services',
            text: 'Implant Services',
        },
        {
            key: 'Prosthodontics, fixed',
            text: 'Prosthodontics, fixed',
        },
        {
            key: 'Oral & Maxillofacial Surgery',
            text: 'Oral & Maxillofacial Surgery',
        },
        {
            key: 'Orthodontics',
            text: 'Orthodontics',
        },
        {
            key: 'Adjunctive General Services',
            text: 'Adjunctive General Services',
        },
        {
            key: 'Sleep Apnea Services',
            text: 'Sleep Apnea Services',
        },
    ];

    const wrapperStyle = {
        border: '1px solid #ccc',
        padding: '5px 5px 10px 10px',
    };

    const onExclusionPropertyChange = (key: keyof ICodeCategoryExclusion, value: string | number | boolean | undefined) =>
        setSelectedCategoryExclusion({ ...exclusion, [key]: value });

    const _onDismiss = () => {
        setSelectedCategoryExclusion(undefined);
        toggleIsCategoryExclusionPanelOpen();
    };

    const disableSaveButton =
        (exclusionValidationErrors && exclusionValidationErrors.length > 0) || !exclusion?.category || !exclusion?.effectiveDate;

    function onCheckboxChange(ev?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean) {
        if (checked === true && exclusion) {
            setSelectedCategoryExclusion({
                ...exclusion,

                calculationType: undefined,
                absoluteFee: undefined,
                feePercentage: undefined,
                labFee: undefined,
                doesNotSlide: true,
            });
        } else {
            setSelectedCategoryExclusion({
                ...exclusion,
                doesNotSlide: false,
            });
        }
    }

    const _onRenderFooterContent = () => {
        return (
            <Stack horizontal tokens={{ childrenGap: 12 }}>
                {exclusionValidationErrors?.length && (
                    <MessageBar messageBarType={MessageBarType.blocked}>
                        <ul style={{ margin: 0 }}>
                            {exclusionValidationErrors.map((error, index) => (
                                <li key={index}>{error}</li>
                            ))}
                        </ul>
                    </MessageBar>
                )}
                <Stack horizontal grow tokens={{ childrenGap: 10 }}></Stack>
                <PrimaryButton
                    text="Save Exclusion"
                    onClick={() => {
                        if (exclusion) {
                            const programPlanExclusions = programPlan?.codeCategoryExclusions ?? [];
                            if (!isNewExclusion) {
                                //update
                                const newProgramPlanExclusions = [...programPlanExclusions];
                                const indexOfExclusion = newProgramPlanExclusions.findIndex((ex) => exclusion?.id === ex.id);

                                if (indexOfExclusion > -1) {
                                    newProgramPlanExclusions[indexOfExclusion] = exclusion;
                                    onProgramPlanPropertyChange('codeCategoryExclusions', newProgramPlanExclusions);
                                }
                            } else {
                                // add
                                onProgramPlanPropertyChange('codeCategoryExclusions', [...programPlanExclusions, exclusion]);
                            }
                            setSelectedCategoryExclusion(undefined);
                            toggleIsCategoryExclusionPanelOpen();
                        }
                    }}
                    disabled={disableSaveButton}
                />
                <DefaultButton
                    text="Cancel"
                    onClick={() => {
                        setSelectedCategoryExclusion(undefined);
                        toggleIsCategoryExclusionPanelOpen();
                    }}
                />
            </Stack>
        );
    };

    return (
        <Panel
            isOpen={isCategoryExclusionPanelOpen}
            onDismiss={_onDismiss}
            headerText={isNewExclusion ? 'Create Category Exclusion' : 'Edit Exclusion'}
            type={PanelType.custom}
            customWidth="800px"
            onRenderFooterContent={_onRenderFooterContent}
            isFooterAtBottom
        >
            <Stack tokens={{ childrenGap: 10 }} grow>
                <Stack tokens={{ childrenGap: 10 }} horizontal verticalAlign="end" grow wrap>
                    <Stack.Item grow>
                        <Field.Dropdown
                            options={categoryOptions}
                            label="Category Type"
                            placeholder="Select"
                            selectedKey={exclusion?.category !== '' ? exclusion?.category : null}
                            style={{ minWidth: 215 }}
                            onChange={(event, option, index) => option && onExclusionPropertyChange('category', option.key)}
                        />
                    </Stack.Item>
                    <Stack.Item grow>
                        <Field.Date
                            value={exclusion?.effectiveDate ?? ''}
                            label="Effective Date"
                            onChange={(event, newValue) => onExclusionPropertyChange('effectiveDate', newValue ? newValue : '')}
                        />
                    </Stack.Item>
                    <Stack.Item grow>
                        <Field.Date
                            value={exclusion?.expirationDate ?? ''}
                            label="Expiration Date"
                            onChange={(event, newValue) => onExclusionPropertyChange('expirationDate', newValue ? newValue : '')}
                        />
                    </Stack.Item>
                </Stack>
                <Stack tokens={{ childrenGap: 10 }} horizontal>
                    <Stack.Item>
                        <Field.Dropdown
                            options={exclusionCalculationTypeOptions}
                            label="Calculation Type"
                            placeholder="Select"
                            selectedKey={exclusion?.calculationType ? exclusion?.calculationType : null}
                            style={{ minWidth: 215 }}
                            onChange={(event, option, index) => {
                                if (option) {
                                    if (exclusion?.doesNotSlide) {
                                        onExclusionPropertyChange('calculationType', '');
                                    } else onExclusionPropertyChange('calculationType', option.key);
                                }
                            }}
                            disabled={exclusion?.doesNotSlide}
                        />
                    </Stack.Item>
                    <Stack verticalAlign="end">
                        <Checkbox
                            label="Do not Slide"
                            checked={exclusion?.doesNotSlide}
                            onChange={(event, checked) => {
                                onCheckboxChange(event, checked);
                            }}
                        />
                    </Stack>
                </Stack>
                <Stack
                    style={
                        exclusion?.calculationType !== '' || exclusion.calculationType === undefined
                            ? wrapperStyle
                            : {
                                  border: '',
                                  padding: '',
                              }
                    }
                >
                    {exclusion?.calculationType === 'flatFeeOnly' && (
                        <Stack grow horizontal horizontalAlign="space-between">
                            <Stack>
                                <Field.Currency
                                    value={exclusion?.feePercentage ?? 0}
                                    prefix="$"
                                    label="One Flat Fee Per Category"
                                    autoComplete="off"
                                    onChange={(event, newValue) =>
                                        onExclusionPropertyChange('feePercentage', newValue ? newValue : '')
                                    }
                                    disabled={exclusion?.doesNotSlide}
                                />
                            </Stack>
                            <Stack tokens={{ childrenGap: 5 }} verticalAlign="center">
                                <Text variant="medium"> {"Subject to plan's minimum and maximum rules"} </Text>
                            </Stack>
                        </Stack>
                    )}
                    {exclusion?.calculationType === 'flatFeePerCode' && (
                        <Stack horizontal horizontalAlign="space-between">
                            <Stack>
                                <Field.Currency
                                    value={exclusion?.feePercentage ?? 0}
                                    prefix="$"
                                    label="One Flat Fee Per Code"
                                    autoComplete="off"
                                    onChange={(event, newValue) =>
                                        onExclusionPropertyChange('feePercentage', newValue ? newValue : '')
                                    }
                                    disabled={exclusion?.doesNotSlide}
                                />
                            </Stack>
                            <Stack tokens={{ childrenGap: 5 }} verticalAlign="center">
                                <Text variant="medium"> {"Subject to plan's minimum and maximum rules"} </Text>
                            </Stack>
                        </Stack>
                    )}
                    {exclusion?.calculationType === 'percentagePerCode' && (
                        <Stack horizontal horizontalAlign="space-between">
                            <Stack>
                                <TextField
                                    value={exclusion?.feePercentage?.toString() ?? ''}
                                    prefix="%"
                                    label="Pt Percentage"
                                    autoComplete="off"
                                    onChange={(event, newValue) =>
                                        onExclusionPropertyChange('feePercentage', newValue ? parseInt(newValue) : '')
                                    }
                                    disabled={exclusion?.doesNotSlide}
                                />
                            </Stack>
                            <Stack tokens={{ childrenGap: 5 }} verticalAlign="center">
                                <Text variant="medium"> {"Subject to plan's minimum and maximum rules"} </Text>
                            </Stack>
                        </Stack>
                    )}
                </Stack>
                <Stack style={wrapperStyle}>
                    <Stack horizontal horizontalAlign="space-between">
                        <Stack>
                            <Field.Currency
                                value={exclusion?.labFee ?? 0}
                                prefix="$"
                                label="Lab Fee Per Code"
                                autoComplete="off"
                                onChange={(event, newValue) => onExclusionPropertyChange('labFee', newValue ? newValue : '')}
                                disabled={exclusion?.doesNotSlide}
                            />
                            <Field.Currency
                                value={exclusion?.absoluteFee ?? 0}
                                prefix="$"
                                label="Absolute Fee Per Code"
                                autoComplete="off"
                                onChange={(event, newValue) => onExclusionPropertyChange('absoluteFee', newValue ? newValue : '')}
                                disabled={exclusion?.doesNotSlide}
                            />
                        </Stack>
                        <Stack tokens={{ childrenGap: 5 }} verticalAlign="center">
                            <Text variant="medium"> {"In addition to plan's minimum and maximum rules"} </Text>
                            <Text variant="medium"> {"In addition to plan's flat rate"} </Text>
                        </Stack>
                    </Stack>
                </Stack>
                {!isNewExclusion && (
                    <Stack>
                        <Toggle
                            checked={exclusion?.isDeleted}
                            label="Deactivated"
                            inlineLabel
                            onChange={() => onExclusionPropertyChange('isDeleted', !exclusion?.isDeleted)}
                        />
                    </Stack>
                )}
            </Stack>
        </Panel>
    );
}

export default CategoryExclusionPanel;
