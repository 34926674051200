import React, { useState, useRef, useEffect } from 'react';
import { orderBy, isEqual, get, isArray, sortBy } from 'lodash';
import {
    IShimmeredDetailsListProps,
    ShimmeredDetailsList,
    IColumn,
    Text,
    DetailsHeader,
    IDetailsHeaderProps,
    Sticky,
    StickyPositionType,
} from '@fluentui/react';

type AscendingDecending = 'asc' | 'desc';

function nestedValueComparator<T>(item: T, getValue?: (item: T) => string, key?: string) {
    const isNumber = !getValue && key && item ? !isNaN(+get(item, key)) : getValue ? !isNaN(+getValue(item)) : false;
    const data =
        !getValue && key && item
            ? typeof get(item, key) === 'string'
                ? isNumber && (get(item, key) ? true : false)
                    ? +get(item, key)
                    : get(item, key)?.toLowerCase()
                : get(item, key)
            : getValue
            ? typeof getValue(item) === 'string'
                ? isNumber && (getValue(item) ? true : false)
                    ? +getValue(item)
                    : getValue(item)?.toLowerCase()
                : getValue(item)
            : undefined;
    return data;
}

function _copyAndSort<T>(items: T[], columns?: IColumn[], isSortedDirection?: AscendingDecending | AscendingDecending[]): T[] {
    if (columns) {
        const orderByFunctions = columns.map(
            (column) =>
                function (item: T) {
                    return nestedValueComparator(item, column?.getValueKey, column?.fieldName);
                },
        );
        return orderBy([...items], [...orderByFunctions], isSortedDirection);
    }
    return items;
}

const getNewColumns = (
    currentColumns: IColumn[],
    columnsToSort?: IColumn[],
    sortDirection?: AscendingDecending | AscendingDecending[],
) => {
    const newColumns: IColumn[] = [...currentColumns];
    const indexOfSortColumn = (nC: IColumn) => (columnsToSort ? columnsToSort.findIndex((c) => c.key === nC.key) : -1);

    newColumns.forEach((newCol: IColumn, index) => {
        const indexOfCurrentColumn = indexOfSortColumn(newCol);
        if (indexOfCurrentColumn > -1) {
            newColumns[index].isSortedDescending =
                !sortDirection || !sortDirection.length
                    ? !newColumns[index].isSortedDescending
                    : !isArray(sortDirection)
                    ? sortDirection === 'desc'
                    : sortDirection[indexOfSortColumn(newCol)] === 'desc';
            newColumns[index].isSorted = true;
        } else {
            newColumns[index].isSortedDescending = true;
            newColumns[index].isSorted = false;
        }
    });
    return newColumns;
};

function onColumnSort<T>(
    items: T[],
    columns: IColumn[],
    columnsToSort: IColumn[],
    sortDirection?: AscendingDecending | AscendingDecending[],
) {
    const newColumns = getNewColumns(columns, columnsToSort, sortDirection);
    const newColumnsToSort = columnsToSort.map((c) => {
        const col = newColumns.find((nC) => nC.key === c.key);
        if (col) return col;
        return c;
    });
    const newItems = _copyAndSort(
        items,
        newColumnsToSort,
        newColumnsToSort.map((c) => (c.isSortedDescending ? 'desc' : 'asc')),
    );

    const newSortDirections = newColumnsToSort.map((c) => (c.isSortedDescending ? 'desc' : 'asc'));

    return { columns: newColumns, items: newItems, sortDirections: newSortDirections };
}

export interface ISortableColumn<T> extends IColumn {
    onRender?: (item?: T, index?: number, column?: ISortableColumn<T>) => JSX.Element | string | number | null | undefined;
    getValueKey?: (item?: T, index?: number, column?: ISortableColumn<T>) => string;
}

export interface ISortableDetailsListProps<T> extends IShimmeredDetailsListProps {
    onMount?: (items: T[]) => void;
    onSort?: (items: T[]) => void;
    sortOnMount?: boolean;
    sortColumns?: string[];
    onItemInvoked?: (item?: T, index?: number, ev?: Event) => void;
    columns?: ISortableColumn<T>[];
    initialSortDirection?: AscendingDecending | AscendingDecending[];
    stickyHeader?: boolean;
}

export default function SortableDetailsList<T>(props: ISortableDetailsListProps<T>): JSX.Element {
    const { columns, items, sortOnMount, sortColumns, onMount, stickyHeader } = props;

    const [_hasSorted, _setHasSorted] = useState<boolean>(false);
    const [_sortDirections, _setSortDirections] = useState<AscendingDecending | AscendingDecending[] | undefined>(
        props.initialSortDirection ? props.initialSortDirection : 'asc',
    );

    const [_items, _setItems] = useState<T[]>(items);
    const [_columns, _setColumns] = useState<IColumn[]>(getColumns(columns));
    const [_sortedColumns, _setSortedColumns] = useState<string[]>(sortColumns ? sortColumns : []);

    const oldItems = useRef(items);
    const oldColumns = useRef(_columns);

    useEffect(() => {
        if (onMount) onMount(_items);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (!isEqual(sortBy(oldItems.current), sortBy(items))) {
            updateItems(items);
            if (_hasSorted) {
                sort({
                    itemsToSort: items,
                    sortDirections: _sortDirections ? _sortDirections : [],
                });
            }
        }

        if (!_hasSorted && items.length && sortOnMount && _sortedColumns) {
            sort({ itemsToSort: items, sortDirections: _sortDirections ? _sortDirections : [] });
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [items]);

    useEffect(() => {
        if (!isEqual(sortBy(oldColumns.current), sortBy(columns))) {
            updateColumns(columns);
            resortColumns();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [columns]);

    function sort(props: {
        itemsToSort: T[];
        sortColumns?: IColumn[];
        sortDirections?: AscendingDecending | AscendingDecending[];
    }) {
        const { itemsToSort: items, sortColumns, sortDirections } = props;
        const currentColumns = columns ? columns : [];

        const columnsToSort = sortColumns
            ? sortColumns
            : (_sortedColumns.map((c) => columns?.find((col) => col.key === c)).filter((i) => i !== undefined) as IColumn[]);

        const newData = onColumnSort(items, currentColumns, columnsToSort, sortDirections);

        updateColumns(newData.columns);

        _setItems(newData.items);
        _setSortDirections(newData.sortDirections);
        _setSortedColumns(columnsToSort.map((c) => c.key) as string[]);
        _setHasSorted(true);
    }

    function resortColumns() {
        const newColsList = oldColumns.current;
        const columnsToSort = _sortedColumns
            .map((c) => _columns?.find((col) => col.key === c))
            .filter((i) => i !== undefined) as IColumn[];
        if (columnsToSort) {
            const data = getNewColumns(newColsList, columnsToSort, _sortDirections);
            _setSortedColumns(columnsToSort.map((c) => c.key) as string[]);
            updateColumns(data);
        }
    }

    function onColumnClick(ev: React.MouseEvent<HTMLElement>, column: IColumn) {
        if (!column.getValueKey && !column.fieldName) return;
        sort({ itemsToSort: items, sortColumns: [column] });
        if (props.onSort) props.onSort(_items);
    }

    function getColumns(cols?: IColumn[]) {
        return cols
            ? cols.map((col) => ({
                  ...col,
                  onColumnClick,
              }))
            : [];
    }

    function updateColumns(cols?: IColumn[]) {
        const newCols = getColumns(cols);
        _setColumns(newCols);
        oldColumns.current = newCols;
    }

    function updateItems(items: T[]) {
        _setItems(items);
        oldItems.current = items;
    }

    function _getKey(item: { key?: string }, index?: number): string {
        if (item?.key) return item.key;
        return `${index}`;
    }

    function onRenderColumn(
        item?: { [key: string]: string },
        index?: number,
        column?: IColumn | undefined,
    ): React.ReactNode | undefined {
        if (column?.fieldName && item && item[column.fieldName.toString()]) {
            return <Text variant="smallPlus">{item[column.fieldName]}</Text>;
        }
    }
    const onRenderDetailsHeader = (props: IDetailsHeaderProps | undefined): JSX.Element | null => {
        if (!props) return null;
        const sortedCols = _sortedColumns
            .map((colName) => _columns.find((c) => c.key === colName))
            .filter((c) => c !== undefined) as IColumn[];

        const headerColumns = props.columns.map((c) => {
            const colSortedIndex = sortedCols.findIndex((sC) => sC.key === c?.key);

            const colName = colSortedIndex > -1 && sortedCols.length > 1 ? `${c?.name} (${colSortedIndex + 1})` : c?.name;
            return { ...c, name: colName };
        });
        if (stickyHeader) {
            return (
                <Sticky stickyPosition={StickyPositionType.Header} isScrollSynced>
                    <DetailsHeader {...props} columns={headerColumns} styles={{ root: { padding: 0 } }} />
                </Sticky>
            );
        }
        return <DetailsHeader {...props} columns={headerColumns} styles={{ root: { padding: 0 } }} />;
    };

    return (
        <ShimmeredDetailsList
            {...props}
            onRenderItemColumn={onRenderColumn}
            columns={_columns}
            getKey={_getKey}
            items={_items}
            onRenderDetailsHeader={onRenderDetailsHeader}
        />
    );
}
