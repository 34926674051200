import {
    FontIcon,
    Link,
    mergeStyles,
    mergeStyleSets,
    MessageBar,
    MessageBarType,
    PrimaryButton,
    SelectionMode,
    Text,
    Toggle,
    useTheme,
} from '@fluentui/react';
import { CombinedPayerAndPayerDetail, IFeeSchedule } from 'api/models/payer.model';
import { SubSection } from 'components';
import SortableDetailsList, { ISortableColumn } from 'components/SortableDetailsList/SortableDetailsList';
import { useDispatch } from 'react-redux';
import { NavLink, useParams } from 'react-router-dom';
import { updateFeeScheduleIsDeleted } from 'state/slices/payers/payers.actions';
import dateOnly from 'utils/dateOnly';
import { isDateBetween } from 'utils/isDateBetween';

type Props = {
    selectedPayer?: CombinedPayerAndPayerDetail;
    openPanel: () => void;
};

function FeeSchedules({ selectedPayer, openPanel }: Props) {
    const { tenantId, payerId } = useParams<{ tenantId: string; payerId: string }>();
    const theme = useTheme();
    const dispatch = useDispatch();

    const linkStyles: React.CSSProperties = {
        textDecoration: 'none',
    };

    const iconClass = mergeStyles({
        fontSize: 15,
        height: 15,
        width: 15,
        margin: '0 35px',
    });

    const classNames = mergeStyleSets({
        red: [{ color: theme.palette.red }, iconClass],
        greenYellow: [{ color: theme.palette.green }, iconClass],
    });

    const gridColumns: ISortableColumn<IFeeSchedule>[] = [
        {
            key: 'displayName',
            fieldName: 'displayName',
            minWidth: 150,
            maxWidth: 150,
            name: 'Display Name',
            getValueKey: (item) => {
                return item?.displayName ?? '';
            },
            onRender: (item, index) => {
                if (item) {
                    return (
                        <NavLink to={`/${tenantId}/payers/${payerId}/fee-schedules/${item.id}`} style={linkStyles} exact={true}>
                            <Link>{item?.displayName ? item?.displayName : 'Add +'}</Link>
                        </NavLink>
                    );
                } else {
                    return undefined;
                }
            },
        },
        {
            key: 'effectiveDate',
            fieldName: 'effectiveDate',
            minWidth: 130,
            maxWidth: 130,
            name: 'Effective Date',
            onRender: (item, index) => {
                if (item) {
                    return (
                        <>
                            {item?.effectiveDate ? (
                                <Text variant="small">{dateOnly(item.effectiveDate as string)}</Text>
                            ) : (
                                <NavLink
                                    to={`/${tenantId}/payers/${payerId}/fee-schedules/${item.id}`}
                                    style={linkStyles}
                                    exact={true}
                                >
                                    <Link>Add +</Link>
                                </NavLink>
                            )}
                        </>
                    );
                } else {
                    return undefined;
                }
            },
        },
        {
            key: 'endDate',
            fieldName: 'endDate',
            minWidth: 130,
            maxWidth: 130,
            name: 'End Date',
            onRender: (item, index) => {
                if (item) {
                    return (
                        <>
                            {item?.endDate ? (
                                <Text variant="small">{dateOnly(item.endDate as string)}</Text>
                            ) : (
                                <NavLink
                                    to={`/${tenantId}/payers/${payerId}/fee-schedules/${item.id}`}
                                    style={linkStyles}
                                    exact={true}
                                >
                                    <Link>Add +</Link>
                                </NavLink>
                            )}
                        </>
                    );
                } else {
                    return undefined;
                }
            },
        },
        {
            key: 'active',
            fieldName: 'active',
            minWidth: 100,
            maxWidth: 100,
            name: 'Current/Active',
            isResizable: true,
            onRender: (item) => {
                if (item) {
                    const today = new Date();
                    const startDate = item.effectiveDate ? new Date(item.effectiveDate as string) : '';
                    const endDate = item.endDate ? new Date(item.endDate as string) : '';

                    const isActive =
                        item.effectiveDate && !item.isDeleted
                            ? isDateBetween({
                                  dateToCheck: today.toString(),

                                  start: startDate.toString(),
                                  end: endDate.toString(),
                              })
                            : false;

                    // const isActive = () => {
                    //     if (!item.isDeleted) {
                    //         if (isBefore(today, startDate) || isAfter(today, endDate)) {
                    //             return false;
                    //         }
                    //         if (isAfter(today, startDate) && isBefore(today, endDate)) {
                    //             return true;
                    //         }
                    //         if (isAfter(today, startDate)) {
                    //             return true;
                    //         }
                    //     }
                    // };

                    return (
                        <>
                            {isActive ? (
                                <FontIcon aria-label="active" iconName="CircleFill" className={classNames.greenYellow} />
                            ) : (
                                <FontIcon aria-label="inactive" iconName="CircleFill" className={classNames.red} />
                            )}
                        </>
                    );
                } else {
                    return undefined;
                }
            },
        },
        {
            key: 'isDeleted',
            fieldName: 'isDeleted',
            minWidth: 100,
            maxWidth: 100,
            name: 'Disabled',
            onRender: (item, index) => {
                if (item) {
                    return (
                        <Toggle
                            checked={item.isDeleted}
                            onChange={(ev, value) => {
                                index = index ? index : 0;
                                dispatch(updateFeeScheduleIsDeleted({ value, index }));
                            }}
                        />
                    );
                } else {
                    return undefined;
                }
            },
        },
    ];

    const _onAddFeeSchedule = () => {
        openPanel();
    };

    const noEndDate = selectedPayer?.feeSchedules?.find((f) => !f.endDate);
    const disableButton = noEndDate ? true : false;

    return (
        <SubSection
            title="Fee Schedules"
            headingEndContent={
                <PrimaryButton
                    text={'Add Fee Schedule'}
                    iconProps={{ iconName: 'Add' }}
                    onClick={_onAddFeeSchedule}
                    disabled={disableButton}
                />
            }
        >
            {selectedPayer?.feeSchedules ? (
                <SortableDetailsList<IFeeSchedule>
                    selectionMode={SelectionMode.none}
                    items={selectedPayer?.feeSchedules}
                    columns={gridColumns}
                    stickyHeader
                    selectionPreservedOnEmptyClick
                />
            ) : (
                <MessageBar messageBarType={MessageBarType.info}>There are no fee schedules for the selected payer.</MessageBar>
            )}
        </SubSection>
    );
}

export default FeeSchedules;
