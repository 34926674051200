import { IUserIdentity } from 'api/models/account.model';
import { ITenantUser } from 'api/models/tenantAccount.model';
import { useSelector } from 'hooks';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import {
    cleanupUsersState,
    setSelectedUser,
    updateSubscriptionUserIdentityProperty,
    updateSubscriptionUserProperty,
} from 'state/slices/subscriptions/subscriptions.slice';
import { getSubscriptionUsers } from 'state/slices/subscriptions/users/users.actions';
import {
    selectSubscriptionUsers,
    selectSubscriptionUsersAsList,
    usersNotInSubscription,
} from 'state/slices/subscriptions/users/users.selectors';

function useSubscriptions() {
    const params = useParams<{ tenantId: string; productId: string }>();
    const { tenantId, productId } = params;

    const dispatch = useDispatch();

    const _getSubscriptionUsers = useCallback(() => {
        dispatch(getSubscriptionUsers({ tenantId, productId }));
    }, [tenantId, productId, dispatch]);

    const _setSelectedUser = useCallback(
        (item: ITenantUser) => {
            dispatch(setSelectedUser(item));
        },
        [dispatch],
    );

    const _updateSubscriptionUserProperty = useCallback(
        (path: keyof ITenantUser, value: any) => {
            dispatch(updateSubscriptionUserProperty({ path, value }));
        },
        [dispatch],
    );

    const _updateSubscriptionUserIdentityProperty = useCallback(
        (path: keyof IUserIdentity, value: any) => {
            dispatch(updateSubscriptionUserIdentityProperty({ path, value }));
        },
        [dispatch],
    );

    const _cleanupUsersState = useCallback(() => {
        dispatch(cleanupUsersState());
    }, [dispatch]);

    const { loading, saving, data: _users, selectedUser } = useSelector(selectSubscriptionUsers);
    const _selectSubscriptionUsersAsList = useSelector(selectSubscriptionUsersAsList);
    const _usersNotInSubscription = useSelector(usersNotInSubscription);

    return {
        getSubscriptionUsers: _getSubscriptionUsers,

        updateSubscriptionUserProperty: _updateSubscriptionUserProperty,

        setSelectedUser: _setSelectedUser,
        cleanupUsersState: _cleanupUsersState,

        loading,
        saving,
        users: _users,
        selectedUser,

        selectSubscriptionUsersAsList: _selectSubscriptionUsersAsList,
        usersNotInSubscription: _usersNotInSubscription,

        updateSubscriptionUserIdentityProperty: _updateSubscriptionUserIdentityProperty,
    };
}

export default useSubscriptions;
