import {
    DefaultButton,
    Panel,
    PanelType,
    PrimaryButton,
    SelectionMode,
    Stack,
    Toggle,
    Text,
    MessageBar,
    MessageBarType,
    FontIcon,
    mergeStyles,
    useTheme,
    mergeStyleSets,
} from '@fluentui/react';
import { IProcedureFee } from 'api/models/procedure-details.model';
import { Field, SortableDetailsList } from 'components';
import PanelSectionHeader from 'components/PanelSectionHeader';
import useProcedureDetails from 'hooks/store/useProcedureDetails';
import { LoadingStatus } from 'interfaces/loading-statuses';
import { round } from 'lodash';
import dateOnly, { classicDateOnly } from 'utils/dateOnly';
import { isDateBetween } from 'utils/isDateBetween';

export default function ProcedureDetailPanel() {
    const {
        selectedProcedureDetail,
        cleanupSelectedProcedureDetail,
        savingProcedureDetail,
        createProcedureFee,
        updateProcedureFee,
        updateProcedureDetail,
        procedureDetailValidationErrors,
    } = useProcedureDetails();

    const onDismiss = () => cleanupSelectedProcedureDetail();
    const theme = useTheme();
    const procedureName = `${selectedProcedureDetail?.code ?? ''} - ${selectedProcedureDetail?.displayName ?? ''}`;

    const saving = savingProcedureDetail === LoadingStatus.Pending;
    const iconClass = mergeStyles({
        fontSize: 15,
        height: 15,
        width: 15,
        margin: '0 20px',
    });
    const classNames = mergeStyleSets({
        red: [{ color: theme.palette.red }, iconClass],
        greenYellow: [{ color: theme.palette.green }, iconClass],
    });
    const procedureFee = selectedProcedureDetail?.fees?.find((f) => !f.endDate);
    const disableButton = procedureFee ? true : false;
    return (
        <Panel
            headerText="Edit Procedure Fees"
            isOpen={!!selectedProcedureDetail}
            type={PanelType.medium}
            onDismiss={onDismiss}
            isFooterAtBottom
            onRenderFooterContent={() => (
                <Stack tokens={{ childrenGap: 10 }} grow>
                    {procedureDetailValidationErrors.length && (
                        <MessageBar messageBarType={MessageBarType.blocked}>
                            <ul style={{ margin: 0 }}>
                                {procedureDetailValidationErrors.map((error, index) => (
                                    <li key={index}>{error}</li>
                                ))}
                            </ul>
                        </MessageBar>
                    )}
                    <Stack horizontal grow tokens={{ childrenGap: 10 }}>
                        <PrimaryButton
                            onClick={() => {
                                if (selectedProcedureDetail) updateProcedureDetail(selectedProcedureDetail);
                            }}
                            disabled={saving || !!procedureDetailValidationErrors.length}
                        >
                            Save
                        </PrimaryButton>
                        <DefaultButton onClick={onDismiss}>Close</DefaultButton>
                    </Stack>
                </Stack>
            )}
        >
            <Stack tokens={{ childrenGap: 10 }}>
                <Text>{procedureName}</Text>
                <PanelSectionHeader text="Procedure Fees" />
                <Stack horizontal grow horizontalAlign="end">
                    <DefaultButton primary text="Add Fee" disabled={disableButton} onClick={createProcedureFee} />
                </Stack>
            </Stack>
            <SortableDetailsList<IProcedureFee>
                selectionMode={SelectionMode.none}
                items={selectedProcedureDetail?.fees ?? []}
                columns={[
                    {
                        key: 'test',
                        name: 'Fee',
                        minWidth: 50,
                        onRender: (item, index) => (
                            <Field.Currency
                                onChange={(ev, value) => {
                                    if (index !== undefined) updateProcedureFee(index, 'fee', value);
                                }}
                                value={round(item?.fee ?? 0, 2)}
                            />
                        ),
                    },
                    {
                        key: 'effectiveDate',
                        minWidth: 125,
                        name: 'Eff. Date',
                        onRender: (item, index) => (
                            <Field.Date
                                hasDatePicker
                                // disabled={!item?.isCurrentFee}
                                value={
                                    item?.effectiveDate ? classicDateOnly(new Date(item.effectiveDate).toISOString()) : undefined
                                }
                                onChange={(ev, value) => {
                                    if (index !== undefined) updateProcedureFee(index, 'effectiveDate', value);
                                }}
                            />
                        ),
                    },
                    {
                        key: 'endDate',
                        minWidth: 125,
                        name: 'End Date',
                        onRender: (item, index) => (
                            <Field.Date
                                hasDatePicker
                                value={item?.endDate ? classicDateOnly(new Date(item.endDate).toISOString()) : undefined}
                                onChange={(ev, value) => {
                                    if (index !== undefined) updateProcedureFee(index, 'endDate', value);
                                }}
                            />
                        ),
                    },
                    {
                        key: 'isCurraent',
                        minWidth: 100,
                        name: 'Current',
                        onRender: (item) => {
                            if (item) {
                                const today = new Date();
                                const startDate = item?.effectiveDate ? new Date(item?.effectiveDate) : '';
                                const endDate = item.endDate ? new Date(item.endDate) : '';
                                const isProcedureFeeCurrent = isDateBetween({
                                    dateToCheck: today.toString(),
                                    start: startDate.toString(),
                                    end: endDate.toString(),
                                });
                                return (
                                    <Stack>
                                        {isProcedureFeeCurrent ? (
                                            <FontIcon
                                                aria-label="active"
                                                iconName="CircleFill"
                                                className={classNames.greenYellow}
                                            />
                                        ) : (
                                            <FontIcon aria-label="inactive" iconName="CircleFill" className={classNames.red} />
                                        )}
                                    </Stack>
                                );
                            }
                        },
                    },
                ]}
            />
        </Panel>
    );
}
