import { Label, MessageBar, MessageBarType, PrimaryButton, Stack, Text, Toggle, useTheme } from '@fluentui/react';
import { ActivityPage } from 'components';
import ManageSlidingFee from './ManageSlidingFee';
import SlidingFeeBar from './components/SlidingFeeBar';
import ProgramPanel from './components/ProgramPanel';
import useSlidingFee from 'hooks/store/useSlidingFee';
import { useEffect } from 'react';
import ISlidingFeeProgram from 'api/models/slidingFee.model';
import ProgramPlan from './components/ProgramPlan';
import { useLocation, useParams } from 'react-router';

export default function SlidingFees() {
    const {
        toggleIsProgramPanelOpen,
        getSlidingFeePrograms,
        programsAsList,
        cleanupSelectedProgram,
        toggleShowProgramHistory,
        showProgramsHistory,
    } = useSlidingFee();
    const { programId } = useParams<{ programId: string }>();
    const theme = useTheme();

    const location = useLocation();
    const routePieces = location.pathname.split('/').filter((string) => string !== '/' && string !== '');
    const plan = routePieces[3];

    useEffect(() => {
        getSlidingFeePrograms();
        return () => {
            cleanupSelectedProgram();
        };
    }, [getSlidingFeePrograms, cleanupSelectedProgram]);

    const createProgram = () => {
        toggleIsProgramPanelOpen();
    };

    const headerContent = () => {
        const defaultSlidingProgram = programsAsList.find((program) => program.isDefaultSlidingFeeProgram);
        return (
            <Stack horizontal verticalAlign="center" tokens={{ childrenGap: 40 }}>
                <Toggle
                    label="Active Only "
                    inlineLabel
                    checked={!showProgramsHistory}
                    styles={{ root: { marginBottom: 3 } }}
                    onClick={() => toggleShowProgramHistory()}
                />
                <Stack horizontal verticalAlign="center" tokens={{ childrenGap: 5 }}>
                    <Label> Sliding Fee Program:</Label> <Text variant="medium">{defaultSlidingProgram?.name ?? 'N/A'}</Text>
                </Stack>
                <PrimaryButton iconProps={{ iconName: 'Add' }} onClick={createProgram}>
                    Create Program
                </PrimaryButton>
            </Stack>
        );
    };

    return (
        <ActivityPage title="Programs" rightContent={headerContent()} hideFooter hasPageHeader>
            <Stack horizontal styles={{ root: { flex: 1, height: '100%', overflow: 'hidden' } }}>
                <SlidingFeeBar
                    programs={programsAsList.map((program: ISlidingFeeProgram) => ({
                        id: program.id,
                        name: program.name,
                    }))}
                />
                <Stack styles={{ root: { padding: 10, display: 'flex' } }} grow>
                    <Stack grow>
                        {!programId ? (
                            <MessageBar messageBarType={MessageBarType.info} theme={theme} title="Choose a Program">
                                There is no program selected. Click a program to manage the plans of that program.
                            </MessageBar>
                        ) : !plan ? (
                            <ManageSlidingFee />
                        ) : (
                            <ProgramPlan />
                        )}
                    </Stack>
                </Stack>
            </Stack>
            <ProgramPanel />
        </ActivityPage>
    );
}
