import { Dictionary } from '@reduxjs/toolkit';
import membershipApi from 'api/membership.api';
import IStatementConfig, { IDunningRule } from './models';
import LookupBase from 'api/models/lookup-base.model';

const { client } = membershipApi;

const statementApi = {
    getStatementConfigurations: async (tenantId: string) => {
        return client.get<IStatementConfig>(`Tenants/${tenantId}/StatementConfigurations`);
    },

    createStatementConfiguration: async (tenantId: string, model: IStatementConfig) => {
        return client.post<IStatementConfig>(`Tenants/${tenantId}/StatementConfigurations`, model);
    },
    updateStatementConfiguration: async (tenantId: string, model: IStatementConfig) => {
        return client.put<IStatementConfig>(`Tenants/${tenantId}/StatementConfigurations`, model);
    },

    getProviderTypes: async () => {
        return client.get<Dictionary<LookupBase>>(`ProviderTypes`);
    },

    getDunningRules: async (tenantId: string) => {
        return client.get<Record<string, IDunningRule>>(`Tenants/${tenantId}/DunningRules`);
    },

    updateDunningRules: async (tenantId: string, model: IDunningRule) => {
        return client.put<IDunningRule>(`Tenants/${tenantId}/DunningRules`, model);
    },
};

export default statementApi;
