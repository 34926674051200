import { createSlice, Dictionary } from '@reduxjs/toolkit';
import { IUserIdentity } from 'api/models/account.model';
import { ITenantUser } from 'api/models/tenantAccount.model';
import { AxiosError } from 'axios';
import { LoadingStatus, LoadingStatuses } from 'interfaces/loading-statuses';
import usersReducers, { userExtraReducers } from './users.reducers';

const initialState: UsersState = {
    data: {},
    accounts: [],
    loading: LoadingStatus.Idle,
    saving: LoadingStatus.Idle,
    showActiveOnly: true,
};

const usersSlice = createSlice({
    name: 'users',
    initialState,
    reducers: {
        ...usersReducers,
    },
    extraReducers: (builder) => {
        userExtraReducers(builder);
    },
});

const { actions, reducer } = usersSlice;

export const { setSelectedUser, setShowActiveOnly, updateIdentityProperty, updateUserProperty, cleanupUsersState } = actions;

export default reducer;

export type UsersState = {
    selectedUser?: ITenantUser;
    showActiveOnly: boolean;
    data: Dictionary<ITenantUser>;
    accounts: IUserIdentity[];
    loading: LoadingStatuses;
    saving: LoadingStatuses;
    error?: AxiosError;
};
