import { combineReducers, createSlice } from '@reduxjs/toolkit';
import { initialState } from './state';
import { extraReducers, reducers } from './reducers';
import featureFlags from './feature-flags.slice';

const tenantSlice = createSlice({
    name: 'tenant',
    initialState,
    reducers,
    extraReducers,
});

const { reducer: currentTenant } = tenantSlice;

export const { updateTenantConfigProps, cleanUpTenantConfig } = tenantSlice.actions;

const tenantReducers = combineReducers({
    currentTenant,
    featureFlags,
});

export default tenantReducers;
