import { DefaultButton, MessageBar, MessageBarType, Panel, PanelType, PrimaryButton, Stack } from '@fluentui/react';
import useUsers from 'hooks/store/useUsers';
import { LoadingStatus } from 'interfaces/loading-statuses';
import { useEffect } from 'react';
import EditUserOverview from './EditUserOverview';

type Props = { isOpen: boolean; closePanel: () => void };
function EditUserPanel({ isOpen, closePanel }: Props) {
    const { selectUsersAsList, selectedUser, saveUserAndIdentity, saving } = useUsers();
    const _isSaving = saving === LoadingStatus.Pending;
    useEffect(() => {
        if (saving === LoadingStatus.Completed) {
            closePanel();
        }
        return;
    }, [saving, closePanel]);

    const _isNewUser = selectedUser?.id ? false : true;
    const _panelHeadingText = _isNewUser ? 'Create User' : 'Edit User';
    const _saveUserAndIdentity = () => {
        if (selectedUser) saveUserAndIdentity(selectedUser);
    };

    const _isDuplicateEmail = selectUsersAsList.find((account) => account.identity.email === selectedUser?.identity?.email)
        ? true
        : false;

    const _isDuplicateEmailMessage =
        _isDuplicateEmail && _isNewUser ? (
            <MessageBar messageBarType={MessageBarType.blocked}>Email already exists</MessageBar>
        ) : (
            ''
        );

    const _disabledDuplicate = _isNewUser && _isDuplicateEmail ? true : false;

    const _onRenderFooterContent = () => {
        const savingText = _isSaving ? 'Saving...' : 'Save';

        return (
            <Stack horizontal tokens={{ childrenGap: 12 }}>
                {_isDuplicateEmailMessage}
                <PrimaryButton
                    text={savingText}
                    disabled={_isSaving || _disabledDuplicate}
                    iconProps={{ iconName: 'SaveAndClose' }}
                    onClick={_saveUserAndIdentity}
                />
                <DefaultButton text="Cancel" disabled={_isSaving} onClick={closePanel} />
            </Stack>
        );
    };
    return (
        <Panel
            isOpen={isOpen}
            onDismiss={closePanel}
            headerText={_panelHeadingText}
            type={PanelType.medium}
            onRenderFooterContent={_onRenderFooterContent}
            isFooterAtBottom
        >
            <EditUserOverview />
        </Panel>
    );
}

export default EditUserPanel;
