import { IComboBoxOption } from '@fluentui/react';
import { createSelector } from '@reduxjs/toolkit';
import { map, sortBy } from 'lodash';
import { RootState } from 'state/store';
import { IDunningRule } from './models';

export const statementConfigurationState = (state: RootState) => state.statementConfiguration;

export const isPatientStatementNotePanelOpen = createSelector(statementConfigurationState, (state) => state.openPanel);

export const isExampleModalOpen = createSelector(statementConfigurationState, (state) => state.exampleModalOpen);

export const statementConfigurations = createSelector(statementConfigurationState, (state) => state.statementConfigurations);

export const disableStatementConfig = createSelector(
    statementConfigurations,
    (statementConfigurations) => statementConfigurations?.isDeleted,
);

export const practiceStatementNotes = createSelector(
    statementConfigurationState,
    (state) => state?.statementConfigurations?.additionalText,
);

export const dunningRules = createSelector(statementConfigurationState, (state) => state?.selectDunningRules ?? {});

export const dunningRulesList = createSelector(dunningRules, (dunningMessages) => {
    if (!Object.keys(dunningMessages).length) return [];
    return map(sortBy(dunningMessages, 'sequence')).filter((dm) => dm !== undefined) as IDunningRule[];
});

export const dunningRulesSaveDisabled = createSelector(dunningRulesList, (dunningRules) =>
    dunningRules.some((dm) => !dm.dunningMessage),
);

export const selectedPracticeStatementNote = createSelector(
    statementConfigurationState,
    (state) => state.selectedPracticeStatementNote,
);

export const providerTypes = createSelector(statementConfigurationState, (state) => state.providerTypes);

export const providerTypesList = createSelector(providerTypes, (providerTypes) => map(providerTypes));

export const providerTypesOptions = createSelector(providerTypesList, (providerTypes) => {
    const providerTypesMapped: IComboBoxOption[] = sortBy(
        map(providerTypes).map((res) => ({
            key: res?.id ?? '',
            text: res?.displayName ?? '',
        })) as IComboBoxOption[],
        'text',
    );
    return providerTypesMapped;
});
