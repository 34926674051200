import Loading from 'components/Loading/Loading';
import { useSelector } from 'hooks';
import { setHubConnectionStatus } from 'hooks/signalR/signalR.slice';
import { appLoaderSignalR } from 'hooks/signalR/signalr.config';
import useHub, { connection, useSignalR } from 'hooks/signalR/useSignalr';
import useAccount from 'hooks/store/useAccount';
import useTenantId from 'hooks/useTenantId';
import { LoadingStatus } from 'interfaces/loading-statuses';
import { PropsWithChildren, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { selectAccountTenantsAsList, selectAccountTenantsData } from 'state/slices/account.slice';
import { loadTenant } from 'state/slices/tenant/actions';

type Props = unknown;

function AppLoader(props: PropsWithChildren<Props>) {
    const dispatch = useDispatch();
    const { push } = useHistory();

    const { account, getAccount } = useAccount();
    const accountTenants = useSelector(selectAccountTenantsAsList);
    const accountTenantsData = useSelector(selectAccountTenantsData);
    const { hubConnectionState } = useHub(connection);
    const { registerSignalRConfig } = useSignalR();

    const tenantId = useTenantId();

    useEffect(() => {
        dispatch(setHubConnectionStatus(hubConnectionState));
    }, [hubConnectionState]);

    useEffect(() => {
        getAccount();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (accountTenants.length === 1 && !tenantId) push(`/${accountTenants[0].id}`);
    }, [account]);

    useEffect(() => {
        if (account.loading === LoadingStatus.Completed && tenantId && hubConnectionState === 'Connected') {
            // can be used to load tenant assets as needed
            dispatch(loadTenant(tenantId));
            registerSignalRConfig(appLoaderSignalR);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [account, tenantId, hubConnectionState]);

    if (account.loading === LoadingStatus.Pending) {
        return <Loading spinnerText="Loading account..." />;
    }

    if (tenantId && !accountTenantsData[tenantId]) {
        return <Loading errorMessage="Unauthorized tenant access" />;
    }

    return <> {props.children} </>;
}

export default AppLoader;
