import {
    Checkbox,
    DefaultButton,
    PrimaryButton,
    Stack,
    TextField,
    Toggle,
} from '@fluentui/react';
import ISlidingFeeProgram from 'api/models/slidingFee.model';
import { Field } from 'components';
import useSlidingFee from 'hooks/store/useSlidingFee';
import { useEffect } from 'react';
import useLocationsOfCare from 'hooks/store/useLocationsOfCare';

function ManageProgram() {
    const { selectedProgram, updateSelectedProgram, updateSlidingFeeProgram, programsAsList } = useSlidingFee();
    const { getAllLocationsOfCare } = useLocationsOfCare();
    useEffect(() => {
        getAllLocationsOfCare();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onProgramPropertyChange = (key: keyof ISlidingFeeProgram, value: string | number | boolean | string[]) => {
        if (selectedProgram) updateSelectedProgram({ ...selectedProgram, [key]: value });
    };

    //Additional check to find out if the there are any programs that are defaulted if not. If every program is not defaulted then enable all checkboxes

    const disbaleDefaultProgram =
        (!programsAsList.every((program) => program.isDefaultSlidingFeeProgram === false) &&
            !selectedProgram?.isDefaultSlidingFeeProgram) ||
        selectedProgram?.isRyanWhite;
    const disbaleIsRyanWhite =
        (!programsAsList.every((program) => program.isRyanWhite === false) && !selectedProgram?.isRyanWhite) ||
        selectedProgram?.isDefaultSlidingFeeProgram;



    const _onSave = () => {
        if (selectedProgram) {
            updateSlidingFeeProgram(selectedProgram);
        }
    };

    const nameHasError = !selectedProgram?.name ? true : false;
    const monthsForUpdateHasError =
        !selectedProgram?.monthsForUpdate && selectedProgram?.monthsForUpdate?.toString() === '' ? true : false;

    const disabledButton = nameHasError || monthsForUpdateHasError;

    return (
        <Stack tokens={{ childrenGap: 10 }}>
            <Stack tokens={{ childrenGap: 10 }} horizontal verticalAlign="end" grow>
                <Stack.Item grow>
                    <TextField
                        value={selectedProgram?.name}
                        label="Name"
                        required
                        autoComplete="off"
                        onChange={(event, newValue) => onProgramPropertyChange('name', newValue ? newValue : '')}
                    />
                </Stack.Item>

                <Stack grow horizontal tokens={{ childrenGap: 10 }}>
                    <Checkbox
                        checked={selectedProgram?.isDefaultSlidingFeeProgram}
                        disabled={disbaleDefaultProgram || selectedProgram?.isDeleted}
                        label="Default Sliding Fee Program"
                        onChange={(event, checked) =>
                            onProgramPropertyChange('isDefaultSlidingFeeProgram', checked ? true : false)
                        }
                    />
                    <Checkbox
                        checked={selectedProgram?.isRyanWhite}
                        disabled={disbaleIsRyanWhite || selectedProgram?.isDeleted}
                        label="Ryan White"
                        onChange={(event, checked) => onProgramPropertyChange('isRyanWhite', checked ? true : false)}
                    />
                </Stack>
            </Stack>
            <Stack tokens={{ childrenGap: 10 }} horizontal grow>
                <Stack.Item grow>
                    <Field.Date
                        value={selectedProgram?.effectiveDate}
                        label="Effective Date"
                        onChange={(event, newValue) => {
                            onProgramPropertyChange('effectiveDate', newValue ? newValue : '');
                        }}
                    />
                </Stack.Item>
                <Stack.Item grow>
                    <Field.Date
                        value={selectedProgram?.expirationDate}
                        label="Expiration Date"
                        onChange={(event, newValue) => {
                            onProgramPropertyChange('expirationDate', newValue ? newValue : '');
                        }}
                    />
                </Stack.Item>
                <Stack.Item grow>
                    <TextField
                        value={selectedProgram?.monthsForUpdate ? selectedProgram.monthsForUpdate.toString() : undefined}
                        label="Number of months between updates"
                        required
                        onChange={(event, newValue) =>
                            onProgramPropertyChange('monthsForUpdate', newValue ? parseInt(newValue) : '')
                        }
                        style={{ width: 280 }}
                    />
                </Stack.Item>
            </Stack>
            <Stack tokens={{ childrenGap: 10 }} horizontal grow>
                <Stack.Item grow>
                    <Toggle
                        checked={selectedProgram?.isDeleted}
                        label="Deactivated"
                        inlineLabel
                        onChange={() => onProgramPropertyChange('isDeleted', !selectedProgram?.isDeleted)}
                    />
                </Stack.Item>
            </Stack>
            <Stack horizontal tokens={{ childrenGap: 12 }} horizontalAlign="end">
                <PrimaryButton text={'Save'} disabled={disabledButton} iconProps={{ iconName: 'Save' }} onClick={_onSave} />
                <DefaultButton text="Cancel" />
            </Stack>
        </Stack>
    );
}

export default ManageProgram;
