import { Dictionary } from '@reduxjs/toolkit';
import {
    ChangeAuditSupport,
    DisplayNameSupport,
    ETagSupport,
    IdSupport,
    SoftDeleteSupport,
} from './support-types';

type LocationOfCareSupports = IdSupport &
    ChangeAuditSupport &
    ETagSupport &
    DisplayNameSupport &
    SoftDeleteSupport;

export default interface ILocationOfCare extends LocationOfCareSupports {
    description?: string;
    placeOfServiceFacility?: string;
    placeOfServiceTypeId?: string;
    placeOfServiceTypeName?: string;
    address1?: string;
    address2?: string;
    city?: string;
    state?: string;
    zip?: string;
    phone?: string;
    timeZone?: string;
    timeZoneName?: string;
    timeZoneOffset?: string;
    clinicals?: string;
    departmentType?: LocationOfCareDepartmentType;
    references?: Dictionary<string>;
}

export enum LocationOfCareDepartmentType {
    Dental = 'dental',
}
