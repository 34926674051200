import ReactDOM from "react-dom";

interface IComponentProps {
  children: JSX.Element;
  domNode: any;
}

const TooltipCardsPortal = (props: IComponentProps) => {
  return ReactDOM.createPortal(props.children, props.domNode);
};

export default TooltipCardsPortal;
