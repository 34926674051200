import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { LoadingStatus } from 'interfaces/loading-statuses';
import {
    getDunningRules,
    getProviderTypes,
    getStatementConfigurations,
    updateDunningRules,
    updateStatementConfiguration,
} from './actions';
import { PatientStatementState, initialState } from './state';
import IStatementConfig, { IDunningRule, IAdditionalText } from './models';

const statementConfigurationSlice = createSlice({
    name: 'statementConfiguration',
    initialState,
    reducers: {
        toggleStatementConfigurationPanel(state) {
            state.openPanel = !state.openPanel;
        },
        toggleExampleModal(state) {
            state.exampleModalOpen = !state.exampleModalOpen;
        },

        updateConfigProps(
            state: PatientStatementState,
            action: PayloadAction<{ path: keyof IStatementConfig; value: string | number }>,
        ) {
            const { path, value } = action.payload;
            if (state.statementConfigurations) (state.statementConfigurations[path] as unknown) = value;
        },

        updateDunningMessageProps(
            state: PatientStatementState,
            action: PayloadAction<{ index: number; path: keyof IDunningRule; value: string }>,
        ) {
            const { index, path, value } = action.payload;

            if (state.selectDunningRules) {
                ((state.selectDunningRules[index] as IDunningRule)[path] as unknown) = value;
            }
        },
        updateDunningRuleMessageById(
            state: PatientStatementState,
            action: PayloadAction<{ message: IDunningRule; value: string }>,
        ) {
            const {
                message: { id },
                value,
            } = action.payload;

            if (!Object.keys(state.selectDunningRules) || !state.selectDunningRules[id]) return;
            state.selectDunningRules[id].dunningMessage = value;
        },

        setSelectedPracticeStatementNote(state: PatientStatementState, action: PayloadAction<IAdditionalText>) {
            state.selectedPracticeStatementNote = action.payload;
        },

        updateSelectedPracticeStatementNoteProps(
            state: PatientStatementState,
            action: PayloadAction<{ path: keyof IAdditionalText; value: string }>,
        ) {
            const { path, value } = action.payload;
            if (state.selectedPracticeStatementNote) (state.selectedPracticeStatementNote[path] as unknown) = value;
        },
        cleanupPracticeStatementNote(state: PatientStatementState) {
            state.selectedPracticeStatementNote = undefined;
        },
        toggleSuppressedEncounterRateCode(
            state: PatientStatementState,
            action: PayloadAction<{ path: keyof IStatementConfig; id: string }>,
        ) {
            const { id, path } = action.payload;
            if (!(state.statementConfigurations as IStatementConfig)[path])
                ((state.statementConfigurations as IStatementConfig)[path] as unknown[]) = [];

            const currentIds = (state.statementConfigurations as IStatementConfig)[path] as string[];

            if (currentIds)
                if (currentIds?.includes(id)) {
                    ((state.statementConfigurations as IStatementConfig)[path] as string[]) = currentIds.filter(
                        (_id) => _id !== id,
                    );
                } else {
                    ((state.statementConfigurations as IStatementConfig)[path] as string[]) = [...currentIds, id];
                }
        },
    },
    extraReducers: (builder) => {
        builder
            // Get Statement Configurations
            .addCase(getStatementConfigurations.pending, (state) => {
                state.loading = LoadingStatus.Pending;
            })
            .addCase(getStatementConfigurations.fulfilled, (state, action) => {
                state.loading = LoadingStatus.Completed;
                state.statementConfigurations = action.payload;
            })
            .addCase(getStatementConfigurations.rejected, (state) => {
                state.loading = LoadingStatus.Failed;
            })

            // Update Statement Configurations
            .addCase(updateStatementConfiguration.pending, (state) => {
                state.loading = LoadingStatus.Pending;
            })
            .addCase(updateStatementConfiguration.fulfilled, (state, action) => {
                state.loading = LoadingStatus.Completed;
                state.statementConfigurations = action.payload;
            })
            .addCase(updateStatementConfiguration.rejected, (state) => {
                state.loading = LoadingStatus.Failed;
            })

            // Get Provider Types
            .addCase(getProviderTypes.pending, (state) => {
                state.loadingProviderTypes = LoadingStatus.Pending;
            })
            .addCase(getProviderTypes.fulfilled, (state, action) => {
                state.loadingProviderTypes = LoadingStatus.Completed;
                state.providerTypes = action.payload;
            })
            .addCase(getProviderTypes.rejected, (state) => {
                state.loadingProviderTypes = LoadingStatus.Failed;
            })

            // Get Duning Rules
            .addCase(getDunningRules.pending, (state) => {
                state.loadingDunningRules = LoadingStatus.Pending;
            })
            .addCase(getDunningRules.fulfilled, (state, action) => {
                state.loadingDunningRules = LoadingStatus.Completed;
                state.selectDunningRules = action.payload;
            })
            .addCase(getDunningRules.rejected, (state) => {
                state.loadingDunningRules = LoadingStatus.Failed;
            })

            // Update Duning Rules
            .addCase(updateDunningRules.pending, (state) => {
                state.loadingDunningRules = LoadingStatus.Pending;
            })
            .addCase(updateDunningRules.fulfilled, (state, action) => {
                state.loadingDunningRules = LoadingStatus.Completed;
                state.selectDunningRules = action.payload;
            })
            .addCase(updateDunningRules.rejected, (state) => {
                state.loadingDunningRules = LoadingStatus.Failed;
            });
    },
});

export const {
    toggleStatementConfigurationPanel,
    updateDunningMessageProps,
    toggleExampleModal,
    setSelectedPracticeStatementNote,
    updateSelectedPracticeStatementNoteProps,
    updateConfigProps,
    cleanupPracticeStatementNote,
    updateDunningRuleMessageById,
    toggleSuppressedEncounterRateCode,
} = statementConfigurationSlice.actions;

export default statementConfigurationSlice.reducer;
