import IProcedureDetail, { IProcedureFee } from 'api/models/procedure-details.model';
import { useSelector } from 'hooks';
import useTenantId from 'hooks/useTenantId';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import {
    procedureDetailValidationErrors,
    proceduresWithDetails,
    selectProcedureDetailsAsList,
    selectProcedureDetailsData,
    selectProcedureDetailsState,
    selectSelectedProcedureDetail,
} from 'state/slices/procedureDetails/procedureDetails.selectors';
import {
    cleanupSelectedProcedureDetail,
    createProcedureDetails,
    createProcedureFee,
    getOrCreateProcedureDetail,
    getProcedureDetailById,
    getProcedureDetails,
    setProcedureDetailsSearch,
    setSelectedProcedureDetailProp,
    updateProcedureDetails,
    getExcelProcedureDetails,
    updateProcedureFee,
    bulkUpdateProcedureDetails,
    addExcelProcedureDetails,
} from 'state/slices/procedureDetails/procedureDetails.slice';

export default function useProcedureDetails() {
    const tenantId = useTenantId();
    const dispatch = useDispatch();

    const { loading, loadingProcedureDetail, savingProcedureDetail, procedureDetailsSearch, error, excelDataUploaded } =
        useSelector(selectProcedureDetailsState);
    const _procedureDetailsData = useSelector(selectProcedureDetailsData);
    const _procedureDetailsAsList = useSelector(selectProcedureDetailsAsList);
    const _selectedProcedureDetail = useSelector(selectSelectedProcedureDetail);
    const _proceduresWithDetails = useSelector(proceduresWithDetails);
    const _procedureDetailValidationErrors = useSelector(procedureDetailValidationErrors);

    const _getProcedureDetails = useCallback(() => {
        dispatch(getProcedureDetails({ tenantId }));
    }, [dispatch, tenantId]);

    const _getExcelProcedureDetails = useCallback(() => {
        dispatch(getExcelProcedureDetails({ tenantId }));
    }, [dispatch, tenantId]);

    const _getProcedureDetail = useCallback(
        (id: string) => {
            dispatch(getProcedureDetailById({ tenantId, id }));
        },
        [dispatch, tenantId],
    );

    const _createProcedureDetail = useCallback(
        (model: IProcedureDetail) => {
            dispatch(createProcedureDetails({ tenantId, model }));
        },
        [dispatch, tenantId],
    );
    const _updateProcedureDetail = useCallback(
        (model: IProcedureDetail) => {
            dispatch(updateProcedureDetails({ tenantId, model }));
        },
        [dispatch, tenantId],
    );
    const _bulkUpdateProcedureDetail = useCallback(
        (model: IProcedureDetail[]) => {
            dispatch(bulkUpdateProcedureDetails({ tenantId, model }));
        },
        [dispatch, tenantId],
    );
    const _getOrCreateProcedureDetail = useCallback(
        (procedureId: string) => {
            dispatch(getOrCreateProcedureDetail({ tenantId, procedureId }));
        },
        [dispatch, tenantId],
    );

    const _updateProcedureDetailsProp = useCallback(
        (path: keyof IProcedureDetail, value: any) => {
            dispatch(setSelectedProcedureDetailProp({ path, value }));
        },
        [dispatch],
    );

    const _setProcedureDetailsSearch = useCallback(
        (search?: string) => {
            dispatch(setProcedureDetailsSearch(search));
        },
        [dispatch],
    );

    const _updateProcedureFee = useCallback(
        (index: number, path: keyof IProcedureFee, value: unknown) => {
            dispatch(updateProcedureFee({ index, path, value }));
        },
        [dispatch],
    );

    const _createProcedureFee = useCallback(() => {
        dispatch(createProcedureFee());
    }, [dispatch]);

    const _cleanupSelectedProcedureDetail = useCallback(() => {
        dispatch(cleanupSelectedProcedureDetail());
    }, [dispatch]);

    const _addExcelProcedureDetails = useCallback(
        (formFile: File) => {
            dispatch(addExcelProcedureDetails({ tenantId, formFile }));
        },
        [dispatch, tenantId],
    );

    return {
        //Selectors
        procedureDetailsSearch,
        loading,
        error,
        excelDataUploaded,
        loadingProcedureDetail,
        procedureDetailsData: _procedureDetailsData,
        procedureDetailsAsList: _procedureDetailsAsList,
        selectedProcedureDetail: _selectedProcedureDetail,
        proceduresWithDetails: _proceduresWithDetails,
        savingProcedureDetail,
        procedureDetailValidationErrors: _procedureDetailValidationErrors,

        //Actions
        getProcedureDetails: _getProcedureDetails,
        getProcedureDetail: _getProcedureDetail,
        getExcelProcedureDetails: _getExcelProcedureDetails,
        createProcedureDetail: _createProcedureDetail,
        updateProcedureDetail: _updateProcedureDetail,
        getOrCreateProcedureDetail: _getOrCreateProcedureDetail,
        cleanupSelectedProcedureDetail: _cleanupSelectedProcedureDetail,
        updateProcedureDetailsProp: _updateProcedureDetailsProp,
        setProcedureDetailsSearch: _setProcedureDetailsSearch,
        createProcedureFee: _createProcedureFee,
        updateProcedureFee: _updateProcedureFee,
        bulkUpdateProcedureDetails: _bulkUpdateProcedureDetail,
        addExcelProcedureDetails: _addExcelProcedureDetails,
    };
}
