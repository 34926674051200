import { DefaultButton, PrimaryButton, Spinner, Stack, TextField, Toggle } from '@fluentui/react';
import { IFeeSchedule } from 'api/models/payer.model';
import { Field, Section } from 'components';
import { isBefore } from 'date-fns';
import { useSelector } from 'hooks';
import { isEqual } from 'lodash';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { getPayerAndDetail, upsertPayerDetail } from 'state/slices/payers/payers.actions';
import { selectLoading, selectPayerDetailSaving, selectSelectedPayer } from 'state/slices/payers/payers.selectors';
import { setSelectedPayer } from 'state/slices/payers/payers.slice';
import FeeScheduleProcedures from './FeeScheduleProcedures';

function ManageFeeSchedule() {
    const { tenantId, payerId, feeScheduleId } = useParams<{ tenantId: string; payerId: string; feeScheduleId: string }>();
    const { push } = useHistory();
    const dispatch = useDispatch();

    const selectedPayer = useSelector(selectSelectedPayer);
    const selectedFeeSchedule =
        selectedPayer && selectedPayer.feeSchedules && selectedPayer.feeSchedules.find((x) => x.id === feeScheduleId);

    const loading = useSelector(selectLoading);
    const saving = useSelector(selectPayerDetailSaving);

    useEffect(() => {
        dispatch(getPayerAndDetail({ tenantId, payerId }));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [payerId, tenantId]);

    const updateSelectedPayer = () => {
        if (!selectedPayer) return;
        if (!selectedFeeSchedule) return;

        const existingFeeSchedule = selectedPayer.feeSchedules?.find((x) => x.id === selectedFeeSchedule.id);
        let feeSchedules;

        if (!existingFeeSchedule) {
            // No existing fee schedule, add new one
            feeSchedules = selectedPayer.feeSchedules
                ? [...selectedPayer.feeSchedules, selectedFeeSchedule]
                : [selectedFeeSchedule];
        } else {
            // Existing fee schedule, update it
            feeSchedules = selectedPayer.feeSchedules?.map((feeSchedule) =>
                feeSchedule.id === selectedFeeSchedule.id ? selectedFeeSchedule : feeSchedule,
            );
        }
        dispatch(
            setSelectedPayer({
                ...selectedPayer,
                feeSchedules,
            }),
        );
    };

    const _onSave = () => {
        if (hasFeeSchedulesDateConflict() || !selectedFeeSchedule?.displayName) return;
        updateSelectedPayer();
        dispatch(upsertPayerDetail(tenantId));
    };

    const hasFeeSchedulesDateConflict = (): boolean => {
        let hasConflict = false;
        selectedPayer &&
            selectedPayer.feeSchedules &&
            selectedPayer.feeSchedules
                .filter((x) => x.id !== selectedFeeSchedule?.id)
                .forEach((fee) => {
                    const endDate = fee.endDate;
                    const effectiveDateIsBefore =
                        endDate && selectedFeeSchedule?.effectiveDate
                            ? isBefore(new Date(selectedFeeSchedule?.effectiveDate), new Date(endDate))
                            : false;
                    const effectiveDateIsEqual =
                        endDate && selectedFeeSchedule?.effectiveDate
                            ? isEqual(new Date(selectedFeeSchedule?.effectiveDate), new Date(endDate))
                            : false;
                    if (effectiveDateIsBefore || effectiveDateIsEqual) {
                        hasConflict = true;
                    }
                });
        return hasConflict;
    };

    const hasDateConflict = (): boolean => {
        const hasConflict = false;
        if (selectedFeeSchedule?.endDate && selectedFeeSchedule?.effectiveDate) {
            return isBefore(new Date(selectedFeeSchedule?.endDate), new Date(selectedFeeSchedule?.effectiveDate));
        }
        return hasConflict;
    };

    const _onReturnToPayer = () => {
        push(`/${tenantId}/payers/${payerId}`);
    };

    const _onPropChange = (path: keyof IFeeSchedule, value?: string | boolean) => {
        if (selectedPayer) {
            if (selectedFeeSchedule) {
                const insertIndex = selectedPayer.feeSchedules?.indexOf(selectedFeeSchedule) as number;

                const feeSchedules =
                    selectedPayer.feeSchedules && selectedPayer.feeSchedules.filter((x) => x.id !== selectedFeeSchedule.id);

                const updatedFeeSchedule: IFeeSchedule = {
                    ...selectedFeeSchedule,
                    [path]: value,
                };

                if (feeSchedules) {
                    feeSchedules.splice(insertIndex, 0, updatedFeeSchedule);
                }

                dispatch(
                    setSelectedPayer({
                        ...selectedPayer,
                        feeSchedules,
                    }),
                );
            }
        }
    };

    const displayNameIsRequired = !selectedFeeSchedule?.displayName ? 'Name is required.' : '';
    const startDateHasConflict = hasFeeSchedulesDateConflict() ? 'Date ranges cannot overlap with an active fee schedule.' : '';
    const endDateHasConflict = hasDateConflict() ? 'End date cannot be before the effective date.' : '';
    const isDisable = hasFeeSchedulesDateConflict() || hasDateConflict() || !selectedFeeSchedule?.displayName || saving;

    if (loading) return <Spinner label="Loading Payer..." />;
    return (
        <Section
            heading={
                <Stack tokens={{ childrenGap: 12 }}>
                    <span>{selectedPayer ? selectedPayer.name : ''} </span>
                    <span>{selectedFeeSchedule?.displayName ? selectedFeeSchedule?.displayName : 'Add Fee Schedule'}</span>
                </Stack>
            }
            headingRightContent={
                <Stack horizontal tokens={{ childrenGap: 12 }} verticalAlign="center">
                    <DefaultButton text={'Payer'} iconProps={{ iconName: 'ChromeBack' }} onClick={_onReturnToPayer} />
                    <PrimaryButton
                        text={saving ? 'Saving' : 'Save'}
                        disabled={isDisable}
                        iconProps={{ iconName: 'SaveAndClose' }}
                        onClick={_onSave}
                    />
                </Stack>
            }
            style={{ marginBottom: 0 }}
            styleContent={{ display: 'flex', flex: 'auto' }}
            grow
        >
            <Stack tokens={{ childrenGap: 10 }} grow>
                <Stack tokens={{ childrenGap: 12 }} horizontal>
                    <TextField
                        label="Name"
                        value={selectedFeeSchedule?.displayName}
                        onChange={(ev, value) => _onPropChange('displayName', value)}
                        errorMessage={displayNameIsRequired}
                    />
                    <Field.Date
                        label="Effective Date"
                        value={selectedFeeSchedule?.effectiveDate}
                        onChange={(ev, value) => _onPropChange('effectiveDate', value)}
                        errorMessage={startDateHasConflict}
                    />
                    <Field.Date
                        label="End Date"
                        value={selectedFeeSchedule?.endDate}
                        onChange={(ev, value) => _onPropChange('endDate', value)}
                        errorMessage={endDateHasConflict}
                    />
                    <Toggle
                        label={'Disabled'}
                        checked={selectedFeeSchedule?.isDeleted}
                        onChange={(ev, value) => _onPropChange('isDeleted', value)}
                    />
                </Stack>
                <Stack grow>
                    <FeeScheduleProcedures selectedFeeSchedule={selectedFeeSchedule} />
                </Stack>
                <Stack horizontalAlign="end">
                    <DefaultButton text={'Payer'} iconProps={{ iconName: 'ChromeBack' }} onClick={_onReturnToPayer} />
                </Stack>
            </Stack>
        </Section>
    );
}

export default ManageFeeSchedule;
