import ILocationOfCare, { LocationOfCareDepartmentType } from 'api/models/locationsOfCare.model';
import { map, sortBy } from 'lodash';
import { createSelector } from 'reselect';
import { RootState } from 'state/store';
import { IComboBoxOption, SelectableOptionMenuItemType } from '@fluentui/react';
import { selectDentalDefaultLoc } from '../tenant/selectors';

const selectLocationsOfCare = (state: RootState) => state.locationsOfCare;

export const locationsOfCareData = createSelector(selectLocationsOfCare, (state) => (state.data ? state.data : {}));

export const locationsOfCareSearchResults = createSelector(selectLocationsOfCare, (state) =>
    state.searchResults ? state.searchResults.filter((item) => !item.isDeleted) : [],
);

export const locationsOfCareAsList = createSelector(
    [locationsOfCareData, locationsOfCareSearchResults, selectLocationsOfCare],
    (data, results, state) =>
        (state.searchResults?.length || state.search ? results : map(data)).filter(
            (item) => !item?.isDeleted,
        ) as ILocationOfCare[],
);

export const selectDentalDefaultLocObject = createSelector(
    locationsOfCareData,
    selectDentalDefaultLoc,
    (locs, defaultDentalLoc) => {
        return defaultDentalLoc ? locs[defaultDentalLoc.toString()] : undefined;
    },
);

export const defaultLocationOfCareOptions = createSelector(locationsOfCareAsList, (data) => {
    const filterLocationOfCare = sortBy(data, 'displayName').filter(
        (item) => item.departmentType === LocationOfCareDepartmentType.Dental,
    );
    const dataMapped: IComboBoxOption[] = filterLocationOfCare.map((res) => ({
        key: res.id,
        text: res.displayName,
    }));

    return dataMapped;
});

export const locationsOfCareOptions = createSelector(locationsOfCareAsList, (data) => {
    const dataMapped: IComboBoxOption[] = map(data).map((res) => ({
        key: res.id,
        text: res.displayName,
    }));
    dataMapped.unshift({
        key: 'selectAll',
        text: 'Select All',
        itemType: SelectableOptionMenuItemType.SelectAll,
    });
    return dataMapped;
});
export default selectLocationsOfCare;
