import { createAsyncThunk } from '@reduxjs/toolkit';
import ITenant from 'api/models/tenant.model';
import { ITenantSubscriptions } from 'api/models/tenantSubscription.model';
import { AppThunk, CreateAsyncThunkOptions } from 'state/store';
import { tenantApi } from './api';
import { ITenantConfig, ITenantConfigSetups } from './model';
import { getUserTasksByTarget } from '../tasks/actions';
import { UserTaskTarget } from '../tasks/model';
import { getActiveFeatureFlagsForTenant } from './feature-flags.slice';

/**
 * Get tenant by id
 * @param tenantId
 * @returns {Promise<ITenant>}
 */
export const getTenant = createAsyncThunk<ITenant, string, CreateAsyncThunkOptions>(
    'getTenant',
    async (tenantId, { rejectWithValue, dispatch }) => {
        try {
            const { data: tenant } = await tenantApi.getById(tenantId);
            dispatch(getActiveFeatureFlagsForTenant({ tenantId }));
            return tenant;
        } catch (error: unknown) {
            return rejectWithValue(JSON.stringify(error));
        }
    },
);

/**
 * Get tenant subscriptions
 * @param tenantId
 * @returns {Promise<ITenantSubscriptions>}
 */
export const getTenantSubscriptions = createAsyncThunk<ITenantSubscriptions, string, CreateAsyncThunkOptions>(
    'getTenantSubscriptions',
    async (tenantId, { rejectWithValue }) => {
        try {
            const response = await tenantApi.getTenantSubscriptions(tenantId);
            return response.data;
        } catch (error: unknown) {
            return rejectWithValue(JSON.stringify(error));
        }
    },
);

/**
 * Get tenant config
 * @param tenantId
 * @returns {Promise<ITenantConfig>}
 */

export const getTenantConfigs = createAsyncThunk<ITenantConfig, string, CreateAsyncThunkOptions>(
    'tenantConfigs/getById',
    async (tenantId, { rejectWithValue }) => {
        try {
            const request = await tenantApi.getTenantConfig(tenantId);
            return request.data;
        } catch (error: unknown) {
            return rejectWithValue(JSON.stringify(error));
        }
    },
);

/**
 * Update tenant config
 * @param payload
 * @returns {Promise<ITenantConfigSetups>}
 */
export const updateTenantConfigSetups = createAsyncThunk<ITenantConfigSetups, ITenantConfigSetups, CreateAsyncThunkOptions>(
    'tenantConfigs/change',
    async (payload, { rejectWithValue }) => {
        try {
            const request = await tenantApi.updateConfig(payload);
            return request.data;
        } catch (error: unknown) {
            return rejectWithValue(JSON.stringify(error));
        }
    },
);

/**
 * Load initial tenant data
 * @param tenantId
 * @returns {Promise<void>}
 */
export const loadTenant =
    (tenantId: string): AppThunk<void> =>
        async (dispatch) => {
            dispatch(getTenant(tenantId));
            dispatch(getTenantSubscriptions(tenantId));
            dispatch(getTenantConfigs(tenantId));
        };

export const getTenantConfigsMessage =
    ({ tenantid }: { tenantid: string; id: string }): AppThunk<void> =>
        async (dispatch) => {
            dispatch(getTenantConfigs(tenantid));
            dispatch(getUserTasksByTarget({ tenantId: tenantid, target: UserTaskTarget.TenantSetup }));
        };

export const updateTenantSetupsAndSave =
    (tenantId: string, setups: ITenantConfigSetups): AppThunk<void> =>
        async (dispatch) => {
            try {
                dispatch(updateTenantConfigSetups({ tenantId, ...setups }));
            } catch (error) {
                console.log(error);
            }
        };
