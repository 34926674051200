import { ActionReducerMapBuilder, PayloadAction } from '@reduxjs/toolkit';
import { IUserIdentity } from 'api/models/account.model';
import { ITenantUser } from 'api/models/tenantAccount.model';
import { LoadingStatus } from 'interfaces/loading-statuses';
import { UsersState } from './users.slice';
import { getUsers, getUserById, updateUser, saveUserAndIdentity } from './users.actions';

const usersReducers = {
    setSelectedUser: (state: UsersState, action: PayloadAction<ITenantUser>) => {
        state.selectedUser = action.payload;
    },
    setShowActiveOnly: (state: UsersState, action: PayloadAction<boolean>) => {
        state.showActiveOnly = action.payload;
    },
    updateIdentityProperty: (state: UsersState, action: PayloadAction<{ path: keyof IUserIdentity; value: any }>) => {
        const { path, value } = action.payload;
        if (state.selectedUser && state.selectedUser.identity) state.selectedUser.identity[path] = value;
    },
    updateUserProperty: (state: UsersState, action: PayloadAction<{ path: keyof ITenantUser; value: any }>) => {
        const { path, value } = action.payload;
        if (state.selectedUser) (state.selectedUser as any)[path] = value;
    },
    cleanupUsersState: (state: UsersState) => {
        state.accounts = [];
        state.data = {};
        state.loading = LoadingStatus.Idle;
        state.saving = LoadingStatus.Idle;
        state.selectedUser = undefined;
        state.showActiveOnly = true;
    },
};

export default usersReducers;

export const userExtraReducers = (builder: ActionReducerMapBuilder<UsersState>) => {
    return (
        builder
            // [GET] Settings Users ==================================
            .addCase(getUsers.pending, (state) => {
                state.loading = LoadingStatus.Pending;
                state.accounts = [];
                state.data = {};
            })
            .addCase(getUsers.fulfilled, (state, action) => {
                state.loading = LoadingStatus.Completed;
                state.data = action.payload;
            })
            .addCase(getUsers.rejected, (state) => {
                state.loading = LoadingStatus.Failed;
            })

            // [GET] Settings User by Id ==================================
            .addCase(getUserById.pending, (state) => {
                state.loading = LoadingStatus.Pending;
            })
            .addCase(getUserById.fulfilled, (state) => {
                state.loading = LoadingStatus.Completed;
            })
            .addCase(getUserById.rejected, (state) => {
                state.loading = LoadingStatus.Failed;
            })

            // [PUT] Settings User ==================================
            .addCase(updateUser.pending, (state) => {
                state.saving = LoadingStatus.Pending;
            })
            .addCase(updateUser.fulfilled, (state, { payload: user }) => {
                state.saving = LoadingStatus.Completed;
                // state.data = { ...state.data, [user.id]: user };
            })
            .addCase(updateUser.rejected, (state) => {
                state.loading = LoadingStatus.Failed;
            })

            // Save/Update User and Identity
            .addCase(saveUserAndIdentity.pending, (state) => {
                state.saving = LoadingStatus.Pending;
            })
            .addCase(saveUserAndIdentity.fulfilled, (state, action) => {
                state.saving = LoadingStatus.Completed;
                // Push or create identity data
                const tenantUserData = action.payload;

                // Push or create user data
                state.data[tenantUserData.id] = tenantUserData;
            })
            .addCase(saveUserAndIdentity.rejected, (state) => {
                state.saving = LoadingStatus.Failed;
            })
    );
};
