import store from 'store';

export const STORE_NAME = 'athenaOne-membership';

type MembershipLocalStorage = {
    ui: UiLocalStorage;
    account: any;
};

type UiLocalStorage = {
    theme: 'dark' | 'light';
    patientBannerExpanded: boolean;
    activityBarExpanded: boolean;
    appBarExpanded: boolean;
    subscriptionBarExpanded: boolean;
};

const newLocalStorage: MembershipLocalStorage = {
    ui: {
        theme: 'light',
        patientBannerExpanded: false,
        activityBarExpanded: true,
        appBarExpanded: true,
        subscriptionBarExpanded: true,
    },
    account: {},
};

class AppStorage {
    store: MembershipLocalStorage;
    constructor() {
        this.store = store.get(STORE_NAME, newLocalStorage);
    }

    private save() {
        store.set(STORE_NAME, this.store);
    }

    get theme() {
        return this.store.ui.theme;
    }
    set theme(theme: 'dark' | 'light') {
        this.store.ui.theme = theme;
        this.save();
    }

    get activityBarExpanded() {
        return this.store.ui.activityBarExpanded;
    }
    set activityBarExpanded(expanded: boolean) {
        this.store.ui.activityBarExpanded = expanded;
        this.save();
    }

    get appBarExpanded() {
        return this.store.ui.appBarExpanded;
    }
    set appBarExpanded(expanded: boolean) {
        this.store.ui.appBarExpanded = expanded;
        this.save();
    }

    get subscriptionBarExpanded() {
        return this.store.ui.appBarExpanded;
    }
    set subscriptionBarExpanded(expanded: boolean) {
        this.store.ui.subscriptionBarExpanded = expanded;
        this.save();
    }
}

const appLocalStorage = new AppStorage();
export default appLocalStorage;
