import ILocationOfCare from 'api/models/locationsOfCare.model';
import { useSelector } from 'hooks';
import useTenantId from 'hooks/useTenantId';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import {
    createLocationOfCare,
    getAllLocationsOfCare,
    getLocationOfCareById,
    refreshLocationsOfCare,
    setLocationOfCarePropAndSave,
    updateLocationOfCare,
} from 'state/slices/locationsOfCare/locationsOfCare.slice';
import selectLocationsOfCare, {
    locationsOfCareAsList,
    locationsOfCareData,
    locationsOfCareSearchResults,
    locationsOfCareOptions,
} from 'state/slices/locationsOfCare/locationsOfCare.selectors';
import { setSearch, setSearchResults } from 'state/slices/locationsOfCare/locationsOfCare.slice';

export default function useLocationsOfCare() {
    const dispatch = useDispatch();
    const tenantId = useTenantId();

    const { loading, search } = useSelector(selectLocationsOfCare);
    const _locationsOfCareAsList = useSelector(locationsOfCareAsList);
    const _locationsOfCareData = useSelector(locationsOfCareData);
    const _locationsOfCareSearchResults = useSelector(locationsOfCareSearchResults);
    const _locationsOfCareOptions = useSelector(locationsOfCareOptions);

    const _getAllLocationsOfCare = useCallback(() => {
        dispatch(getAllLocationsOfCare({ tenantId }));
    }, [dispatch, tenantId]);

    const _refreshLocationsOfCare = useCallback(() => {
        dispatch(refreshLocationsOfCare({ tenantId }));
    }, [dispatch, tenantId]);

    const _setSearch = useCallback(
        (search: string | undefined) => {
            dispatch(setSearch(search));
        },
        [dispatch],
    );

    const _setSearchResults = useCallback(
        (results: ILocationOfCare[]) => {
            dispatch(setSearchResults(results));
        },
        [dispatch],
    );

    const _getLocationOfCareById = useCallback(
        (locationOfCareId: string) => {
            dispatch(getLocationOfCareById({ tenantId, locationOfCareId }));
        },
        [dispatch, tenantId],
    );

    const _createLocationOfCare = useCallback(
        (locationOfCare: ILocationOfCare) => {
            dispatch(createLocationOfCare({ tenantId, locationOfCare }));
        },
        [dispatch, tenantId],
    );

    const _updateLocationOfCare = useCallback(
        (locationOfCare: ILocationOfCare) => {
            dispatch(updateLocationOfCare({ tenantId, locationOfCare }));
        },
        [dispatch, tenantId],
    );

    const _setLocationOfCarePropAndSave = useCallback(
        (id: string, path: keyof ILocationOfCare, value: any) => {
            dispatch(setLocationOfCarePropAndSave(tenantId, id, path, value));
        },
        [dispatch, tenantId],
    );

    return {
        // Selectors
        loading,
        search,
        locationsOfCareData: _locationsOfCareData,
        locationsOfCareAsList: _locationsOfCareAsList,
        locationsOfCareSearchResults: _locationsOfCareSearchResults,
        locationsOfCareOptions: _locationsOfCareOptions,
        // Async Actions
        getAllLocationsOfCare: _getAllLocationsOfCare,
        refreshLocationsOfCare: _refreshLocationsOfCare,
        getLocationOfCareById: _getLocationOfCareById,
        createLocationOfCare: _createLocationOfCare,
        updateLocationOfCare: _updateLocationOfCare,
        setLocationOfCarePropAndSave: _setLocationOfCarePropAndSave,

        //Actions
        setSearch: _setSearch,
        setSearchResults: _setSearchResults,
    };
}
