import { ActionReducerMapBuilder, PayloadAction } from '@reduxjs/toolkit';
import { IUserIdentity } from 'api/models/account.model';
import { ITenantUser } from 'api/models/tenantAccount.model';
import { LoadingStatus } from 'interfaces/loading-statuses';
import { SubscriptionState } from '../../subscriptions/subscriptions.slice';
import { getSubscriptionUsers } from './users.actions';
import { updateProviderProfile } from 'state/slices/providerProfiles/providerProfiles.actions';

const usersReducers = {
    setSelectedUser: (state: SubscriptionState, action: PayloadAction<ITenantUser>) => {
        state.users.selectedUser = action.payload;
    },
    updateSubscriptionUserProperty: (
        state: SubscriptionState,
        action: PayloadAction<{ path: keyof ITenantUser; value: any }>,
    ) => {
        const { path, value } = action.payload;
        if (state.users.selectedUser) (state.users.selectedUser as any)[path] = value;
    },
    toggleProviderOnly: (state: SubscriptionState, { payload: isProvider }: PayloadAction<boolean | undefined>) => {
        isProvider !== undefined ? (state.users.isProvider = isProvider) : (state.users.isProvider = !state.users.isProvider);
    },
    toggleActiveOnly: (state: SubscriptionState) => {
        state.users.isActive = !state.users.isActive;
    },
    updateSubscriptionUserIdentityProperty: (
        state: SubscriptionState,
        action: PayloadAction<{ path: keyof IUserIdentity; value: any }>,
    ) => {
        const { path, value } = action.payload;
        if (state.users.selectedUser) (state.users.selectedUser.identity as any)[path] = value;
    },
    cleanupUsersState: (state: SubscriptionState) => {
        state.users.data = {};
        state.users.loading = LoadingStatus.Idle;
        state.users.saving = LoadingStatus.Idle;
        state.users.selectedUser = undefined;
    },
};

export default usersReducers;

export const userExtraReducers = (builder: ActionReducerMapBuilder<SubscriptionState>) => {
    return (
        builder

            // [GET] Subscription Users ==================================
            .addCase(getSubscriptionUsers.pending, (state) => {
                state.users.loading = LoadingStatus.Pending;
                state.users.data = {};
            })
            .addCase(getSubscriptionUsers.fulfilled, (state, action) => {
                state.users.loading = LoadingStatus.Completed;
                state.users.data = action.payload;
            })
            .addCase(getSubscriptionUsers.rejected, (state) => {
                state.users.loading = LoadingStatus.Failed;
            })
            // Update subscription user comes from update provider profile payload.
            .addCase(updateProviderProfile.pending, (state) => {
                state.users.saving = LoadingStatus.Pending;
            })
            .addCase(updateProviderProfile.fulfilled, (state, action) => {
                state.users.saving = LoadingStatus.Completed;
                if (action.payload.user) {
                    state.users.data[action.payload.user.id] = action.payload.user;
                    state.users.selectedUser = action.payload.user;
                }
            })
            .addCase(updateProviderProfile.rejected, (state) => {
                state.users.saving = LoadingStatus.Failed;
            })
    );
};
