import { DefaultButton, IDropdownOption, Stack, TextField, Text } from '@fluentui/react';
import { Field, Section } from 'components';
import { useSelector } from 'hooks';
import useValidation, { IValidationConfig, IValidationError, getValidationError } from 'hooks/useValidation';
import { useDispatch } from 'react-redux';
import { disableStatementConfig, providerTypesOptions, statementConfigurations } from '../state/selectors';
import { toggleSuppressedEncounterRateCode, updateConfigProps } from '../state/slice';
import { selectProceduresOptions } from 'state/slices/procedures/procedures.slice';
import { updateStatementConfiguration } from '../state/actions';
import { useEffect } from 'react';
import useTenantId from 'hooks/useTenantId';

const StatementDetail = () => {
    const dispatch = useDispatch();
    const tenantId = useTenantId();

    const _disableStatementConfig = useSelector(disableStatementConfig);
    const config = useSelector(statementConfigurations);
    const list = useSelector(providerTypesOptions);
    const _selectProceduresOptions = useSelector(selectProceduresOptions);

    const validNumberInput = (value?: string): boolean => {
        const res = value ? /^[0-9]+$/.test(value) : true;
        return res;
    };

    const onSave = () => {
        if (config) dispatch(updateStatementConfiguration({ tenantId, statementConfiguration: config }));
    };

    const validationConfig: IValidationConfig = [];

    if (config?.statementDueDate === 'custom') {
        validationConfig.push({
            fieldName: 'daysUntilDue',
            validation: ['required', 'numberComparison'],
            value: config?.daysUntilDue,
            itemOptions: {
                numberFrom: 1,
                numberTo: 18,
            },
        });
    }

    const [errors, onSubmit, handleCleanupErrors] = useValidation(validationConfig, onSave);

    useEffect(() => {
        const statementDueDateValue = config?.daysUntilDue === 0 ? 'uponReceipt' : 'custom';
        dispatch(updateConfigProps({ path: 'statementDueDate', value: statementDueDateValue }));
    }, [config?.daysUntilDue]);

    useEffect(() => {
        if (config?.statementDueDate === 'uponReceipt') {
            dispatch(updateConfigProps({ path: 'daysUntilDue', value: 0 }));
        }
    }, [config?.statementDueDate]);

    useEffect(() => {
        return () => {
            handleCleanupErrors();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const statementDueDateList: IDropdownOption[] = [
        {
            key: 'custom',
            text: 'Custom',
        },
        {
            key: 'uponReceipt',
            text: 'Upon Receipt',
        },
    ];

    return (
        <Section
            headingRightContent={
                <>
                    <DefaultButton disabled={_disableStatementConfig} onClick={onSubmit}>
                        Save
                    </DefaultButton>
                </>
            }
            heading={`Statement Details`}
            grow
        >
            <Stack wrap horizontal verticalAlign="end" tokens={{ childrenGap: 15 }}>
                <Field.Dropdown
                    selectedKey={config?.statementDueDate}
                    label="Statement Due Date"
                    style={{ width: 300 }}
                    options={statementDueDateList}
                    disabled={_disableStatementConfig}
                    onChange={(en, option) => {
                        dispatch(updateConfigProps({ path: 'statementDueDate', value: option?.key as string }));
                    }}
                />

                <Text> due in</Text>

                <TextField
                    value={config?.daysUntilDue?.toString() ?? '0'}
                    onChange={(en, value) => {
                        if (validNumberInput(value)) dispatch(updateConfigProps({ path: 'daysUntilDue', value: Number(value) }));
                    }}
                    style={{ width: 40 }}
                    maxLength={2}
                    disabled={_disableStatementConfig}
                    errorMessage={getValidationError(errors, 'daysUntilDue') ? 'Days must be between 1-18' : ''}
                />

                <Text> days</Text>

                <Field.Dropdown
                    selectedKey={config?.providerType}
                    label="Statement Display Provider Type"
                    style={{ width: 300 }}
                    options={list}
                    disabled={_disableStatementConfig}
                    onChange={(en, option) => {
                        dispatch(updateConfigProps({ path: 'providerType', value: option?.key as string }));
                    }}
                />

                <Field.SearchCombo
                    label="Suppress Encounter Rate Codes"
                    style={{ width: 300 }}
                    multiSelect
                    disabled={_disableStatementConfig}
                    options={_selectProceduresOptions}
                    selectedKey={config?.suppressEncounterRateCodes}
                    onChange={(en, option) => {
                        dispatch(
                            toggleSuppressedEncounterRateCode({
                                path: 'suppressEncounterRateCodes',
                                id: option?.key as string,
                            }),
                        );
                    }}
                />
            </Stack>
        </Section>
    );
};

export default StatementDetail;
