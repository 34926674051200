import { AnyAction, createAsyncThunk, Dictionary, ThunkDispatch } from '@reduxjs/toolkit';
import membershipApi from 'api/membership.api';
import { IPayer, IPayerDetail, CombinedPayerAndPayerDetail, IFeeScheduleProcedure, IFeeSchedule } from 'api/models/payer.model';
import { AppThunk, RootState } from 'state/store';
import { setSelectedPayer } from './payers.slice';
import { push } from 'connected-react-router';
import { batch } from 'react-redux';
import { AxiosError } from 'axios';
import { map } from 'lodash';

export type TenantBlobFilePayload = {
    blob: Blob;
    tenantName?: string;
};

export const addExcelPayerDetailFeeSchedules = createAsyncThunk<
    IPayerDetail,
    { tenantId: string; payerDetailId: string; feeScheduleId: string; formFile: File },
    { rejectValue: AxiosError<any> }
>('payers/addExcelPayerDetailFeeSchedules', async ({ tenantId, payerDetailId, feeScheduleId, formFile }, { rejectWithValue }) => {
    try {
        const res = await membershipApi.addExcelPayerDetailFeeSchedules(tenantId, payerDetailId, feeScheduleId, formFile);
        return res.data;
    } catch (e: any) {
        return rejectWithValue(e);
    }
});

export const getExcelPayerDetailFeeSchedules = createAsyncThunk<
    TenantBlobFilePayload,
    { tenantId: string; payerDetailId: string; feeScheduleId: string },
    { state: RootState }
>('excelPayerDetailFeeSchedule/excelPayerDetailFeeSchedule', async ({ tenantId, payerDetailId, feeScheduleId }, { getState }) => {
    const account = getState().account.data;
    const tenant = map(account?.tenants);
    const currentTenant = tenant.find((tenant) => tenant.id === tenantId);

    const response = await membershipApi.getExcelPayerDetailFeeSchedules(tenantId, payerDetailId, feeScheduleId);
    const newObject = {
        blob: response.data,
        tenantName: currentTenant?.displayName,
    };
    return newObject;
});

export const getPayers = createAsyncThunk<Dictionary<IPayer>>('payers/getPayers', async () => {
    const payers = await membershipApi.getPayers();
    return payers.data;
});

export const getPayersDetails = createAsyncThunk<Dictionary<IPayerDetail>, { tenantId: string }>(
    'payers/getPayersDetails',
    async ({ tenantId }) => {
        const payerDetails = await membershipApi.getPayerDetails(tenantId);
        return payerDetails.data;
    },
);

export const createPayersDetail = createAsyncThunk<Dictionary<IPayerDetail>, { tenantId: string; model: IPayerDetail }>(
    'payers/createPayersDetail',
    async ({ tenantId, model }) => {
        await membershipApi.createPayerDetail(tenantId, model);
        const payerDetails = await membershipApi.getPayerDetails(tenantId);
        return payerDetails.data;
    },
);

export const getPayerAndDetail = createAsyncThunk<CombinedPayerAndPayerDetail, { tenantId: string; payerId: string }>(
    'payers/getPayerAndDetail',
    async ({ tenantId, payerId }) => {
        const { data: payer } = await membershipApi.getPayersById(payerId);
        const { data: payerDetail } = await membershipApi.getPayerDetailById(tenantId, payerId);
        const combinedPayer: CombinedPayerAndPayerDetail = {
            id: payer.id,
            name: payer.name,
            payerId: payer.payerId,
            displayName: payerDetail?.displayName,
            feeSchedules: payerDetail?.feeSchedules,
            isQuickPick: payerDetail ? payerDetail.isQuickPick : false,
            isDeleted: payerDetail?.isDeleted,
            street1: payer.street1,
            street2: payer.street2,
            city: payer.city,
            state: payer.state,
            zip: payer.zip,
        };
        return combinedPayer;
    },
);

export const updatePayersDetail = createAsyncThunk<Dictionary<IPayerDetail>, { tenantId: string; model: IPayerDetail }>(
    'payers/updatePayersDetail',
    async ({ tenantId, model }) => {
        await membershipApi.updatePayerDetail(tenantId, model);
        const payerDetails = await membershipApi.getPayerDetails(tenantId);
        return payerDetails.data;
    },
);

export const createAndUploadFeeSchedule = createAsyncThunk<Dictionary<IPayerDetail>, { tenantId: string; model: IPayerDetail }>(
    'payers/updatePayersDetail',
    async ({ tenantId, model }) => {
        await membershipApi.updatePayerDetail(tenantId, model);
        const payerDetails = await membershipApi.getPayerDetails(tenantId);
        return payerDetails.data;
    },
);

export const upsertPayerDetailFeeSchedule = createAsyncThunk<
    Dictionary<IPayerDetail>,
    {
        tenantId: string;
        payerId: string;
        feeScheduleId: string;
        model: IPayerDetail;
        isNew: boolean;
    }
>('payers/upsertPayerDetailFeeSchedule', async ({ tenantId, payerId, feeScheduleId, model, isNew }, { dispatch }) => {
    let payerDetails: Dictionary<IPayerDetail> = {};
    if (isNew) {
        await dispatch(updatePayersDetail({ tenantId, model }));
        const result = await membershipApi.getPayerDetails(tenantId);
        payerDetails = result.data;
    } else {
        await dispatch(createPayersDetail({ tenantId, model }));
        const result = await membershipApi.getPayerDetails(tenantId);
        payerDetails = result.data;
    }
    
    dispatch(push(`/${tenantId}/payers/${payerId}/fee-schedules/${feeScheduleId}`));
    return payerDetails;
});

export const updatePayerDetailQuickPick =
    (tenantId: string, payerGuid: string): AppThunk<void> =>
    (dispatch, getState) => {
        const state = getState();
        const payerDetails = state.payers.payerDetails.data;
        const payerDetail = payerDetails ? payerDetails[payerGuid] : undefined;

        if (payerDetail) {
            const model: IPayerDetail = {
                ...payerDetail,
                isQuickPick: !payerDetail.isQuickPick,
            };
            dispatch(updatePayersDetail({ tenantId, model }));
        } else {
            const payer = state.payers.data ? state.payers.data[payerGuid] : undefined;
            if (payer) {
                const model: IPayerDetail = {
                    id: payer.id,
                    isDeleted: payer.isDeleted ? payer.isDeleted : false,
                    displayName: payer.name ? payer.name : '',
                    payerId: payer.payerId,
                    isQuickPick: true,
                    feeSchedules: [],
                };
                dispatch(createPayersDetail({ tenantId, model }));
            }
        }
    };

export const upsertPayerDetail =
    (tenantId: string) =>
    async (dispatch: ThunkDispatch<RootState, null, AnyAction>, getState: () => RootState): Promise<void> => {
        const state = getState();
        const payerDetails = state.payers.payerDetails.data;
        const selectedPayer = state.payers.selectedPayer;
        if (selectedPayer) {
            const payer = state.payers.data ? state.payers.data[selectedPayer?.id] : undefined;

            const model: IPayerDetail = {
                ...selectedPayer,
                displayName: selectedPayer.displayName ? selectedPayer.displayName : payer?.name ? payer.name : '',
            };
            const payerDetail = payerDetails ? payerDetails[selectedPayer.id] : undefined;
            if (payerDetail) {
                dispatch(updatePayersDetail({ tenantId, model }));
            } else {
                dispatch(createPayersDetail({ tenantId, model }));
            }
        }
    };

export const updateFeeScheduleProcedureFee =
    ({ value, feeScheduleId, procedureId }: { value: any; feeScheduleId: string; procedureId: string }): AppThunk<void> =>
    async (dispatch, getState) => {
        const state = getState();
        const tenantProcedures = state.procedures.data;
        const selectedPayer = state.payers.selectedPayer;
        const selectedFeeSchedule = selectedPayer?.feeSchedules?.find((x) => x.id === feeScheduleId) as IFeeSchedule;
        const feeScheduleIndex = selectedPayer?.feeSchedules?.indexOf(selectedFeeSchedule) as number;
        const procedureChanged = tenantProcedures && tenantProcedures[procedureId];

        if (procedureChanged) {
            const updateFeeScheduleProcedure: IFeeScheduleProcedure = {
                id: procedureChanged.id,
                code: procedureChanged.code,
                fee: value ?? 0,
            };
            if (selectedFeeSchedule) {
                const procedures =
                    selectedFeeSchedule.procedures && selectedFeeSchedule.procedures.filter((x) => x.id !== procedureId);

                const updatedFeeSchedule: IFeeSchedule = {
                    ...selectedFeeSchedule,
                    procedures:
                        procedures && updateFeeScheduleProcedure.fee === 0
                            ? [...procedures]
                            : procedures
                            ? [...procedures, updateFeeScheduleProcedure]
                            : [updateFeeScheduleProcedure],
                };

                if (selectedPayer) {
                    const feeSchedules =
                        selectedPayer.feeSchedules &&
                        selectedPayer.feeSchedules.filter((value: any, i: number) => i !== feeScheduleIndex);
                    if (feeSchedules) {
                        feeSchedules.splice(feeScheduleIndex, 0, updatedFeeSchedule);
                    }
                    dispatch(
                        setSelectedPayer({
                            ...selectedPayer,
                            feeSchedules,
                        }),
                    );
                }
            }
        }
    };

export const updateFeeScheduleIsDeleted =
    ({ value, index }: { value: any; index: number }): AppThunk<void> =>
    async (dispatch, getState) => {
        const state = getState();
        const selectedPayer = state.payers.selectedPayer;
        const selectedFeeSchedule = selectedPayer?.feeSchedules && selectedPayer?.feeSchedules[index];

        if (selectedPayer) {
            if (selectedFeeSchedule) {
                const updateFeeSchedule: IFeeSchedule = {
                    ...selectedFeeSchedule,
                    isDeleted: value,
                };

                const feeSchedules =
                    selectedPayer.feeSchedules && selectedPayer.feeSchedules.filter((value: any, i: number) => i !== index);

                if (feeSchedules) {
                    feeSchedules.splice(index, 0, updateFeeSchedule);
                }

                dispatch(
                    setSelectedPayer({
                        ...selectedPayer,
                        feeSchedules,
                    }),
                );
            }
        }
    };
