import { IUserIdentity } from 'api/models/account.model';
import { ITenantUser } from 'api/models/tenantAccount.model';
import { useSelector } from 'hooks';
import useTenantId from 'hooks/useTenantId';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { getUserById, getUsers, saveUserAndIdentity, updateUser } from 'state/slices/users/users.actions';
import { selectFilteredUserList, selectUsers, selectUsersAsList } from 'state/slices/users/users.selectors';
import { cleanupUsersState, setSelectedUser, updateIdentityProperty, updateUserProperty } from 'state/slices/users/users.slice';

function useUsers() {
    const dispatch = useDispatch();
    const tenantId = useTenantId();

    const _getUsers = useCallback(() => {
        dispatch(getUsers({ tenantId }));
    }, [dispatch, tenantId]);

    const _getUserById = useCallback(
        (userId: string) => {
            dispatch(getUserById({ tenantId, userId }));
        },
        [dispatch, tenantId],
    );

    const _updateUser = useCallback(
        (user: ITenantUser) => {
            dispatch(updateUser({ tenantId, user }));
        },
        [dispatch, tenantId],
    );

    const _saveUserAndIdentity = useCallback(
        (item: ITenantUser) => {
            dispatch(saveUserAndIdentity({ tenantId, item }));
        },
        [dispatch, tenantId],
    );

    const _updateUserProperty = useCallback(
        (path: keyof ITenantUser, value: any) => {
            dispatch(updateUserProperty({ path, value }));
        },
        [dispatch],
    );
    const _updateIdentityProperty = useCallback(
        (path: keyof IUserIdentity, value: any) => {
            dispatch(updateIdentityProperty({ path, value }));
        },
        [dispatch],
    );

    const { loading, saving, data: _users, accounts, selectedUser } = useSelector(selectUsers);
    const _selectUsersAsList = useSelector(selectUsersAsList);
    const _selectFilteredUserList = useSelector(selectFilteredUserList);

    const _setSelectedUser = useCallback(
        (item: ITenantUser) => {
            dispatch(setSelectedUser(item));
        },
        [dispatch],
    );

    const _cleanupUsersState = useCallback(() => {
        dispatch(cleanupUsersState());
    }, [dispatch]);

    return {
        getUsers: _getUsers,
        getUserById: _getUserById,
        updateUser: _updateUser,
        setSelectedUser: _setSelectedUser,
        cleanupUsersState: _cleanupUsersState,

        updateUserProperty: _updateUserProperty,
        updateIdentityProperty: _updateIdentityProperty,

        saveUserAndIdentity: _saveUserAndIdentity,

        loading,
        saving,
        users: _users,
        accounts,
        selectedUser,

        selectUsersAsList: _selectUsersAsList,
        selectFilteredUserList: _selectFilteredUserList,
    };
}

export default useUsers;
