import { DefaultButton, Panel, PanelType, PrimaryButton, Stack, Text, MessageBar, MessageBarType } from '@fluentui/react';
import { useEffect, useState } from 'react';
import { LoadingStatus, LoadingStatuses } from 'interfaces/loading-statuses';
import UploadFile from 'pages/components/UploadFile';

type Props = {
    isOpen: boolean;
    closePanel: () => void;
    downloadTable?: () => void;
    uploadTable?: any;
    loading?: LoadingStatus;
    errorMessage: string | undefined;
};

function UploadFilePanel({ isOpen, closePanel, downloadTable, uploadTable, loading, errorMessage }: Props) {
    const _isSaving = false;
    const _panelHeadingText = 'Bulk Import Offices Fees';

    const _onRenderFooterContent = () => {
        return (
            <Stack horizontal tokens={{ childrenGap: 12 }}>
                <DefaultButton text="Cancel" disabled={_isSaving} onClick={closePanel} />
            </Stack>
        );
    };

    const [pendingFile, setPendingFile] = useState<File | undefined>(undefined);
    const [pendingFileLoading, setPendingFileLoading] = useState<LoadingStatuses>(LoadingStatus.Idle);

    useEffect(() => {
        if (loading === LoadingStatus.Completed) {
            setPendingFileLoading(LoadingStatus.Completed);
            setPendingFile(undefined);
            closePanel();
        }
        if (loading === LoadingStatus.Failed) {
            setPendingFileLoading(LoadingStatus.Idle);
            setPendingFile(undefined);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loading]);

    function _onFileSelected(file: File) {
        setPendingFile(file);
        setPendingFileLoading(LoadingStatus.Pending);
        uploadTable(file);
    }

    return (
        <Panel
            isOpen={isOpen}
            onDismiss={closePanel}
            headerText={_panelHeadingText}
            type={PanelType.medium}
            onRenderFooterContent={_onRenderFooterContent}
            isFooterAtBottom
        >
            <Stack tokens={{ childrenGap: 10 }}>
                <Stack horizontal tokens={{ childrenGap: 30 }}>
                    <Stack grow tokens={{ childrenGap: 10 }}>
                        <Stack.Item>
                            <Text>1. Download excel template</Text>
                        </Stack.Item>
                        <Stack.Item>
                            <PrimaryButton
                                text={'Download'}
                                disabled={_isSaving}
                                iconProps={{ iconName: 'SaveAndClose' }}
                                onClick={downloadTable}
                            />
                        </Stack.Item>
                        <Stack.Item>
                            <Text>2. Edit your excel file</Text>
                        </Stack.Item>
                        <Stack.Item>
                            <Text>3. Upload your excel file</Text>
                        </Stack.Item>
                        <Stack.Item>
                            <div>
                                <UploadFile
                                    onFileSelected={_onFileSelected}
                                    fileData={pendingFile}
                                    pendingFileLoading={pendingFileLoading}
                                />
                            </div>
                        </Stack.Item>
                    </Stack>
                    <Stack verticalAlign="center" horizontalAlign="end">
                        <Stack.Item>
                            <Text variant="medium" styles={{ root: { fontWeight: 'bold' } }}>
                                Please verify that all fees were uploaded correctly after importing. Address any discrepancies
                                manually if there is a conflict
                            </Text>
                        </Stack.Item>
                    </Stack>
                </Stack>

                {errorMessage?.length ? (
                    <MessageBar isMultiline messageBarType={MessageBarType.error}>
                        {errorMessage}
                    </MessageBar>
                ) : null}
            </Stack>
        </Panel>
    );
}

export default UploadFilePanel;
