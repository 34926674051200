import { MessageBar, PrimaryButton, Stack } from '@fluentui/react';

import { Section } from 'components';
import useTenantId from 'hooks/useTenantId';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DunningMessageTextField from './components/DunningMessageTextField';
import { getDunningRules, updateDunningRules, updateStatementConfiguration } from './state/actions';
import { disableStatementConfig, dunningRulesList, dunningRulesSaveDisabled, statementConfigurations } from './state/selectors';
import IStatementConfig from './state/models';

const dunningLabelMessages: string[] = [
    'Dunning Level 1: This is printed on the first statement that goes out for a charge.',
    'Dunning Level 2: Typically, printed on the second statement. At least 1 statement must have gone out and at least 35 days must have passed since the charge was first billed to reach this level.',
    'Dunning Level 3: Typically, printed on the third statement. At least 2 statements must have gone out and at least 70 days must have passed since the charge was first billed to reach this level.',
    'Dunning Level 4: COLLECTIONS NOTICE: Typically, the fourth communication the patient receives. At least 3 statements must have gone out and at least 105 days must have passed since the charge was first billed for the collections notice to be generated.',
];

const DunningMessage = () => {
    const dispatch = useDispatch();
    const tenantId = useTenantId();
    const _disableStatementConfig = useSelector(disableStatementConfig);
    const _dunningRulesMessageList = useSelector(dunningRulesList);
    const config = useSelector(statementConfigurations);
    const disableSave = useSelector(dunningRulesSaveDisabled);

    useEffect(() => {
        dispatch(getDunningRules({ tenantId }));
    }, [dispatch, tenantId]);

    const onSave = () => {
        if (config && _dunningRulesMessageList) {
            const item: IStatementConfig = {
                ...config,
                dunningRules: _dunningRulesMessageList,
            };

            dispatch(updateStatementConfiguration({ tenantId, statementConfiguration: item }));
            dispatch(updateDunningRules({ tenantId }));
        }
    };

    return (
        <Section
            style={{ marginBottom: 0 }}
            styleContent={{ display: 'flex', flex: 1 }}
            headingRightContent={
                <PrimaryButton disabled={_disableStatementConfig || disableSave} onClick={onSave}>
                    Save
                </PrimaryButton>
            }
            preBodyContent={disableSave && <MessageBar messageBarType={2}>Please fill out all required fields</MessageBar>}
            heading={`Edit Dunning Message`}
        >
            <Stack tokens={{ childrenGap: 15 }}>
                {_dunningRulesMessageList.map((rule) => (
                    <DunningMessageTextField
                        key={rule.id}
                        rule={rule}
                        label={dunningLabelMessages[rule.sequence]}
                        disabled={_disableStatementConfig}
                    />
                ))}
            </Stack>
        </Section>
    );
};

export default DunningMessage;
