import { useDispatch } from 'react-redux';
import { msalProvider } from 'api/auth/authProvider';
import { useSelector } from 'hooks';
import { setTheme } from '../../state/slices/ui/ui.slice';
import Avatar from './Avatar';
import {
    ContextualMenuItemType,
    Toggle,
    IContextualMenuItem,
} from '@fluentui/react';
import { useAccount } from '@azure/msal-react';

type Props = {
    menuItems?: IContextualMenuItem[];
};

export default function AvatarContainer({ menuItems }: Props) {
    const dispatch = useDispatch();
    const account = useAccount();

    const { profile } = useSelector((state) => state.user);
    const selectedTheme = useSelector((state) => state.ui.selectedTheme);

    const _logout = () => msalProvider.logoutRedirect({ account: account ?? undefined});
    const _onThemeChange = () => {
        if (selectedTheme === 'light') {
            dispatch(setTheme('dark'));
        } else {
            dispatch(setTheme('light'));
        }
    };

    const menuItemList: IContextualMenuItem[] = menuItems ? menuItems : [];

    const contextMenu: any = {
        items: [
            {
                key: 'userName',
                name: 'userName',
                text: account ? account.name : 'User Name',
                itemType: ContextualMenuItemType.Header,
            },
            {
                key: 'divider1',
                itemType: ContextualMenuItemType.Divider,
            },
            ...menuItemList,
            {
                key: 'theme',
                onRender: () => (
                    <Toggle
                        styles={{ root: { marginLeft: 34, marginTop: 8 } }}
                        onChange={_onThemeChange}
                        label="Theme"
                        inlineLabel
                        checked={selectedTheme === 'light' ? true : false}
                        onText="Light"
                        offText="Dark"
                    />
                ),
            },
            {
                key: 'divider2',
                itemType: ContextualMenuItemType.Divider,
            },
            {
                key: 'logout',
                text: 'Logout',
                iconProps: { iconName: 'SignOut' },
                onClick: _logout,
            },
        ],
    };

    return <Avatar {...{ profile, selectedTheme, contextMenu }} />;
}
