import { IconButton, SelectionMode, Stack, Text, Toggle, TooltipHost } from '@fluentui/react';
import { ICodeExclusion, ISlidingFeePlan } from 'api/models/slidingFee.model';
import { SortableDetailsList, SubSection } from 'components';
import { EditDetailsColumn } from 'components/EditDetailsColumn';
import useSlidingFee from 'hooks/store/useSlidingFee';
import dateOnly from 'utils/dateOnly';
import CodeExclusionPanel from './CodeExclusionPanel';
import { v4 as uuid } from 'uuid';
import { useSelector, useDispatch } from 'react-redux';
import {
    selectSelectedCodeExclusionList,
    selectSelectedProgramPlanCodeExclusionListDeleted,
    toggleDeletedCodeExclusions,
} from 'state/slices/sliding-fee/sliding-fee.slice';

type Props = {
    programPlan?: ISlidingFeePlan;
    onProgramPlanPropertyChange: (key: keyof ISlidingFeePlan, value: string | number | ICodeExclusion[] | boolean) => void;
};

export default function CodeCategoryExclusion(props: Props): JSX.Element {
    const { programPlan, onProgramPlanPropertyChange } = props;
    const { setSelectedCodeExclusion, toggleIsCodeExclusionPanelOpen } = useSlidingFee();

    const _onItemInvoked = (item: ICodeExclusion, index?: number) => {
        setSelectedCodeExclusion(item);
        toggleIsCodeExclusionPanelOpen();
    };
    const _selectSelectedCodeExclusion = useSelector(selectSelectedCodeExclusionList);
    const isActive = useSelector(selectSelectedProgramPlanCodeExclusionListDeleted);

    const dispatch = useDispatch();

    const onRenderCalculationType = (item?: ICodeExclusion) => {
        if (item?.calculationType === 'flatFeeOnly') {
            return <Text variant="smallPlus">Flat Fee Only</Text>;
        }
        if (item?.calculationType === 'percentagePerCode') {
            return <Text variant="smallPlus">Percentage Per Code</Text>;
        }
        if (item?.calculationType === 'flatFeePerCode') {
            return <Text variant="smallPlus">Flat Fee Per Code </Text>;
        } else {
            return '';
        }
    };

    return (
        <SubSection
            title="Specific Code Exclusions"
            headingCenterContent={
                <Stack horizontal horizontalAlign="space-between">
                    <Stack.Item>
                        <TooltipHost content="Add Specific Code Exclusions">
                            <IconButton
                                iconProps={{ iconName: 'Add' }}
                                onClick={() => {
                                    setSelectedCodeExclusion(newCodeExclusion());
                                    toggleIsCodeExclusionPanelOpen();
                                }}
                            />
                        </TooltipHost>
                    </Stack.Item>
                    <Stack.Item>
                        <Toggle
                            styles={{ root: { marginBottom: 2, minWidth: 150 } }}
                            checked={!!isActive}
                            inlineLabel
                            label="Active Only"
                            onChange={() => {
                                dispatch(toggleDeletedCodeExclusions());
                            }}
                        />
                    </Stack.Item>
                </Stack>
            }
        >
            <CodeExclusionPanel programPlan={programPlan} onProgramPlanPropertyChange={onProgramPlanPropertyChange} />
            {programPlan && programPlan.codeExclusions && programPlan.codeExclusions.length ? (
                <SortableDetailsList
                    selectionMode={SelectionMode.none}
                    items={_selectSelectedCodeExclusion}
                    columns={[
                        {
                            key: 'code',
                            minWidth: 100,
                            maxWidth: 150,
                            fieldName: 'code',
                            name: 'Code',
                            getValueKey: (item?: ICodeExclusion) => {
                                return item?.code ?? '';
                            },
                            onRender: (item?: ICodeExclusion, index?: number) => {
                                if (item) {
                                    return (
                                        <EditDetailsColumn editOnClick={() => _onItemInvoked(item)} title={item?.code ?? ''} />
                                    );
                                } else {
                                    return undefined;
                                }
                            },
                        },
                        {
                            key: 'calculationType',
                            minWidth: 150,
                            maxWidth: 150,
                            fieldName: 'calculationType',
                            name: 'Calculation Type',
                            onRender: onRenderCalculationType,
                        },
                        {
                            key: 'doesNotSlide',
                            minWidth: 150,
                            maxWidth: 150,
                            fieldName: 'doesNotSlide',
                            name: 'Does Not Slide',
                            onRender: (item?: ICodeExclusion) => {
                                if (item?.doesNotSlide) {
                                    return <Text variant="smallPlus">&#10003;</Text>;
                                }
                            },
                        },
                        {
                            key: 'feePercentage',
                            minWidth: 120,
                            maxWidth: 120,
                            fieldName: 'feePercentage',
                            name: 'Fee or Percentage',
                        },
                        {
                            key: 'labFee',
                            minWidth: 100,
                            maxWidth: 100,
                            fieldName: 'labFee',
                            name: 'Lab Fee',
                        },
                        {
                            key: 'absoluteFee',
                            minWidth: 100,
                            maxWidth: 100,
                            fieldName: 'absoluteFee',
                            name: 'Absolute Fee',
                        },
                        {
                            key: 'effectiveDate',
                            fieldName: 'effectiveDate',
                            minWidth: 100,
                            maxWidth: 250,
                            name: 'Effective Date',
                            onRender: (item?: ICodeExclusion) => {
                                if (item) {
                                    const date = item.effectiveDate ? dateOnly(item.effectiveDate as string) : '';
                                    return <Text variant="smallPlus">{date}</Text>;
                                }
                            },
                        },
                        {
                            key: 'expirationDate',
                            name: 'Expiration Date',
                            minWidth: 100,
                            maxWidth: 100,
                            fieldName: 'expirationDate',
                            onRender: (item?: ICodeExclusion) => {
                                if (item) {
                                    const date = item.expirationDate ? dateOnly(item.expirationDate as string) : '';
                                    return <Text variant="smallPlus">{date}</Text>;
                                }
                            },
                        },
                    ]}
                />
            ) : null}
        </SubSection>
    );
}

function newCodeExclusion(): ICodeExclusion {
    return {
        id: uuid(),
        isDeleted: false,
        absoluteFee: 0,
        calculationType: '',
        doesNotSlide: false,
        effectiveDate: '',
        expirationDate: '',
        feePercentage: 0,
        labFee: 0,
        code: '',
    };
}
