import React from 'react';
import { MessageBar, MessageBarType } from '@fluentui/react';
import { IValidationError, ValidationType } from 'hooks/useValidation';

export type Props = {
    errors: IValidationError[];
};

const getPlurality = (errorCount: number, single: string, multiple: string) => {
    return errorCount > 1 ? multiple : single;
};
const getFieldPlurality = (errorCount: number) => {
    return getPlurality(errorCount, 'Field', 'Fields');
};

const addAnd = (value: string) => {
    const commas = value
        .split('')
        .filter((char) => char === ',')
        .map((char, index) => ({ char, index }));

    if (commas.length) {
        const indexOfFinalComma = commas && commas.length ? commas[commas.length - 1].index : -1;
        const firstSection = value.slice(0, indexOfFinalComma).toString();
        const lastSection = value.slice(indexOfFinalComma + 1).toString();
        return [firstSection, 'and', lastSection].join(' ');
    }
    return value;
};

export function ValidationBar({ errors }: Props) {
    if (errors.length) {
        const requiredFields = errors.filter((error) => error.errorTypes.indexOf(ValidationType.Required) > -1);
        const phone = errors.filter((error) => error.errorTypes.indexOf(ValidationType.Phone) > -1);
        const email = errors.filter((error) => error.errorTypes.indexOf(ValidationType.Email) > -1);
        const ssn = errors.filter((error) => error.errorTypes.indexOf(ValidationType.SSN) > -1);
        const date = errors.filter((error) => error.errorTypes.indexOf(ValidationType.Date) > -1);
        const zip = errors.filter((error) => error.errorTypes.indexOf(ValidationType.Zip) > -1);
        const taxonomy = errors.filter((error) => error.errorTypes.indexOf(ValidationType.Taxonomy) > -1);
        const time = errors.filter((error) => error.errorTypes.indexOf(ValidationType.Time) > -1);

        const requiredString = requiredFields.length
            ? addAnd(
                  `${getFieldPlurality(requiredFields.length)}: ${requiredFields
                      .map((error) => error.fieldName.toLowerCase())
                      .join(', ')} ${getPlurality(errors.length, 'is required', 'are required')}.`,
              )
            : undefined;
        const phoneString = phone.length
            ? addAnd(
                  `${getFieldPlurality(phone.length)}: ${phone
                      .map((error) => error.fieldName.toLowerCase())
                      .join(', ')} ${getPlurality(phone.length, 'is an invalid phone number', 'have invalid phone numbers')}.`,
              )
            : undefined;
        const emailString = email.length
            ? addAnd(
                  `${getFieldPlurality(email.length)}: ${email
                      .map((error) => error.fieldName.toLowerCase())
                      .join(', ')} ${getPlurality(email.length, 'is an invalid email', 'have invalid emails')}.`,
              )
            : undefined;
        const ssnString = ssn.length
            ? addAnd(
                  `${getFieldPlurality(ssn.length)}: ${ssn
                      .map((error) => error.fieldName.toLowerCase())
                      .join(', ')} ${getPlurality(ssn.length, 'is an invalid SSN', 'have invalid SSNs')}.`,
              )
            : undefined;
        const dateString = date.length
            ? addAnd(
                  `${getFieldPlurality(date.length)}: ${date
                      .map((error) => error.fieldName.toLowerCase())
                      .join(', ')} ${getPlurality(date.length, 'is an invalid date', 'have invalid dates')}.`,
              )
            : undefined;
        const zipString = zip.length
            ? addAnd(
                  `${getFieldPlurality(zip.length)}: ${zip
                      .map((error) => error.fieldName.toLowerCase())
                      .join(', ')} ${getPlurality(zip.length, 'is an invalid zip code', 'have invalid zip codes')}.`,
              )
            : undefined;
        const taxonomyString = taxonomy.length
            ? addAnd(
                  `${getFieldPlurality(taxonomy.length)}: ${taxonomy
                      .map((error) => error.fieldName.toLowerCase())
                      .join(', ')} ${getPlurality(
                      taxonomy.length,
                      'is an invalid taxonomy code',
                      'have invalid taxonomy codes',
                  )}.`,
              )
            : undefined;
        const timeString = time.length
            ? addAnd(
                  `${getFieldPlurality(time.length)}: ${errors
                      .map((error) => error.fieldName.toLowerCase())
                      .join(', ')} ${getPlurality(time.length, 'is an invalid time', 'have invalid time values')}.`,
              )
            : undefined;

        const getErrorString = () => {
            const errors: string[] = [];
            [requiredString, phoneString, emailString, ssnString, dateString, zipString, taxonomyString, timeString].forEach(
                (item) => {
                    if (item) errors.push(item);
                },
            );

            return errors.join(' ');
        };

        return (
            <MessageBar isMultiline messageBarType={MessageBarType.error}>
                {getErrorString()}
            </MessageBar>
        );
    }
    return null;
}

export default ValidationBar;
