import {
    AnyAction,
    createAsyncThunk,
    createSelector,
    createSlice,
    Dictionary,
    PayloadAction,
    ThunkDispatch,
} from '@reduxjs/toolkit';
import membershipApi from 'api/membership.api';
import { IUserIdentity } from 'api/models/account.model';
import axios from 'axios';
import { LoadingStatus } from 'interfaces/loading-statuses';
import { uniq } from 'lodash';
import { RootState } from 'state/store';

const initialState: UsersState = {
    data: {},
    userIdsLoading: [],
    loading: LoadingStatus.Idle,
};

export type UsersState = {
    data: Dictionary<IUserIdentity>;
    userIdsLoading: string[];
    loading: LoadingStatus;
};

export const getUserIdentities = createAsyncThunk<
    IUserIdentity[],
    {
        tenantId: string;
        userIds: string[];
    }
>('getUserIdentities', async ({ tenantId, userIds }) => {
    const requests = userIds.map((id) => membershipApi.getAccountById(tenantId, id));
    const response = await axios.all(requests);
    return response.map((d) => d.data);
});

export const getMissingUserIdentities =
    (tenantId: string, userIds: string[]) =>
    (dispatch: ThunkDispatch<RootState, null, AnyAction>, getState: () => RootState): void => {
        const { data, userIdsLoading } = getState().userIdentities;
        const usersToFetch = uniq(userIds.filter((id) => id !== '')).filter((id) => !data[id] && !userIdsLoading.includes(id));

        if (usersToFetch.length) {
            dispatch(setUsersLoading(usersToFetch));
            dispatch(getUserIdentities({ tenantId, userIds: usersToFetch }));
        }
    };

export const selectUserIdentitiesState = (state: RootState): UsersState => state.userIdentities;
export const selectUserIdentitiesData = createSelector(selectUserIdentitiesState, (state) => state.data);
export const selectUserIdentitiesDataLoading = createSelector(selectUserIdentitiesState, (state) => state.loading);

const userIdentities = createSlice({
    name: 'user-identities',
    initialState,
    reducers: {
        cleanupUserIdentitiesData: (state: UsersState): void => {
            state.data = {};
            state.userIdsLoading = [];
        },
        setUsersLoading: (state: UsersState, action: PayloadAction<string[]>): void => {
            state.userIdsLoading = [...state.userIdsLoading, ...action.payload];
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getUserIdentities.pending, (state) => {
                state.loading = LoadingStatus.Pending;
            })
            .addCase(getUserIdentities.fulfilled, (state, action) => {
                state.loading = LoadingStatus.Completed;
                const mappedIdenties: Record<string, IUserIdentity> = {};
                action.payload.forEach((identity) => {
                    if (identity.id) {
                        mappedIdenties[identity.id] = identity;
                        const indexOfId = state.userIdsLoading.indexOf(identity.id);
                        if (indexOfId > -1) {
                            state.userIdsLoading = [
                                ...state.userIdsLoading.slice(0, indexOfId),
                                ...state.userIdsLoading.slice(indexOfId + 1),
                            ];
                        }
                    }
                });
                state.data = { ...state.data, ...mappedIdenties };
            })
            .addCase(getUserIdentities.rejected, (state) => {
                state.loading = LoadingStatus.Failed;
            });
    },
});

export const { cleanupUserIdentitiesData, setUsersLoading } = userIdentities.actions;

export default userIdentities.reducer;
