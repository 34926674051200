import { Stack, Text } from '@fluentui/react';
import { TModal } from 'components';
import { useSelector } from 'hooks';
import { useDispatch } from 'react-redux';
import { isExampleModalOpen } from '../state/selectors';
import { toggleExampleModal } from '../state/slice';

const PracticeExampleModal = () => {
    const dispatch = useDispatch();
    const isOpen = useSelector(isExampleModalOpen);

    const onDismissed = () => {
        dispatch(toggleExampleModal());
    };

    return (
        <TModal
            title="Practice Statement Notes"
            modalProps={{
                isOpen: isOpen,
                onDismiss: onDismissed,
            }}
        >
            <Stack
                styles={{
                    root: {
                        padding: 15,
                    },
                }}
                tokens={{ childrenGap: 5 }}
            >
                <Stack.Item>
                    <Text> Practice statement notes can be used for several purposes.</Text>
                </Stack.Item>
                <Stack>
                    <Text variant="mediumPlus">
                        <b>Billing Questions</b>
                    </Text>
                    <Text variant="medium">
                        If You have question about your bill, please call us during regular business hours, or email us at
                        username@medicalpractice.com.
                    </Text>
                </Stack>
                <Stack>
                    <Text variant="mediumPlus">
                        <b>Financial Assistance</b>
                    </Text>
                    <Text variant="medium">
                        If you need assistance paying your bill, please contact our office ato see if you qualify for one of our
                        financial assistance programs.
                    </Text>
                </Stack>
                <Stack>
                    <Text variant="mediumPlus">
                        <b>Credit Card Policy </b>
                    </Text>
                    <Text variant="medium">
                        If you would like to make a credit card payment over the phone, please call (555)-555-555.
                    </Text>
                </Stack>
                <Stack>
                    <Text variant="mediumPlus">
                        <b>Location Change </b>
                    </Text>
                    <Text variant="medium">
                        Our office has recently added a new location in Watertown. If this location would be more convenient for
                        you, please schedule your appointment in our Watertown office.
                    </Text>
                </Stack>
                <Stack>
                    <Text variant="mediumPlus">
                        <b>Flu Vaccine Reminder</b>
                    </Text>
                    <Text variant="medium">
                        Flu vaccines are now available. Please visit the Patient Portal to schedule your appointment
                    </Text>
                </Stack>
            </Stack>
        </TModal>
    );
};

export default PracticeExampleModal;
