import { createAsyncThunk, Dictionary } from '@reduxjs/toolkit';
import membershipApi from 'api/membership.api';
import { ITenantUser } from 'api/models/tenantAccount.model';

export const getSubscriptionUsers = createAsyncThunk<
    Dictionary<ITenantUser>,
    {
        tenantId: string;
        productId: string;
    }
>('getSubscriptionUsers', async ({ tenantId, productId }) => {
    const { data: users } = await membershipApi.getTenantSubscriptionUsers(tenantId, productId);
    return users;
});
