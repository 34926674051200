import { Stack, Text, useTheme } from '@fluentui/react';

export default function PanelSectionHeader({
    text,
    isPrimary,
}: {
    text: string;
    isPrimary?: boolean;
}) {
    const { palette } = useTheme();
    return (
        <Stack
            style={{
                backgroundColor: isPrimary ? palette.themeLighter : palette.neutralQuaternaryAlt,
                padding: '5px 10px',
                borderLeft: isPrimary
                    ? `5px solid ${palette.themePrimary}`
                    : `5px solid ${palette.neutralPrimaryAlt}`,
            }}
        >
            <Text variant="smallPlus">
                <strong>{text}</strong>
            </Text>
        </Stack>
    );
}
