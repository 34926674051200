import {
    PrimaryButton,
    ScrollablePane,
    ScrollbarVisibility,
    SearchBox,
    SelectionMode,
    Stack,
    Toggle,
    Text,
} from '@fluentui/react';
import { IFeeSchedule } from 'api/models/payer.model';
import { IProcedure } from 'api/models/procedure.model';
import { Field, SubSection } from 'components';
import SortableDetailsList from 'components/SortableDetailsList/SortableDetailsList';
import Fuse from 'fuse.js';
import { useSelector } from 'hooks';
import useProcedure from 'hooks/store/useProcedure';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
    addExcelPayerDetailFeeSchedules,
    updateFeeScheduleProcedureFee,
    getExcelPayerDetailFeeSchedules,
} from 'state/slices/payers/payers.actions';
import { selectPayersDetailsLoading, selectPayersError } from 'state/slices/payers/payers.selectors';
import UploadFilePanel from 'components/UploadPanel/UploadPanel';
import { useBoolean } from '@uifabric/react-hooks';
import { round } from 'lodash';
import {
    isActiveFeeSchedule,
    toggleActiveFeeScheduleList,
    toggleFeeScheduleProcedures,
} from 'state/slices/procedures/procedures.slice';
import { useFuseSearch } from 'hooks/useFuseSearch';
import { on } from 'events';
import { isDateBetween } from 'utils/isDateBetween';

type Props = {
    selectedFeeSchedule?: IFeeSchedule;
};

export type ProcedureList = {
    id: string;
    code?: string;
    displayName?: string;
    fee?: number;
};

function FeeScheduleProcedures({ selectedFeeSchedule }: Props) {
    const { tenantId, payerId, feeScheduleId } = useParams<{ tenantId: string; payerId: string; feeScheduleId: string }>();

    const [focusedId, setFocusedId] = useState<string>();
    const [isOpen, { setTrue: setIsOpen, setFalse: setIsClosed }] = useBoolean(false);
    const loading = useSelector(selectPayersDetailsLoading);
    const error = useSelector(selectPayersError);
    const [uploadError, setUploadError] = useState<string | undefined>('');
    const dispatch = useDispatch();
    const isActive = useSelector(isActiveFeeSchedule);
    const feeScheduleProceduresList = useSelector(toggleActiveFeeScheduleList);
    const [search, setSearch] = useState<string | undefined>('');
    const [results, setResults] = useState<ProcedureList[]>([]);

    const fuse = useMemo(
        () =>
            new Fuse([] as IProcedure[], {
                threshold: 0.2,
                keys: [
                    { name: 'displayName', weight: 1 },
                    { name: 'code', weight: 2 },
                ],
                findAllMatches: true,
            }),
        [],
    );

    const fuseSearch = () => {
        const results = fuse.search(search ?? '');
        const mappedResults = results
            .sort((curr, prev) => {
                return (curr.score as number) + (prev.score as number);
            })
            .map((proc) => proc.item);
        const procedures = mappedResults.length ? mappedResults : feeScheduleProceduresList;
        const mergedProcedures = procedures.map((procedure) => {
            const savedProcedure = selectedFeeSchedule?.procedures
                ? selectedFeeSchedule?.procedures.find((x) => x.id === procedure.id)
                : undefined;
            return {
                id: procedure.id,
                code: procedure.code,
                displayName: procedure.displayName,
                fee: savedProcedure ? savedProcedure.fee : 0,
                effectiveDate: procedure ? procedure.effectiveDate : '',
                endDate: procedure ? procedure.endDate : '',
            };
        });
        setResults(mergedProcedures);
    };

    useEffect(() => {
        fuse.setCollection(feeScheduleProceduresList as IProcedure[]);
        fuseSearch();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [search, feeScheduleProceduresList, selectedFeeSchedule?.procedures]);

    useEffect(() => {
        setUploadError(error);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [error]);

    const getPayerExcelData = () => {
        dispatch(getExcelPayerDetailFeeSchedules({ tenantId, payerDetailId: payerId, feeScheduleId }));
    };

    const handleUpload = (file: File) => {
        dispatch(
            addExcelPayerDetailFeeSchedules({
                tenantId,
                payerDetailId: payerId,
                feeScheduleId,
                formFile: file,
            }),
        );
    };

    const focusNextFeeField = (currentFieldId: string) => {
        const nextFieldId = `fee-${+currentFieldId.split('-')[1] + 1}`;
        setFocusedId(nextFieldId);
    };
    const focusPreviousFeeField = (currentFieldId: string) => {
        const nextFieldId = `fee-${+currentFieldId.split('-')[1] - 1}`;
        setFocusedId(nextFieldId);
    };
    const onFocus = (focusedId: string) => {
        setFocusedId(focusedId);
    };
    const onKeyDown = (ev?: any) => {
        if (focusedId && ev.code === 'Tab') {
            focusNextFeeField(focusedId);
            ev.preventDefault();
        }
        if (focusedId && ev.shiftKey && ev.code === 'Tab') {
            focusPreviousFeeField(focusedId);
            ev.preventDefault();
            ev.stopPropagation();
        }
    };
    useEffect(() => {
        if (focusedId) {
            const field = document.getElementById(focusedId);
            field?.focus();
        }
    }, [focusedId]);

    const isActiveFee = (item: any) => {
        const active =
            isDateBetween({ dateToCheck: new Date().toISOString(), start: item.effectiveDate, end: item.endDate }) &&
            !item.isDeleted;
        return active;
    };

    return (
        <SubSection
            title="Fee Schedule Procedures"
            headingEndContent={
                <Stack tokens={{ childrenGap: 10 }} horizontal>
                    <Toggle
                        label={'Active Only'}
                        checked={isActive}
                        onChange={() => {
                            dispatch(toggleFeeScheduleProcedures());
                        }}
                        inlineLabel
                    />
                    <SearchBox
                        onChange={(ev, value) => {
                            const newValue = value ?? '';
                            setSearch(newValue);
                        }}
                        value={search}
                        placeholder="Search by Code or Display Name"
                        styles={{ root: { width: 300 } }}
                    />
                    <PrimaryButton text={'Bulk Import'} onClick={setIsOpen} />
                    <UploadFilePanel
                        isOpen={isOpen}
                        closePanel={setIsClosed}
                        downloadTable={getPayerExcelData}
                        uploadTable={(ev: File) => {
                            handleUpload(ev);
                        }}
                        loading={loading}
                        errorMessage={uploadError}
                    />
                </Stack>
            }
            style={{ marginBottom: 0 }}
            styleContent={{ display: 'flex', flex: 'auto' }}
            grow
        >
            <div style={{ position: 'relative', display: 'flex', flex: 1 }}>
                <ScrollablePane scrollbarVisibility={ScrollbarVisibility.auto} styles={{ contentContainer: { display: 'flex' } }}>
                    <div style={{ flexGrow: 1 }}>
                        <SortableDetailsList<ProcedureList>
                            selectionMode={SelectionMode.none}
                            stickyHeader
                            items={results}
                            columns={[
                                {
                                    key: 'code',
                                    fieldName: 'code',
                                    minWidth: 75,
                                    maxWidth: 75,
                                    name: 'Code',
                                    onRender: (item) =>
                                        item && isActiveFee(item) ? (
                                            <Text>{item?.code ?? ''}</Text>
                                        ) : (
                                            <Text>
                                                <i>{item?.code ?? ''}</i>
                                            </Text>
                                        ),
                                },
                                {
                                    key: 'displayName',
                                    fieldName: 'displayName',
                                    minWidth: 300,
                                    name: 'Display Name',
                                    onRender: (item) =>
                                        item && isActiveFee(item) ? (
                                            <Text>{item?.displayName ?? ''}</Text>
                                        ) : (
                                            <Text>
                                                <i>{item?.displayName ?? ''}</i>
                                            </Text>
                                        ),
                                },
                                {
                                    key: 'fee',
                                    fieldName: 'fee',
                                    minWidth: 120,
                                    maxWidth: 120,
                                    name: 'Contracted Fee',
                                    onRender: (item, index) => (
                                        <Field.Currency
                                            id={index !== undefined ? `fee-${index.toString()}` : ''}
                                            onChange={(e, value) => {
                                                if (item) {
                                                    const procedureId = item.id;
                                                    dispatch(
                                                        updateFeeScheduleProcedureFee({
                                                            value,
                                                            feeScheduleId,
                                                            procedureId,
                                                        }),
                                                    );
                                                }
                                            }}
                                            styles={{
                                                fieldGroup: { height: 20 },
                                                field: {
                                                    padding: 3,
                                                },
                                            }}
                                            onKeyDown={onKeyDown}
                                            tabIndex={index}
                                            onFocus={(ev) => onFocus(ev.target.id)}
                                            prefix="$"
                                            value={round(item?.fee ?? 0, 2)}
                                        />
                                    ),
                                },
                            ]}
                            initialSortDirection={['asc']}
                            sortOnMount
                            compact
                        />
                    </div>
                </ScrollablePane>
            </div>
        </SubSection>
    );
}

export default FeeScheduleProcedures;
