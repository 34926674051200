import { Shimmer } from '@fluentui/react';
import { useSelector } from 'hooks';
import useTenantId from 'hooks/useTenantId';
import { LoadingStatus } from 'interfaces/loading-statuses';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import {
    getMissingUserIdentities,
    selectUserIdentitiesData,
    selectUserIdentitiesDataLoading,
} from 'state/slices/users-idenitites/user-indentities.slice';
import { validate as validUUID } from 'uuid';

export type UserDisplayNameProps = { userId?: string; useSuffix?: boolean };

export function useUserIdentity({ userId, useSuffix }: UserDisplayNameProps) {
    const tenantId = useTenantId();
    const userIdentitiesData = useSelector(selectUserIdentitiesData);
    const dispatch = useDispatch();

    useEffect(() => {
        if (userId && validUUID(userId) && !userIdentitiesData[userId]) {
            dispatch(getMissingUserIdentities(tenantId, [userId]));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userId]);

    if (!userId) return { userName: 'N/A', user: undefined };

    const user = userIdentitiesData[userId];

    const userName = user
        ? useSuffix && user.professionalSuffix
            ? `${user.firstName} ${user.lastName}, ${user.professionalSuffix}`
            : user.displayName ?? 'N/A'
        : userId;

    return { userName, user, firstName: user?.firstName, lastName: user?.lastName, professionalSuffix: user?.professionalSuffix };
}

export default function UserDisplayName({ userId, useSuffix }: UserDisplayNameProps) {
    const { userName, user } = useUserIdentity({ userId, useSuffix });
    const loadingUserIdentities = useSelector(selectUserIdentitiesDataLoading);

    // No user yet, but it's a valid UUID assume we're still loading identities
    if (!user && validUUID(userId ?? '') && loadingUserIdentities === LoadingStatus.Pending) {
        return <Shimmer />;
    }

    return <>{userName}</>;
}
