import { ITenantSubscriptionService } from 'api/models/tenantSubscription.model';
import { flatten, map, sortBy, uniqBy } from 'lodash';
import { createSelector } from 'reselect';
import { RootState } from 'state/store';
import { isDateBetween } from 'utils/isDateBetween';

export const selectTenantState = (state: RootState) => state.tenant;

export const selectTenantSubscriptions = createSelector(selectTenantState, (state) =>
    (state.currentTenant.subscriptions ?? []).filter((sub) => !sub.isDeleted),
);

export const selectAllTenantSubscriptionServices = createSelector(selectTenantSubscriptions, (subscriptions) =>
    flatten(
        subscriptions.map((subscription) =>
            (map(subscription?.services).filter((sub) => sub !== undefined) as ITenantSubscriptionService[])
                .filter((service) => {
                    const { startDate: start, endDate: end } = service;
                    const today = new Date();
                    return isDateBetween({ dateToCheck: today.toISOString(), start, end });
                })
                .map((service) => ({ ...service, productId: subscription.productId })),
        ),
    ),
);

export const selectTenantSubscriptionsServices = createSelector(selectAllTenantSubscriptionServices, (subscriptions) =>
    sortBy(
        uniqBy(subscriptions, (service) => service.serviceType),
        (service) => service.serviceName,
    ),
);

export const selecteTenantSubscriptionsWithServices = createSelector(selectAllTenantSubscriptionServices, (subscriptions) => {
    return uniqBy(
        map(subscriptions, (service) => ({
            productId: service.productId,
            startDate: service.startDate,
            endDate: service.endDate,
        })),
        (service) => service.productId,
    );
});

export const selectSourceSystem = createSelector(selectTenantState, (state) => state.currentTenant.data?.sourceSystem);

export const tenantConfig = createSelector(selectTenantState, (state) => state.currentTenant.config);
export const tenantConfigIsLoading = createSelector(selectTenantState, (state) => state.currentTenant.loadingConfig);

export const selectDentalDefaultLoc = createSelector(tenantConfig, (config) => config?.defaultDentalDepartmentId);

export const selectDentalDefaultAppointmentCancellationReason = createSelector(
    tenantConfig,
    (config) => config?.rescheduleCancellationReasonId,
);
