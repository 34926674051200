import IProcedureDetail from 'api/models/procedure-details.model';
import { isAfter } from 'date-fns';
import { LoadingStatus } from 'interfaces/loading-statuses';
import { map } from 'lodash';
import { createSelector } from 'reselect';
import { RootState } from 'state/store';
import dateOnly from 'utils/dateOnly';
import { isDateBetween } from 'utils/isDateBetween';
import { selectProceduresDataAsList } from '../procedures/procedures.slice';

/**
 * Returns the entirety of the procedure details state
 */
export const selectProcedureDetailsState = (state: RootState) => state.procedureDetails;

export const selectExcelUploading = createSelector(
    selectProcedureDetailsState,
    ({ excelDataUploading }) => excelDataUploading === LoadingStatus.Pending,
);

export const isProcedureActive = createSelector(selectProcedureDetailsState, (state) => state.isProcedureActive);

/**
 * Returns a dictionary of the loaded procedure details
 */
export const selectProcedureDetailsData = createSelector(selectProcedureDetailsState, (state) => state.data);

/**
 * Returns loaded procedure detail dictionary as an array.
 * Also removes sotf deleted items from list
 */
export const selectProcedureDetailsAsList = createSelector(
    selectProcedureDetailsState,
    (state) => map(state.data).filter((item) => !item?.isDeleted) as IProcedureDetail[],
);

/**
 * Returns the selected procedure detail from state.
 */
export const selectSelectedProcedureDetail = createSelector(
    selectProcedureDetailsState,
    (state) => state.selectedProcedureDetail,
);

/**
 * Returns procedures with the mapped procedure detail fee data if it exists.
 * Otherwise it maps the fee data as 0
 */
export const proceduresWithDetails = createSelector(
    selectProceduresDataAsList,
    selectProcedureDetailsData,
    (procedures, procedureDetails) => {
        return procedures.map((item) => {
            return {
                ...item,
                fee: item
                    ? procedureDetails[item.id]?.fees?.find((f) => {
                          const today = new Date();
                          const startDate = f?.effectiveDate ? new Date(f?.effectiveDate) : '';
                          const endDate = f.endDate ? new Date(f.endDate) : '';
                          return isDateBetween({
                              dateToCheck: today.toString(),
                              start: startDate.toString(),
                              end: endDate.toString(),
                          });
                      })?.fee ?? 0
                    : 0,
            };
        });
    },
);

export const toggleProcedureDetailsList = createSelector(proceduresWithDetails, isProcedureActive, (procedures, isActive) => {
    const updatedProcedures = isActive
        ? procedures.filter((item) =>
              isDateBetween({
                  dateToCheck: new Date().toString(),
                  start: item.effectiveDate,
                  end: item.endDate,
              }),
          )
        : procedures;
    return updatedProcedures;
});

export const procedureDetailValidationErrors = createSelector(selectSelectedProcedureDetail, (selectedProcedureDetail) => {
    const errors: string[] = [];

    const fees = selectedProcedureDetail?.fees ?? [];

    if (fees) {
        const currentEffectiveDate = fees[0]?.effectiveDate ? dateOnly(fees[0]?.effectiveDate) : '';
        const currentEndDate = fees[0]?.endDate ? dateOnly(fees[0]?.endDate) : '';

        const isEffectiveDateInvalid = fees
            ? isDateBetween({
                  dateToCheck: fees[0]?.effectiveDate,
                  start: fees[1]?.effectiveDate,
                  end: fees[1]?.endDate,
              })
            : false;

        if (isEffectiveDateInvalid) errors.push('The current fee has an overlapping effective date.');

        const isEndDateInvalid =
            currentEndDate && currentEffectiveDate
                ? isAfter(new Date(currentEffectiveDate), new Date(currentEndDate)) || currentEffectiveDate === currentEndDate
                : false;

        if (isEndDateInvalid) errors.push("The current fee's end date must be after the effective date.");
    }

    return errors;
});
