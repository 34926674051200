import {
    ConstrainMode,
    Dropdown,
    IDropdownOption,
    MessageBar,
    MessageBarType,
    ScrollablePane,
    ScrollbarVisibility,
    SelectionMode,
    Stack,
    TooltipHost,
} from '@fluentui/react';
import ILocationOfCare, { LocationOfCareDepartmentType } from 'api/models/locationsOfCare.model';
import { Section, SortableDetailsList } from 'components';
import { ISortableColumn } from 'components/SortableDetailsList/SortableDetailsList';
import useLocationsOfCare from 'hooks/store/useLocationsOfCare';
import { map } from 'lodash';
import { useSelector } from 'react-redux';
import { selectDentalDefaultLocObject } from 'state/slices/locationsOfCare/locationsOfCare.selectors';

export default function LocationsOfCareList() {
    const { locationsOfCareAsList, locationsOfCareSearchResults, search, setLocationOfCarePropAndSave } = useLocationsOfCare();
    const defaultDentalLOC = useSelector(selectDentalDefaultLocObject);

    function addressString(location?: ILocationOfCare) {
        if (location) {
            const { address1, address2, city, state, zip } = location;

            return `${address1 ? `${address1}, ` : ''} ${address2 ? ` ${address2}, ` : ''} ${city ?? `${city}, `} ${
                state ?? `${state} `
            } ${zip ?? zip}`;
        }
        return '';
    }

    const columns: ISortableColumn<ILocationOfCare>[] = [
        {
            key: 'id',
            minWidth: 50,
            maxWidth: 100,
            name: 'Id',
            fieldName: 'id',
            isResizable: true,
        },
        {
            key: 'displayName',
            minWidth: 100,
            maxWidth: 330,
            name: 'Name',
            isResizable: true,
            fieldName: 'displayName',
            onRender: (item) => {
                return (
                    <TooltipHost content={item?.displayName}>
                        <span>{item?.displayName}</span>
                    </TooltipHost>
                );
            },
        },

        {
            key: 'address',
            minWidth: 50,
            maxWidth: 100,
            isResizable: true,
            name: 'Address',
            getValueKey: (item) => addressString(item),
            onRender: (item) => {
                const address = addressString(item);
                return <span title={address}>{address}</span>;
            },
        },
        {
            key: 'clinicals',
            minWidth: 50,
            maxWidth: 100,
            isResizable: true,
            name: 'athena Clinicals',
            onRender: (item) => {
                return <span>{item?.clinicals}</span>;
            },
        },
        {
            key: 'dept-type',
            minWidth: 170,
            maxWidth: 340,
            isResizable: true,
            name: 'Dept. Type',
            getValueKey: (item) => item?.departmentType ?? '',
            onRender: (item) => (
                <Dropdown
                    styles={{
                        title: { height: '26px', lineHeight: 24 },
                        caretDown: { height: 26 },
                    }}
                    options={[
                        { key: '', text: '(None)' },
                        ...(map(LocationOfCareDepartmentType, (type, key) => ({
                            key: type,
                            text: key,
                        })) as IDropdownOption[]),
                    ]}
                    disabled={item?.id === defaultDentalLOC?.id}
                    placeholder="(Select type)"
                    selectedKey={item?.departmentType ?? ''}
                    onChange={(ev, option) => {
                        if (option && item) setLocationOfCarePropAndSave(item?.id, 'departmentType', option.key);
                    }}
                />
            ),
        },
    ];

    return (
        <Stack tokens={{ childrenGap: 10 }} style={{ padding: 10, display: 'flex', flex: 1 }} grow>
            {!locationsOfCareSearchResults.length && search && <MessageBar>No locations of care found in search.</MessageBar>}
            <div style={{ position: 'relative', display: 'flex', flex: 1 }}>
                <ScrollablePane scrollbarVisibility={ScrollbarVisibility.auto}>
                    <Section heading="Default Location of Care">
                        {defaultDentalLOC ? (
                            <SortableDetailsList<ILocationOfCare>
                                columns={columns}
                                selectionMode={SelectionMode.none}
                                items={[defaultDentalLOC]}
                                sortOnMount
                                sortColumns={['displayName']}
                                initialSortDirection={'asc'}
                                constrainMode={ConstrainMode.horizontalConstrained}
                            />
                        ) : (
                            <MessageBar messageBarType={MessageBarType.warning}>No default location of care set.</MessageBar>
                        )}
                    </Section>

                    <Section heading="Locations of Care">
                        <SortableDetailsList<ILocationOfCare>
                            columns={columns}
                            selectionMode={SelectionMode.none}
                            items={locationsOfCareAsList.filter((loc) => loc.id !== defaultDentalLOC?.id)}
                            sortOnMount
                            sortColumns={['displayName']}
                            initialSortDirection={'asc'}
                            constrainMode={ConstrainMode.horizontalConstrained}
                        />
                    </Section>
                </ScrollablePane>
            </div>
        </Stack>
    );
}
