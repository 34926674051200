import { Dictionary, createAsyncThunk } from '@reduxjs/toolkit';
import LookupBase from 'api/models/lookup-base.model';

import { CreateAsyncThunkOptions, RootState } from 'state/store';
import { v4 as uuid } from 'uuid';
import { toggleStatementConfigurationPanel } from './slice';
import IStatementConfig, { IDunningRule, IAdditionalText } from './models';
import statementApi from './api';
import { map } from 'lodash';

export const getStatementConfigurations = createAsyncThunk<IStatementConfig, { tenantId: string }>(
    'statementConfigurations/getStatementConfigurations',
    async ({ tenantId }) => {
        const statementConfigurations = await statementApi.getStatementConfigurations(tenantId);
        return statementConfigurations.data;
    },
);

export const createStatementConfiguration = createAsyncThunk<
    IStatementConfig,
    { tenantId: string; statementConfiguration: IStatementConfig }
>('statementConfigurations/createStatementConfiguration', async ({ tenantId, statementConfiguration }) => {
    const response = await statementApi.createStatementConfiguration(tenantId, statementConfiguration);
    return response.data;
});

export const updateStatementConfiguration = createAsyncThunk<
    IStatementConfig,
    { tenantId: string; statementConfiguration: IStatementConfig }
>('statementConfigurations/updateStatementConfiguration', async ({ tenantId, statementConfiguration }) => {
    const response = await statementApi.updateStatementConfiguration(tenantId, statementConfiguration);
    return response.data;
});

export const getProviderTypes = createAsyncThunk<Dictionary<LookupBase>>('statementConfigurations/getProviderTypes', async () => {
    const response = await statementApi.getProviderTypes();
    return response.data;
});

export const getDunningRules = createAsyncThunk<Record<string, IDunningRule>, { tenantId: string }>(
    'statementConfigurations/getDunningRules',
    async ({ tenantId }) => {
        const response = await statementApi.getDunningRules(tenantId);
        return response.data;
    },
);

export const updateDunningRules = createAsyncThunk<Record<string, IDunningRule>, { tenantId: string }, CreateAsyncThunkOptions>(
    'statementConfigurations/updateDunningRules',
    async ({ tenantId }, { getState }) => {
        const dunningRules = getState().statementConfiguration.selectDunningRules;
        const requests = map(dunningRules, (dunningRule) => {
            return statementApi.updateDunningRules(tenantId, dunningRule);
        });
        const response = await Promise.all(requests);
        const data: Record<string, IDunningRule> = {};
        response.forEach((res) => {
            data[res.data.id] = res.data;
        });

        return data;
    },
);

export const updatedPracticeStatementNotes = (tenantId: string, selectedNote: IAdditionalText) => {
    return async (dispatch: any, getState: () => RootState) => {
        const state = getState();
        const config = state.statementConfiguration.statementConfigurations;

        if (config && config.additionalText) {
            const updatedAdditionalText = config.additionalText.filter((note) => note.id !== selectedNote?.id);
            const updatedNote = {
                ...selectedNote,
                id: selectedNote?.id ?? uuid(),
            };
            const updatedConfig: IStatementConfig = {
                ...config,
                additionalText: [...updatedAdditionalText, updatedNote],
            };

            dispatch(updateStatementConfiguration({ tenantId, statementConfiguration: updatedConfig }));
            dispatch(toggleStatementConfigurationPanel());
        }
    };
};
