import { createSlice } from '@reduxjs/toolkit';

const initialState: UserState = {
    photo: null,
    profile: null,
    photoLoading: false,
    profileLoading: false,
    photoError: null,
    profileError: null,
};

const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        getCurrentUserProfileLoading(state) {
            state.profileLoading = true;
        },
        getCurrentUserProfileSuccess(state, action) {
            state.profile = action.payload;
            state.profileLoading = false;
        },
        getCurrentUserProfileFailure(state, action) {
            state.profileError = action.payload;
            state.profileLoading = false;
        },
        getCurrentUserPhotoLoading(state) {
            state.photoLoading = true;
        },
        getCurrentUserPhotoSuccess(state, action) {
            state.photo = action.payload;
            state.photoLoading = false;
        },
        getCurrentUserPhotoFailure(state, action) {
            state.photoError = action.payload;
            state.photoLoading = false;
        },
    },
});

export const {
    getCurrentUserPhotoFailure,
    getCurrentUserPhotoLoading,
    getCurrentUserPhotoSuccess,
    getCurrentUserProfileFailure,
    getCurrentUserProfileLoading,
    getCurrentUserProfileSuccess,
} = userSlice.actions;

export default userSlice.reducer;

type UserState = {
    photo: any;
    profile: any | null;
    photoLoading: boolean;
    profileLoading: boolean;
    photoError: string | null;
    profileError: string | null;
};

