import { DefaultButton, Panel, PanelType, PrimaryButton, Stack, TextField } from '@fluentui/react';
import { IFeeSchedule, IPayerDetail } from 'api/models/payer.model';
import { Field } from 'components';
import { isBefore, isEqual } from 'date-fns';
import { LoadingStatus } from 'interfaces/loading-statuses';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { upsertPayerDetailFeeSchedule } from 'state/slices/payers/payers.actions';
import { selectPayersDetails, selectPayersDetailsLoading, selectSelectedPayer } from 'state/slices/payers/payers.selectors';
import { v4 as uuid } from 'uuid';

type Props = { isOpen: boolean; closePanel: () => void };

function AddFeeSchedulePanel({ isOpen, closePanel }: Props) {
    const { tenantId, payerId } = useParams<{ tenantId: string; payerId: string }>();
    const selectedPayer = useSelector(selectSelectedPayer);
    const payerDetails = useSelector(selectPayersDetails);
    const loading = useSelector(selectPayersDetailsLoading);
    const [feeScheduleState, setFeeScheduleState] = useState<IFeeSchedule>(getNewFeeSchedule());
    const dispatch = useDispatch();

    const _onSave = () => {
        if (hasFeeSchedulesDateConflict() || !feeScheduleState.displayName) return false;
        if (selectedPayer) {
            const payerDetail = payerDetails ? payerDetails[payerId] : undefined;
            const isNew = payerDetail ? true : false;
            const model: IPayerDetail = {
                id: selectedPayer.id,
                isDeleted: selectedPayer.isDeleted ? selectedPayer.isDeleted : false,
                displayName: selectedPayer.displayName ? selectedPayer.displayName : selectedPayer.name ?? '',
                payerId: selectedPayer.payerId,
                isQuickPick: selectedPayer.isQuickPick,
                feeSchedules: selectedPayer.feeSchedules ? [...selectedPayer.feeSchedules, feeScheduleState] : [feeScheduleState],
            };
            dispatch(
                upsertPayerDetailFeeSchedule({
                    tenantId,
                    payerId,
                    feeScheduleId: feeScheduleState.id,
                    model,
                    isNew,
                }),
            );
        }
    };

    const _onPropChange = (path: keyof IFeeSchedule, value?: string | boolean) => {
        if (feeScheduleState) {
            setFeeScheduleState({
                ...feeScheduleState,
                [path]: value,
            });
        }
    };

    const hasFeeSchedulesDateConflict = (): boolean => {
        let hasConflict = false;
        selectedPayer &&
            selectedPayer.feeSchedules &&
            selectedPayer.feeSchedules
                .filter((x) => x.id !== feeScheduleState.id)
                .forEach((fee) => {
                    const endDate = fee.endDate;
                    const effectiveDateIsBefore =
                        endDate && feeScheduleState.effectiveDate
                            ? isBefore(new Date(feeScheduleState.effectiveDate), new Date(endDate))
                            : false;
                    const effectiveDateIsEqual =
                        endDate && feeScheduleState.effectiveDate
                            ? isEqual(new Date(feeScheduleState.effectiveDate), new Date(endDate))
                            : false;
                    if (effectiveDateIsBefore || effectiveDateIsEqual) {
                        hasConflict = true;
                    }
                });
        return hasConflict;
    };

    const hasDateConflict = (): boolean => {
        const hasConflict = false;
        if (feeScheduleState.endDate && feeScheduleState.effectiveDate) {
            return isBefore(new Date(feeScheduleState.endDate), new Date(feeScheduleState.effectiveDate));
        }
        return hasConflict;
    };

    const displayNameIsRequired = !feeScheduleState.displayName ? 'Name is required.' : '';
    const startDateHasConflict = hasFeeSchedulesDateConflict() ? 'Date ranges cannot overlap with an active fee schedule.' : '';
    const endDateHasConflict = hasDateConflict() ? 'End date cannot be before the effective date.' : '';
    const isDisable = hasFeeSchedulesDateConflict() || hasDateConflict() || !feeScheduleState.displayName;
    const _isSaving = loading === LoadingStatus.Pending;

    const _onRenderFooterContent = () => {
        const savingText = _isSaving ? 'Saving...' : 'Save';

        return (
            <Stack horizontal tokens={{ childrenGap: 12 }}>
                <PrimaryButton
                    text={savingText}
                    disabled={isDisable}
                    iconProps={{ iconName: 'SaveAndClose' }}
                    onClick={_onSave}
                />
                <DefaultButton text="Cancel" disabled={_isSaving} onClick={closePanel} />
            </Stack>
        );
    };
    return (
        <Panel
            isOpen={isOpen}
            onDismiss={closePanel}
            headerText="Add Fee Schedule"
            type={PanelType.medium}
            onRenderFooterContent={_onRenderFooterContent}
            isFooterAtBottom
        >
            <Stack tokens={{ childrenGap: 12 }} horizontal>
                <TextField
                    label="Name"
                    value={feeScheduleState.displayName}
                    onChange={(ev, value) => _onPropChange('displayName', value)}
                    errorMessage={displayNameIsRequired}
                />
                <Field.Date
                    label="Effective Date"
                    value={feeScheduleState.effectiveDate}
                    onChange={(ev, value) => _onPropChange('effectiveDate', value)}
                    errorMessage={startDateHasConflict}
                />
                <Field.Date
                    label="End Date"
                    value={feeScheduleState.endDate}
                    onChange={(ev, value) => _onPropChange('endDate', value)}
                    errorMessage={endDateHasConflict}
                />
            </Stack>
        </Panel>
    );
}

export default AddFeeSchedulePanel;

function getNewFeeSchedule(): IFeeSchedule {
    return {
        id: uuid(),
        displayName: '',
        effectiveDate: '',
        endDate: '',
        isDeleted: false,
        procedures: [],
    };
}
