import {
    ConstrainMode,
    Dropdown,
    IComboBoxOption,
    MessageBar,
    ScrollablePane,
    ScrollbarVisibility,
    SelectionMode,
    Stack,
} from '@fluentui/react';
import { IEncounter } from 'api/models/encounter.model';
import SortableDetailsList, { ISortableColumn } from 'components/SortableDetailsList/SortableDetailsList';
import Fuse from 'fuse.js';
import { LoadingStatus } from 'interfaces/loading-statuses';
import { isEqual } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { updateEncounter } from 'state/slices/encounters/encounters.actions';
import { encountersSearch } from 'state/slices/encounters/encounters.selectors';
import { cleanupEncountersSearch } from 'state/slices/encounters/encounters.slice';

type Props = {
    items: IEncounter[];
    loading?: LoadingStatus;
};

const options: IComboBoxOption[] = [
    { key: 'Dental', text: 'Dental' },
    { key: '', text: '(None)' },
];

export default function EncounterReasonsList(props: Props) {
    const { items, loading } = props;
    const { tenantId } = useParams<{
        tenantId: string;
    }>();
    const dispatch = useDispatch();

    const handleDeptChange = (item: IEncounter, value: any) => {
        const model: IEncounter = {
            ...item,
            departmentType: value,
        };
        dispatch(updateEncounter({ tenantId, model }));
    };
    const searchString = useSelector(encountersSearch);
    const [results, setResults] = useState<IEncounter[]>([]);

    const fuse = useMemo(
        () =>
            new Fuse([] as IEncounter[], {
                threshold: 0.1,
                keys: [
                    { name: 'displayName', weight: 1 },
                    { name: 'departmentType', weight: 2 },
                ],
                findAllMatches: true,
                useExtendedSearch: true,
            }),
        [],
    );

    const fuseSearch = () => {
        const results = fuse.search(searchString ? `'${searchString}` : '');
        const mappedResults = results
            .sort((curr, prev) => {
                return (curr.score as number) + (prev.score as number);
            })
            .map((proc) => proc.item);
        setResults(mappedResults);
    };

    useEffect(() => {
        if (!isEqual(items, results)) {
            fuse.setCollection(items as IEncounter[]);
            fuseSearch();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [items]);

    useEffect(() => {
        fuseSearch();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [items]);
    useEffect(() => {
        fuseSearch();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchString]);

    useEffect(() => {
        dispatch(cleanupEncountersSearch());
    }, [dispatch]);

    const gridColumns: ISortableColumn<IEncounter>[] = [
        {
            key: 'displayName',
            fieldName: 'displayName',
            minWidth: 400,
            maxWidth: 700,
            name: 'Reason',
            isResizable: true,
            getValueKey: (item) => {
                return item?.displayName ?? '';
            },
        },
        {
            key: 'departmentType',
            name: 'Dept. Type',
            isResizable: true,
            minWidth: 100,
            maxWidth: 100,
            fieldName: 'departmentType',
            getValueKey: (item) => {
                return item?.departmentType ?? '';
            },
            onRender: (item) => (
                <Dropdown
                    styles={{
                        title: { height: '26px', lineHeight: 24 },
                        caretDown: { height: 26 },
                    }}
                    options={options}
                    placeholder="(Select type)"
                    selectedKey={item?.departmentType ?? ''}
                    onChange={(ev, option) => {
                        if (option && item) handleDeptChange(item, option.key);
                    }}
                />
            ),
        },
    ];

    return (
        <Stack tokens={{ childrenGap: 10 }} style={{ padding: 10, display: 'flex', flex: 1 }} grow>
            {!items.length && <MessageBar>No encounter reasons are found in search.</MessageBar>}
            <div style={{ position: 'relative', display: 'flex', flex: 1 }}>
                <ScrollablePane scrollbarVisibility={ScrollbarVisibility.auto}>
                    <SortableDetailsList<IEncounter>
                        columns={gridColumns}
                        selectionMode={SelectionMode.none}
                        items={results.length ? results : items}
                        sortOnMount
                        sortColumns={['displayName', 'departmentType']}
                        initialSortDirection={'asc'}
                        constrainMode={ConstrainMode.horizontalConstrained}
                        enableShimmer={loading === LoadingStatus.Pending}
                        stickyHeader={true}
                    />
                </ScrollablePane>
            </div>
        </Stack>
    );
}
