import {
    DefaultButton,
    IDropdownOption,
    Label,
    PrimaryButton,
    ScrollablePane,
    ScrollbarVisibility,
    Stack,
    TextField,
} from '@fluentui/react';
import { useId } from '@uifabric/react-hooks';
import ISlidingFeeProgram, { ICodeCategoryExclusion, ISlidingFeePlan } from 'api/models/slidingFee.model';
import { Field, Section } from 'components';
import useSlidingFee from 'hooks/store/useSlidingFee';
import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import CodeCategoryExclusion from './CodeCategoryExclusion';
import GroupCodeExclusion from './GroupCodeExclusion';
import SpecificCodeExclusion from './SpecificCodeExclusion';

export const exclusionCalculationTypeOptions: IDropdownOption[] = [
    {
        key: '',
        text: 'None',
    },
    {
        key: 'flatFeeOnly',
        text: 'Flat fee per Category/Group',
    },
    {
        key: 'flatFeePerCode',
        text: 'Flat fee Per Code',
    },
    {
        key: 'percentagePerCode',
        text: 'Percentage Per Code',
    },
];

export const programCalculationTypeOptions: IDropdownOption[] = [
    {
        key: '',
        text: 'None',
    },
    {
        key: 'highestCategoryFlatRate',
        text: 'Highest Flat Fee',
    },
    {
        key: 'encounterFlatRate',
        text: 'Encounter Flat Rate ',
    },
    {
        key: 'encounterAdjustableRate',
        text: 'Pt. Percentage, Min/Max',
    },
];

function ProgramPlan(): JSX.Element {
    const { selectedProgram, setSelectedProgramPlan, selectedProgramPlan, updateSlidingFeeProgramPlan } = useSlidingFee();
    const [hasError, setHasError] = useState<boolean>(false);
    const { push } = useHistory();
    const { tenantId, programId } = useParams<{ tenantId: string; programId: string }>();
    const programPlan = selectedProgramPlan.data;
    const isNew = selectedProgramPlan.isNew;
    const insertIndex = selectedProgramPlan.insertIndex;
    const textFieldId = useId('percentOfPoverty');
    const [planName] = useState<string>(programPlan?.name as string);

    const onProgramPlanPropertyChange = (
        key: keyof ISlidingFeePlan,
        value: string | number | ICodeCategoryExclusion[] | boolean,
    ) => {
        if (!programPlan) return;
        setSelectedProgramPlan({ ...programPlan, [key]: value }, isNew, insertIndex);
    };

    const _onReturnToProgramPlans = () => {
        setSelectedProgramPlan(undefined);
        push(`/${tenantId}/sliding-fee/${programId}`);
    };

    const _onSave = () => {
        if (selectedProgram) {
            const selectedProgramPlans = selectedProgram?.plans;
            if (programPlan && selectedProgramPlans && insertIndex !== undefined) {
                const plans = selectedProgramPlans.filter((_, i: number) => i !== insertIndex);
                const updatedPlan = { ...programPlan };
                if (updatedPlan) {
                    plans.splice(insertIndex, 0, updatedPlan);
                }
                const program: ISlidingFeeProgram = {
                    ...selectedProgram,
                    plans,
                };
                updateSlidingFeeProgramPlan(program);
            }
        }
    };

    const nameHasError = !programPlan?.name ? 'Name is required.' : '';
    const filteredPlans =
        selectedProgram && selectedProgram?.plans && selectedProgram.plans.filter((plan) => plan.name !== planName);
    const isDuplicate = filteredPlans?.some((plan) => plan.name === programPlan?.name);
    const hasDuplicateError = isDuplicate ? 'Name is already being used.' : '';

    useEffect(() => {
        if (!programPlan?.name || isDuplicate) {
            setHasError(true);
        } else {
            setHasError(false);
        }
    }, [programPlan, isDuplicate]);

    useEffect(() => {
        if (programPlan?.calculationType === 'encounterFlatRate') {
            onProgramPlanPropertyChange('maxFee', '');
            onProgramPlanPropertyChange('minFee', '');
            onProgramPlanPropertyChange('patientPercentage', '');
        }
        if (programPlan?.calculationType === 'encounterAdjustableRate') {
            onProgramPlanPropertyChange('flatFee', '');
        }
        if (programPlan?.calculationType === '') {
            onProgramPlanPropertyChange('flatFee', '');
            onProgramPlanPropertyChange('maxFee', '');
            onProgramPlanPropertyChange('minFee', '');
            onProgramPlanPropertyChange('patientPercentage', '');
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [programPlan?.calculationType]);

    return (
        <Stack style={{ position: 'relative', display: 'flex', flex: 1 }}>
            <ScrollablePane scrollbarVisibility={ScrollbarVisibility.auto}>
                <Section
                    heading={'Edit Plan'}
                    headingRightContent={
                        <Stack horizontal tokens={{ childrenGap: 12 }}>
                            <PrimaryButton
                                text={'Save'}
                                disabled={hasError}
                                iconProps={{ iconName: 'SaveAndClose' }}
                                onClick={_onSave}
                            />
                        </Stack>
                    }
                >
                    <Stack tokens={{ childrenGap: 10 }}>
                        <Stack tokens={{ childrenGap: 10 }} horizontal grow>
                            <Stack.Item grow>
                                <TextField
                                    value={programPlan?.name}
                                    label="Name"
                                    required
                                    errorMessage={nameHasError || hasDuplicateError}
                                    autoComplete="off"
                                    onChange={(event, newValue) => onProgramPlanPropertyChange('name', newValue ? newValue : '')}
                                />
                            </Stack.Item>
                            <Stack.Item grow>
                                <Field.Date
                                    value={programPlan?.effectiveDate}
                                    label="Effective Date"
                                    onChange={(event, newValue) =>
                                        onProgramPlanPropertyChange('effectiveDate', newValue ? newValue : '')
                                    }
                                />
                            </Stack.Item>
                            <Stack.Item grow>
                                <Field.Date
                                    value={programPlan?.expirationDate}
                                    hasDatePicker
                                    label="Expiration Date"
                                    onChange={(event, newValue) => {
                                        onProgramPlanPropertyChange('expirationDate', newValue ?? '');
                                    }}
                                />
                            </Stack.Item>
                        </Stack>
                        <Stack>
                            <Label htmlFor={textFieldId}>Percent of Poverty</Label>
                            <Stack tokens={{ childrenGap: 10 }} horizontal grow>
                                <Stack.Item grow>
                                    <TextField
                                        type="number"
                                        prefix="% from"
                                        value={programPlan?.povertyPercentageFrom?.toString()}
                                        autoComplete="off"
                                        onChange={(e, value) =>
                                            value && onProgramPlanPropertyChange('povertyPercentageFrom', value)
                                        }
                                    />
                                </Stack.Item>
                                <Stack.Item grow>
                                    <TextField
                                        type="number"
                                        prefix="% to"
                                        value={programPlan?.povertyPercentageTo?.toString()}
                                        autoComplete="off"
                                        onChange={(e, value) =>
                                            value && onProgramPlanPropertyChange('povertyPercentageTo', value)
                                        }
                                    />
                                </Stack.Item>
                            </Stack>
                        </Stack>
                        <Stack tokens={{ childrenGap: 10 }} horizontal>
                            <Stack.Item grow>
                                <Field.Dropdown
                                    style={{ minWidth: 200 }}
                                    placeholder="Select"
                                    label="Adjustment Type"
                                    selectedKey={programPlan?.adjustmentType !== '' ? programPlan?.adjustmentType : null}
                                    options={[
                                        {
                                            key: '',
                                            text: 'None',
                                        },
                                        {
                                            key: 'Sliding Fee',
                                            text: 'Sliding Fee',
                                        },
                                        {
                                            key: 'Contractual',
                                            text: 'Contractual',
                                        },
                                        {
                                            key: 'Grant',
                                            text: 'Grant',
                                        },
                                        {
                                            key: 'Prompt Pay',
                                            text: 'Prompt Pay',
                                        },
                                        {
                                            key: 'Courtesy',
                                            text: 'Courtesy',
                                        },
                                        {
                                            key: 'Write Off',
                                            text: 'Write Off',
                                        },
                                    ]}
                                    onChange={(e, option) => option && onProgramPlanPropertyChange('adjustmentType', option.key)}
                                />
                            </Stack.Item>
                            <Stack.Item grow>
                                <Field.Dropdown
                                    style={{ minWidth: 200 }}
                                    placeholder="Select"
                                    label="Calculation Type"
                                    selectedKey={programPlan?.calculationType !== '' ? programPlan?.calculationType : null}
                                    options={programCalculationTypeOptions}
                                    onChange={(e, option) => option && onProgramPlanPropertyChange('calculationType', option.key)}
                                />
                            </Stack.Item>
                        </Stack>
                        <Stack tokens={{ childrenGap: 10 }} grow>
                            {programPlan?.calculationType === 'encounterFlatRate' && (
                                <Stack.Item grow>
                                    <Field.Currency
                                        value={programPlan?.flatFee}
                                        prefix="$"
                                        label="Flat Fee"
                                        autoComplete="off"
                                        onChange={(event, newValue) =>
                                            onProgramPlanPropertyChange('flatFee', newValue ? newValue : '')
                                        }
                                    />
                                </Stack.Item>
                            )}
                            {programPlan?.calculationType === 'encounterAdjustableRate' && (
                                <Stack>
                                    <Stack.Item grow>
                                        <TextField
                                            value={programPlan?.patientPercentage?.toString()}
                                            prefix="%"
                                            label="Plan Patient Responsibility Percentage"
                                            autoComplete="off"
                                            onChange={(event, newValue) =>
                                                onProgramPlanPropertyChange(
                                                    'patientPercentage',
                                                    newValue ? parseInt(newValue) : '',
                                                )
                                            }
                                        />
                                    </Stack.Item>
                                    <Stack.Item grow>
                                        <Field.Currency
                                            value={programPlan?.minFee}
                                            prefix="$"
                                            label="Encounter Minimum Fee"
                                            autoComplete="off"
                                            onChange={(event, newValue) =>
                                                onProgramPlanPropertyChange('minFee', newValue ? newValue : '')
                                            }
                                        />
                                    </Stack.Item>
                                    <Stack.Item grow>
                                        <Field.Currency
                                            value={programPlan?.maxFee}
                                            prefix="$"
                                            label="Encounter Maximum Fee"
                                            autoComplete="off"
                                            onChange={(event, newValue) =>
                                                onProgramPlanPropertyChange('maxFee', newValue ? newValue : '')
                                            }
                                        />
                                    </Stack.Item>
                                </Stack>
                            )}
                        </Stack>
                        <CodeCategoryExclusion
                            programPlan={programPlan}
                            onProgramPlanPropertyChange={onProgramPlanPropertyChange}
                        />
                        <GroupCodeExclusion programPlan={programPlan} onProgramPlanPropertyChange={onProgramPlanPropertyChange} />
                        <SpecificCodeExclusion
                            programPlan={programPlan}
                            onProgramPlanPropertyChange={onProgramPlanPropertyChange}
                        />
                        <Stack horizontalAlign="end">
                            <DefaultButton
                                text={'Program Plans'}
                                iconProps={{ iconName: 'ChromeBack' }}
                                onClick={_onReturnToProgramPlans}
                            />
                        </Stack>
                    </Stack>
                </Section>
            </ScrollablePane>
        </Stack>
    );
}

export default ProgramPlan;
