import { Middleware } from 'redux';

import { setActivityBarExpanded, setTheme } from 'state/slices/ui/ui.slice';
import appLocalStorage from 'utils/appLocalStorage';

import { RootState } from '../store';

const localStorageMiddleware: Middleware<
    unknown, // Most middleware do not modify the dispatch return value
    RootState
> = (storeApi) => (next) => (action) => {
    switch (action.type) {
        case setTheme.type: {
            appLocalStorage.theme = action.payload;
            break;
        }
        case setActivityBarExpanded.type: {
            appLocalStorage.activityBarExpanded = action.payload;
            break;
        }
    }
    next(action);
};

export default localStorageMiddleware;
