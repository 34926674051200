import { useState, FormEvent } from 'react';
import { TextField, ITextFieldProps } from '@fluentui/react';
import { StateType } from './StateOptions';

type Props = ITextFieldProps;

const zipRegex = /(\d{5})(\d)/;

const getValidZip = (value?: string) => {
    if (!value) return true;
    const rawValue = value ? value.split('-').join('') : value;
    return rawValue && (rawValue.length === 5 || rawValue.length === 9) && !isNaN(+rawValue) ? true : false;
};

export const stateTypeZipFormatLookup: Record<
    StateType,
    { allowedCharsRegex: RegExp; validRegex: RegExp; spacerChar: string; numberCharsToInsertSpacer: number; maxLength: number }
> = {
    [StateType.Canada]: {
        allowedCharsRegex: new RegExp(/^[A-Z.0-9. ]+$/),
        validRegex: new RegExp(/^[A-Z.0-9]{3}( )?[A-Z.0-9]{3}$/i),
        spacerChar: ' ',
        numberCharsToInsertSpacer: 3,
        maxLength: 7,
    },
    [StateType.US]: {
        allowedCharsRegex: new RegExp(/^[0-9.-]+$/),
        validRegex: new RegExp(/^\d{5}(?:[-\s]\d{4})?$/),
        spacerChar: '-',
        numberCharsToInsertSpacer: 5,
        maxLength: 10,
    },
};

export default function ZipCodeField(props: Props): JSX.Element {
    const [zipErrorMessage, setZipErrorMessage] = useState<string>();
    const [zipCode, setZipCode] = useState<string>();

    const onGetZipErrorMessage = (value?: string) => {
        const isValidZip = getValidZip(value) ? '' : 'Invalid Zip';
        return isValidZip;
    };

    const onZipChange = (ev: FormEvent<HTMLInputElement | HTMLTextAreaElement>, value?: string) => {
        const rawValue = value ? value.split('-').join('') : value;
        let newValue = rawValue ? rawValue : '';
        if (rawValue && rawValue.length > 5) newValue = rawValue.replace(zipRegex, '$1-$2');
        setZipCode(newValue);
    };

    const validateAndChangeZip = (): void => {
        const error = onGetZipErrorMessage(zipCode);
        if (error && zipCode) {
            setZipErrorMessage(error);
        } else {
            setZipErrorMessage(undefined);
        }
    };

    return (
        <TextField
            label="Zip Code"
            {...props}
            onChange={onZipChange}
            onBlur={validateAndChangeZip}
            errorMessage={zipErrorMessage}
            maxLength={10}
            value={zipCode}
        />
    );
}
