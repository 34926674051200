import { ActionReducerMapBuilder, PayloadAction } from '@reduxjs/toolkit';
import { IEncounter } from 'api/models/encounter.model';
import { LoadingStatus } from 'interfaces/loading-statuses';
import { isEqual } from 'lodash';
import { getAllEncounterReasons, refreshEncounterReasons, updateEncounter } from './encounters.actions';
import { EncounterState } from './encounters.slice';

const encountersReducers = {
    setEncountersSearch: (state: EncounterState, action: PayloadAction<string | undefined>) => {
        state.encountersSearch = action.payload;
    },
    cleanupEncountersSearch: (state: EncounterState) => {
        state.encountersSearch = undefined;
    },
};

export default encountersReducers;

export const encountersExtraReducers = (builder: ActionReducerMapBuilder<EncounterState>) => {
    return (
        builder
            // [GET] encounters ==================================
            .addCase(getAllEncounterReasons.pending, (state) => {
                state.loading = LoadingStatus.Pending;
            })
            .addCase(getAllEncounterReasons.fulfilled, (state, action) => {
                state.loading = LoadingStatus.Completed;
                state.data = action.payload;
            })
            .addCase(getAllEncounterReasons.rejected, (state) => {
                state.loading = LoadingStatus.Failed;
            })
            .addCase(refreshEncounterReasons.pending, (state) => {
                state.loading = LoadingStatus.Pending;
            })
            .addCase(refreshEncounterReasons.fulfilled, (state, action) => {
                state.loading = LoadingStatus.Completed;
                state.data = action.payload;
            })
            .addCase(refreshEncounterReasons.rejected, (state) => {
                state.loading = LoadingStatus.Failed;
            })
            .addCase(updateEncounter.pending, (state) => {
                state.saving = LoadingStatus.Pending;
            })
            .addCase(updateEncounter.fulfilled, (state, action) => {
                const encounter = action.payload;

                const locationId = encounter.id;
                if (state.data && !isEqual(encounter, state.data[locationId])) {
                    if (state.data[locationId]) {
                        (state.data[locationId] as IEncounter)._etag = encounter._etag;
                        (state.data[locationId] as IEncounter).modifiedOn = encounter.modifiedOn;
                        (state.data[locationId] as IEncounter).modifiedBy = encounter.modifiedBy;
                        (state.data[locationId] as IEncounter).departmentType = encounter.departmentType;
                    }
                }

                state.saving = LoadingStatus.Completed;
            })
            .addCase(updateEncounter.rejected, (state) => {
                state.saving = LoadingStatus.Failed;
            })
    );
};
