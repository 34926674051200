import { TText } from 'components';
import { Stack, Icon, TooltipHost, DirectionalHint, Link } from '@fluentui/react';
import { useHistory } from 'react-router';

type Props = {
    errorText: string;
    iconName: string;
    goBack?: boolean;
    goBackPath?: string;
    goBackTooltipContent?: string;
};

export default function ErrorPage({
    errorText,
    iconName,
    goBack = true,
    goBackPath = '/',
    goBackTooltipContent = 'Back to organization list',
}: Props) {
    const { push } = useHistory();
    return (
        <Stack horizontalAlign="center" verticalAlign="center" grow>
            <TText variant="xLargePlus" bold>
                <Icon iconName={iconName} />
            </TText>
            <TText variant="large">{errorText}</TText>
            {goBack && (
                <TooltipHost
                    directionalHint={DirectionalHint.bottomCenter}
                    content={goBackTooltipContent}
                >
                    <Link onClick={() => push(goBackPath)}>Go Back</Link>
                </TooltipHost>
            )}
        </Stack>
    );
}
