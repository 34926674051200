import {
    ConstrainMode,
    DefaultButton,
    MessageBar,
    PrimaryButton,
    ScrollablePane,
    ScrollbarVisibility,
    SearchBox,
    SelectionMode,
    Spinner,
    SpinnerSize,
    Stack,
} from '@fluentui/react';
import { useBoolean } from '@uifabric/react-hooks';
import { ActivityPage, Section, SortableDetailsList } from 'components';
import { useSelector } from 'hooks';
import { useSignalR } from 'hooks/signalR/useSignalr';
import { useFuseSearch } from 'hooks/useFuseSearch';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import AppointmentCancellationReasonModal from './AppointmentCancellationReasonModal';
import { getAppointmentCancellationsReasons, refreshAppointmentCancellationsReasons } from './state/actions';
import IAppointmentCancellationReason from './state/model';
import { appointmentCancellationReasonDataAsList, selectDentalDefaultACRObject } from './state/selectors';
import { getUserTasksByTarget } from 'state/slices/tasks/actions';
import { UserTaskTarget } from 'state/slices/tasks/model';
import { TaskType, selectUserTasksByTarget } from 'state/slices/tasks/selectors';

/**
 *:
 *
 * @export
 * @return {*}
 */

export default function AppointmentCancellationReasons() {
    const dispatch = useDispatch();
    const { tenantId } = useParams<{ tenantId: string }>();
    const [isOpen, { setTrue: openModal, setFalse: closeModal }] = useBoolean(false);
    const _cancellationReasons = useSelector(appointmentCancellationReasonDataAsList);

    const { registerSignalRMessage } = useSignalR();

    const defaultReason = useSelector(selectDentalDefaultACRObject);

    const { setSearch, search, results } = useFuseSearch({
        list: _cancellationReasons,
        fuseOptions: { threshold: 0.1, keys: ['displayName'] },
    });

    const task = useSelector(selectUserTasksByTarget);

    useEffect(() => {
        if (tenantId) dispatch(getAppointmentCancellationsReasons({ tenantId }));
    }, [tenantId, dispatch, getAppointmentCancellationsReasons]);

    useEffect(() => {
        dispatch(getUserTasksByTarget({ tenantId, target: UserTaskTarget.TenantSetup }));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const disableRefreshTask = task?.some((t) => t.type === TaskType.RefreshingAppointmentCancellationReasons);
    const enableConfirmDefaultButton = task?.some((t) => t.type === TaskType.SetRescheduleCancellationReasonId);

    return (
        <ActivityPage
            rightContent={
                <>
                    {enableConfirmDefaultButton && <DefaultButton text={'Confirm Default '} onClick={openModal} />}
                    {!disableRefreshTask ? (
                        <PrimaryButton
                            text={'Refresh Data'}
                            disabled={disableRefreshTask}
                            onClick={() => {
                                dispatch(refreshAppointmentCancellationsReasons({ tenantId }));
                                setSearch('');
                            }}
                        />
                    ) : (
                        <Spinner size={SpinnerSize.medium} />
                    )}

                    <SearchBox
                        placeholder="Search by display name"
                        value={search}
                        onChange={(ev, value) => setSearch(value ?? '')}
                    />
                </>
            }
            title="Appointment Cancellation Reasons"
            hideFooter
            hasPageHeader
        >
            <Stack tokens={{ childrenGap: 10 }} style={{ padding: 10, display: 'flex', flex: 1 }} grow>
                {!results.length && search && <MessageBar>No results found in search.</MessageBar>}
                <div style={{ position: 'relative', display: 'flex', flex: 1 }}>
                    <ScrollablePane scrollbarVisibility={ScrollbarVisibility.auto}>
                        <Section heading="Default Appointment Cancellation Reason ">
                            {defaultReason ? (
                                <SortableDetailsList<IAppointmentCancellationReason>
                                    columns={[
                                        {
                                            key: 'id',
                                            minWidth: 90,
                                            maxWidth: 150,
                                            fieldName: 'id',

                                            name: 'ID',
                                            onRender(item) {
                                                return <span>{item?.references.athenaOne}</span>;
                                            },
                                        },
                                        {
                                            key: 'displayName',
                                            minWidth: 90,

                                            fieldName: 'displayName',
                                            name: 'Display Name',
                                        },
                                    ]}
                                    selectionMode={SelectionMode.none}
                                    items={[defaultReason]}
                                    sortOnMount
                                    sortColumns={['displayName']}
                                    initialSortDirection={'asc'}
                                    constrainMode={ConstrainMode.horizontalConstrained}
                                />
                            ) : (
                                <MessageBar>No default Appointment Cancellation Reasons found.</MessageBar>
                            )}
                        </Section>
                        <Section heading="Appointment Cancellation Reasons">
                            <SortableDetailsList<IAppointmentCancellationReason>
                                columns={[
                                    {
                                        key: 'id',
                                        minWidth: 90,
                                        maxWidth: 150,
                                        fieldName: 'id',
                                        name: 'ID',

                                        onRender(item) {
                                            return <span>{item?.references.athenaOne}</span>;
                                        },
                                        getValueKey: (item) => {
                                            return parseInt(item?.references.athenaOne ?? '').toString() ?? '';
                                        },
                                    },
                                    {
                                        key: 'displayName',
                                        minWidth: 90,

                                        fieldName: 'displayName',
                                        name: 'Display Name',
                                    },
                                ]}
                                selectionMode={SelectionMode.none}
                                items={results.length ? results : _cancellationReasons.filter((x) => x.id !== defaultReason?.id)}
                                sortOnMount
                                sortColumns={['displayName']}
                                initialSortDirection={'asc'}
                                constrainMode={ConstrainMode.horizontalConstrained}
                            />
                        </Section>
                    </ScrollablePane>
                </div>
            </Stack>
            <AppointmentCancellationReasonModal isOpen={isOpen} onDismiss={closeModal} />
        </ActivityPage>
    );
}
