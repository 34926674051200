import ISlidingFeeProgram, {
    ICodeCategoryExclusion,
    ICodeExclusion,
    ICodeGroupExclusion,
    ISlidingFeePlan,
} from 'api/models/slidingFee.model';
import useSelector from 'hooks/useSelector';
import { RouteParams } from 'interfaces/route-params';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import {
    cleanupSelectedProgram,
    cleanupSelectedProgramPlan,
    createSlidingFeeProgram,
    getSlidingFeeProgramById,
    getSlidingFeePrograms,
    selectExpanded,
    selectIsCategoryExclusionPanelOpen,
    selectIsCodeExclusionPanelOpen,
    selectIsCodeGroupExclusionPanelOpen,
    selectIsProgramPanelOpen,
    selectIsProgramPlanPanelOpen,
    selectPrograms,
    selectProgramsAsList,
    selectProgramsHistory,
    selectProgramsLoading,
    selectSelectedCategoryExclusion,
    selectSelectedCodeExclusion,
    selectSelectedCodeGroupExclusion,
    selectSelectedProgram,
    selectSelectedProgramLoading,
    selectSelectedProgramPlan,
    setExpanded,
    setIsCategoryExclusionPanelOpen,
    setIsCodeExclusionPanelOpen,
    setIsGroupExclusionPanelOpen,
    setIsProgramPanelOpen,
    setIsProgramPlanPanelOpen,
    setSelectedCategoryExclusion,
    setSelectedCodeExclusion,
    setSelectedGroupExclusion,
    setSelectedProgramPlan,
    toggleShowProgramHistory,
    updateSelectedProgram,
    updateSlidingFeeProgram,
    updateSlidingFeeProgramPlan,
} from 'state/slices/sliding-fee/sliding-fee.slice';

function useSlidingFee() {
    const { tenantId } = useParams<RouteParams>();
    const dispatch = useDispatch();

    const _isExpanded = useSelector(selectExpanded);
    const _isProgramPanelOpen = useSelector(selectIsProgramPanelOpen);
    const _isProgramPlanPanelOpen = useSelector(selectIsProgramPlanPanelOpen);
    const _isCodeExclusionPanelOpen = useSelector(selectIsCodeExclusionPanelOpen);
    const _isCategoryExclusionPanelOpen = useSelector(selectIsCategoryExclusionPanelOpen);
    const _isCodeGroupExclusionPanelOpen = useSelector(selectIsCodeGroupExclusionPanelOpen);

    const _programs = useSelector(selectPrograms);
    const _programsLoading = useSelector(selectProgramsLoading);
    const _programsAsList = useSelector(selectProgramsAsList);
    const _showProgramsHistory = useSelector(selectProgramsHistory);
    const _selectedProgram = useSelector(selectSelectedProgram);
    const _selectedProgramLoading = useSelector(selectSelectedProgramLoading);
    const _selectedProgramPlan = useSelector(selectSelectedProgramPlan);
    const _selectedCategoryExclusion = useSelector(selectSelectedCategoryExclusion);
    const _selectedCodeExclusion = useSelector(selectSelectedCodeExclusion);
    const _selectedCodeGroupExclusion = useSelector(selectSelectedCodeGroupExclusion);

    const _toggleExpanded = useCallback(() => {
        dispatch(setExpanded(!_isExpanded));
    }, [dispatch, _isExpanded]);

    const _toggleIsProgramPanelOpen = useCallback(() => {
        dispatch(setIsProgramPanelOpen(!_isProgramPanelOpen));
    }, [dispatch, _isProgramPanelOpen]);

    const _toggleIsProgramPlanPanelOpen = useCallback(() => {
        dispatch(setIsProgramPlanPanelOpen(!_isProgramPlanPanelOpen));
    }, [dispatch, _isProgramPlanPanelOpen]);

    const _toggleIsCodeExclusionPanelOpen = useCallback(() => {
        dispatch(setIsCodeExclusionPanelOpen(!_isCodeExclusionPanelOpen));
    }, [dispatch, _isCodeExclusionPanelOpen]);

    const _toggleIsCodeGroupExclusionPanelOpen = useCallback(() => {
        dispatch(setIsGroupExclusionPanelOpen(!_isCodeGroupExclusionPanelOpen));
    }, [dispatch, _isCodeGroupExclusionPanelOpen]);

    const _toggleIsCategoryExclusionPanelOpen = useCallback(() => {
        dispatch(setIsCategoryExclusionPanelOpen(!_isCategoryExclusionPanelOpen));
    }, [dispatch, _isCategoryExclusionPanelOpen]);

    const _setSelectedProgramPlan = useCallback(
        (plan?: ISlidingFeePlan, isNew?: boolean, insertIndex?: number) => {
            dispatch(setSelectedProgramPlan({ plan, isNew, insertIndex }));
        },
        [dispatch],
    );

    const _setSelectedCategoryExclusion = useCallback(
        (exclusion?: ICodeCategoryExclusion) => {
            dispatch(setSelectedCategoryExclusion({ exclusion }));
        },
        [dispatch],
    );

    const _setSelectedCodeExclusion = useCallback(
        (exclusion?: ICodeExclusion) => {
            dispatch(setSelectedCodeExclusion({ exclusion }));
        },
        [dispatch],
    );
    const _setSelectedGroupExclusion = useCallback(
        (exclusion?: ICodeGroupExclusion) => {
            dispatch(setSelectedGroupExclusion({ exclusion }));
        },
        [dispatch],
    );

    const _cleanupSelectedProgram = useCallback(() => {
        dispatch(cleanupSelectedProgram());
    }, [dispatch]);

    const _cleanupSelectedProgramPlan = useCallback(() => {
        dispatch(cleanupSelectedProgramPlan());
    }, [dispatch]);

    const _updateSelectedProgram = useCallback(
        (program: ISlidingFeeProgram) => {
            dispatch(updateSelectedProgram({ program }));
        },
        [dispatch],
    );

    const _getSlidingFeeProgramById = useCallback(
        (programId: string) => {
            dispatch(getSlidingFeeProgramById({ tenantId, programId }));
        },
        [dispatch, tenantId],
    );

    const _getSlidingFeePrograms = useCallback(() => {
        dispatch(getSlidingFeePrograms({ tenantId }));
    }, [dispatch, tenantId]);

    const _createSlidingFeeProgram = useCallback(
        (program: ISlidingFeeProgram) => {
            dispatch(createSlidingFeeProgram({ tenantId, program }));
        },
        [dispatch, tenantId],
    );

    const _updateSlidingFeeProgram = useCallback(
        (program: ISlidingFeeProgram) => {
            dispatch(updateSlidingFeeProgram({ tenantId, program }));
        },
        [dispatch, tenantId],
    );

    const _updateSlidingFeeProgramPlan = useCallback(
        (program: ISlidingFeeProgram) => {
            dispatch(updateSlidingFeeProgramPlan(tenantId, program));
        },
        [dispatch, tenantId],
    );
    const _toggleShowProgramHistory = useCallback(() => {
        dispatch(toggleShowProgramHistory());
    }, [dispatch]);

    return {
        isExpanded: _isExpanded,
        isProgramPanelOpen: _isProgramPanelOpen,
        isProgramPlanPanelOpen: _isProgramPlanPanelOpen,
        isCodeExclusionPanelOpen: _isCodeExclusionPanelOpen,
        isCategoryExclusionPanelOpen: _isCategoryExclusionPanelOpen,
        isCodeGroupExclusionPanelOpen: _isCodeGroupExclusionPanelOpen,
        toggleShowProgramHistory: _toggleShowProgramHistory,
        programs: _programs,
        programsLoading: _programsLoading,
        programsAsList: _programsAsList,
        showProgramsHistory: _showProgramsHistory,
        selectedProgram: _selectedProgram,
        selectedProgramLoading: _selectedProgramLoading,
        selectedProgramPlan: _selectedProgramPlan,
        selectedCategoryExclusion: _selectedCategoryExclusion,
        selectedCodeExclusion: _selectedCodeExclusion,
        selectCodeGroupExclusion: _selectedCodeGroupExclusion,

        toggleExpanded: _toggleExpanded,
        toggleIsProgramPanelOpen: _toggleIsProgramPanelOpen,
        toggleIsProgramPlanPanelOpen: _toggleIsProgramPlanPanelOpen,
        toggleIsCodeExclusionPanelOpen: _toggleIsCodeExclusionPanelOpen,
        toggleIsCategoryExclusionPanelOpen: _toggleIsCategoryExclusionPanelOpen,
        toggleIsCodeGroupExclusionPanelOpen: _toggleIsCodeGroupExclusionPanelOpen,
        setSelectedProgramPlan: _setSelectedProgramPlan,
        setSelectedCategoryExclusion: _setSelectedCategoryExclusion,
        setSelectedCodeExclusion: _setSelectedCodeExclusion,
        setSelectedGroupExclusion: _setSelectedGroupExclusion,
        cleanupSelectedProgram: _cleanupSelectedProgram,
        cleanupSelectedProgramPlan: _cleanupSelectedProgramPlan,
        updateSelectedProgram: _updateSelectedProgram,
        getSlidingFeeProgramById: _getSlidingFeeProgramById,
        getSlidingFeePrograms: _getSlidingFeePrograms,
        createSlidingFeeProgram: _createSlidingFeeProgram,
        updateSlidingFeeProgram: _updateSlidingFeeProgram,
        updateSlidingFeeProgramPlan: _updateSlidingFeeProgramPlan,
    };
}

export default useSlidingFee;
