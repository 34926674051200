import { IComboBoxOption, IDropdownOption } from '@fluentui/react';
import { createAsyncThunk, createSelector, createSlice, Dictionary } from '@reduxjs/toolkit';
import membershipApi from 'api/membership.api';
import { IProcedure, IProcedureSearch } from 'api/models/procedure.model';
import { tr } from 'date-fns/locale';
import { LoadingStatus, LoadingStatuses } from 'interfaces/loading-statuses';
import { map } from 'lodash';
import { RootState } from 'state/store';
import { isDateBetween } from 'utils/isDateBetween';

type ProcedureState = {
    loading: LoadingStatuses;
    searchLoading: LoadingStatuses;
    procedureSearchData?: Dictionary<IProcedure>;
    startSearchData?: Dictionary<IProcedure>;
    endSearchData?: Dictionary<IProcedure>;
    data: Dictionary<IProcedure>;
    toggleActiveFeeSchedule: boolean;
};

const initialState: ProcedureState = {
    loading: LoadingStatus.Idle,
    searchLoading: LoadingStatus.Idle,
    data: {},
    toggleActiveFeeSchedule: true,
};

export const proceduresSearch = createAsyncThunk<Dictionary<IProcedure>, { query: IProcedureSearch }>(
    'proceduresSearch',
    async ({ query }) => {
        const res = await membershipApi.proceduresSearch({ ...query });
        return res.data;
    },
);

export const getProcedures = createAsyncThunk<Dictionary<IProcedure>>('getProcedures', async () => {
    const res = await membershipApi.getProcedures();
    return res.data;
});

const proceduresSlice = createSlice({
    name: 'procedures',
    initialState,
    reducers: {
        clearProcedures(state) {
            state.procedureSearchData = undefined;
            state.data = {};
            state.searchLoading = LoadingStatus.Idle;
        },
        toggleFeeScheduleProcedures: (state: ProcedureState) => {
            state.toggleActiveFeeSchedule = !state.toggleActiveFeeSchedule;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(proceduresSearch.pending, (state) => {
                state.searchLoading = LoadingStatus.Pending;
            })
            .addCase(proceduresSearch.fulfilled, (state, action) => {
                state.searchLoading = LoadingStatus.Completed;
                state.procedureSearchData = action.payload;
                state.startSearchData = action.payload;
                state.endSearchData = action.payload;
            })
            .addCase(proceduresSearch.rejected, (state) => {
                state.searchLoading = LoadingStatus.Failed;
            })
            .addCase(getProcedures.pending, (state) => {
                state.loading = LoadingStatus.Pending;
            })
            .addCase(getProcedures.fulfilled, (state, action) => {
                state.loading = LoadingStatus.Completed;
                state.data = action.payload;
            })
            .addCase(getProcedures.rejected, (state) => {
                state.loading = LoadingStatus.Failed;
            });
    },
});

const { reducer, actions } = proceduresSlice;

export const { clearProcedures, toggleFeeScheduleProcedures } = actions;

export const selectProcedureState = (state: RootState) => state.procedures;

export const selectProceduresSearchData = (state: RootState): Dictionary<IProcedure> | undefined =>
    state.procedures.procedureSearchData;
export const selectProceduresList = (state: RootState): Dictionary<IProcedure> | undefined => state.procedures.data;

export const isActiveFeeSchedule = (state: RootState): boolean => state.procedures.toggleActiveFeeSchedule;

export const selectProceduresDataAsList = createSelector(
    selectProcedureState,
    (state) => map(state.data, (item) => item).filter((item) => !item?.isDeleted) as IProcedure[],
);

export const selectProceduresOptions = createSelector(selectProceduresDataAsList, (procedures) =>
    procedures.map((dx) => {
        const optionText = `${dx.code} - ${dx.displayName}`;
        const option = { key: dx.id, text: optionText, title: optionText } as IDropdownOption;
        return option;
    }),
);

export const toggleActiveFeeScheduleList = createSelector(
    selectProceduresDataAsList,
    isActiveFeeSchedule,
    (procedures, isActive) => {
        return isActive
            ? procedures.filter((item) =>
                  isDateBetween({
                      dateToCheck: new Date().toString(),
                      start: item.effectiveDate,
                      end: item.endDate,
                  }),
              )
            : procedures;
    },
);

export const selectProceduresSearchDataAsList = createSelector(selectProceduresSearchData, (data) => {
    if (data) return Object.keys(data).map((key) => data[key]);
    return [];
});

export const proceduresOptionsList = createSelector(
    selectProceduresDataAsList,
    (proceduresList) =>
        proceduresList.map((dx) => ({
            key: dx?.code ?? '',
            text: `${dx.code} - ${
                dx.displayName ? (dx.displayName.length > 35 ? `${dx.displayName.slice(0, 35)}...` : dx.description) : ''
            }`,
            title: dx.code ? dx.code : '',
        })) as IComboBoxOption[],
);

export const selectProceduresData = createSelector(selectProcedureState, (state) => state.data);

export default reducer;
