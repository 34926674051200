import { createSlice, PayloadAction, createSelector, createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from 'state/store';
import { filter, orderBy } from 'lodash';
import ITenantAccount, { ITenantData } from 'api/models/tenantAccount.model';
import { getAccount } from '../account.slice';

const initialState: TenantsState = {
    loading: 'idle',
};

const tenantsSlice = createSlice({
    name: 'tenants',
    initialState,
    reducers: {
        searchTenants(state, action: PayloadAction<string | undefined>) {
            const search = action.payload;
            if (search && state.data)
                state.searchResults = filter(state.data, function (tenant) {
                    return tenant.displayName.toLowerCase().indexOf(search?.toLowerCase()) > -1;
                });
        },
        cleanupTenantsSearch(state) {
            state.searchResults = [];
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getAccount.pending, (state) => {
                state.loading = 'pending';
            })
            .addCase(getAccount.fulfilled, (state, action) => {
                state.loading = 'completed';
                state.errors = null;
                state.data = action.payload.data.tenants;
            })
            .addCase(getAccount.rejected, (state, action) => {
                state.loading = 'failed';
                state.errors = action.payload;
            });
    },
});

export const { searchTenants, cleanupTenantsSearch } = tenantsSlice.actions;

export default tenantsSlice.reducer;

export const tenantsState = (state: RootState) => state.tenants;

export const tenantsData = createSelector(tenantsState, (state) => state.data);

export const tenantData = (state: RootState, id: string) =>
    tenantsData(state) ? ((tenantsData(state) as any)[id] as ITenantAccount) : undefined;

export const tenants = createSelector(tenantsState, (state) => {
    if (state.data) {
        const tenantsList: ITenantAccount[] = Object.keys(state.data)
            .map((tenantId) => (state.data ? state.data[tenantId] : undefined))
            .filter((tenant) => tenant !== undefined) as ITenantAccount[];

        const tenants = orderBy(
            state.searchResults && state.searchResults.length ? state.searchResults : tenantsList,
            ['displayName'],
            'asc',
        );

        return tenants;
    }

    return [];
});

export const tenantsLoading = createSelector(tenantsState, (state) => state.loading);

type TenantsState = {
    data?: ITenantData;
    searchResults?: ITenantAccount[];
    errors?: any;
    userHasAccess?: boolean;
    loading: LoadingStatus;
};
