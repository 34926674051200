import ITenant from 'api/models/tenant.model';
import { ITenantSubscriptions } from 'api/models/tenantSubscription.model';
import { LoadingStatus } from 'interfaces/loading-statuses';
import { ITenantConfig } from './model';

export interface ITenantState {
    data?: ITenant;
    loadingTenant: LoadingStatus;

    config?: ITenantConfig;
    loadingConfig: LoadingStatus;

    subscriptions?: ITenantSubscriptions;
    loadingSubscriptions: LoadingStatus;
}

export const initialState: ITenantState = {
    loadingTenant: LoadingStatus.Idle,
    loadingSubscriptions: LoadingStatus.Idle,
    loadingConfig: LoadingStatus.Idle,
};
