import { Provider } from 'react-redux';
import App from 'pages/App';
import store, { history } from './state/store';
import { ConnectedRouter } from 'connected-react-router';
import { ThemeInitializer, Loading } from 'components';
import { msalProvider } from 'api/auth/authProvider';
import ErrorPage from 'pages/components/ErrorPage';
import {
    MsalAuthenticationTemplate,
    MsalProvider,
    useAccount,
    useMsalAuthentication,
} from '@azure/msal-react';
import { AccountInfo, EventType, InteractionRequiredAuthError, InteractionType } from '@azure/msal-browser';
import { MembershipApiConfig } from 'api/membership.api';
import { useEffect } from 'react';
import { Switch, Route, useHistory, useLocation } from 'react-router-dom';
import { setDomainHint, fetchDomainHint } from 'utils/domainHint';
import isDev from 'utils/isDev';

const tenant = `${isDev ? process.env.REACT_APP_TenantName : '#{TenantName}#'}`;
msalProvider.initialize().then(() => {
    // If multiple accounts are signed in, this will set the active account to the account that matches the tenant
    if (!activeAccountMatchesTenant(msalProvider.getActiveAccount()) && msalProvider.getAllAccounts().length > 0) {
        const accountByTenant = msalProvider.getAllAccounts().find((account) => account.environment.includes(tenant));
        if (accountByTenant) {
            msalProvider.setActiveAccount(accountByTenant);
        }
    }

    // Optional - This will update account state if a user signs in from another tab or window
    msalProvider.enableAccountStorageEvents();

    // On successful login, set the active account to the account that was used to log in
    const callbackId = msalProvider.addEventCallback((message) => {
        if (message.eventType === EventType.LOGIN_SUCCESS) {
            msalProvider.setActiveAccount((message.payload as any).account);
        }
    });
});

function activeAccountMatchesTenant(account: AccountInfo | null) {
    if (!account) return false;
    return account.environment.includes(tenant);
}

function Root() {
    return (
        <Provider store={store}>
            <ThemeInitializer>
                <ConnectedRouter history={history}>
                    <MsalProvider instance={msalProvider}>
                        <Switch>
                            <Route path="/login" exact component={LoginReRoute} />
                            <Route path="/" component={() => <AppAuthenticationTemplates />} />
                        </Switch>
                    </MsalProvider>
                </ConnectedRouter>
            </ThemeInitializer>
        </Provider>
    );
}

export default Root;

function AppAuthenticationTemplates() {
    const { login, error } = useMsalAuthentication(InteractionType.Redirect);
    useEffect(() => {
        if (error instanceof InteractionRequiredAuthError) {
            login(InteractionType.Popup, { scopes: MembershipApiConfig.scopes });
        }
    }, [error, login]);

    return (
        <MsalAuthenticationTemplate
            interactionType={InteractionType.Redirect}
            loadingComponent={() => <Loading spinnerText="Authenticating..." />}
            authenticationRequest={{ scopes: MembershipApiConfig.scopes }}
            errorComponent={() => (
                <ErrorPage errorText="You do not have access to this application" iconName="Error" goBack={false} />
            )}
        >
            <App />
        </MsalAuthenticationTemplate>
    );
}

function LoginReRoute() {
    const account = useAccount();

    const { search } = useLocation();
    const domain_hint = new URLSearchParams(search);
    setDomainHint(domain_hint.get('domain_hint'));

    return (
        <MsalAuthenticationTemplate
            interactionType={InteractionType.Redirect}
            loadingComponent={() => <Loading spinnerText="Logging In..." />}
            authenticationRequest={{
                scopes: MembershipApiConfig.scopes,
                domainHint: fetchDomainHint(),
                account: account ?? undefined,
            }}
        >
            <Redirect />
        </MsalAuthenticationTemplate>
    );
}

function Redirect() {
    const { push } = useHistory();
    useEffect(() => {
        push('/');
    }, []);
    return <Loading spinnerText="Redirecting..." />;
}

