import React, { PropsWithChildren, CSSProperties, ReactNode } from 'react';
import { Stack, StackItem, styled, ITheme, IStyleFunctionOrObject } from '@fluentui/react';
import SubSectionHeader from './SubSectionHeader';

type Props = {
    title: string;
    id?: string | number;
    style?: CSSProperties;
    styleContent?: CSSProperties;
    sectionBodyStyle?: CSSProperties;
    headerStyle?: CSSProperties;
    headingCenterContent?: ReactNode;
    headingEndContent?: ReactNode;
    labelStyle?: CSSProperties;
    preBodyContent?: ReactNode;
    footer?: ReactNode;
    theme?: ITheme;
    styles?: IStyleFunctionOrObject<any, any>;
    isRequired?: boolean;
    grow?: boolean;
};

const SubSection = (props: PropsWithChildren<Props>) => {
    const sectionBodyStyle: React.CSSProperties = {
        paddingTop: 10,
        paddingBottom: 10,
        width: '100%',
        ...props.sectionBodyStyle,
    };

    return (
        <Stack
            className="section"
            id={props.id ? `'${props.id}` : ''}
            style={{ ...props.style }}
            grow={props.grow}
        >
            <SubSectionHeader
                text={props.title}
                headingCenterContent={props.headingCenterContent}
                headingEndContent={props.headingEndContent}
                headerStyle={props.headerStyle}
                labelStyle={props.labelStyle}
                isRequired={props.isRequired}
            />
            {props.preBodyContent || props.children || props.footer ? (
                <Stack
                    style={sectionBodyStyle}
                    tokens={{ childrenGap: 10 }}
                    className="section__body"
                    grow={props.grow}
                >
                    {props.preBodyContent && <StackItem>{props.preBodyContent}</StackItem>}
                    <StackItem
                        className="section__content"
                        style={{ ...props.styleContent }}
                        disableShrink
                    >
                        {props.children}
                    </StackItem>
                    {props.footer && <StackItem>{props.footer}</StackItem>}
                </Stack>
            ) : null}
        </Stack>
    );
};

export default styled<Props, any, any>(SubSection, {});
