import { DefaultButton, PrimaryButton, Spinner, SpinnerSize } from '@fluentui/react';
import { useBoolean } from '@uifabric/react-hooks';
import ILocationOfCare from 'api/models/locationsOfCare.model';
import { ActivityPage, Field } from 'components';
import { useSelector } from 'hooks';
import useLocationsOfCare from 'hooks/store/useLocationsOfCare';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getUserTasksByTarget } from 'state/slices/tasks/actions';
import { UserTaskTarget } from 'state/slices/tasks/model';
import { TaskType, selectUserTasksByTarget } from 'state/slices/tasks/selectors';
import LocationOfCareMappingModal from './components/LocationOfCareMappingModal';
import LocationsOfCareList from './components/LocationsOfCareList';

// windows key + . for emoji 💩
//
export default function LocationsOfCare() {
    const {
        getAllLocationsOfCare,
        refreshLocationsOfCare,
        search,
        setSearchResults,
        setSearch,
        locationsOfCareAsList,
        locationsOfCareSearchResults,
        loading,
    } = useLocationsOfCare();

    const onChangeSearch = (ev?: React.ChangeEvent<HTMLInputElement>, value?: string | undefined) => {
        setSearch(value);
    };

    const [isOpen, { setTrue: openModal, setFalse: closeModal }] = useBoolean(false);

    const dispatch = useDispatch();

    const task = useSelector(selectUserTasksByTarget);

    const { tenantId } = useParams<{ tenantId: string }>();

    useEffect(() => {
        dispatch(getUserTasksByTarget({ tenantId, target: UserTaskTarget.TenantSetup }));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch]);

    const disableRefreshTask = task?.some((t) => t.type === TaskType.RefreshingLocationOfCare);
    const enableDoneMappingButton = task?.some((t) => t.type === TaskType.MappingDentalLocationOfCare);

    useEffect(() => {
        getAllLocationsOfCare();
    }, [getAllLocationsOfCare]);

    return (
        <ActivityPage
            rightContent={
                <>
                    {enableDoneMappingButton && <DefaultButton text={'Done Mapping'} onClick={openModal} />}
                    {!disableRefreshTask ? (
                        <PrimaryButton
                            text={'Refresh Data'}
                            onClick={(res) => {
                                refreshLocationsOfCare();
                                setSearch(undefined);

                                dispatch(getUserTasksByTarget({ tenantId, target: UserTaskTarget.TenantSetup }));
                            }}
                        />
                    ) : (
                        <Spinner size={SpinnerSize.medium} />
                    )}
                    <Field.FuseSearch<ILocationOfCare>
                        style={{ minWidth: 230 }}
                        placeholder="Search by name or description"
                        items={locationsOfCareAsList}
                        resultItems={locationsOfCareSearchResults}
                        loading={loading}
                        onSearch={setSearchResults}
                        onChange={onChangeSearch}
                        value={search ? search : ''}
                        options={{
                            threshold: 0.1,
                            keys: ['displayName', 'description', 'departmentType'],
                        }}
                    />
                </>
            }
            title="Locations Of Care"
            hideFooter
            hasPageHeader
        >
            <LocationsOfCareList />
            <LocationOfCareMappingModal isOpen={isOpen} onDismiss={closeModal} />
        </ActivityPage>
    );
}
