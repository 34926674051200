import { Stack, TextField } from '@fluentui/react';

import { useDispatch } from 'react-redux';
import { updateDunningRuleMessageById } from '../state/slice';
import IStatementConfig, { IDunningRule } from '../state/models';

type IDunningMessageProps = {
    rule: IDunningRule;
    label: string;

    disabled?: boolean;
};

const DunningMessageTextField = ({ label, rule, disabled }: IDunningMessageProps) => {
    const dispatch = useDispatch();

    const _onValueChange = (ev: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
        dispatch(updateDunningRuleMessageById({ message: rule, value: newValue ?? '' }));
    };

    return (
        <Stack styles={{ root: { width: 600 } }}>
            <TextField
                label={label}
                value={rule.dunningMessage}
                description={`Used ${rule.dunningMessage.length} of 80 characters `}
                multiline
                required
                maxLength={80}
                disabled={disabled}
                rows={2}
                onChange={_onValueChange}
            />
        </Stack>
    );
};

export default DunningMessageTextField;
