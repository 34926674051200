import { AnyAction, combineReducers } from 'redux';
import { connectRouter, routerMiddleware } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import thunkMiddleware, { ThunkAction } from 'redux-thunk';
import { ActionReducerMapBuilder, configureStore } from '@reduxjs/toolkit';
import * as reducersFromSlices from './slices';
import { useDispatch } from 'react-redux';
import localStorageMiddleware from './middleware/local-storage.middleware';

export const history = createBrowserHistory();

const middleware = [thunkMiddleware, localStorageMiddleware, routerMiddleware(history)];

const appReducer = combineReducers({
    ...reducersFromSlices,
    router: connectRouter(history),
});

export const rootReducer = (state: any, action: any) => {
    // We can clear out state here
    //if (action.type === '@@router/LOCATION_CHANGE') {
    //console.log(action.type);
    //state = undefined;
    //}

    return appReducer(state, action);
};

const store = configureStore({
    reducer: rootReducer,
    middleware,
    devTools: process.env.NODE_ENV === 'development',
});

export type RootState = ReturnType<typeof rootReducer>;
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, AnyAction>;
export type ExtraReducer<State> = (builder: ActionReducerMapBuilder<State>) => ActionReducerMapBuilder<State>;
export type CreateAsyncThunkOptions<RejectValueType = string> = {
    state: RootState;
    dispatch: AppDispatch;
    rejectValue: RejectValueType;
};

export default store;
