import { Label, Spinner, SpinnerSize, Stack, Text, useTheme } from '@fluentui/react';
import { useBoolean } from '@uifabric/react-hooks';
import { ITenantUser } from 'api/models/tenantAccount.model';
import { ActivityPage, ContentPane } from 'components';
import BreadcrumbMenu from 'components/Breadcrumb/BreadcrumbMenu';
import useUsers from 'hooks/store/useUsers';
import { LoadingStatus } from 'interfaces/loading-statuses';
import { useEffect, useState } from 'react';
import UserFilter from './components/UserFilter';
import EditUserPanel from './EditUserPanel';
import UsersDetailsList from './UsersDetailsList';
import { useFuseSearch } from 'hooks/useFuseSearch';

const blankUser: ITenantUser = {
    id: '',
    isDeleted: false,
    permissions: [],
    identity: {
        id: '',
        email: '',
        firstName: '',
        lastName: '',
    },
};

export default function Users() {
    const { getUsers, loading, selectFilteredUserList, setSelectedUser, cleanupUsersState } = useUsers();
    const theme = useTheme();
    const { setSearch, search, results } = useFuseSearch({
        list: selectFilteredUserList,
        fuseOptions: {
            threshold: 0.0,
            keys: ['identity.firstName', 'identity.lastName', 'identity.email'],
            ignoreLocation: true,
        },
    });

    const [isOpen, { setTrue: setIsOpen, setFalse: setIsClosed }] = useBoolean(false);

    useEffect(() => {
        getUsers();
        return () => {
            cleanupUsersState();
        };
    }, [getUsers, cleanupUsersState]);

    const _createNewUser = () => {
        setIsOpen();
        setSelectedUser(blankUser);
    };

    return (
        <ActivityPage
            hasPageHeader={true}
            hideFooter={true}
            title={
                <Stack horizontal verticalAlign="center">
                    <BreadcrumbMenu />
                    <Text variant="xLarge">({results && search ? results.length : selectFilteredUserList.length})</Text>
                </Stack>
            }
            mainButtons={[
                {
                    type: 'PrimaryButton',
                    text: 'Create User',
                    iconProps: { iconName: 'Add' },
                    onClick: _createNewUser,
                },
            ]}
        >
            <ContentPane style={{ padding: 10, background: theme?.palette.neutralLighterAlt }}>
                <UserFilter placeholder="Search Users" searchTerm={search} onSearchChange={setSearch} />

                <UsersDetailsList setPanelOpen={setIsOpen} searchTerm={search} results={results} />
                {loading === LoadingStatus.Pending && (
                    <Spinner
                        style={{ marginBottom: 12 }}
                        size={SpinnerSize.large}
                        label="Loading users..."
                        labelPosition="right"
                    />
                )}
            </ContentPane>

            <EditUserPanel isOpen={isOpen} closePanel={setIsClosed} />
        </ActivityPage>
    );
}
