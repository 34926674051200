import { IAppointmentCancellationReasons } from './model';
import { LoadingStatus } from 'interfaces/loading-statuses';

export type AppointmentCancellationReasonState = {
    data: IAppointmentCancellationReasons;
    loading: LoadingStatus;
};

const initialAppointmentCancellationReasonState: AppointmentCancellationReasonState = {
    data: {},
    loading: LoadingStatus.Idle,
};

export default initialAppointmentCancellationReasonState;
