import {
    Checkbox,
    DefaultButton,
    MessageBar,
    MessageBarType,
    Panel,
    PanelType,
    PrimaryButton,
    Stack,
    Text,
    TextField,
    Toggle,
} from '@fluentui/react';
import { ICodeGroupExclusion, ISlidingFeePlan } from 'api/models/slidingFee.model';
import { Field } from 'components';
import useProcedure from 'hooks/store/useProcedure';
import useSlidingFee from 'hooks/store/useSlidingFee';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { selectRangeExclusionIsValid, selectSelectedCodeGroupExclusionIsNew } from 'state/slices/sliding-fee/sliding-fee.slice';
import { exclusionCalculationTypeOptions } from './ProgramPlan';

type Props = {
    programPlan?: ISlidingFeePlan;
    onProgramPlanPropertyChange: (key: keyof ISlidingFeePlan, value: string | number | ICodeGroupExclusion[] | boolean) => void;
};

function GroupExclusionPanel(props: Props): JSX.Element {
    const { programPlan, onProgramPlanPropertyChange } = props;
    const {
        selectCodeGroupExclusion,
        toggleIsCodeGroupExclusionPanelOpen,
        isCodeGroupExclusionPanelOpen,
        setSelectedGroupExclusion,
    } = useSlidingFee();
    const { proceduresOptionsList, getProcedures } = useProcedure();
    const exclusion = selectCodeGroupExclusion.data;
    const isNewExclusion = useSelector(selectSelectedCodeGroupExclusionIsNew);

    const onExclusionPropertyChange = (key: keyof ICodeGroupExclusion, value: string | number | boolean | undefined) => {
        setSelectedGroupExclusion({ ...exclusion, [key]: value });
    };

    const _onDismiss = () => {
        setSelectedGroupExclusion(undefined);
        toggleIsCodeGroupExclusionPanelOpen();
    };

    useEffect(() => {
        getProcedures();
    }, []);

    const exclusionValidationErrors = useSelector(selectRangeExclusionIsValid);

    const disableSaveButton =
        (exclusionValidationErrors && exclusionValidationErrors.length > 0) ||
        !exclusion?.startCode ||
        !exclusion?.endCode ||
        !exclusion?.effectiveDate;

    const _onRenderFooterContent = () => {
        return (
            <Stack tokens={{ childrenGap: 12 }}>
                {exclusionValidationErrors?.length && (
                    <MessageBar messageBarType={MessageBarType.blocked}>
                        <ul style={{ margin: 0 }}>
                            {exclusionValidationErrors.map((error, index) => (
                                <li key={index}>{error}</li>
                            ))}
                        </ul>
                    </MessageBar>
                )}
                <Stack horizontal grow tokens={{ childrenGap: 10 }}>
                    <PrimaryButton
                        text="Save Exclusion"
                        disabled={disableSaveButton}
                        onClick={() => {
                            if (programPlan && exclusion) {
                                const programPlanExclusions = programPlan?.codeRangeExclusions ?? [];
                                if (!isNewExclusion) {
                                    //update
                                    const newProgramPlanExclusions = [...programPlanExclusions];
                                    const indexOfExclusion = newProgramPlanExclusions.findIndex((ex) => exclusion?.id === ex.id);

                                    if (indexOfExclusion > -1) {
                                        newProgramPlanExclusions[indexOfExclusion] = exclusion;
                                        onProgramPlanPropertyChange('codeRangeExclusions', newProgramPlanExclusions);
                                    }
                                } else {
                                    // add
                                    onProgramPlanPropertyChange('codeRangeExclusions', [...programPlanExclusions, exclusion]);
                                }
                                setSelectedGroupExclusion(undefined);
                                toggleIsCodeGroupExclusionPanelOpen();
                            }
                        }}
                    />
                    <DefaultButton
                        text="Cancel"
                        onClick={() => {
                            setSelectedGroupExclusion(undefined);
                            toggleIsCodeGroupExclusionPanelOpen();
                        }}
                    />
                </Stack>
            </Stack>
        );
    };

    const wrapperStyle = {
        border: '1px solid #ccc',
        padding: '5px 5px 10px 10px',
    };

    function onCheckboxChange(ev?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean) {
        if (checked === true && exclusion) {
            setSelectedGroupExclusion({
                ...exclusion,

                calculationType: undefined,
                absoluteFee: undefined,
                feePercentage: undefined,
                labFee: undefined,
                doesNotSlide: true,
            });
        } else {
            setSelectedGroupExclusion({
                ...exclusion,
                doesNotSlide: false,
            });
        }
    }

    return (
        <Panel
            isOpen={isCodeGroupExclusionPanelOpen}
            onDismiss={_onDismiss}
            headerText={isNewExclusion ? 'Create Range Exclusion' : 'Edit Range Exclusion'}
            type={PanelType.custom}
            customWidth="800px"
            onRenderFooterContent={_onRenderFooterContent}
            isFooterAtBottom
        >
            <Stack tokens={{ childrenGap: 10 }} grow>
                <Stack tokens={{ childrenGap: 10 }} horizontal verticalAlign="end" grow wrap>
                    <Stack.Item grow>
                        <Field.SearchCombo
                            label="Start Code"
                            options={proceduresOptionsList}
                            selectedKey={exclusion?.startCode}
                            required
                            onChange={(event, option, index) => {
                                if (option) {
                                    onExclusionPropertyChange('startCode', option.key);
                                }
                            }}
                        />
                    </Stack.Item>
                    <Stack.Item grow>
                        <Field.SearchCombo
                            label="End Code"
                            options={proceduresOptionsList}
                            selectedKey={exclusion?.endCode}
                            required
                            onChange={(event, option, index) => option && onExclusionPropertyChange('endCode', option.key)}
                        />
                    </Stack.Item>
                </Stack>
                <Stack tokens={{ childrenGap: 10 }} horizontal verticalAlign="end" grow>
                    <Stack.Item grow>
                        <Field.Date
                            value={exclusion?.effectiveDate ?? ''}
                            label="Effective Date"
                            onChange={(event, newValue) => onExclusionPropertyChange('effectiveDate', newValue ? newValue : '')}
                        />
                    </Stack.Item>
                    <Stack.Item grow>
                        <Field.Date
                            value={exclusion?.expirationDate ?? ''}
                            label="Expiration Date"
                            onChange={(event, newValue) => onExclusionPropertyChange('expirationDate', newValue ? newValue : '')}
                        />
                    </Stack.Item>
                </Stack>
                <Stack tokens={{ childrenGap: 10 }} horizontal>
                    <Stack.Item>
                        <Field.Dropdown
                            options={exclusionCalculationTypeOptions}
                            label="Calculation Type"
                            placeholder="Select"
                            disabled={exclusion?.doesNotSlide}
                            selectedKey={exclusion?.calculationType ? exclusion?.calculationType : null}
                            style={{ minWidth: 215 }}
                            onChange={(event, option, index) =>
                                option && onExclusionPropertyChange('calculationType', option.key)
                            }
                        />
                    </Stack.Item>
                    <Stack verticalAlign="end">
                        <Checkbox
                            label="Do not Slide"
                            checked={exclusion?.doesNotSlide}
                            onChange={(event, checked) => {
                                onCheckboxChange(event, checked);
                            }}
                        />
                    </Stack>
                </Stack>
                <Stack
                    style={
                        exclusion?.calculationType !== '' || exclusion.calculationType === undefined
                            ? wrapperStyle
                            : {
                                  border: '',
                                  padding: '',
                              }
                    }
                    tokens={{ childrenGap: 5 }}
                    grow
                >
                    {exclusion?.calculationType === 'flatFeeOnly' && (
                        <Stack horizontal horizontalAlign="space-between">
                            <Stack>
                                <Field.Currency
                                    value={exclusion?.feePercentage ?? 0}
                                    prefix="$"
                                    label="One Flat Fee Per Group"
                                    autoComplete="off"
                                    onChange={(event, newValue) =>
                                        onExclusionPropertyChange('feePercentage', newValue ? newValue : '')
                                    }
                                    disabled={exclusion?.doesNotSlide}
                                />
                            </Stack>
                            <Stack tokens={{ childrenGap: 5 }} verticalAlign="center">
                                <Text variant="medium"> {"Subject to plan's minimum and maximum rules"} </Text>
                            </Stack>
                        </Stack>
                    )}
                    {exclusion?.calculationType === 'flatFeePerCode' && (
                        <Stack horizontal horizontalAlign="space-between">
                            <Stack>
                                <Field.Currency
                                    value={exclusion?.feePercentage ?? 0}
                                    prefix="$"
                                    label="One Flat Fee Per Code"
                                    autoComplete="off"
                                    onChange={(event, newValue) =>
                                        onExclusionPropertyChange('feePercentage', newValue ? newValue : '')
                                    }
                                    disabled={exclusion?.doesNotSlide}
                                />
                            </Stack>
                            <Stack tokens={{ childrenGap: 5 }} verticalAlign="center">
                                <Text variant="medium"> {"Subject to plan's minimum and maximum rules"} </Text>
                            </Stack>
                        </Stack>
                    )}
                    {exclusion?.calculationType === 'percentagePerCode' && (
                        <Stack horizontal horizontalAlign="space-between">
                            <Stack>
                                <TextField
                                    value={exclusion?.feePercentage?.toString() ?? ''}
                                    prefix="%"
                                    label="Pt Percentage"
                                    autoComplete="off"
                                    onChange={(event, newValue) =>
                                        onExclusionPropertyChange('feePercentage', newValue ? parseInt(newValue) : '')
                                    }
                                    disabled={exclusion?.doesNotSlide}
                                />
                            </Stack>
                            <Stack tokens={{ childrenGap: 5 }} verticalAlign="center">
                                <Text variant="medium"> {"Subject to plan's minimum and maximum rules"} </Text>
                            </Stack>
                        </Stack>
                    )}
                </Stack>
                <Stack>
                    <Stack style={wrapperStyle} horizontal horizontalAlign="space-between">
                        <Stack>
                            <Field.Currency
                                value={exclusion?.labFee ?? 0}
                                prefix="$"
                                label="Lab Fee Per Code"
                                autoComplete="off"
                                onChange={(event, newValue) => onExclusionPropertyChange('labFee', newValue ? newValue : '')}
                                disabled={exclusion?.doesNotSlide}
                            />
                            <Field.Currency
                                value={exclusion?.absoluteFee ?? 0}
                                prefix="$"
                                label="Absolute Fee Per Code"
                                autoComplete="off"
                                onChange={(event, newValue) => onExclusionPropertyChange('absoluteFee', newValue ? newValue : '')}
                                disabled={exclusion?.doesNotSlide}
                            />
                        </Stack>
                        <Stack tokens={{ childrenGap: 5 }} verticalAlign="center">
                            <Text variant="medium"> {"In addition to plan's minimum and maximum rules"} </Text>
                            <Text variant="medium"> {"In addition to plan's flat rate"} </Text>
                        </Stack>
                    </Stack>
                </Stack>

                {!isNewExclusion && (
                    <Stack>
                        <Toggle
                            checked={exclusion?.isDeleted}
                            label="Deactivated"
                            inlineLabel
                            onChange={(ev, value) => onExclusionPropertyChange('isDeleted', !exclusion?.isDeleted)}
                        />
                    </Stack>
                )}
            </Stack>
        </Panel>
    );
}

export default GroupExclusionPanel;
