import { ISearchComboFieldProps } from './SearchComboField';
import states from './StateOptions';
import { Field } from 'components';
import { IComboBoxOption } from '@fluentui/react';

export interface IStateFieldProps extends Omit<ISearchComboFieldProps, 'options'> {
    options?: IComboBoxOption[];
}

export default function StateField(props: IStateFieldProps): JSX.Element {
    return <Field.SearchCombo label="State" placeholder="(Select State)" options={states} {...props} />;
}
