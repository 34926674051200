import { createAsyncThunk, Dictionary } from '@reduxjs/toolkit';
import membershipApi from 'api/membership.api';
import { ITenantUser } from 'api/models/tenantAccount.model';

export const getUsers = createAsyncThunk<
    Dictionary<ITenantUser>,
    {
        tenantId: string;
    }
>('users/getUsers', async ({ tenantId }) => {
    const users = await membershipApi.getTenantUsers(tenantId);
    return users.data;
});

export const getUserById = createAsyncThunk<
    ITenantUser,
    {
        tenantId: string;
        userId: string;
    }
>('users/getUserById', async ({ tenantId, userId: UserId }) => {
    const result = await membershipApi.getTenantUserById(tenantId, UserId);
    return result.data;
});

export const updateUser = createAsyncThunk<
    ITenantUser,
    {
        tenantId: string;
        user: ITenantUser;
    }
>('users/updateUser', async ({ tenantId, user: User }) => {
    const result = await membershipApi.updateTenantUser(tenantId, User);
    return result.data;
});

export const saveUserAndIdentity = createAsyncThunk(
    'users/saveUserAndIdentity',
    async ({ tenantId, item }: { tenantId: string; item: ITenantUser }) => {
        const { data: identityData } = await membershipApi.addOrUpdateIdentity(tenantId, item.identity);
        const { data: tenantUserData } = await membershipApi.updateTenantUser(tenantId, {
            ...item,
            id: identityData.id!,
            identity: identityData,
        });

        return { ...tenantUserData, identity: identityData };
    },
);
