import localStorage from 'store';

export default class SliceLocalStorage {
    private keysToStore: string[];
    private storagePath: string;

    private storage: { [key: string]: any } = {};
    private store: typeof this.storage;

    constructor(storeName: string, actionKeysToStore: string[]) {
        this.keysToStore = actionKeysToStore;
        this.store = localStorage.get(storeName, this.storage);
        this.storagePath = `${storeName}/`;
    }

    private save(actionKey: string) {
        this.store.set(`${this.storagePath}${actionKey}`, this.store);
    }

    public getItem(actionKey: string) {
        return this.storage[`${this.storagePath}${actionKey}`];
    }
    public setItem(actionKey: string, value: any) {
        if (this.keysToStore.indexOf(actionKey) > -1) {
            this.storage[`${this.storagePath}${actionKey}`] = value;
            this.save(actionKey);
        }
    }
}
