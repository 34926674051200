import { IComboBox, IComboBoxOption, Stack, Text } from '@fluentui/react';
import { Field, TModal } from 'components';
import { useSelector } from 'hooks';
import useTenantId from 'hooks/useTenantId';
import { FormEvent, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { defaultLocationOfCareOptions } from 'state/slices/locationsOfCare/locationsOfCare.selectors';
import { updateTenantSetupsAndSave } from 'state/slices/tenant/actions';
import { selectDentalDefaultLoc, selectSourceSystem, tenantConfig } from 'state/slices/tenant/selectors';

type LocationOfCareMappingModalProps = {
    isOpen: boolean;
    onDismiss: () => void;
};

function LocationOfCareMappingModal({ isOpen, onDismiss }: LocationOfCareMappingModalProps) {
    const dispatch = useDispatch();
    const locationsOfCareOption = useSelector(defaultLocationOfCareOptions);

    const tenantId = useTenantId();

    const defaultLoc = useSelector(selectDentalDefaultLoc);
    const sourceSystem = useSelector(selectSourceSystem);

    const [defaultDentalDepartmentId, setDefaultDentalDepartmentId] = useState<number | undefined>(defaultLoc);

    useEffect(() => {
        setDefaultDentalDepartmentId(defaultLoc);
    }, [isOpen]);

    const _onSave = () => {
        if (defaultDentalDepartmentId)
            dispatch(
                updateTenantSetupsAndSave(tenantId, {
                    defaultDentalDepartmentId,
                    setDefaultDentalDepartmentId: true,
                    mapDentalLocationsOfCare: true,
                }),
            );
        onDismiss();
    };

    const _onDismissed = () => setDefaultDentalDepartmentId(undefined);
    const _defaultLocOnChange = (_: FormEvent<IComboBox>, option?: IComboBoxOption) => {
        setDefaultDentalDepartmentId(option?.key ? parseInt(option.key as string) : undefined);
    };

    return (
        <TModal
            title="Confirm Mapping"
            modalProps={{
                isOpen: isOpen,
                onDismiss,
                onDismissed: _onDismissed,
            }}
            mainButtons={[
                {
                    primary: true,
                    text: 'Confirm',
                    disabled: !defaultDentalDepartmentId,
                    onClick: _onSave,
                },
                {
                    text: 'Cancel',
                    onClick: onDismiss,
                },
            ]}
        >
            <Stack tokens={{ childrenGap: 10, padding: 20, maxWidth: 550 }}>
                <Stack tokens={{ childrenGap: 10 }} grow>
                    <Text>
                        {` I confirm that all dental locations of care are mapped so that appointment changes from ${sourceSystem} can be received for those locations. Choose or confirm default location for future patient creation.`}
                    </Text>
                </Stack>

                <Field.SearchCombo
                    label=" Default Location of Care"
                    placeholder="(Select Default Location Of Care)"
                    options={locationsOfCareOption}
                    selectedKey={defaultDentalDepartmentId?.toString()}
                    required
                    maxResults={100}
                    onChange={_defaultLocOnChange}
                    errorMessage={!defaultDentalDepartmentId ? 'Default Location of Care is required.' : ''}
                />
            </Stack>
        </TModal>
    );
}

export default LocationOfCareMappingModal;
