import ITenantAccount from 'api/models/tenantAccount.model';
import useSelector from 'hooks/useSelector';
import useTenantId from 'hooks/useTenantId';
import { intersection, map, some } from 'lodash';

import { selectAccount, selectIsAccountGlobalAdmin } from 'state/slices/account.slice';

export enum GlobalPermissions {
    GlobalAdmin = 'global-admin',
}

export enum Permission {
    AppointmentCancellationReason = 'AppointmentCancellationReason.Manage',
    MembershipSubscription = 'Subscriptions.Membership.Manage',
    DentalSubscription = 'Subscriptions.Dental.Manage',
    LocationsOfCare = 'LocationsOfCare.Manage',
    Payers = 'Payers.Manage',
    EncounterReasons = 'EncounterReasons.Manage',
    Users = 'Users.Manage',
    Programs = 'Programs.Manage',
    ProcedureDetails = 'ProcedureDetails.Manage',
    StatementConfiguration = 'StatementConfiguration.Manage',
    Tasks = 'Task.Manage',
}

export const permissionsAsList = map(Permission);

/**
 * usePermissions
 *
 * A hook used to check to see if the passed permissions exist for a given user.
 *
 * @param {Permission[]} permissions Array of permissions to check
 * @param {boolean} [exact] If the list of permissions for a user has to match the permissions arg
 * @return {boolean[]} [requestedPermissions, isGlobalAdmin]
 */
function usePermissions(permissions?: Permission[], exact?: boolean): boolean[] {
    const tenantId = useTenantId();
    const account = useSelector(selectAccount);
    const currentTenant: ITenantAccount | undefined | null =
        account && account.data?.tenants ? account.data.tenants[tenantId] : null;

    const isGlobalAdmin = useSelector(selectIsAccountGlobalAdmin);
    let hasPermissions = false;

    if (isGlobalAdmin) {
        hasPermissions = true;
    } else {
        if (permissions?.length) {
            if (exact) {
                hasPermissions = intersection(currentTenant?.permissions, permissions).length === permissions.length;
            } else {
                hasPermissions = some(currentTenant?.permissions, (perm) => permissions.find((p) => perm === p) !== undefined);
            }
        } else {
            hasPermissions = false;
        }
    }

    return [hasPermissions, isGlobalAdmin];
}

export default usePermissions;
