import { createSlice } from '@reduxjs/toolkit';
import providerProfilesReducers, { providerProfilesExtraReducers } from './providerProfiles.reducers';
import { initialProviderProfileState } from './providerProfiles.state';

const providerProfilesSlice = createSlice({
    name: 'providerProfilesSlice',
    initialState: initialProviderProfileState,
    reducers: providerProfilesReducers,
    extraReducers: (builder) => {
        providerProfilesExtraReducers(builder);
    },
});

const { actions, reducer } = providerProfilesSlice;
export const { updateProviderProfileProp, cleanupSelectedProviderProfile, setSelectProviderProfile } = actions;

export default reducer;
