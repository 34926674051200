import { createSlice, Dictionary, PayloadAction } from '@reduxjs/toolkit';
import { ITenantUser } from 'api/models/tenantAccount.model';
import { LoadingStatuses } from 'interfaces/loading-statuses';
import { RootState } from 'state/store';
import appLocalStorage from 'utils/appLocalStorage';
import { initialSubscriptionsState } from './users/users.localstorage';
import usersReducers, { userExtraReducers } from './users/users.reducers';

// This is the store for our subscriptions object
const subscriptionSlice = createSlice({
    name: 'subscriptions',
    initialState: initialSubscriptionsState,
    reducers: {
        setExpanded(state, action: PayloadAction<boolean>) {
            state.isExpanded = action.payload;
            appLocalStorage.subscriptionBarExpanded = action.payload;
        },
        ...usersReducers,
    },
    extraReducers: (builder) => {
        userExtraReducers(builder);
    },
});

const { reducer, actions } = subscriptionSlice;

export const {
    setExpanded,
    setSelectedUser,
    updateSubscriptionUserProperty,
    updateSubscriptionUserIdentityProperty,
    cleanupUsersState,
    toggleProviderOnly,
    toggleActiveOnly,
} = actions;

export const selectExpanded = (state: RootState) => state.subscriptions.isExpanded;

export default reducer;

// Define the type of our state object
export type SubscriptionState = {
    isExpanded: boolean;
    users: {
        selectedUser?: ITenantUser | null;
        data: Dictionary<ITenantUser>;
        loading: LoadingStatuses;
        saving: LoadingStatuses;
        error?: string | null;
        isProvider: boolean;
        isActive: boolean;
    };
};
