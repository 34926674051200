import { ITextFieldProps, TextField } from '@fluentui/react';
import { useState, useEffect, FormEvent } from 'react';

export type CurrentFieldProps = {
    value?: number;
    canBeDecimal?: boolean;
    onChange: (ev: FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: number) => void;
} & Omit<ITextFieldProps, 'value' | 'onChange'>;

export default function CurrencyField(props: CurrentFieldProps): JSX.Element {
    const [fieldValue, setFieldValue] = useState<string>();
    const { value, canBeDecimal = true } = props;

    useEffect(() => {
        setFieldValue(value?.toString());
    }, [value]);

    const _onChange = (ev: FormEvent<HTMLInputElement | HTMLTextAreaElement>, val?: string) => {
        const value = val ? val?.replace(/,/g, '') : '0';

        const valueIsEmpty = value !== undefined && value !== '';

        const isDecimalNumber = valueIsEmpty ? RegExp(/^[+-]?([0-9]+\.?[0-9]*|\.[0-9]+)$/).test(value) : false;

        const isDecimalGreaterThanTwo = value !== undefined ? value.split('').reverse().indexOf('.') > 2 : false;

        if (isDecimalNumber && !isDecimalGreaterThanTwo) {
            const numericalValue = valueIsEmpty ? (canBeDecimal ? parseFloat(value) : parseInt(value)) : 0;
            const isNumber = value !== undefined ? !isNaN(parseFloat(value)) : false;
            if (isNumber && props.onChange) {
                props.onChange(ev, numericalValue);
            }
            setFieldValue(canBeDecimal ? value : value.replace(/\./g, ''));
        }
    };

    function numberWithCommas(x?: string) {
        return x?.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    }

    return <TextField prefix="$" {...props} autoComplete="off" value={numberWithCommas(fieldValue)} onChange={_onChange} />;
}
