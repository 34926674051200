import { Dictionary } from '@reduxjs/toolkit';
import LookupBase from 'api/models/lookup-base.model';

import { LoadingStatus } from 'interfaces/loading-statuses';
import IStatementConfig, { IAdditionalText, IDunningRule } from './models';

export const initialState: PatientStatementState = {
    loading: LoadingStatus.Idle,
    openPanel: false,
    exampleModalOpen: false,
    loadingProviderTypes: LoadingStatus.Idle,
    loadingDunningRules: LoadingStatus.Idle,
    selectDunningRules: {},
};

export type PatientStatementState = {
    statementConfigurations?: IStatementConfig;
    loading: LoadingStatus;
    openPanel: boolean;
    exampleModalOpen: boolean;
    selectedPracticeStatementNote?: IAdditionalText;
    providerTypes?: Dictionary<LookupBase>;
    loadingProviderTypes: LoadingStatus;
    loadingDunningRules: LoadingStatus;
    selectDunningRules: Record<string, IDunningRule>;
};
