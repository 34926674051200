import { DefaultButton, MessageBar, Panel, PanelType, PrimaryButton, Stack, TextField } from '@fluentui/react';
import { Field } from 'components';
import { useDispatch, useSelector } from 'react-redux';
import { isPatientStatementNotePanelOpen, practiceStatementNotes, selectedPracticeStatementNote } from '../state/selectors';
import {
    cleanupPracticeStatementNote,
    toggleStatementConfigurationPanel,
    updateSelectedPracticeStatementNoteProps,
} from '../state/slice';
import { updatedPracticeStatementNotes } from '../state/actions';
import useTenantId from 'hooks/useTenantId';

import { useValidation } from 'hooks';
import { IValidationConfig, getValidationError } from 'hooks/useValidation';
import { isDateBetween } from 'utils/isDateBetween';
import { useEffect } from 'react';
import { classicDateOnly } from 'utils/dateOnly';

const PracticeStatementNotePanel = () => {
    const dispatch = useDispatch();
    const isOpen = useSelector(isPatientStatementNotePanelOpen);
    const selectedNote = useSelector(selectedPracticeStatementNote);
    const tenantId = useTenantId();
    const practiceStatementNotesList = useSelector(practiceStatementNotes);

    const onDismiss = () => {
        dispatch(toggleStatementConfigurationPanel());
    };

    const onDismissed = () => {
        dispatch(cleanupPracticeStatementNote());
    };

    //add and edit depending on if selectedNote id
    const onSave = () => {
        if (selectedNote) dispatch(updatedPracticeStatementNotes(tenantId, selectedNote));
    };

    const validationConfig: IValidationConfig = [
        {
            fieldName: 'displayName',

            validation: ['required'],
            value: selectedNote?.displayName,
        },
        {
            fieldName: 'Message',

            validation: ['required'],
            value: selectedNote?.message,
        },
        {
            fieldName: 'State Date',

            validation: ['required'],
            value: selectedNote?.startDate,
        },
    ];

    const [errors, onSubmit, handleCleanupErrors] = useValidation(validationConfig, onSave);

    const isStartValid = practiceStatementNotesList
        ? practiceStatementNotesList
              .filter((note) => note.id !== selectedNote?.id)
              .some((note) => {
                  return isDateBetween({
                      dateToCheck: selectedNote?.startDate,
                      start: note.startDate,
                      end: note.endDate,
                  });
              })
        : false;

    useEffect(() => {
        return () => {
            handleCleanupErrors();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (!isOpen) handleCleanupErrors();
    }, [isOpen]);

    return (
        <Panel
            headerText="Add Practice Statement Note"
            isOpen={isOpen}
            isFooterAtBottom
            type={PanelType.medium}
            onDismiss={onDismiss}
            onDismissed={onDismissed}
            onRenderFooterContent={() => (
                <Stack tokens={{ childrenGap: 5 }} grow>
                    {isStartValid && (
                        <Stack.Item>
                            <MessageBar messageBarType={1} isMultiline={false}>
                                Note: This note will overlap with an existing note. Please choose a different start date.
                            </MessageBar>
                        </Stack.Item>
                    )}
                    <Stack horizontal tokens={{ childrenGap: 12 }}>
                        <PrimaryButton disabled={isStartValid} onClick={onSubmit} text={'Save'} />
                        <DefaultButton onClick={onDismiss} text="Cancel" />
                        <Stack grow horizontalAlign="end"></Stack>
                    </Stack>
                </Stack>
            )}
        >
            <Stack styles={{ root: { padding: 10 } }} tokens={{ childrenGap: 5 }} grow>
                <TextField
                    label="Display Name"
                    required
                    autoComplete="none"
                    value={selectedNote?.displayName ?? ''}
                    onChange={(en, value) => {
                        dispatch(updateSelectedPracticeStatementNoteProps({ path: 'displayName', value: value ? value : '' }));
                    }}
                    errorMessage={getValidationError(errors, 'displayName') ? 'Display Name is required' : ''}
                />
                <TextField
                    label={`Message`}
                    value={selectedNote?.message ?? ''}
                    required
                    autoComplete="none"
                    multiline
                    rows={3}
                    onChange={(en, value) => {
                        dispatch(updateSelectedPracticeStatementNoteProps({ path: 'message', value: value ? value : '' }));
                    }}
                    description={`Used ${selectedNote?.message?.length ?? 0} of 450 characters`}
                    maxLength={450}
                    errorMessage={getValidationError(errors, 'Message') ? 'Message is required' : ''}
                />

                <Stack horizontal tokens={{ childrenGap: 5 }}>
                    <Stack grow>
                        <Field.Date
                            label="Effective Date"
                            required
                            hasDatePicker
                            value={selectedNote?.startDate ?? ''}
                            onChange={(en, value) => {
                                dispatch(
                                    updateSelectedPracticeStatementNoteProps({
                                        path: 'startDate',
                                        value: value ? value : '',
                                    }),
                                );
                            }}
                            errorMessage={getValidationError(errors, 'Start Date') ? 'Start Date is required' : ''}
                        />
                    </Stack>
                    <Stack grow>
                        <Field.Date
                            label="Expiration Date"
                            required
                            value={selectedNote?.endDate ?? ''}
                            onChange={(en, value) => {
                                dispatch(
                                    updateSelectedPracticeStatementNoteProps({ path: 'endDate', value: value ? value : '' }),
                                );
                            }}
                            hasDatePicker
                            isReasonable
                            minReasonableErrorMessage="End date must be after start date."
                            minReasonableDate={
                                selectedNote?.startDate ? new Date(classicDateOnly(selectedNote?.startDate)) : undefined
                            }
                        />
                    </Stack>
                </Stack>
            </Stack>
        </Panel>
    );
};

export default PracticeStatementNotePanel;
