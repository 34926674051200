import { Stack, Text } from '@fluentui/react';
import { TModal } from 'components';
import useTenantId from 'hooks/useTenantId';
import { useDispatch } from 'react-redux';

import { updateTenantSetupsAndSave } from 'state/slices/tenant/actions';

type SubscriptionMappingModalProps = {
    isOpen: boolean;
    onDismiss: () => void;
};

function SubscriptionMappingModal({ isOpen, onDismiss }: SubscriptionMappingModalProps) {
    const dispatch = useDispatch();

    const tenantId = useTenantId();

    const _onSave = () => {
        dispatch(
            updateTenantSetupsAndSave(tenantId, {
                mapApplicableUsersAsProviders: true,
                mapProviderLicenseNumbers: true,
            }),
        );
        onDismiss();
    };

    return (
        <TModal
            title="Confirm Mapping"
            modalProps={{
                isOpen: isOpen,
                onDismiss,
            }}
            mainButtons={[
                {
                    primary: true,
                    text: 'Confirm',

                    onClick: _onSave,
                },
                {
                    text: 'Cancel',
                    onClick: onDismiss,
                },
            ]}
        >
            <Stack tokens={{ childrenGap: 10, padding: 20, maxWidth: 550 }}>
                <Stack tokens={{ childrenGap: 10 }} grow>
                    <Text>
                        {` I confirm that all providers are mapped to users and all providers have licenses populated as applicable.`}
                    </Text>
                </Stack>
            </Stack>
        </TModal>
    );
}

export default SubscriptionMappingModal;
