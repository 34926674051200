import { DefaultButton, SearchBox, Stack } from '@fluentui/react';

import { useBoolean } from '@uifabric/react-hooks';
import { ActivityPage, Section } from 'components';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import SubscriptionBar, { subscriptionPermissionMap } from './components/SubscriptionBar';
import ManageUsers from './ManageUsers';
import SubscriptionFilter from './components/SubscriptionFilter';
import { useSelector } from 'hooks';
import { useDispatch } from 'react-redux';
import { getProviderProfiles } from 'state/slices/providerProfiles/providerProfiles.actions';
import { ITenantSubscription } from 'api/models/tenantSubscription.model';

import { Route, Switch, useLocation } from 'react-router-dom';
import { AccessDenied } from 'routes/RouteWrapper';

import usePermissions from 'hooks/usePermissions';

import { getTenantSubscriptions } from 'state/slices/tenant/actions';
import { selectTenantSubscriptions } from 'state/slices/tenant/selectors';
import { TaskType, selectUserTasksByTarget } from 'state/slices/tasks/selectors';
import SubscriptionMappingModal from './components/SubscriptionModal';

import { sortBy } from 'lodash';
import { selectSubscriptionUsersAsProviderList } from 'state/slices/subscriptions/users/users.selectors';
import { sub } from 'date-fns';
import { useFuseSearch } from 'hooks/useFuseSearch';
import { ITenantUser } from 'api/models/tenantAccount.model';

export default function Subscriptions() {
    const parmas = useParams<{ tenantId: string }>();
    const [isOpen, { setTrue: openModal, setFalse: closeModal }] = useBoolean(false);
    const { tenantId } = parmas;

    const [isEditorOpen, { setTrue: setEditorOpen, setFalse: setEditorClosed }] = useBoolean(false);
    const location = useLocation();
    const routePieces = location.pathname.split('/').filter((string) => string !== '/' && string !== '');
    const productId = routePieces[2];

    const dispatch = useDispatch();

    const _tenantSubscriptions = useSelector(selectTenantSubscriptions);
    const task = useSelector(selectUserTasksByTarget);

    const subscriptionsUsers = useSelector(selectSubscriptionUsersAsProviderList);
    const { setSearch, search, results } = useFuseSearch({
        list: subscriptionsUsers,
        fuseOptions: {
            threshold: 0.1,
            keys: ['identity.firstName', 'identity.lastName', 'identity.email'],
            ignoreLocation: true,
        },
    });

    useEffect(() => {
        dispatch(getProviderProfiles({ tenantId }));
        dispatch(getTenantSubscriptions(tenantId));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tenantId]);

    const subscriptions = useSelector((state) => {
        if (state.tenants.data) {
            const tenant = state.tenants.data[tenantId];

            if (tenant) return sortBy(_tenantSubscriptions, ['productId']);
        }

        return [] as ITenantSubscription[];
    });

    const enableDoneMappingButton =
        task?.some((t) => t.type === TaskType.MappingApplicableUsersAsProviders) && productId === 'dental';

    return (
        <ActivityPage
            title="Subscriptions"
            hideFooter
            hasPageHeader
            rightContent={
                <>
                    {enableDoneMappingButton ? <DefaultButton text={'Done Mapping'} onClick={openModal} /> : null}
                    <SearchBox
                        styles={{ root: { width: 300 } }}
                        placeholder={'Search Users'}
                        showIcon={true}
                        disableAnimation={true}
                        defaultValue=""
                        value={search}
                        onChange={(_, value) => setSearch(value!)}
                        onClear={(event) => {
                            event.preventDefault();
                            setSearch('');
                        }}
                    />
                </>
            }
        >
            <Stack horizontal styles={{ root: { flex: 1, height: '100%', overflow: 'hidden' } }}>
                <SubscriptionBar subscriptions={subscriptions || []} />
                <Stack styles={{ root: { padding: 10, display: 'flex' } }} grow>
                    <Switch>
                        <Route path="/:tenantId/subscriptions/:productId">
                            <SubscriptionUsers
                                isEditorOpen={isEditorOpen}
                                setEditorOpen={setEditorOpen}
                                setEditorClosed={setEditorClosed}
                                searchTerm={search}
                                results={results}
                            />
                        </Route>
                    </Switch>
                </Stack>
            </Stack>
            <SubscriptionMappingModal isOpen={isOpen} onDismiss={closeModal} />
        </ActivityPage>
    );
}

type SubscriptionUsersProps = {
    searchTerm: string;
    results: ITenantUser[];
    isEditorOpen: boolean;
    setEditorOpen: () => void;
    setEditorClosed: () => void;
};

function SubscriptionUsers({ searchTerm, isEditorOpen, setEditorOpen, setEditorClosed, results }: SubscriptionUsersProps) {
    const { productId } = useParams<{ productId: string }>();
    const [hasPermission] = usePermissions([subscriptionPermissionMap[productId]]);
    const subscriptionsUsers = useSelector(selectSubscriptionUsersAsProviderList);

    if (!hasPermission) return <AccessDenied />;

    return (
        <Section
            style={{ marginBottom: 0 }}
            styleContent={{ display: 'flex', flex: 1 }}
            headingRightContent={<SubscriptionFilter setEditorOpen={setEditorOpen} />}
            heading={`Users (${searchTerm ? results.length : subscriptionsUsers.length})`}
            grow
        >
            <Stack grow>
                <ManageUsers
                    searchTerm={searchTerm}
                    isEditorOpen={isEditorOpen}
                    onEditorClose={setEditorClosed}
                    onEditorOpen={setEditorOpen}
                    results={results}
                />
            </Stack>
        </Section>
    );
}
