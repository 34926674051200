import React from 'react';
import {
    styled,
    ITheme,
    Text,
    ITextProps,
    IStyleFunctionOrObject,
    IPalette,
} from '@fluentui/react';

type Props = {
    children: React.ReactNode;
    theme?: ITheme;
    styles?: IStyleFunctionOrObject<any, any>;
    color?: keyof IPalette;
    bold?: boolean;
} & ITextProps;

const TText = ({ children, theme, styles, style, color, bold = false, ...props }: Props) => {
    const getColor = () => {
        return color ? theme?.palette[color] : theme?.palette.neutralPrimary;
    };
    return (
        <Text
            styles={{
                root: { color: `${getColor()} !important`, fontWeight: bold ? 'bold' : 'normal' },
            }}
            {...props}
        >
            {children}
        </Text>
    );
};

export default styled<Props, any, any>(TText, {});
