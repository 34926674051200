import { ReactNode } from 'react';
import { Stack } from '@fluentui/react';
import { ActivityBar, ContentPane } from 'components';

type Props = {
    children: ReactNode;
};

const SidebarLayout = (props: Props) => {
    return (
        <Stack horizontal styles={{ root: { flex: 1, height: '100%', overflow: 'hidden' } }}>
            <ActivityBar />
            <ContentPane>{props.children}</ContentPane>
        </Stack>
    );
};

export default SidebarLayout;
