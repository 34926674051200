import { createAsyncThunk } from '@reduxjs/toolkit';
import { IUserTaskItem, IUserTaskItemList, UserTaskStatus, UserTaskTarget } from './model';
import { GetTasksByTargetRequest, tasksApi } from './api';
import { AppThunk, CreateAsyncThunkOptions } from 'state/store';
import { removeCompletedUserTasks, setNewOrUpdatedUserTask } from './slice';
import { getAllLocationsOfCare } from '../locationsOfCare/locationsOfCare.slice';
import { getAppointmentCancellationsReasons } from 'pages/AppointmentCancellationReasons/state/actions';
import { getAllEncounterReasons } from '../encounters/encounters.actions';
import { getProviderProfiles } from '../providerProfiles/providerProfiles.actions';
import { TaskType } from './selectors';

export const getUserTasksByTarget = createAsyncThunk<IUserTaskItemList, GetTasksByTargetRequest, CreateAsyncThunkOptions>(
    'tasks/getUserTasksByTarget',
    async (request, { rejectWithValue }) => {
        try {
            const { data: userTasks } = await tasksApi.getByTarget(request);
            return userTasks;
        } catch (error) {
            return rejectWithValue(JSON.stringify(error));
        }
    },
);

export const getTenantUserTasksByTarget = (): AppThunk<void> => (dispatch, getState) => {
    const tenantId = getState().tenant.currentTenant.data?.id;

    if (tenantId) dispatch(getUserTasksByTarget({ tenantId, target: UserTaskTarget.TenantSetup }));
};

export const handleUserTaskMessage =
    (task: IUserTaskItem): AppThunk<void> =>
    (dispatch) => {
        // some way to do something based on the type and state
        if (!task.type) return;
        if (!task.status) return;

        switch (task.status) {
            case UserTaskStatus.Completed: {
                dispatch(removeCompletedUserTasks(task));
                break;
            }
            default:
                dispatch(setNewOrUpdatedUserTask(task));
        }

        const action = taskResolver[task.type as TaskType]?.[task.status];
        if (action) dispatch(action(task));
        dispatch(getUserTasksByTarget({ tenantId: task.references.tenantId as string, target: UserTaskTarget.TenantSetup }));
    };

type TaskActionLookup = Partial<Record<TaskType, TaskStatusLookup>>;
type TaskStatusLookup = Partial<Record<UserTaskStatus, TaskAction>>;
type TaskAction = (task: IUserTaskItem) => AppThunk<void>;

const taskResolver: TaskActionLookup = {
    [TaskType.RefreshingLocationOfCare]: {
        [UserTaskStatus.Completed]: (task) => (dispatch, getState) => {
            dispatch(getAllLocationsOfCare({ tenantId: task.references.tenantId as string }));
        },
    },
    [TaskType.RefreshingAppointmentCancellationReasons]: {
        [UserTaskStatus.Completed]: (task) => (dispatch) =>
            dispatch(getAppointmentCancellationsReasons({ tenantId: task.references.tenantId as string })),
    },
    [TaskType.RefreshingEncounterReason]: {
        [UserTaskStatus.Completed]: (task) => (dispatch) =>
            dispatch(getAllEncounterReasons({ tenantId: task.references.tenantId as string })),
    },
    [TaskType.RefreshingProviderProfiles]: {
        [UserTaskStatus.Completed]: (task) => (dispatch) =>
            dispatch(getProviderProfiles({ tenantId: task.references.tenantId as string })),
    },
};
