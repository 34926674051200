import membershipApi from 'api/membership.api';
import { IUserTaskItem, UserTaskTarget } from './model';

export const tasksApi = {
    getByTarget,
};

function getByTarget({ tenantId, target }: GetTasksByTargetRequest) {
    return membershipApi.client.get<IUserTaskItem[]>(`UserTasks/Tenants/${tenantId}/Targets/${target}`);
}

export type GetTasksByTargetRequest = {
    tenantId: string;
    target: UserTaskTarget;
};
