import { DefaultButton, PrimaryButton, SearchBox, Stack, Toggle } from '@fluentui/react';
import { useBoolean } from '@uifabric/react-hooks';
import IProcedureDetail, { IProcedureFee } from 'api/models/procedure-details.model';
import { ActivityPage } from 'components';
import UploadFilePanel from 'components/UploadPanel/UploadPanel';
import useProcedure from 'hooks/store/useProcedure';
import useProcedureDetails from 'hooks/store/useProcedureDetails';
import { useFuseSearch } from 'hooks/useFuseSearch';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    isProcedureActive,
    selectExcelUploading,
    toggleProcedureDetailsList,
} from 'state/slices/procedureDetails/procedureDetails.selectors';
import { cleanupProcedureDetailsSearch, toggleProceduresList } from 'state/slices/procedureDetails/procedureDetails.slice';
import ProcedureDetailsList from './components/ProcedureDetailsList';

export default function ProceduresDetails() {
    const { getProcedures } = useProcedure();
    const {
        getExcelProcedureDetails,
        loading,
        error,
        excelDataUploaded,
        bulkUpdateProcedureDetails,
        procedureDetailsAsList,
        getProcedureDetails,
        addExcelProcedureDetails,
    } = useProcedureDetails();

    const [uploadError, setUploadError] = useState<string | undefined>('');
    const [isOpen, { setTrue: setIsOpen, setFalse: setIsClosed }] = useBoolean(false);
    const dispatch = useDispatch();

    const excelUploading = useSelector(selectExcelUploading);
    const procedureList = useSelector(toggleProcedureDetailsList);

    const { results, onSearch, search, onSearchChange } = useFuseSearch<IProcedureDetail>({
        fuseOptions: {
            keys: ['displayName', 'code', 'fees', 'category'],
            threshold: 0.1,
            ignoreLocation: true,
            getFn: (procDetials, path) => {
                const newPath = path[0] as keyof IProcedureDetail;
                const valueAtPath = procDetials[newPath] as IProcedureFee[];

                let feeString = '';
                if (newPath === 'fees' && valueAtPath) {
                    const feeValue = valueAtPath.find((item) => item.fee)?.fee ?? 0;
                    const stringValue = feeValue.toString();
                    if (+valueAtPath % 1 !== 0) {
                        const indexOfDecimal = stringValue.indexOf('.');
                        const postDecimalValue = stringValue.slice(indexOfDecimal + 1);
                        const preDecimalValue = stringValue.slice(0, indexOfDecimal);
                        if (postDecimalValue.length === 1 && indexOfDecimal > -1)
                            feeString = preDecimalValue + '.' + postDecimalValue + '0';
                    } else {
                        feeString = stringValue + '.00';
                    }
                }

                return feeString ? feeString : valueAtPath ? valueAtPath.toString() : '';
            },
        },

        list: procedureList,
    });

    useEffect(() => {
        setUploadError(error);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [error]);

    const handleUpload = (file: File) => addExcelProcedureDetails(file);

    const handleBulkSave = () => bulkUpdateProcedureDetails(procedureDetailsAsList);

    useEffect(() => {
        dispatch(cleanupProcedureDetailsSearch());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleBulkCancel = () => {
        getProcedureDetails();
        getProcedures();
    };

    return (
        <ActivityPage
            title={'Procedure Details'}
            rightContent={
                <Stack tokens={{ childrenGap: 10 }} horizontal>
                    <SearchBox
                        onChange={onSearchChange}
                        onSearch={onSearch}
                        value={search}
                        style={{ minWidth: 200 }}
                        placeholder="Search by code or description"
                    />
                    {excelDataUploaded ? (
                        <>
                            <PrimaryButton text={'Save'} onClick={handleBulkSave} />
                            <DefaultButton text={'Cancel'} onClick={handleBulkCancel} />
                        </>
                    ) : (
                        <PrimaryButton
                            text={excelUploading ? 'Uploading...' : 'Bulk Import'}
                            onClick={setIsOpen}
                            disabled={excelUploading}
                        />
                    )}
                </Stack>
            }
            hideFooter
            hasPageHeader
        >
            <ProcedureDetailsList search={search} results={results} />
            <UploadFilePanel
                isOpen={isOpen}
                closePanel={setIsClosed}
                downloadTable={getExcelProcedureDetails}
                uploadTable={(ev: File) => {
                    handleUpload(ev);
                }}
                loading={loading}
                errorMessage={uploadError}
            />
        </ActivityPage>
    );
}
