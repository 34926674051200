import { ScrollablePane, ScrollbarVisibility, Spinner, SpinnerSize } from '@fluentui/react';
import useSubscriptionUsers from 'hooks/store/useSubscriptionUsers';
import useUsers from 'hooks/store/useUsers';
import { LoadingStatus } from 'interfaces/loading-statuses';
import { PropsWithChildren, useEffect } from 'react';
import UsersDetailsList from './components/UsersDetailsList';
import EditUserPanel from './EditUserPanel';
import { ITenantUser } from 'api/models/tenantAccount.model';

type Props = {
    searchTerm: string;
    results: ITenantUser[];
    isEditorOpen: boolean;
    onEditorOpen: () => void;
    onEditorClose: () => void;
};

function ManageUsers({ searchTerm, isEditorOpen, onEditorOpen, onEditorClose, results }: PropsWithChildren<Props>) {
    const { getSubscriptionUsers, loading, cleanupUsersState } = useSubscriptionUsers();
    const { getUsers } = useUsers();

    useEffect(() => {
        getSubscriptionUsers();
        getUsers();
        return () => {
            cleanupUsersState();
        };
    }, [getSubscriptionUsers, cleanupUsersState, getUsers]);

    return (
        <>
            {loading === LoadingStatus.Pending ? (
                <Spinner
                    style={{ marginBottom: 12 }}
                    size={SpinnerSize.large}
                    label="Loading subscription users..."
                    labelPosition="right"
                />
            ) : (
                <div style={{ position: 'relative', display: 'flex', flex: 1 }}>
                    <ScrollablePane scrollbarVisibility={ScrollbarVisibility.auto}>
                        <UsersDetailsList
                            searchTerm={searchTerm}
                            results={results}
                            isEditorOpen={isEditorOpen}
                            onEditorOpen={onEditorOpen}
                            onEditorClose={onEditorClose}
                        />
                    </ScrollablePane>
                </div>
            )}

            <EditUserPanel isOpen={isEditorOpen} closePanel={onEditorClose} />
        </>
    );
}

export default ManageUsers;
