import { MessageBar, MessageBarType, SearchBox, Stack, Toggle } from '@fluentui/react';
import { Section } from 'components';
import { useFuseSearch } from 'hooks/useFuseSearch';
import { useDispatch, useSelector } from 'react-redux';
import {
    selectPayerNonQuickPicks,
    selectPayerQuickPicks,
    selectPayersDetailsLoading,
    selectPayersLoading,
    showNonQuickPicksHistory,
    showQuickPicksHistory,
} from 'state/slices/payers/payers.selectors';
import { setShowNonQuickPicksHistory, setShowQuickPicksHistory } from 'state/slices/payers/payers.slice';
import PayersDetailsList from './components/PayersDetailsList';

function PayersPage() {
    const dispatch = useDispatch();

    const payers = useSelector(selectPayerNonQuickPicks);
    const quickPicks = useSelector(selectPayerQuickPicks);
    const isNonQuickPickActive = useSelector(showNonQuickPicksHistory);
    const isQuickPickActive = useSelector(showQuickPicksHistory);
    const payersDetailsLoading = useSelector(selectPayersDetailsLoading);
    const payersLoading = useSelector(selectPayersLoading);

    const { results, onSearch, search, onSearchChange } = useFuseSearch({
        fuseOptions: {
            threshold: 0.2,
            keys: [
                { name: 'name', weight: 1 },
                { name: 'displayName', weight: 2 },
                { name: 'payerId', weight: 3 },
            ],
            findAllMatches: true,
            useExtendedSearch: true,
        },
        list: payers,
    });

    return (
        <Stack tokens={{ childrenGap: 10 }} grow>
            <Section
                heading={'Quickpicks'}
                style={{ marginBottom: 0 }}
                styleContent={{ display: 'flex', flex: 'auto' }}
                headingRightContent={
                    <Stack horizontal tokens={{ childrenGap: 12 }}>
                        <Toggle
                            label={'Active Only'}
                            checked={!!isQuickPickActive}
                            onChange={() => {
                                dispatch(setShowQuickPicksHistory());
                            }}
                            inlineLabel
                        />
                    </Stack>
                }
                grow
            >
                {!quickPicks ? (
                    <MessageBar messageBarType={MessageBarType.info}>There are no payers selected as a quickpick.</MessageBar>
                ) : (
                    <PayersDetailsList items={quickPicks} loading={payersDetailsLoading} />
                )}
            </Section>
            <Section
                heading={'Payers'}
                style={{ marginBottom: 0 }}
                styleContent={{ display: 'flex', flex: 'auto' }}
                grow
                headingRightContent={
                    <Stack horizontal tokens={{ childrenGap: 12 }}>
                        <Toggle
                            label={'Active Only'}
                            checked={!!isNonQuickPickActive}
                            onChange={() => {
                                dispatch(setShowNonQuickPicksHistory());
                            }}
                            inlineLabel
                        />
                        <SearchBox
                            onChange={onSearchChange}
                            onSearch={onSearch}
                            value={search}
                            style={{ minWidth: 300 }}
                            placeholder="Search by payer, display name, or payer id"
                        />
                    </Stack>
                }
            >
                <PayersDetailsList items={payers} results={results} loading={payersLoading} search={search} />
            </Section>
        </Stack>
    );
}

export default PayersPage;
