import React from 'react';
import { styled, ITheme, IStyleFunctionOrObject, IFontStyles, Text } from '@fluentui/react';
import TText from './TText';

export enum BadgeSize {
    size16 = 0,
    size24 = 1,
    size32 = 2,
}

type Props = {
    type?: string;
    value: string | number;
    size?: BadgeSize;
    theme?: ITheme;
    styles?: IStyleFunctionOrObject<any, any>;
    className?: string;
};

const Badge = (props: Props) => {
    let circleSize, variant: keyof IFontStyles;

    switch (props.size) {
        case BadgeSize.size16: {
            circleSize = 16;
            variant = 'tiny';
            break;
        }
        case BadgeSize.size32: {
            circleSize = 32;
            variant = props.value && `${props.value}`.length >= 3 ? 'small' : 'medium';
            break;
        }
        default: {
            circleSize = 24;
            variant = 'small';
            break;
        }
    }

    const circleStyles: React.CSSProperties = {
        fontWeight: 'bold',
        background: props.theme?.semanticColors.errorIcon,
        height: circleSize,
        width: circleSize,
        fontSize: variant,
        borderRadius: '50%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    };

    const pillStyles: React.CSSProperties = {
        background: props.theme?.palette.neutralLight,
        padding: '0 15px',
        borderRadius: 15,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    };

    return (
        <div style={props.type === 'pill' ? pillStyles : circleStyles} className={props.className}>
            <Text variant={variant} styles={{ root: { color: props.theme?.palette.white } }}>
                {props.value}
            </Text>
        </div>
    );
};

export default styled<Props, any, any>(Badge, {});
