import { Stack, TooltipHost, useTheme } from '@fluentui/react';
import { Dictionary } from '@reduxjs/toolkit';
import { Services } from 'api/models/tenantSubscription.model';
import { useSelector } from 'hooks';
import { map } from 'lodash';
import { selecteTenantSubscriptionsWithServices, selectTenantSubscriptionsServices } from 'state/slices/tenant/selectors';
import dateOnly from 'utils/dateOnly';

const productNameLookup: Dictionary<string> = {
    dental: 'Dental',
    credentialing: 'Credentialing',
};

export default function TenantSubscriptionServices() {
    const { palette } = useTheme();
    const subscriptionServices = useSelector(selectTenantSubscriptionsServices);
    const subscriptionsWithServices = useSelector(selecteTenantSubscriptionsWithServices);

    const subscriptionColor = {
        [Services.EnhancedClaimsResolution]: palette.blackTranslucent40,
        [Services.ThirdPartyCoding]: palette.purple,
    };

    return (
        <Stack horizontal wrap={false} tokens={{ childrenGap: 5 }}>
            {subscriptionServices.map((service) =>
                service?.serviceType ? (
                    <TooltipHost
                        key={service.serviceType}
                        content={
                            <Stack>
                                <span>Subscriptions with Service:</span>
                                {map(subscriptionsWithServices, (service) => (
                                    <span key={service.productId}>
                                        - {productNameLookup[service.productId]} (
                                        {service.startDate ? `Start: ${dateOnly(service.startDate)}` : ''}{' '}
                                        {service.endDate ? `End ${dateOnly(service.endDate)}` : ''})
                                    </span>
                                ))}
                            </Stack>
                        }
                    >
                        <Stack
                            horizontal
                            tokens={{ childrenGap: 5 }}
                            style={{
                                background: subscriptionColor[service.serviceType],
                                padding: 3,
                                paddingLeft: 6,
                                paddingRight: 6,
                                borderRadius: 10,
                                color: '#ffffff',
                            }}
                        >
                            <span>{service.serviceName}</span>
                        </Stack>
                    </TooltipHost>
                ) : null,
            )}
        </Stack>
    );
}
