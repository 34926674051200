import { AnyAction, createAsyncThunk, Dictionary, ThunkDispatch } from '@reduxjs/toolkit';
import membershipApi from 'api/membership.api';
import { IEncounter } from 'api/models/encounter.model';
import { map } from 'lodash';
import { RootState } from 'state/store';

export const getAllEncounterReasons = createAsyncThunk<
    Dictionary<IEncounter>,
    { tenantId: string },
    { rejectValue: string }
>('encounters/getAllEncounterReasons', async ({ tenantId }) => {
    const request = await membershipApi.getEncounterReasons(tenantId);
    return request.data;
});

export const updateEncounter = createAsyncThunk<
    IEncounter,
    { tenantId: string; model: IEncounter }
>('payers/updateEncounter', async ({ tenantId, model }) => {
    const request = await membershipApi.updateEncounterReason(tenantId, model);
    return request.data;
});

export const refreshEncounterReasons = createAsyncThunk<
    Dictionary<IEncounter>,
    { tenantId: string },
    { rejectValue: string }
>('encounters/refreshEncounterReasons', async ({ tenantId }) => {
    const encounterReasonsData = await membershipApi.getEncounterReasons(tenantId);
    const filteredencounterReasons = map(encounterReasonsData.data).filter(
        (item) => !item?.isDeleted,
    );
    const request = await membershipApi.refreshEncounterReasons(tenantId);
    const filteredRequest = map(request.data).filter((item) => !item?.isDeleted);

    const combinedData = filteredencounterReasons.concat(filteredRequest);

    const dictionary = Object.assign({ ...combinedData });

    return dictionary;
});
