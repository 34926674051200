import { ITenantData } from './tenantAccount.model';

interface IServiceUserAccount {
    identity: IUserIdentity;
    tenants: ITenantData;
}

export interface IUserIdentity {
    id: string;
    email: string;
    firstName: string;
    lastName: string;
    displayName?: string;
    professionalSuffix?: string;
    systemRoles?: GlobalPermissions[];
    providerId?: string;
}

export enum GlobalPermissions {
    GlobalAdmin = 'global-admin',
}

export default IServiceUserAccount;
