import React, { ReactNode, CSSProperties } from 'react';
import { ITheme, useTheme } from '@fluentui/react';
import './ContentPane.scss';

type Props = {
    theme?: ITheme;
    style?: React.CSSProperties;
    children: ReactNode;
};

const ContentPane = (props: Props): JSX.Element => {
    const theme = useTheme();
    const _pageStyle: CSSProperties = {
        background: theme.palette.neutralLight,
        color: theme.palette.neutralPrimary,
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
        ...props.style,
    };

    return (
        <div className="contentPane" style={_pageStyle}>
            {props.children}
        </div>
    );
};

export default ContentPane;
