import { ScrollablePane, ScrollbarVisibility, Stack } from '@fluentui/react';
import useTenantId from 'hooks/useTenantId';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { getProcedures } from 'state/slices/procedures/procedures.slice';
import PracticeExampleModal from './components/PracticeExampleModal';
import PracticeStatementNotes from './components/PracticeStatementNotes';
import PracticeStatementNotePanel from './components/PraticeStatementNotePanel';
import StatementDetail from './components/StatementDetail';
import { getProviderTypes, getStatementConfigurations } from './state/actions';

const StatementDetails = () => {
    const dispatch = useDispatch();
    const tenantId = useTenantId();

    useEffect(() => {
        dispatch(getStatementConfigurations({ tenantId }));
        dispatch(getProviderTypes());
        dispatch(getProcedures());
    }, []);

    return (
        <Stack style={{ position: 'relative', display: 'flex', flex: 1 }}>
            <ScrollablePane scrollbarVisibility={ScrollbarVisibility.auto}>
                <Stack tokens={{ childrenGap: 15 }} grow>
                    <StatementDetail />
                    <PracticeStatementNotes />

                    <PracticeStatementNotePanel />
                    <PracticeExampleModal />
                </Stack>
            </ScrollablePane>
        </Stack>
    );
};

export default StatementDetails;
