import React from 'react';
import { useLocation } from 'react-router';
import { styled, SearchBox, Stack, ITheme, IContextualMenuItem } from '@fluentui/react';
import './NavBar.scss';
import AvatarContainer from '../Avatar/Avatar.container';
import Logo from './Logo';

type Props = {
    avatarMenuItems?: IContextualMenuItem[];
    preAppName?: JSX.Element;
    appName?: string;
    appNameLink?: string;
    preAvatarContent?: JSX.Element;
} & React.PropsWithChildren<{
    theme: ITheme & { palette: { navbar: string } };
}>;

const NavBar = (props: Props) => {
    const location = useLocation();
    const isTenantSelected = location.pathname !== '/';
    return (
        <Stack
            horizontal
            horizontalAlign="space-between"
            verticalAlign="center"
            className="app-nav-bar"
            style={{ background: props.theme.palette.navbar }}
        >
            <Stack
                horizontal
                horizontalAlign="start"
                tokens={{ childrenGap: 10 }}
                verticalAlign="center"
                style={{ height: '100%' }}
            >
                <Logo appName={props.appName} appNameLink={props.appNameLink} preAppName={props.preAppName} variant="medium" />
                {isTenantSelected && props.children}
            </Stack>
            <Stack tokens={{ childrenGap: 10 }} horizontal horizontalAlign="end" verticalAlign="center">
                {props.preAvatarContent ?? props.preAvatarContent}
                <AvatarContainer menuItems={props.avatarMenuItems} />
            </Stack>
        </Stack>
    );
};

export default styled<any, any, any>(NavBar, {});
