import { ActionReducerMapBuilder, PayloadAction } from '@reduxjs/toolkit';
import { LoadingStatus } from 'interfaces/loading-statuses';
import { PayersState } from './payers.slice';
import {
    addExcelPayerDetailFeeSchedules,
    createPayersDetail,
    getExcelPayerDetailFeeSchedules,
    getPayerAndDetail,
    getPayers,
    getPayersDetails,
    updatePayersDetail,
    upsertPayerDetailFeeSchedule,
} from './payers.actions';
import { CombinedPayerAndPayerDetail } from 'api/models/payer.model';
import { push } from 'connected-react-router';

const payersReducers = {
    setSelectedPayer: (state: PayersState, action: PayloadAction<CombinedPayerAndPayerDetail | undefined>) => {
        state.selectedPayer = action.payload;
    },
    setPayersDetailsSearch: (state: PayersState, action: PayloadAction<string | undefined>) => {
        state.payerDetailsSearch = action.payload;
    },
    setShowNonQuickPicksHistory: (state: PayersState) => {
        state.showNonQuickPicksHistory = !state.showNonQuickPicksHistory;
    },
    setShowQuickPicksHistory: (state: PayersState) => {
        state.showQuickPickHistory = !state.showQuickPickHistory;
    },
};

export default payersReducers;

export const payersExtraReducers = (builder: ActionReducerMapBuilder<PayersState>) => {
    return (
        builder
            // [PUT] addExcelPayerDetailFeeSchedules ==================================
            .addCase(addExcelPayerDetailFeeSchedules.pending, (state) => {
                state.payerDetails.loading = LoadingStatus.Pending;
                state.error = '';
            })
            .addCase(addExcelPayerDetailFeeSchedules.fulfilled, (state, action) => {
                state.payerDetails.loading = LoadingStatus.Completed;
                state.selectedPayer = action.payload;
            })
            .addCase(addExcelPayerDetailFeeSchedules.rejected, (state, action) => {
                state.payerDetails.loading = LoadingStatus.Failed;
                state.error = action.payload?.response ? action.payload.response.data.message : 'Error';
            })
            .addCase(getExcelPayerDetailFeeSchedules.pending, (state) => {
                state.loading = LoadingStatus.Pending;
            })
            .addCase(getExcelPayerDetailFeeSchedules.fulfilled, (state, action) => {
                const url = window.URL.createObjectURL(new Blob([action.payload.blob]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `${action.payload.tenantName}-Payer-Fees.xlsx`);
                document.body.appendChild(link);
                link.click();
                state.loading = LoadingStatus.Completed;
            })
            .addCase(getExcelPayerDetailFeeSchedules.rejected, (state) => {
                state.loading = LoadingStatus.Failed;
            })
            // [GET] Payers ==================================
            .addCase(getPayers.pending, (state) => {
                state.loading = LoadingStatus.Pending;
                state.error = '';
            })
            .addCase(getPayers.fulfilled, (state, action) => {
                state.loading = LoadingStatus.Completed;
                state.data = action.payload;
            })
            .addCase(getPayers.rejected, (state) => {
                state.loading = LoadingStatus.Failed;
            })

            // [GET] Payers Details ==================================
            .addCase(getPayersDetails.pending, (state) => {
                state.payerDetails.loading = LoadingStatus.Pending;
            })
            .addCase(getPayersDetails.fulfilled, (state, action) => {
                state.payerDetails.loading = LoadingStatus.Completed;
                state.payerDetails.data = action.payload;
            })
            .addCase(getPayersDetails.rejected, (state) => {
                state.payerDetails.loading = LoadingStatus.Failed;
            })

            // [POST] Payer Detail ==================================
            .addCase(createPayersDetail.pending, (state) => {
                state.payerDetails.saving = LoadingStatus.Pending;
            })
            .addCase(createPayersDetail.fulfilled, (state, action) => {
                state.payerDetails.saving = LoadingStatus.Completed;
                state.payerDetails.data = action.payload;
            })
            .addCase(createPayersDetail.rejected, (state) => {
                state.payerDetails.saving = LoadingStatus.Failed;
            })

            // [PUT] Payer Detail ==================================
            .addCase(updatePayersDetail.pending, (state) => {
                state.payerDetails.saving = LoadingStatus.Pending;
            })
            .addCase(updatePayersDetail.fulfilled, (state, action) => {
                state.payerDetails.saving = LoadingStatus.Completed;
                state.payerDetails.data = action.payload;
            })
            .addCase(updatePayersDetail.rejected, (state) => {
                state.payerDetails.saving = LoadingStatus.Failed;
            })

            // upsertPayerDetailFeeSchedule ==================================
            .addCase(upsertPayerDetailFeeSchedule.pending, (state) => {
                state.payerDetails.loading = LoadingStatus.Pending;
            })
            .addCase(upsertPayerDetailFeeSchedule.fulfilled, (state, action) => {
                state.payerDetails.loading = LoadingStatus.Completed;
                state.payerDetails.data = action.payload;
            })
            .addCase(upsertPayerDetailFeeSchedule.rejected, (state) => {
                state.payerDetails.loading = LoadingStatus.Failed;
            })

            .addCase(getPayerAndDetail.pending, (state) => {
                state.loading = LoadingStatus.Pending;
            })
            .addCase(getPayerAndDetail.fulfilled, (state, action) => {
                state.loading = LoadingStatus.Completed;
                state.selectedPayer = action.payload;
            })
            .addCase(getPayerAndDetail.rejected, (state) => {
                state.loading = LoadingStatus.Failed;
            })
    );
};
