import { IComboBox, IComboBoxOption, Stack, Text } from '@fluentui/react';
import { Field, TModal } from 'components';
import { useSelector } from 'hooks';
import useTenantId from 'hooks/useTenantId';
import { FormEvent, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { updateTenantSetupsAndSave } from 'state/slices/tenant/actions';
import { selectDentalDefaultAppointmentCancellationReason } from 'state/slices/tenant/selectors';
import { appointmentCancellationReasonOptions } from './state/selectors';

type AppointmentCancellationReasonModalProps = {
    isOpen: boolean;
    onDismiss: () => void;
};

function AppointmentCancellationReasonModal({ isOpen, onDismiss }: AppointmentCancellationReasonModalProps) {
    const dispatch = useDispatch();
    const appointmentCancellationReasonOption = useSelector(appointmentCancellationReasonOptions);
    const tenantId = useTenantId();

    const defaultACR = useSelector(selectDentalDefaultAppointmentCancellationReason);

    const [rescheduleCancellationReasonId, setRescheduleCancellationReasonId] = useState<number | undefined>(defaultACR);

    useEffect(() => {
        setRescheduleCancellationReasonId(defaultACR);
    }, [isOpen]);

    const _onSave = () => {
        if (rescheduleCancellationReasonId)
            dispatch(
                updateTenantSetupsAndSave(tenantId, { rescheduleCancellationReasonId, setRescheduleCancellationReasonId: true }),
            );
        onDismiss();
    };

    const _onDismissed = () => setRescheduleCancellationReasonId(undefined);
    const _defaultAcrOnChange = (_: FormEvent<IComboBox>, option?: IComboBoxOption) => {
        setRescheduleCancellationReasonId(option?.key ? parseInt(option.key as string) : undefined);
    };

    return (
        <TModal
            title="Confirm Default Appointment Cancellation Reason"
            modalProps={{
                isOpen: isOpen,
                onDismiss,
                onDismissed: _onDismissed,
            }}
            mainButtons={[
                {
                    primary: true,
                    text: 'Confirm',
                    disabled: !rescheduleCancellationReasonId,
                    onClick: _onSave,
                },
                {
                    text: 'Cancel',
                    onClick: onDismiss,
                },
            ]}
        >
            <Stack tokens={{ childrenGap: 10, padding: 20, maxWidth: 550 }}>
                <Stack tokens={{ childrenGap: 10 }} grow>
                    <Text>{` Choose or confirm default appointment cancellation reason for rescheduling.`}</Text>
                </Stack>

                <Field.SearchCombo
                    label=" Default Appointment Cancellation Reason"
                    placeholder="(Select Default Appointment Cancellation Reason)"
                    options={appointmentCancellationReasonOption}
                    selectedKey={rescheduleCancellationReasonId?.toString()}
                    required
                    maxResults={100}
                    onChange={_defaultAcrOnChange}
                    errorMessage={!rescheduleCancellationReasonId ? 'Default Appointment Cancellation Reason is required.' : ''}
                />
            </Stack>
        </TModal>
    );
}

export default AppointmentCancellationReasonModal;
