import { useReducer, useEffect, useState } from 'react';
import { Stack, TextField, IStackTokens } from '@fluentui/react';
import states from './StateOptions';
import { Field } from 'components';
import { isEmpty } from 'lodash';

export interface IAddress {
    address1?: string;
    address2?: string;
    city?: string;
    state?: string;
    zip?: string;
}

type Props = {
    value?: IAddress;
    fieldsRequired?: (required: boolean) => void;
    onChange?: (path: keyof IAddress, value: string) => void;
    disabled?: boolean;
    alwaysValidate?: boolean;
};

type Action =
    | { type: 'changeAddress'; payload: { value: string; path: keyof IAddress } }
    | { type: 'updateAddress'; payload: IAddress };

type State = IAddress;

const reducer = (state: State, action: Action) => {
    switch (action.type) {
        case 'changeAddress':
            return {
                ...state,
                [action.payload.path]: action.payload.value,
            };
        case 'updateAddress':
            return {
                ...action.payload,
            };
        default:
            return state;
    }
};

const initialState = (address?: IAddress): State => ({
    ...address,
    zip: address ? address.zip : '',
    address1: address ? address.address1 : '',
    address2: address ? address.address2 : '',
    city: address ? address.city : '',
    state: address ? address.state : '',
});

const zipRegex = /(\d{5})(\d)/;

const getValidZip = (value?: string) => {
    const rawValue = value ? value.split('-').join('') : value;
    return rawValue && (rawValue.length === 5 || rawValue.length === 9) && !isNaN(+rawValue)
        ? true
        : false;
};

const Address = ({
    value,
    onChange,
    disabled,
    alwaysValidate,
    fieldsRequired: fieldRequiredCallback,
}: Props): JSX.Element => {
    const [state, dispatch] = useReducer(reducer, value, initialState);
    const [loaded, setLoaded] = useState<boolean>(false);
    const [zipErrorMessage, setZipErrorMessage] = useState<string>();

    useEffect(() => {
        if (!isEmpty(value) && !loaded) {
            dispatch({ type: 'updateAddress', payload: value as IAddress });
            setLoaded(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value, loaded]);

    const fieldsRequired = () => {
        const {
            address1: streetAddress1,
            address2: streetAddress2,
            city,
            state: addressState,
            zip,
        } = state;

        const required =
            alwaysValidate !== undefined
                ? alwaysValidate
                : streetAddress1 || streetAddress2 || city || addressState || zip
                ? true
                : false;

        if (fieldRequiredCallback) fieldRequiredCallback(required);
        return required;
    };

    const stackTokens: IStackTokens = {
        childrenGap: 10,
    };

    const onGetZipErrorMessage = (value?: string) => {
        const isValidZip = getValidZip(value) ? '' : 'Invalid Zip';
        return isValidZip;
    };

    const setFieldErrorMessage = (value: string | undefined, path: keyof IAddress) => {
        if (fieldsRequired()) {
            switch (path) {
                case 'zip':
                    setZipErrorMessage(value);
                    break;
                default:
                    return;
            }
        }
    };

    const validateField = (value: string | undefined, path: keyof IAddress) => {
        if (fieldsRequired()) {
            switch (path) {
                case 'zip': {
                    const zipError = onGetZipErrorMessage(value);
                    if (zipError) {
                        setFieldErrorMessage(zipError, path);
                    } else {
                        setFieldErrorMessage(undefined, path);
                    }
                    break;
                }
                default:
                    return;
            }
        }
    };

    const cleaAllErrorMessages = () => {
        setZipErrorMessage(undefined);
    };

    const validateAndChangeAddress = (value: string, path: keyof IAddress) => {
        if (onChange) onChange(path, value);
        if (fieldsRequired()) {
            validateField(value, path);
        } else {
            cleaAllErrorMessages();
        }
    };

    const handleChange = (value: string, path: keyof IAddress) => {
        if (path !== 'zip') validateField(value, path);
        dispatch({ type: 'changeAddress', payload: { value, path } });
    };

    const handleZipChange = (value?: string) => {
        const rawValue = value ? value.split('-').join('') : value;
        let newValue = rawValue ? rawValue : '';
        if (rawValue && rawValue.length > 5) newValue = rawValue.replace(zipRegex, '$1-$2');

        handleChange(newValue ? newValue : '', 'zip');
    };

    return (
        <Stack grow>
            <Stack tokens={{ childrenGap: 12 }} horizontal grow>
                <Stack.Item grow>
                    <TextField
                        label="Line 1"
                        onChange={(e, value) => handleChange(value ? value : '', 'address1')}
                        onBlur={(e) => validateAndChangeAddress(e.target.value, 'address1')}
                        maxLength={100}
                        value={state.address1}
                        disabled={disabled}
                        required={fieldsRequired()}
                    />
                </Stack.Item>
                <Stack.Item grow>
                    <TextField
                        label="Line 2"
                        onChange={(e, value) => handleChange(value ? value : '', 'address2')}
                        onBlur={(e) => validateAndChangeAddress(e.target.value, 'address2')}
                        maxLength={100}
                        disabled={disabled}
                        value={state.address2}
                    />
                </Stack.Item>
            </Stack>
            <Stack tokens={stackTokens} horizontal grow>
                <Stack.Item grow>
                    <TextField
                        label="City"
                        onChange={(e, value) => handleChange(value ? value : '', 'city')}
                        onBlur={(e) => validateAndChangeAddress(e.target.value, 'city')}
                        maxLength={50}
                        value={state.city}
                        disabled={disabled}
                        required={fieldsRequired()}
                    />
                </Stack.Item>
                <Stack.Item grow>
                    <Field.SearchCombo
                        label="State"
                        placeholder="(Select State)"
                        onChange={(e, option) => {
                            const value = option ? (option.key as string).toUpperCase() : '';
                            handleChange(value, 'state');
                        }}
                        onBlur={() => {
                            if (state.state) {
                                const value = state.state.toUpperCase();
                                validateAndChangeAddress(value, 'state');
                            }
                        }}
                        selectedKey={state.state ? state.state.toUpperCase() : undefined}
                        options={states}
                        disabled={disabled}
                        required={fieldsRequired()}
                    />
                </Stack.Item>
                <Stack.Item grow>
                    <TextField
                        label="Zip"
                        onChange={(e, value) => handleZipChange(value)}
                        onBlur={(e) => validateAndChangeAddress(e.target.value, 'zip')}
                        errorMessage={zipErrorMessage}
                        maxLength={10}
                        value={state.zip}
                        disabled={disabled}
                        required={fieldsRequired()}
                    />
                </Stack.Item>
            </Stack>
        </Stack>
    );
};

export default Address;
