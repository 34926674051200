import { IDropdownOption, Dropdown, Text } from '@fluentui/react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { useSelector } from 'hooks';
import { tenantData, tenantsData } from 'state/slices/tenants/tenants.slice';
import { selectAccountTenantsAsList } from 'state/slices/account.slice';

const CustomerSelector = () => {
    const { push } = useHistory();
    const match = useRouteMatch<{ tenantId: string }>('/:tenantId');

    const _tenantsData = useSelector(tenantsData);
    const tenantsList = useSelector(selectAccountTenantsAsList);

    const _onChange = (item: IDropdownOption) => {
        if (item.key !== 'selectAnother') {
            push(`/${item.key}`);
        } else {
            push(`/`);
        }
    };

    function getCustomerId() {
        return match ? match.params.tenantId : '';
    }

    const currentTenant = useSelector((state) => tenantData(state, getCustomerId()));

    const currentOrganizationOption: IDropdownOption = {
        key: currentTenant?.id as string,
        text: currentTenant?.displayName as string,
    };

    const options: IDropdownOption[] = _tenantsData
        ? [
              currentOrganizationOption,
              {
                  key: 'selectAnother',
                  text: 'Select Another Organization',
              },
          ]
        : [];

    if (!match?.params.tenantId) return null;

    return tenantsList.length === 1 ? (
        <Text style={{ color: 'white' }}>{currentTenant?.displayName}</Text>
    ) : (
        <Dropdown
            styles={{
                title: {
                    minWidth: 200,
                    backgroundColor: 'transparent',
                    border: 'none',
                    color: 'white',
                    height: '26px',
                    paddingRight: 8,
                    selectors: {
                        ':hover': {
                            color: 'lightgray !important',
                        },
                        ':focus': {
                            color: 'lightgray !important',
                        },
                        ':active': {
                            color: 'lightgray !important',
                        },
                    },
                },
                dropdown: {
                    selectors: {
                        ':focus .ms-Field.Dropdown-title': {
                            color: 'lightgray !important',
                        },
                    },
                },
                caretDownWrapper: {
                    display: 'none',
                },
            }}
            label=""
            key={getCustomerId()}
            selectedKey={getCustomerId()}
            onChange={(e, option) => {
                if (option) _onChange(option);
            }}
            options={options}
        />
    );
};

export default CustomerSelector;
