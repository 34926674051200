import React from 'react';
import { Icon, TooltipHost, DirectionalHint, useTheme, Stack } from '@fluentui/react';
import { NavLink } from 'react-router-dom';
import './ActivityBarLink.scss';
import { Badge, TText } from '..';
import { BadgeSize } from '../Badge';
import { useBoolean } from '@uifabric/react-hooks';
import usePermissions from 'hooks/usePermissions';
import { useSelector } from 'react-redux';
import { selectActiveFeatureFlagsLookup } from 'state/slices/tenant/feature-flags.slice';
import { IActivity } from './ActivityBar';

type Props = IActivity & {
    expanded?: boolean;
    onClick?: (event: React.MouseEvent<HTMLAnchorElement | MouseEvent>) => void;
};

/**
 * Activity Bar Link
 * Wrapper for react-router-dom NavLink to pass in expanded property and add text when expanded
 * @export
 * @param {Props} props
 * @returns
 */
function ActivityBarLink(props: Props): JSX.Element | null {
    const theme = useTheme();

    const featureFlags = useSelector(selectActiveFeatureFlagsLookup);
    const hasFeatureFlag = props.featureFlag ? featureFlags.has(props.featureFlag) : true;

    const [hasPermissions] = usePermissions(props.permissions);
    const { themePrimary, neutralPrimary, neutralLight, neutralQuaternaryAlt } = theme.palette;
    const activeLinkStyles = { background: neutralQuaternaryAlt };

    const [hovered, { toggle }] = useBoolean(false);

    if (!hasPermissions || !hasFeatureFlag) return null;

    const linkStyles: React.CSSProperties = {
        color: neutralPrimary,
        userSelect: 'none',
        background: hovered ? neutralLight : '',
        transition: 'background .15s',
        cursor: hovered ? 'pointer' : '',
        fontSize: '16px',
    };

    return (
        <TooltipHost content={props.tooltip} directionalHint={DirectionalHint.rightCenter} hidden={props.expanded}>
            <NavLink
                className={props.expanded ? 'activityLink activityLink--expanded' : 'activityLink'}
                to={props.path}
                exact={props.exact}
                activeStyle={activeLinkStyles}
                activeClassName="activityLink--active"
                style={linkStyles}
                onMouseEnter={toggle}
                onMouseLeave={toggle}
                onClick={props.onClick}
            >
                <div className="activitiyLink__activeBar" style={{ background: themePrimary }}></div>
                <Stack style={{ position: 'relative' }}>
                    <Icon iconName={props.iconName} className="activityLink__icon" />
                    {props.notifications && (
                        <Badge value={props.notifications} className="activityLink__badge" size={BadgeSize.size16} />
                    )}
                </Stack>
                {props.icon && <>{props.icon}</>}
                <TText className="activityLink__text">{props.expanded ? props.tooltip : null}</TText>
            </NavLink>
        </TooltipHost>
    );
}

export default ActivityBarLink;
