import { ITheme, IFontStyles, Stack, Icon, useTheme } from '@fluentui/react';
import { useSelector } from 'hooks';
import { IThemeCustom } from 'state/slices/ui/ui.slice';

type Props = {
    theme?: ITheme;
    variant: keyof IFontStyles;
    appName?: string;
    appNameLink?: string;
    preAppName?: JSX.Element;
};

const Logo = (props: Props): JSX.Element => {
    const theme = useTheme();
    const { neutralPrimary, white } = theme.palette;
    const { selectedTheme } = useSelector((state) => state.ui);
    const logoStyle = {
        color: selectedTheme === 'light' ? white : neutralPrimary,
        height: '100%',
        padding: 10,
        background: (theme as IThemeCustom).palette.navbar,
        cursor: 'pointer',
    };
    return (
        <Stack horizontal style={logoStyle} verticalAlign="center" tokens={{ childrenGap: 20 }}>
            {props.preAppName ? props.preAppName : null}
            <Stack
                className="nav-bar_logo"
                horizontal
                verticalAlign="center"
                tokens={{ childrenGap: 10 }}
                onClick={() => window.location.reload()}
            >
                <span>
                    <strong>athena</strong>One
                </span>{' '}
                <span>|</span> <span>{props.appName} </span>
            </Stack>
        </Stack>
    );
};

export default Logo;
