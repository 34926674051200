import React, { PropsWithChildren, CSSProperties, ReactNode } from 'react';
import { ActivitySubHeading } from '.';
import { Stack, StackItem, styled, ITheme, IStyleFunctionOrObject } from '@fluentui/react';
import { IActionButton } from './ActionButton';

type Props = {
    heading?: string | JSX.Element;
    id?: string | number;
    style?: CSSProperties;
    styleContent?: CSSProperties;
    sectionButtons?: IActionButton[];
    headingCenterContent?: ReactNode;
    headingRightContent?: ReactNode;
    preBodyContent?: ReactNode;
    footer?: ReactNode;
    theme?: ITheme;
    styles?: IStyleFunctionOrObject<any, any>;
    grow?: boolean;
};

const Section = (props: PropsWithChildren<Props>) => {
    const sectionBodyStyle: React.CSSProperties = {
        padding: '10px 10px 10px',
        background: props.theme?.palette.neutralLighterAlt,
        width: '100%',
    };

    return (
        <Stack
            className="section ms-motion-slideUpIn"
            id={props.id ? `'${props.id}` : ''}
            style={{ marginBottom: 10, ...props.style }}
            grow={props.grow}
        >
            {props.heading && (
                <ActivitySubHeading
                    heading={props.heading}
                    sectionButtons={props.sectionButtons}
                    headingCenterContent={props.headingCenterContent}
                    headingRightContent={props.headingRightContent}
                />
            )}
            <Stack style={sectionBodyStyle} tokens={{ childrenGap: 10 }} className="section__body" grow={props.grow}>
                {props.preBodyContent && <StackItem>{props.preBodyContent}</StackItem>}
                <StackItem className="section__content" style={{ ...props.styleContent }}>
                    {props.children}
                </StackItem>
                {props.footer && <StackItem>{props.footer}</StackItem>}
            </Stack>
        </Stack>
    );
};

export default styled<Props, any, any>(Section, {});
