import {
    Link,
    MessageBar,
    ScrollablePane,
    ScrollbarVisibility,
    SelectionMode,
    Stack,
    Text,
    Toggle,
    TooltipHost,
} from '@fluentui/react';
import { CombinedPayerAndPayerDetail } from 'api/models/payer.model';
import SortableDetailsList, { ISortableColumn } from 'components/SortableDetailsList/SortableDetailsList';
import UserDisplayName from 'components/UserDisplayName';
import useTenantId from 'hooks/useTenantId';
import { LoadingStatus } from 'interfaces/loading-statuses';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { updatePayerDetailQuickPick } from 'state/slices/payers/payers.actions';
import { selectUserIdentitiesData } from 'state/slices/users-idenitites/user-indentities.slice';
import { classicDateOnly } from 'utils/dateOnly';

type Props = {
    items: CombinedPayerAndPayerDetail[];
    loading?: LoadingStatus;
    results?: CombinedPayerAndPayerDetail[];
    search?: string;
};

function PayersDetailsList(props: Props) {
    const { items, loading, results, search } = props;
    const tenantId = useTenantId();
    const dispatch = useDispatch();

    const userIdentitiesData = useSelector(selectUserIdentitiesData);

    const linkStyles: React.CSSProperties = {
        textDecoration: 'none',
    };

    const handleToggleQuickpick = (item: CombinedPayerAndPayerDetail) => {
        dispatch(updatePayerDetailQuickPick(tenantId, item.id));
    };

    const gridColumns: ISortableColumn<CombinedPayerAndPayerDetail>[] = [
        {
            key: 'payer',
            fieldName: 'payer',
            minWidth: 125,
            maxWidth: 250,
            name: 'Payer',
            isResizable: true,
            getValueKey: (item) => {
                return item?.name ?? '';
            },
            onRender: (item) => {
                if (item) {
                    return (
                        <NavLink to={`/${tenantId}/payers/${item.id}`} style={linkStyles} exact={true}>
                            {!item.isDeleted ? (
                                <Link>{item?.name ?? ''}</Link>
                            ) : (
                                <Link>
                                    <i>{item?.name ?? ''}</i>
                                </Link>
                            )}
                        </NavLink>
                    );
                } else {
                    return undefined;
                }
            },
        },

        {
            key: 'payerId',
            name: 'Payer ID',
            minWidth: 75,
            maxWidth: 75,
            fieldName: 'payerId',
            isResizable: true,
            onRender: (item) => {
                if (item) {
                    return !item.isDeleted ? item?.payerId ?? '' : <i>{item?.payerId ?? ''}</i>;
                } else {
                    return undefined;
                }
            },
        },
        {
            key: 'address',
            name: 'Address',
            minWidth: 125,
            maxWidth: 250,
            fieldName: 'street1',
            isResizable: true,
            onRender: (item) => {
                const street1String = item?.street1 ? `${item?.street1}` : '';
                const street2String = item?.street2 ? `, ${item?.street2}` : '';
                const cityString = item?.city ? `, ${item?.city}` : '';
                const stateString = item?.state ? `${item?.city ? ', ' : ''}${item?.state}` : '';
                const zipString = item?.zip ? ` ${item?.zip}` : '';

                const addressString = `${street1String}${street2String}${cityString}${stateString}${zipString}`;

                return (
                    <TooltipHost content={addressString}>
                        {!item?.isDeleted ? <span>{addressString}</span> : <i>{addressString}</i>}
                    </TooltipHost>
                );
            },
        },
        {
            key: 'displayName',
            fieldName: 'displayName',
            minWidth: 75,
            maxWidth: 150,
            isResizable: true,
            name: 'Display Name',
            getValueKey: (item) => {
                return item?.displayName ?? '';
            },
            onRender: (item) => {
                if (item) {
                    return (
                        <>
                            {item?.displayName ? (
                                !item.isDeleted ? (
                                    <Text variant="small">{item?.displayName}</Text>
                                ) : (
                                    <Text variant="small">
                                        <i>{item?.displayName}</i>
                                    </Text>
                                )
                            ) : (
                                <NavLink to={`/${tenantId}/payers/${item.id}`} style={linkStyles} exact={true}>
                                    {!item.isDeleted ? (
                                        <Link>Add +</Link>
                                    ) : (
                                        <Link>
                                            <i>Add +</i>
                                        </Link>
                                    )}
                                </NavLink>
                            )}
                        </>
                    );
                } else {
                    return undefined;
                }
            },
        },
        {
            key: 'feeSchedule',
            fieldName: 'feeSchedule',
            minWidth: 75,
            maxWidth: 150,
            isResizable: true,
            name: 'Active Fee Schedule',
            onRender: (item) => {
                if (item) {
                    return (
                        <>
                            {item?.feeSchedules && item.feeSchedules[0]?.displayName ? (
                                <NavLink
                                    to={`/${tenantId}/payers/${item.id}/fee-schedules/${item.feeSchedules[0]?.id}`}
                                    style={linkStyles}
                                >
                                    {!item.isDeleted ? (
                                        <Link>{item.feeSchedules[0]?.displayName}</Link>
                                    ) : (
                                        <Link>
                                            <i>{item.feeSchedules[0]?.displayName}</i>
                                        </Link>
                                    )}
                                </NavLink>
                            ) : (
                                <NavLink to={`/${tenantId}/payers/${item.id}`} style={linkStyles} exact={true}>
                                    {!item.isDeleted ? (
                                        <Link>Add +</Link>
                                    ) : (
                                        <Link>
                                            <i>Add +</i>
                                        </Link>
                                    )}
                                </NavLink>
                            )}
                        </>
                    );
                } else {
                    return undefined;
                }
            },
        },
        {
            key: 'quickPick',
            fieldName: 'isQuickPick',
            minWidth: 75,
            maxWidth: 75,
            isResizable: true,
            name: 'Quickpicks',
            onRender: (item) => {
                if (item) {
                    return <Toggle checked={item.isQuickPick} onClick={(value) => handleToggleQuickpick(item)}></Toggle>;
                } else {
                    return undefined;
                }
            },
        },
        {
            key: 'modifiedBy',
            fieldName: 'modifiedBy',
            minWidth: 75,
            maxWidth: 150,
            isResizable: true,
            name: 'Modified By',
            onRender: (item) => {
                if (item) {
                    return <UserDisplayName userId={item.modifiedBy} />;
                }
            },
            getValueKey: (item) => {
                if (item) {
                    const user = userIdentitiesData[item.modifiedBy ?? ''];
                    const label = user ? user.displayName : item.modifiedBy;

                    return label ?? '';
                }
                return '';
            },
        },
        {
            key: 'modifiedOn',
            fieldName: 'modifiedOn',
            minWidth: 75,
            maxWidth: 150,
            isResizable: true,
            name: 'Modified Date',
            onRender: (item) => {
                if (item) {
                    return <Text variant="small">{item.modifiedOn ? classicDateOnly(item.modifiedOn ?? '') : ''}</Text>;
                }
            },
        },
    ];

    return (
        <div style={{ position: 'relative', display: 'flex', flex: 1 }}>
            <ScrollablePane scrollbarVisibility={ScrollbarVisibility.auto} styles={{ contentContainer: { display: 'flex' } }}>
                {search && results && !results.length ? (
                    <Stack tokens={{ childrenGap: 10 }} grow>
                        <MessageBar>No payers found.</MessageBar>
                    </Stack>
                ) : (
                    <div style={{ flexGrow: 1 }}>
                        <SortableDetailsList<CombinedPayerAndPayerDetail>
                            items={search && results ? results : items}
                            sortOnMount
                            sortColumns={['payer']}
                            selectionMode={SelectionMode.none}
                            columns={gridColumns}
                            enableShimmer={loading === LoadingStatus.Pending}
                            stickyHeader={true}
                        />
                    </div>
                )}
            </ScrollablePane>
        </div>
    );
}

export default PayersDetailsList;
