import React, { useEffect, useRef, FormEvent, useState } from 'react';
import {
    MaskedTextField,
    ITextFieldProps,
    Callout,
    FocusTrapZone,
    Calendar,
    DirectionalHint,
    ICalendarProps,
} from '@fluentui/react';
import { format, isValid } from 'date-fns';
import { useBoolean } from '@uifabric/react-hooks';
import ActionButton from 'components/ActionButton';
import DayPickerStrings from './DateFieldCalendar.config';

export interface IDateFieldProps extends ITextFieldProps {
    showGoToToday?: boolean;
    isReasonable?: boolean;
    hasDatePicker?: boolean;
    allowFutureDates?: boolean;
    onChange?: (
        event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement> | undefined,
        newValue?: string | undefined,
    ) => void;
    handleError?: (error: string | undefined) => void;
    minReasonableDate?: Date | undefined;
    maxReasonableDate?: Date | undefined;
    maxReasonableErrorMessage?: string;
    minReasonableErrorMessage?: string;
    invalidDateErrorMessage?: string;
}

const isValueEmpty = (value?: string) => value === '__/__/____';

const formatDate = (dateToConvert?: string, dateFormat = 'MM/dd/yyyy') => {
    if (dateToConvert && isValid(new Date(dateToConvert))) {
        const localDate = dateToConvert.toString();
        const newDate = new Date(localDate);
        return format(newDate, dateFormat);
    }
    if (dateToConvert && isValueEmpty(dateToConvert)) return '';
    throw new Error(`Date Error: (${dateToConvert}) is not a valid date string.`);
};

const getDefaultMinReasonableDate = (): Date => {
    const minimumDate = new Date();
    minimumDate.setFullYear(minimumDate.getFullYear() - 130);
    return minimumDate;
};

const getDefaultMaxReasonableDate = (): Date => {
    const minimumDate = new Date();
    minimumDate.setFullYear(minimumDate.getFullYear() + 130);
    return minimumDate;
};

const isReasonableDate = (
    currentDate?: Date,
    allowFutureDates?: boolean,
    maxDate?: Date,
    minDate?: Date,
    maxReasonableErrorMessage?: string | JSX.Element,
    minReasonableErrorMessage?: string | JSX.Element,
) => {
    const maximumDate = maxDate ? maxDate : getDefaultMaxReasonableDate();
    const minimumDate = minDate ? minDate : getDefaultMinReasonableDate();
    const maxDateValid = allowFutureDates ? true : currentDate && currentDate <= maximumDate;
    const minDateValid = currentDate && currentDate >= minimumDate;

    const maxErrorMessage = maxDateValid
        ? undefined
        : maxReasonableErrorMessage
        ? maxReasonableErrorMessage
        : 'Unreasonable Date';

    const minErrorMessage = minDateValid
        ? undefined
        : minReasonableErrorMessage
        ? minReasonableErrorMessage
        : 'Unreasonable Date';

    const getErrorMessage = () => {
        if (maxErrorMessage) return maxErrorMessage;
        if (minErrorMessage) return minErrorMessage;
        return undefined;
    };

    return {
        reasonable: minDateValid && maxDateValid,
        error: getErrorMessage(),
    };
};

const DateField = ({
    style,
    showGoToToday,
    isReasonable,
    hasDatePicker,
    value,
    onChange,
    allowFutureDates,
    handleError,
    maxReasonableDate: maxDate,
    minReasonableDate: minDate,
    minReasonableErrorMessage,
    maxReasonableErrorMessage,
    invalidDateErrorMessage = 'Invalid Date',
    disabled,
    ...props
}: IDateFieldProps): JSX.Element => {
    const [internalValue, setInternalValue] = useState<string>();
    const [showCalendar, { toggle: toggleShowCalendar }] = useBoolean(false);

    const calendarButtonElement = useRef<HTMLDivElement>(null);

    // On initial load we need to set the date as formatted, otherwise we get a scrambled date.
    useEffect(() => {
        if (value) {
            // if (formatDate(value) !== internalValue)
            setInternalValue(formatDate(value));
        } else if (value === undefined) {
            setInternalValue(undefined);
        } else {
            setInternalValue('');
        }
    }, [value]);

    const dateIsReasonable = (date: Date) =>
        isReasonableDate(date, allowFutureDates, maxDate, minDate, maxReasonableErrorMessage, minReasonableErrorMessage);

    const handleInvalidDateError = (date: Date): string | undefined => {
        if (isValid(date)) {
            if (handleError) handleError(undefined);
            return undefined;
        } else {
            if (handleError) handleError(invalidDateErrorMessage);
            return invalidDateErrorMessage;
        }
    };

    const onGetErrorMessage = (value?: string): string | undefined => {
        if (isValueEmpty(value)) {
            return undefined;
        }
        if (value) {
            const dateToTest = new Date(value);
            if (isReasonable) {
                if (dateIsReasonable(dateToTest).reasonable) {
                    return handleInvalidDateError(dateToTest);
                } else {
                    const error = dateIsReasonable(dateToTest).error as string;
                    if (handleError) handleError(error);
                    return error;
                }
            } else {
                return handleInvalidDateError(dateToTest);
            }
        }
    };

    const handleChange = (e?: FormEvent<HTMLTextAreaElement | HTMLInputElement>, value?: string) => {
        //This is what is called every time a user types. We assume the date value we have initially is already formatted.
        //This means we can just set the value without formatting it each time. (This avoids a invalid date error throw)
        const dateFromValue = value ? new Date(value) : undefined;
        const negotiatedFormattedDate = isValid(dateFromValue) ? formatDate(value) : value;
        if (negotiatedFormattedDate !== internalValue) setInternalValue(value);

        if (value !== undefined && onChange) {
            const newDate = new Date(value);
            if (isValid(newDate) && !onGetErrorMessage(value)) {
                onChange(e, newDate.toISOString());
            }
            if (isValueEmpty(value)) {
                onChange(e, '');
            }
        }
    };

    const calendarProps: ICalendarProps = {
        showMonthPickerAsOverlay: false,
        isDayPickerVisible: true,
        isMonthPickerVisible: true,
        showGoToToday: showGoToToday,
        highlightCurrentMonth: true,
        highlightSelectedMonth: true,
        strings: DayPickerStrings,
        value: value ? new Date(value) : undefined,
        onDismiss: toggleShowCalendar,
        minDate: isReasonable ? (minDate ? minDate : getDefaultMinReasonableDate()) : undefined,
        maxDate: isReasonable
            ? allowFutureDates
                ? getDefaultMaxReasonableDate()
                : maxDate ?? getDefaultMaxReasonableDate()
            : undefined,
        onSelectDate: (date) => {
            handleChange(undefined, date.toISOString());
            toggleShowCalendar();
        },
    };

    return (
        <MaskedTextField
            {...props}
            mask="99/99/9999"
            onChange={handleChange}
            value={internalValue}
            onGetErrorMessage={onGetErrorMessage}
            validateOnFocusOut
            validateOnLoad={!!value}
            onClick={(ev) => {
                if (!internalValue || isValueEmpty(internalValue)) ev.currentTarget.setSelectionRange(0, 0);
            }}
            style={{ ...style }}
            styles={hasDatePicker ? { suffix: { paddingRight: 0, paddingLeft: 0, background: 'transparent' } } : undefined}
            disabled={disabled}
            onRenderSuffix={
                hasDatePicker
                    ? () => (
                          <div>
                              <div ref={calendarButtonElement}>
                                  <ActionButton
                                      type="IconButton"
                                      iconProps={{ iconName: 'Calendar' }}
                                      onClick={toggleShowCalendar}
                                      style={{
                                          maxHeight: 30,
                                          display: 'flex',
                                          alignItems: 'center',
                                      }}
                                      disabled={disabled}
                                  />
                              </div>
                              {showCalendar && (
                                  <Callout
                                      isBeakVisible={false}
                                      className="ms-DatePicker-callout"
                                      gapSpace={0}
                                      doNotLayer={false}
                                      target={calendarButtonElement}
                                      directionalHint={DirectionalHint.bottomLeftEdge}
                                      onDismiss={toggleShowCalendar}
                                      setInitialFocus
                                  >
                                      <FocusTrapZone
                                          firstFocusableSelector="ms-DatePicker-day--today"
                                          isClickableOutsideFocusTrap
                                      >
                                          <Calendar {...calendarProps} />
                                      </FocusTrapZone>
                                  </Callout>
                              )}
                          </div>
                      )
                    : undefined
            }
        />
    );
};

export default DateField;
