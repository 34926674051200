import {
    IChoiceGroupProps,
    ChoiceGroup as FluentChoiceGroup,
    IStyleFunction,
    IChoiceGroupStyleProps,
    IChoiceGroupStyles,
} from '@fluentui/react';

type Props = {
    inline?: boolean;
} & IChoiceGroupProps;

const choiceGroupStyles: IStyleFunction<IChoiceGroupStyleProps, IChoiceGroupStyles> = () => ({
    root: {
        flexDirection: 'row',
        display: 'flex',
        flexWrap: 'wrap',
        alignItems: 'center',
    },
    flexContainer: {
        flexDirection: 'row',
        flex: 1,
        display: 'flex',
        flexWrap: 'wrap',
        selectors: {
            '.ms-ChoiceField': {
                marginRight: 20,
            },
        },
    },
    label: { marginRight: 20, marginTop: 6, padding: 0 },
});

function ChoiceGroupField({ inline = false, ...props }: Props): JSX.Element {
    return <FluentChoiceGroup {...props} styles={inline ? choiceGroupStyles : {}} />;
}

export default ChoiceGroupField;
