import { createAsyncThunk } from '@reduxjs/toolkit';
import { IAppointmentCancellationReasons } from './model';
import appointmentCancellationReasonsApi from './api';
import { map } from 'lodash';
import { CreateAsyncThunkOptions } from 'state/store';

interface AppointmentCancellationReasonsRequest {
    tenantId: string;
}

export const getAppointmentCancellationsReasons = createAsyncThunk<
    IAppointmentCancellationReasons,
    AppointmentCancellationReasonsRequest,
    CreateAsyncThunkOptions
>('appointmentCancellationReasons/getAll', async ({ tenantId }, { rejectWithValue }) => {
    try {
        const request = await appointmentCancellationReasonsApi.getAll(tenantId);
        return request.data;
    } catch (error: unknown) {
        return rejectWithValue(JSON.stringify(error));
    }
});

export const refreshAppointmentCancellationsReasons = createAsyncThunk<
    IAppointmentCancellationReasons,
    AppointmentCancellationReasonsRequest,
    CreateAsyncThunkOptions
>('appointmentCancelationReasons/refresh', async ({ tenantId }, { rejectWithValue }) => {
    try {
        const request = await appointmentCancellationReasonsApi.refresh(tenantId);
        const filteredRequest = map(request.data).filter((item) => !item?.isDeleted);

        const filteredResult = Object.assign({ ...filteredRequest });
        return filteredResult;
    } catch (error: unknown) {
        return rejectWithValue(JSON.stringify(error));
    }
});
