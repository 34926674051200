import { Stack } from '@fluentui/react';
import { Section } from 'components';
import { useDispatch, useSelector } from 'react-redux';
import { selectUserTasksByTarget } from 'state/slices/tasks/selectors';
import { TaskCard } from './TaskCard';
import useTenantId from 'hooks/useTenantId';
import { useEffect } from 'react';
import { getUserTasksByTarget } from 'state/slices/tasks/actions';
import { UserTaskTarget } from 'state/slices/tasks/model';

export default function TaskBeacon() {
    const dispatch = useDispatch();
    const tenantId = useTenantId();

    const tasks = useSelector(selectUserTasksByTarget);

    useEffect(() => {
        dispatch(getUserTasksByTarget({ tenantId, target: UserTaskTarget.TenantSetup }));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch]);

    if (!tasks.length) return null;

    const taskCards = tasks.filter((t) => !t.type?.includes('refreshing')).map((t) => <TaskCard key={t.id} {...t} />);

    return (
        <Section heading="Beacon">
            <Stack horizontal style={{ padding: 10 }} tokens={{ childrenGap: 10 }} wrap>
                {taskCards}
            </Stack>
        </Section>
    );
}
