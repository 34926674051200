import {
    DefaultButton,
    PrimaryButton,
    ScrollablePane,
    ScrollbarVisibility,
    Spinner,
    Stack,
    TextField,
    Toggle,
} from '@fluentui/react';
import { useBoolean } from '@uifabric/react-hooks';
import { CombinedPayerAndPayerDetail } from 'api/models/payer.model';
import { Section } from 'components';
import { useSelector } from 'hooks';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { upsertPayerDetail, getPayerAndDetail } from 'state/slices/payers/payers.actions';
import { selectLoading, selectPayerDetailSaving, selectSelectedPayer } from 'state/slices/payers/payers.selectors';
import { setSelectedPayer } from 'state/slices/payers/payers.slice';
import AddFeeSchedulePanel from './AddFeeSchedulePanel';
import FeeSchedules from './FeeSchedules';

function ManagePayer() {
    const dispatch = useDispatch();
    const { tenantId, payerId } = useParams<{ tenantId: string; payerId: string }>();
    const { push } = useHistory();

    const selectedPayer = useSelector(selectSelectedPayer);
    const loading = useSelector(selectLoading);
    const saving = useSelector(selectPayerDetailSaving);

    const [isEditorOpen, { setTrue: setEditorOpen, setFalse: setEditorClosed }] = useBoolean(false);

    useEffect(() => {
        dispatch(getPayerAndDetail({ tenantId, payerId }));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [payerId, tenantId]);

    const _onSave = () => {
        dispatch(upsertPayerDetail(tenantId));
    };

    const _onReturnToPayers = () => {
        push(`/${tenantId}/payers`);
        dispatch(setSelectedPayer(undefined));
    };

    const _onPropChange = (path: keyof CombinedPayerAndPayerDetail, value?: string | boolean) => {
        if (selectedPayer) {
            dispatch(
                setSelectedPayer({
                    ...selectedPayer,
                    [path]: value,
                }),
            );
        }
    };

    if (loading) return <Spinner label="Loading Payer..." />;
    return (
        <Section
            heading={selectedPayer?.name}
            headingRightContent={
                <Stack horizontal tokens={{ childrenGap: 12 }}>
                    <DefaultButton text={'Payers'} iconProps={{ iconName: 'ChromeBack' }} onClick={_onReturnToPayers} />
                    <PrimaryButton
                        disabled={saving}
                        text={saving ? 'Saving' : 'Save'}
                        iconProps={{ iconName: 'SaveAndClose' }}
                        onClick={_onSave}
                    />
                </Stack>
            }
            style={{ marginBottom: 0 }}
            styleContent={{ display: 'flex', flex: 'auto' }}
            grow
            footer={
                <Stack horizontalAlign="end" grow>
                    <DefaultButton text={'Payers'} iconProps={{ iconName: 'ChromeBack' }} onClick={_onReturnToPayers} />
                </Stack>
            }
        >
            <div style={{ position: 'relative', display: 'flex', flex: 1 }}>
                <ScrollablePane scrollbarVisibility={ScrollbarVisibility.auto}>
                    <Stack tokens={{ childrenGap: 10 }} grow>
                        <Stack tokens={{ childrenGap: 12 }} horizontal grow>
                            <TextField
                                label="Display Name"
                                value={selectedPayer?.displayName ? selectedPayer?.displayName : selectedPayer?.name}
                                onChange={(ev, value) => {
                                    _onPropChange('displayName', value);
                                }}
                            />
                            <Toggle
                                label="Is quickpick"
                                onText="Yes"
                                offText="No"
                                checked={selectedPayer?.isQuickPick ?? false}
                                onChange={(ev, value) => _onPropChange('isQuickPick', value)}
                            />
                            <Toggle
                                label={'Disabled'}
                                checked={selectedPayer?.isDeleted ?? false}
                                onChange={(ev, value) => _onPropChange('isDeleted', value)}
                            />
                        </Stack>
                        <Stack grow={1}>
                            <FeeSchedules selectedPayer={selectedPayer} openPanel={setEditorOpen} />
                        </Stack>
                    </Stack>
                </ScrollablePane>
            </div>
            <AddFeeSchedulePanel isOpen={isEditorOpen} closePanel={setEditorClosed} />
        </Section>
    );
}

export default ManagePayer;
